import { styled } from "styled-components";
import { useState } from "react";
import { serverLine } from "../../controllers/serverLine.js";
import onChangeStop from "../../controllers/onChangeStop.js";
import { CustomLexicalEditor } from "../richTextEditor/CustomLexicalEditor.js";
import getPlusButtonPosForSmallEditor from "../../controllers/lexical/getPlusButtonPosForSmallEditor.js";
import LoadingSection from "../helperComponents/LoadingSection.js";
import lexicalObjToString from "../../controllers/lexical/lexicalObjToString.js";
import CustomButton from "../helperComponents/CustomButton.js";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 50px 0;
  gap: 25px;
  align-items: center;
`;

const Inputs = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  width: 100%;
  gap: 50px;
  justify-content: center;

  @media (max-width: 900px) {
    width: 90vw;
  }
`;

const Button = styled.div`
  width: 100%;
  font-size: 18px;
  border: 1px solid var(--translucent);
  background: var(--translucent);
  border-radius: 15px;
  color: var(--color);
  text-align: center;
  cursor: pointer;
  padding: 20px 15px;
`;

const Textarea = styled.textarea`
  font-size: 18px;
  border: none;
  border-bottom: 1px solid var(--translucentHard);
  background: transparent;
  border-radius: 0;
  color: var(--color);
  /* text-align: center; */
  width: 100%;
  font-family: "Inter";
  resize: none;

  padding: 15px 0;

  &::placeholder {
    color: var(--translucentHard);
  }
`;

const Text = styled.div`
  text-align: center;
  width: 100%;
  opacity: 0.7;
  font-size: 18px;
`;

const Main = styled.div`
  padding: 15px;

  width: 100%;
  background-color: var(--translucent);
  border-radius: 10px;
`;

export default function LifeStoryEditor() {
  const [loading, setLoading] = useState(true);
  const [value, setValue] = useState(null);

  useState(() => {
    loadLifeStory();
  }, []);

  if (loading) return <LoadingSection />;

  return (
    <Inputs>
      <Text>
        Write your story from childhood to now because people remember stories
        and it helps in differentiating from others. Also, the way you tell a
        story says a lot about you.
      </Text>

      <Main>
        <CustomLexicalEditor
          initialEditorState={value}
          addButtonPosX={getPlusButtonPosForSmallEditor()}
          onEditorChange={onEditorChange}
        />
      </Main>

      <CustomButton onClick={save} width="100px">
        Save
      </CustomButton>
    </Inputs>
  );

  async function loadLifeStory() {
    let newData = await serverLine.get("/life-story");
    if (newData) {
      if (newData.content) setValue(newData.content);
    }
    setLoading(false);
  }

  async function save() {
    let lifeStoryStr = lexicalObjToString(value);
    if (!lifeStoryStr) return;
    if (!lifeStoryStr.trim()) return;
    if (lifeStoryStr.split(" ").length < 30)
      return window.doAlert("Story should be of at least 30 words");

    setLoading(true);
    await serverLine.patch("/life-story", {
      content: value,
    });
    window.doAlert("Story Saved");
    setLoading(false);
  }

  function onEditorChange(newState) {
    setValue(newState);
  }
}
