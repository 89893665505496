import { styled } from "styled-components";
import { useState } from "react";
import { CustomLexicalEditor } from "../../richTextEditor/CustomLexicalEditor.js";
import getSubdomain from "../../../controllers/getSubdomain.js";
import { serverLine } from "../../../controllers/serverLine.js";
import WithProfileHeader from "../../profilePage/WithProfileHeader.js";
import ContentActionButtons from "../../contentPage/ContentActionButtons.js";
import LoadingSection from "../../helperComponents/LoadingSection.js";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 50px 0;
  gap: 25px;
  align-items: center;
`;

const Main = styled.div`
  display: flex;
  flex-direction: column;
  /* align-items: center; */

  background-color: var(--translucent);
  padding: 50px;
  margin-top: 100px;
  padding-top: 50px;
  width: 38vw;
  border-radius: 10px;
  gap: 50px;
  justify-content: center;

  @media (max-width: 900px) {
    width: 90vw;
  }
`;

const TheStory = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const Text = styled.h1`
  /* text-align: center; */
  width: 100%;
  opacity: 0.7;
  /* font-size: 18px; */
`;

export default function RenderLifeStory() {
  const [loading, setLoading] = useState(true);
  const [value, setValue] = useState(null);

  useState(() => {
    loadLifeStory();
  }, []);

  if (loading) return <LoadingSection />;

  return (
    <WithProfileHeader userData={value.author}>
      <Main>
        <TheStory>
          <Text>My Story</Text>
          <CustomLexicalEditor
            readOnly={true}
            initialEditorState={value.content}
            onEditorChange={console.log}
          />
        </TheStory>
        <ContentActionButtons setContent={setValue} content={value} />
      </Main>
    </WithProfileHeader>
  );

  async function loadLifeStory() {
    let subdomain = getSubdomain();
    let newData = await serverLine.get("/life-story/?username=" + subdomain);

    if (newData) {
      setValue(newData);
    }
    setLoading(false);
  }
}
