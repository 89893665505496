import { AiOutlineYoutube } from "react-icons/ai";
import { BiLogoUpwork } from "react-icons/bi";
import { BsInstagram, BsTiktok, BsTwitterX } from "react-icons/bs";
import { GrGithub } from "react-icons/gr";
import { LiaArtstation } from "react-icons/lia";
import { PiMediumLogoFill } from "react-icons/pi";
import { RiLinkedinLine, RiUnsplashFill } from "react-icons/ri";
import { TbBrandFiverr } from "react-icons/tb";

let socialMediaLinkTypes = [
  {
    label: "Github",
    value: "GITHUB",
    icon: <GrGithub />,
    baseLink: "https://github.com",
  },
  {
    label: "Upwork",
    value: "UPWORK",
    icon: <BiLogoUpwork />,
    baseLink: "https://upwork.com/freelancers",
  },
  {
    label: "Fiverr",
    value: "FIVERR",
    icon: <TbBrandFiverr />,
    baseLink: "https://fiverr.com",
  },
  {
    label: "Instagram",
    value: "INSTAGRAM",
    icon: <BsInstagram />,
    baseLink: "https://instagram.com",
  },
  {
    label: "Youtube",
    value: "YOUTUBE",
    icon: <AiOutlineYoutube />,
    baseLink: "https://youtube.com",
  },
  {
    label: "Facebook",
    value: "FACEBOOK",
    icon: <BsInstagram />,
    baseLink: "https://facebook.com",
  },
  {
    label: "Twitter",
    value: "TWITTER",
    icon: <BsTwitterX />,
    baseLink: "https://twitter.com",
  },
  {
    label: "Medium",
    value: "MEDIUM",
    icon: <PiMediumLogoFill />,
    baseLink: "https://medium.com",
  },
  {
    label: "Unsplash",
    value: "UNSPLASH",
    icon: <RiUnsplashFill />,
    baseLink: "https://unsplash.com",
  },
  {
    label: "Linkedin",
    value: "LINKEDIN",
    icon: <RiLinkedinLine />,
    baseLink: "https://linkedin.com/in",
  },
  {
    label: "Artstation",
    value: "ARTSTATION",
    icon: <LiaArtstation />,
    baseLink: "https://artstation.com",
  },
  {
    label: "Tik Tok",
    value: "TIKTOK",
    icon: <BsTiktok />,
    baseLink: "https://tiktok.com",
  },
];

let socialTypeVsData = {};

for (let item of socialMediaLinkTypes) {
  socialTypeVsData[item.value] = item;
}

export { socialMediaLinkTypes, socialTypeVsData };
