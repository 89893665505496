import styled from "styled-components";
import WithProfileHeader from "../../WithProfileHeader";
import ProfileSections from "../../ProfileSections";
import BackToHome from "../../../helperComponents/BackToHome";
import WithProfileHeaderOneColumn from "../../WithProfileHeaderOneColumn";
import VerticalOneHeader from "./VerticalOneHeader";
import VerticalTheme from "../VerticalTheme";

const MainContent = styled.div`
  padding: 60px 0px;
  /* padding-top: 150px; */
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 100px;

  @media (max-width: 900px) {
    padding: 5vw;
  }
`;

export default function VerticalOneTheme({ profileData, contentPageData }) {
  return (
    <VerticalTheme
      profileData={profileData}
      contentPageData={contentPageData}
      Header={VerticalOneHeader}
    />
  );
}
