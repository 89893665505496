import { styled } from "styled-components";
import CustomToggle from "../helperComponents/CustomToggle";
import sectionAndVariations from "../../data/sectionAndVariations";
import { useContext } from "react";
import Context from "../../Context";
import sendPostMessage from "../../controllers/sendPostMessage";
import { serverLine } from "../../controllers/serverLine";
import SectionAndLabel from "../SectionAndLabel";
import contentTypeVsData from "../../data/contentTypeVsData";
import sectionVariationOptions from "../../data/sectionVariationOptions";

let supportedTypes = [
  "ARTICLE",
  "SALEABLE",
  "SERVICE",
  "PROJECT_PROGRAMMING",
  "PROJECT_ART",
  "PROJECT_GRAPHIC_DESIGN",
  "PROJECT_PHOTOGRAPHY",
];

export default function SectionVariationSelector({ sectionType }) {
  const { profileMetaData, setProfileMetaData, popupAlert } =
    useContext(Context);

  if (!profileMetaData) return null;

  let contentTypeData = contentTypeVsData[sectionType];

  if (!supportedTypes.includes(sectionType)) return null;

  return (
    <SectionAndLabel label={"Styling"}>
      <CustomToggle
        value={getCurrentValue()}
        options={sectionVariationOptions}
        onChange={setVariation}
      />
    </SectionAndLabel>
  );

  function getCurrentValue() {
    let existingVariation = {};

    if (profileMetaData.sectionVariation) {
      existingVariation = profileMetaData.sectionVariation;
    }

    if (existingVariation[sectionType]) return existingVariation[sectionType];
    return contentTypeData.defaultVariation;
  }

  async function setVariation(variationType) {
    let toSend = {
      changes: {},
    };

    let existingVariation = {};

    if (profileMetaData.sectionVariation) {
      existingVariation = profileMetaData.sectionVariation;
    }

    existingVariation[sectionType] = variationType;

    toSend.changes.sectionVariation = existingVariation;

    console.log(toSend, variationType, sectionType);

    try {
      let newMeta = {
        ...profileMetaData,
        ...toSend.changes,
      };
      sendPostMessage({ profileMetaData: { draftState: newMeta } });

      setProfileMetaData(newMeta);
      console.log("NEW META", newMeta);

      await serverLine.patch("/my-profile-meta", toSend);
    } catch (e) {
      popupAlert(e.message);
    }
  }
}
