import styled from "styled-components";
import DragAndDropItems from "./editors/DragAndDropItems";

const Container = styled.div``;

export default function PageItemsArranger({ makeChange, data }) {
  return (
    <Container>
      <DragAndDropItems
        data={data}
        type="PAGE_SECTIONS"
        onChange={makeChange("sections", { isNotEvent: true })}
      />
    </Container>
  );
}
