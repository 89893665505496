import lexicalObjToString from "../lexical/lexicalObjToString";

export default function checkRequiredFields({ state, type }) {
  return false;
  let theState = state;
  if (type === "PROJECT_PROGRAMMING") {
    // if (!theState.draftState.title) return `Title is required`;
    // if (!theState.draftState.tagline) return `Tagline is required`;
    // if (!theState.draftState.description) return `Description is required`;
    // if (!theState.draftState.images) return `Images is required`;
    // if (!theState.draftState.images.length) return `Images are is required`;
  }

  if (type === "PROJECT_ART") {
    // if (!theState.draftState.title) return `Title is required`;
    // if (!theState.draftState.description) return `Description is required`;
    // if (!theState.draftState.images) return `Images is required`;
    // if (!theState.draftState.images.length) return `Images are is required`;
  }

  if (type === "VIDEO") {
    if (!theState.draftState.title) return `Title is required`;
    if (!theState.draftState.description) return `Description is required`;

    if (!theState.draftState.videoLink) return `Video link is required`;

    let vidLink = theState.draftState.videoLink;

    if (
      vidLink.indexOf("youtube.com") === -1 &&
      vidLink.indexOf("vimeo.com") === -1
    )
      return `We only support youtube and vimeo at the time`;
  }

  if (type === "LITERATURE") {
    let theString = lexicalObjToString(theState.content.richText);
    if (!theString) return `Please type something`;
    if (!theString.trim()) return `Please type something`;
  }

  return false;
}
