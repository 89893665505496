import { styled } from "styled-components";
import ShowSelectedImage from "../helperComponents/ShowSelectedImage";
import getImageURL from "../../controllers/getImageURL";
import selectFile from "../../controllers/selectFile";
import { nanoid } from "nanoid";
import { RiCloseLine } from "react-icons/ri";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import ProjectImagesDND from "./ProjectImagesDND";
import React, { useRef } from "react";
// import useDrag and useDrop hooks from react-dnd
import { useDrag, useDrop } from "react-dnd";
import compressAndUploadFile from "../../controllers/compressAndUploadFile";

const type = "Image";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const Image = styled.img`
  border-radius: 10px;
  object-fit: cover;
  width: 100%;
  ${({ height }) => {
    if (height)
      return `
      height:${height};
`;
  }}
  ${({ width }) => {
    if (width)
      return `
      height:${width};
  `;
  }}
`;

const ImageItemContainer = styled.div`
  position: relative;
  /* width: 100%; */
  width: 130px;
`;

const Symbol = styled.div`
  display: flex;
  position: absolute;
  right: 10px;
  bottom: 10px;
  background-color: rgba(0, 0, 0, 0.5);
  padding: 10px;
  backdrop-filter: blur(25px);
  border-radius: 10px;
  color: var(--color);
  font-size: 15px;
`;

const DeleteButton = styled.div`
  display: flex;
  position: absolute;
  right: -10px;
  top: -10px;
  background-color: var(--color);
  padding: 5px;
  backdrop-filter: blur(25px);
  border-radius: 100px;
  color: var(--bgColor);
  cursor: pointer;
  font-size: 18px;
  transition: 0.15s ease-in-out;

  &:hover {
    transform: scale(1.5);
  }
`;

let size = "130px";

export default function ImagesEditorWithDND({
  images = [],
  setImages,
  setLoading,
}) {
  return (
    <Container>
      <DndProvider backend={HTML5Backend}>
        <ProjectImagesDND
          size={size}
          renderList={renderItems()}
          images={images}
          selectImage={selectImage}
        />
      </DndProvider>
    </Container>
  );

  function moveItem(from, to) {
    console.log(from, to);
    let newImages = [...images];
    let fromItem = newImages[from];

    newImages.splice(from, 1);

    newImages.splice(to, 0, fromItem);

    setImages(removeNuls(newImages));
  }

  async function selectImage() {
    let selectedImages = await selectFile({
      onlyImage: true,
      allowMultipleFiles: true,
    });

    let newImages = [...images];

    setLoading(true);
    for (let item of selectedImages) {
      let fileData = await compressAndUploadFile(null, item);
      newImages.push({ type: "USER_UPLOAD", data: fileData.fileName });
    }

    setLoading(false);

    setImages(removeNuls(newImages));
  }

  function removeNuls(theList) {
    let newList = [];

    for (let item of theList) {
      if (item) newList.push(item);
    }

    return newList;
  }

  function deleteImage(index) {
    return () => {
      let newImages = [...images];
      newImages.splice(index, 1);
      setImages(removeNuls(newImages));
    };
  }

  function renderItems() {
    let items = [];

    for (let i = 0; i < images.length; i++) {
      let img = images[i];
      if (!img) continue;
      items.push(
        <ImageItem
          key={i}
          moveImage={moveItem}
          index={i}
          onDelete={deleteImage(i)}
          src={img}
        />
      );
    }

    return items;
  }
}

function ImageItem({ src, onDelete, index, moveImage }) {
  const ref = useRef(null); // Initialize the reference

  const [, drop] = useDrop({
    accept: type,

    drop(item) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;

      const hoverIndex = index;

      if (dragIndex === hoverIndex) {
        return;
      }

      moveImage(dragIndex, hoverIndex);
      //   item.index = hoverIndex;
    },
  });

  const [{ isDragging }, drag] = useDrag(() => ({
    type: type,

    item: { id: src.data, index },

    collect: (monitor) => {
      return {
        isDragging: monitor.isDragging(),
      };
    },
  }));

  drag(drop(ref));

  return (
    <ImageItemContainer ref={ref}>
      <Image height={size} width={size} src={getImageURL(src.data)} />

      <DeleteButton onClick={onDelete}>
        <RiCloseLine />
      </DeleteButton>

      {src.type === "NOT_UPLOADED" ? <Symbol>New</Symbol> : null}
    </ImageItemContainer>
  );
}
