let sectionAndVariations = {
  PROJECT_PROGRAMMING: {
    default: "ONE",
    variations: [
      { value: "ONE", label: "Simple" },
      { value: "TWO", label: "Simple 2" },
      { value: "THREE", label: "Sleek" },
      { value: "FOUR", label: "Big" },
    ],
  },
  ARTICLE: {
    default: "ONE",
    variations: [
      { value: "ONE", label: "List" },
      { value: "TWO", label: "List 2" },
      { value: "THREE", label: "V Card" },
      { value: "FOUR", label: "H Card" },
      { value: "FIVE", label: "Sleek" },
    ],
  },
  LIST: {
    default: "ONE",
    variations: [
      { value: "ONE", label: "List" },
      { value: "TWO", label: "Detailed List" },
      { value: "THREE", label: "Card" },
    ],
  },
};

export default sectionAndVariations;
