import styled from "styled-components";
import getImageURL from "../../controllers/getImageURL.js";
import getStaticImageURL from "../../controllers/getStaticImageURL";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
  align-items: center;
`;

const List = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  width: 100%;
`;

const SubTitle = styled.h3`
  text-transform: capitalize;
  margin: 0;
`;

const Hr = styled.div`
  height: 1px;
  width: 100%;
  margin: 0px 0;
  background: var(--translucentHard);
`;

const ProfileImage = styled.img`
  height: 100px;
  width: 100px;
  object-fit: cover;
  border-radius: 500px;

  ${({ mobileStyle }) => {
    return mobileStyle;
  }}
`;

export default function ProfilePicture({
  size,
  imageObj,
  height,
  width,
  style,
  mobileStyle,
}) {
  if (!style) style = {};

  if (size) {
    style = { ...style, height: size, width: size };
  }

  if (height && width) {
    style = { ...style, height: height, width: width };
  }

  return imageObj ? (
    <ProfileImage
      mobileStyle={mobileStyle}
      style={style}
      src={getImageURL(imageObj, true)}
    />
  ) : (
    <ProfileImage
      mobileStyle={mobileStyle}
      style={style}
      src={getStaticImageURL("default/default-profile4.jpeg")}
    />
  );
}
