import { useEffect } from "react";
import { serverLine } from "../../controllers/serverLine";
import { nanoid } from "nanoid";

export default function RegisterView({ subjectID, subjectType }) {
  useEffect(() => {
    let localStorageVariableName = "inwire-external-clientID";

    let externalClientID = localStorage.getItem(localStorageVariableName);

    if (!externalClientID) {
      externalClientID = nanoid();
      localStorage.setItem(localStorageVariableName, externalClientID);
    }

    if (!window.viewDuration) window.viewDuration = {};
    if (!window.viewIntervals) window.viewIntervals = {};

    window.viewDuration[subjectID] = 0;

    window.setTimeout(() => {
      window.viewDuration[subjectID] += 5;
      makeReq(externalClientID);
    }, 1000 * 5);

    //We don't need the duration feature, also it will increase the load on the server

    // let theInterval = window.setInterval(() => {
    //   window.viewDuration[subjectID] += 30;
    //   makeReq(externalClientID);
    // }, 1000 * 30);

    // return () => {
    //   clearInterval(theInterval);
    // };
  }, []);

  function makeReq(externalClientID) {
    serverLine.post("/view", {
      subjectID,
      subjectType,
      duration: window.viewDuration[subjectID],
      externalClientID: externalClientID,
    });
  }

  return null;
}
