import { Rating } from "@mui/material";
import styled from "styled-components";

const Container = styled.div`
  width: 100%;
  border: 1px solid var(--translucentHard);
  padding: 25px;
  display: flex;
  border-radius: 5px;
  flex-direction: column;
  border-left: 1px solid var(--color);
  gap: 25px;

  @media (max-width: 900px) {
    gap: 10px;
    padding: 15px;
  }
`;

const ProjectTagline = styled.div`
  font-size: 20px;
  font-weight: 900;

  @media (max-width: 900px) {
    font-size: 15px;
  }
`;

const ProjectDescription = styled.div`
  font-size: 21px;
  line-height: 30px;

  @media (max-width: 900px) {
    line-height: 20px;
    font-size: 15px;
  }
`;

export default function ReviewCard({ item }) {
  if (!item.reviewOfWorker) return null;
  if (!item.ratingOfWorker) return null;

  return (
    <Container>
      <Rating value={item.ratingOfWorker} readOnly={true} />

      <ProjectDescription>{item.reviewOfWorker}</ProjectDescription>

      <ProjectTagline>{item.client.name}</ProjectTagline>
    </Container>
  );
}
