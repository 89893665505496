import styled from "styled-components";

const Container = styled.div`
  display: flex;
  padding: 20px 0;
  flex-direction: column;

  cursor: pointer;
  border-bottom: 3px solid var(--translucent);

  &:hover {
    border-bottom: 3px solid var(--translucentHard);
  }
`;
const Title = styled.div`
  white-space: nowrap;
  font-size: 24px;
  font-weight: 900;
  max-width: 30vw;
  overflow: hidden;
`;

const Subtitle = styled.div``;

export default function ReportBox({ item }) {
  let { _id, title } = item;

  return (
    <Container>
      <Title>{item.reason}</Title>
      <Subtitle>{item.description}</Subtitle>
    </Container>
  );
}
