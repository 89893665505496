import { styled } from "styled-components";
import { serverLine } from "../../../controllers/serverLine";
import { useEffect, useState } from "react";
import getSubPath from "../../../controllers/getSubPath";
import CustomButton from "../../helperComponents/CustomButton";
import ReportBox from "../../report/ReportBox";
import LoadingSection from "../../helperComponents/LoadingSection";
import WithHeader from "../WithHeader";
import goTo from "../../../controllers/goTo";

const Container = styled.div`
  width: 62vw;
  margin-top: 100px;
  display: flex;
  flex-direction: column;
  gap: 50px;

  @media (max-width: 900px) {
    width: 100%;
  }
`;
const Title = styled.h1``;
const Section = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const TableHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  padding-bottom: 20px;
  border-bottom: 1px solid var(--translucentHard);
`;
const SubHeader = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;
`;

const MainTableHeading = styled.div`
  width: 200px;
  opacity: 0.5;
  font-size: 18px;
`;

const ColHeading = styled.div`
  width: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0.5;
  font-size: 18px;
`;

const List = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const Top = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const Buttons = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;
`;

export default function ReportsPage() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  let subjectID = getSubPath(1);

  useEffect(() => {
    serverLine.get("/reports/?subjectID=" + subjectID).then((newData) => {
      setData(newData);
      setLoading(false);
    });
  }, []);

  if (loading) return <LoadingSection />;

  if (!data.reportItem) return "ReportItem Not Found";

  return (
    <WithHeader>
      <Container>
        <Top>
          <Title> {data.reportItem.title} </Title>
          <Buttons>
            <CustomButton onClick={banPost}>
              {data.reportItem.banned ? "Un-Ban Post" : "Ban Post"}
            </CustomButton>
            <CustomButton onClick={ignoreReports}>Ignore Reports</CustomButton>
            <CustomButton
              semanticHref={"/content/" + data.reportItem.subjectID}
              onClick={goTo("/content/" + data.reportItem.subjectID)}
            >
              Visit Content
            </CustomButton>
          </Buttons>
        </Top>

        <Section>
          <List>{renderContent()}</List>
        </Section>
      </Container>
    </WithHeader>
  );

  async function banPost() {
    setLoading(true);
    await serverLine.post("/ban", {
      subjectID,
      action: data.reportItem.banned ? "UN_BAN" : "BAN",
    });
    setLoading(false);

    if (data.reportItem.banned) {
      window.doAlert("Content Un Banned");
    } else {
      window.doAlert("Content Banned");
    }

    let newData = { ...data };

    newData.reportItem.banned = newData.reportItem.banned ? false : true;

    setData(newData);
  }

  async function ignoreReports() {
    setLoading(true);
    await serverLine.post("/ignore-reports", { subjectID });
    setLoading(false);

    window.doAlert("Done");
  }

  function renderContent() {
    let toReturn = [];

    for (let item of data.reports) {
      toReturn.push(<ReportBox key={item._id} item={item} />);
    }

    return toReturn;
  }
}
