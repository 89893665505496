import CustomButton from "../helperComponents/CustomButton";
import { styled } from "styled-components";
import goTo from "../../controllers/goTo";
import { serverLine } from "../../controllers/serverLine";
import { useContext, useEffect, useRef } from "react";
import Context from "../../Context";
import RenderContent from "../contentPage/RenderContent";
import { useSearchParams } from "react-router-dom";

import getSubPath from "../../controllers/getSubPath";
import { FiArrowLeft } from "react-icons/fi";
import { CgClose } from "react-icons/cg";
import LoadingSection from "../helperComponents/LoadingSection";

const Container = styled.div`
  position: fixed;
  height: 100vh;
  width: 100vw;
  z-index: 500;
  top: 0;
  left: 0;
`;

const Background = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  /* background-color: rgba(20, 20, 20, 0.1); */
`;

const Main = styled.div`
  position: absolute;
  width: 80vw;
  left: 10vw;
  top: 0;
  overflow-y: hidden;
  height: 100vh;
  display: flex;
  flex-direction: column;

  @media (max-width: 900px) {
    width: 100vw;
    flex-direction: column-reverse;
    left: 0vw;
  }
`;

const MainCore = styled.div`
  width: 100%;
  border-radius: 10px;
  overflow-y: auto;
  height: calc(100vh - 90px);
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 25px;
  background: var(--translucentInteraction);

  border: 0.1px solid var(--translucentHard);

  /* backdrop-filter: blur(100px); */

  @media (max-width: 900px) {
    height: calc(100vh - 70px);
  }
`;

const Buttons = styled.div`
  width: 100%;
  display: flex;
  padding: 20px 0;
  /* position: fixed; */
  /* left: 10vw; */
  /* top: 20px; */
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 900px) {
    padding: 20px;
    /* display: none; */
  }
`;
const Padding = styled.div`
  padding: 20px;
`;

export default function PopupFeedItem({ type }) {
  const { popupFeedItem, setPopupFeedItem, nonPopupRoute } =
    useContext(Context);
  const [searchParams, setSearchParams] = useSearchParams();

  const ref = useRef(null);

  let contentID = getSubPath(1);

  useEffect(() => {
    if (ref) {
      if (ref.current) {
        ref.current.scrollTop = 0; // For Safari
        // document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
      }
    }
  }, [contentID]);

  useEffect(() => {
    if (popupFeedItem && contentID) {
      if (popupFeedItem._id !== contentID) {
        setPopupFeedItem(null);
        load();
      }
    }

    if (contentID && !popupFeedItem && type === "POST") {
      load();
    }
  }, [contentID]);

  let btnHeight = "30px";
  let btnWidth = "50px";

  let buttons = (
    <Buttons>
      <CustomButton
        // style={{ fontSize: "25px", opacity: 0.5 }}
        variant="outlined"
        height={btnHeight}
        width={btnWidth}
        icon={<FiArrowLeft />}
        onClick={goBack}
      >
        {/* Back */}
      </CustomButton>

      <CustomButton
        // style={{ fontSize: "25px", opacity: 0.5 }}
        variant="outlined"
        height={btnHeight}
        width={btnWidth}
        size="50px"
        icon={<CgClose />}
        onClick={closePopup}
      ></CustomButton>
    </Buttons>
  );

  let mainContent = null;

  mainContent = popupFeedItem ? (
    <RenderContent setPostData={setPopupFeedItem} postData={popupFeedItem} />
  ) : (
    <LoadingSection />
  );

  return (
    <Container>
      <Background onClick={goBack} />
      <Main>
        {buttons}
        <MainCore ref={ref}>
          <br />
          {mainContent}
          <br /> <br /> <br />
        </MainCore>
      </Main>
    </Container>
  );

  function load() {
    if (contentID)
      serverLine.get("/content/?contentID=" + contentID).then(setPopupFeedItem);
  }

  function goBack() {
    goTo(-1)();
  }

  function closePopup() {
    goTo(nonPopupRoute)();
  }
}
