import styled from "styled-components";
import HorizontalBarGraph from "../../stat/HorizontalBarGraph";
import { useContext, useEffect, useState } from "react";

import { serverLine } from "../../../controllers/serverLine";
import hozStatDataProcessor from "../../../controllers/stat/hozStatDataProcessor";
import CustomButton from "../../helperComponents/CustomButton";
import goTo from "../../../controllers/goTo";
import Context from "../../../Context";
import getSubPath from "../../../controllers/getSubPath";
import LoadingSection from "../../helperComponents/LoadingSection";
import WithHeader from "../WithHeader";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 100px;
  padding-top: 100px;
  height: 100%;
  overflow-y: scroll;
  padding: 100px 200px;
  width: 100%;
`;

const Main = styled.div`
  display: flex;
  flex-direction: column;
  gap: 50px;

  width: 100%;
`;

const Title = styled.h1``;
const Section = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const TableHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-bottom: 20px;
  border-bottom: 1px solid var(--translucentHard);
`;
const SubHeader = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;
`;

const MainTableHeading = styled.div`
  width: 200px;

  font-size: 18px;
`;

const ColHeading = styled.div`
  width: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
`;

const List = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const TopRow = styled.div`
  display: flex;
  flex-direction: column;
  /* justify-content: space-between; */
  gap: 50px;
`;

const Buttons = styled.div`
  display: flex;
  flex-direction: row;
  gap: 30px;
`;

export default function ContentStatPage() {
  const { nonPopupRoute } = useContext(Context);
  const [data, setData] = useState(null);

  let contentID = getSubPath(1, nonPopupRoute);

  useEffect(() => {
    serverLine.get("/stat/?type=POST&contentID=" + contentID).then(setData);
  }, []);

  if (!data)
    return (
      <WithHeader>
        <LoadingSection />
      </WithHeader>
    );

  let fields = ["postViewCount", "externalPostViewCount"];

  return (
    <WithHeader>
      <Container>
        <Main>
          {/* <TopRow> */}
          <Title>{data.post.title}</Title>
          {/* </TopRow> */}

          <HorizontalBarGraph
            fields={fields}
            title="Content Views Stat"
            dataProcessor={hozStatDataProcessor}
            rawData={data.stat}
          />

          <br />
          <br />

          <Buttons>
            <CustomButton
              variant="outlined"
              onClick={goTo("/content/" + contentID)}
            >
              Open Content
            </CustomButton>

            <CustomButton variant="outlined" onClick={goTo("/stat/")}>
              Goto Stat Home
            </CustomButton>
          </Buttons>
        </Main>
      </Container>
    </WithHeader>
  );
}
