import { styled } from "styled-components";
import { useContext, useEffect, useRef, useState } from "react";

import selectFile from "../../controllers/selectFile.js";
import compressAndUploadFile from "../../controllers/compressAndUploadFile.js";
import { serverLine } from "../../controllers/serverLine.js";

import Context from "../../Context.js";

import { AiOutlineCheck, AiOutlineUpload } from "react-icons/ai/index.js";
import CustomToggle from "../helperComponents/CustomToggle.js";
import ProfilePicture from "../helperComponents/ProfilePicture.js";
import ShowSelectedImage from "../helperComponents/ShowSelectedImage.js";
import DropDownLocationInput from "../helperComponents/DropDownLocationInput.js";
import MaterialInput from "../helperComponents/MaterialInput.js";
import CustomButton from "../helperComponents/CustomButton.js";
import ProfileTagsEditorParent from "./ProfileTagsEditorParent.js";
import LoadingSection from "../helperComponents/LoadingSection.js";
import sendPostMessage from "../../controllers/sendPostMessage.js";

import ThemePreview from "./ThemePreview.js";
import { colorVariations, themeList } from "../../data/themeList.js";
import ColorPreview from "./ColorPreview.js";

const Container = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
`;

export default function ThemeColorSelector() {
  const { profileMetaData, setProfileMetaData, setLoggedInUser, popupAlert } =
    useContext(Context);

  if (!profileMetaData) return <LoadingSection />;

  return <Container>{renderColors()}</Container>;

  function renderColors() {
    let items = [];

    for (let colorID in colorVariations) {
      items.push(
        <ColorPreview
          key={colorID}
          colorID={colorID}
          data={colorVariations[colorID]}
          onClick={selectColor(colorID)}
        />
      );
    }

    return items;
  }

  function selectColor(colorID) {
    return async () => {
      let toSend = {
        changes: {},
      };

      toSend.changes.themeColorID = colorID;

      let newConfig = { themeColorID: colorID };

      try {
        let newMeta = {
          ...profileMetaData,
          ...newConfig,
        };
        sendPostMessage({ profileMetaData: { draftState: newMeta } });
        await serverLine.patch("/my-profile-meta", toSend);
        setProfileMetaData(newMeta);
      } catch (e) {
        popupAlert(e.message);
      }
    };
  }
}
