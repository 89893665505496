import styled from "styled-components";

const Section = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const SectionTitle = styled.div`
  font-size: 18px;
  opacity: 0.5;
`;

export default function ProfileSectionBlank({ id, label, children }) {
  return (
    <Section id={id}>
      <SectionTitle>{label}</SectionTitle>
      {children}
    </Section>
  );
}
