import { styled } from "styled-components";
import { AiOutlineEdit, AiOutlineLeft, AiOutlineRight } from "react-icons/ai";
import { useRef } from "react";
import CustomButton from "../helperComponents/CustomButton";

const Container = styled.div`
  width: 100%;
  display: flex;
  font-size: 21px;
  flex-direction: column;
  position: relative;
  gap: 25px;
  align-items: center;
`;

const ScrollableView = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  border-radius: 10px;
  width: 100%;
  overflow: hidden;
  gap: 15px;
  overflow-y: auto;
`;

const Buttons = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
`;

export default function ProjectSlideshow({ list }) {
  const ref = useRef(null);

  // return null;

  return (
    <Container>
      <ScrollableView ref={ref}>{list}</ScrollableView>
      <Buttons>
        <CustomButton
          size="50px"
          variant="outlined"
          onClick={scrollHorizontally(-400)}
          icon={<AiOutlineLeft />}
        />
        <CustomButton
          variant="outlined"
          size="50px"
          onClick={scrollHorizontally(400)}
          icon={<AiOutlineRight />}
        />
      </Buttons>
    </Container>
  );

  function scrollHorizontally(pixels) {
    return () => {
      if (ref) {
        if (ref.current) {
          ref.current.scroll({
            left: ref.current.scrollLeft + pixels,
            top: 0,
            behavior: "smooth",
          });

          // ref.current.scrollLeft += pixels;
        }
      }
    };
  }
}
