export default function isImageTypeContent(theType) {
  let doubleColumnTypes = [
    "PROJECT_ART",
    "PROJECT_GRAPHIC_DESIGN",
    "PROJECT_PHOTOGRAPHY",
  ];

  if (doubleColumnTypes.includes(theType)) return true;

  return false;
}
