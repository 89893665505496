import styled from "styled-components";
import { useContext } from "react";
import Context from "../../Context.js";
import { useNavigate } from "react-router-dom";
import goTo from "../../controllers/goTo.js";
import CustomButton from "../helperComponents/CustomButton.js";
import { AiOutlineEdit, AiOutlinePlus, AiOutlineYoutube } from "react-icons/ai";
import FollowButton from "../helperComponents/FollowButton.js";
import LoadingSection from "../helperComponents/LoadingSection.js";
import getProfileLink from "../../controllers/getProfileLink.js";
import getLocationString from "../../controllers/getLocationString.js";
import ProfilePicture from "../helperComponents/ProfilePicture.js";
import rearrangeContent from "../../controllers/profile/rearrangeContent.js";
import renderSocialLinks from "../../controllers/renderSocialLinks.js";
import ProfilePageDescription from "./ProfilePageDescription.js";

const TopHeader = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  gap: 70px;
  width: var(--profileHeaderWidth);
  height: 100vh;
  background-color: var(--bgColorDark);
  backdrop-filter: blur(20px);

  border-radius: 20px;
  color: var(--headerTextColor);
  z-index: 10;
  /* align-items: center; */
  padding: 45px 30px;
  /* padding-top: 64px; */

  border-bottom: 1px solid var(--translucent);

  @media (max-width: 900px) {
    padding: 20px;
    padding-bottom: 30px;
    height: auto;
    flex-direction: column;
    gap: 0;
  }
`;

const ProfileTop = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Name = styled.div`
  font-size: 30px;

  font-weight: 900;
  color: var(--headerTextColor);
  font-family: "Raleway";
  margin-top: 25px;
  margin-bottom: 5px;
  cursor: pointer;

  @media (max-width: 900px) {
    font-size: 23px;
  }
`;

const Links = styled.div`
  display: flex;
  flex-direction: column;

  gap: 70px;
`;

const LinkRow = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 0;
  justify-content: center;
  gap: 20px;
  align-items: center;

  @media (max-width: 900px) {
    gap: 10px;
    margin-top: 25px;
  }
`;

const Description = styled.div`
  font-size: 19px;
  font-family: "Montserrat", sans-serif;
  margin-top: 10px;
  text-align: center;
  opacity: 0.7;
  line-height: 30px;
  word-break: break-all;
  width: 95%;

  @media (max-width: 900px) {
    font-size: 18px;
  }
`;

let btnSize = "45px";

const Bold = styled.b``;

export default function ProfilePageHeader({ profileData }) {
  let { setForm, loggedInUser, loggedInUserID } = useContext(Context);

  let buttonHeight = "40px";

  let textButtonStyle = { height: buttonHeight };
  let followButtonStyle = textButtonStyle;

  let isLoggedInUser = profileData.user._id == loggedInUserID;

  let locationStr = getLocationString(profileData);

  let socialLinkStyle = {
    height: btnSize,
    width: btnSize,
    backdropFilter: "unset",
  };

  let socialLinks = renderSocialLinks(profileData.profileMetaData.socialLinks, {
    style: socialLinkStyle,
    variant: "outlined",
  });

  if (!profileData) return <LoadingSection />;

  let followBtn = (
    <FollowButton style={followButtonStyle} userID={profileData.user._id} />
  );

  let imageHeight = "270px";
  let imageWidth = "200px";
  // imageWidth = "imageHeight";

  return (
    <TopHeader>
      <ProfileTop>
        <ProfilePicture
          // style={{ borderRadius: "10px" }}
          width={imageWidth}
          height={imageHeight}
          imageObj={profileData.profileMetaData.profileImage}
        />
        <Name onClick={goTo(getProfileLink(profileData.user.username))}>
          {profileData.profileMetaData.name}
        </Name>

        <ProfilePageDescription
          style={{ textAlign: "center", fontSize: "18px", marginTop: "10px" }}
          profileData={profileData}
        />
      </ProfileTop>

      <Links>
        <LinkRow>
          {renderLinks()}
          {followBtn}
        </LinkRow>
        <LinkRow>{socialLinks}</LinkRow>
      </Links>
    </TopHeader>
  );

  function renderLinks() {
    let toReturn = [];

    if (!profileData.LINK) return [];

    let rearrangedContent = rearrangeContent({
      profileMetaData: profileData.profileMetaData,
      list: profileData.LINK,
      type: "LINK",
    });

    for (let item of rearrangedContent) {
      toReturn.push(
        <CustomButton
          circular={true}
          style={{ color: "var(--headerTextColor)", backdropFilter: "unset" }}
          // width={linkButtonWidth}
          onClick={goTo(item.content.externalLink, { isAbsolute: true })}
        >
          {item.title}
        </CustomButton>
      );
    }

    return toReturn;
  }
}
