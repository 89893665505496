export default function rearrangeContent({ profileMetaData, list, type }) {
  let idVsElement = {};

  if (!list) return [];

  for (let item of list) {
    idVsElement[item._id] = item;
  }

  if (!profileMetaData) return list;

  if (!profileMetaData.contentOrder) return list;

  if (!profileMetaData.contentOrder[type]) return list;

  let theOrder = profileMetaData.contentOrder[type];

  if (!theOrder) return [];

  let toReturn = [];
  for (let itemID of theOrder) {
    let theItem = idVsElement[itemID];
    if (theItem) {
      toReturn.push(theItem);
    }
  }

  return toReturn;
}
