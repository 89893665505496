import styled from "styled-components";
import CustomButton from "../helperComponents/CustomButton";
import { useContext } from "react";
import Context from "../../Context";
import ThemeColorSelector from "./ThemeColorSelector";
import { MdCheck } from "react-icons/md";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;
const Image = styled.img`
  width: 100%;
  border-radius: 10px;
  border: 1px solid var(--translucentHard);
  overflow: hidden;
`;
const Bottom = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;
const Name = styled.div`
  font-size: 18px;
  font-weight: 900;
  opacity: 0.5;
`;

const Left = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
`;
export default function ThemePreview({ data, onClick }) {
  const { profileMetaData } = useContext(Context);

  let selectedThemeID = profileMetaData.themeID;

  if (!selectedThemeID) selectedThemeID = "SIMPLE";

  return (
    <Container>
      <Image src={`/theme-previews/${data.previewImage}`} />
      <Bottom>
        <Left>
          <Name>{data.name}</Name>
          {selectedThemeID !== data._id ? null : (
            <CustomButton
              variant="minimal"
              disabled={true}
              icon={<MdCheck />}
            />
          )}
        </Left>

        {selectedThemeID !== data._id ? (
          <CustomButton onClick={onClick}>Select</CustomButton>
        ) : null}
      </Bottom>

      {selectedThemeID == data._id ? <ThemeColorSelector /> : null}
    </Container>
  );
}
