import contentTypeVsData from "../../data/contentTypeVsData";
import checkRequiredFields from "./checkRequiredFields";
import { serverLine } from "../serverLine";
import goTo from "../goTo";

export default async function createContentItem({
  type,
  state,
  isNew,
  addTitleToLiterature,
  setLoading,
  onAdd,
  popupAlert,
  setState,
  path,
}) {
  let newState = { ...state };

  let requiredFieldError = checkRequiredFields({
    state,
    type,
    isNew,
  });

  if (requiredFieldError) return window.doAlert(requiredFieldError);

  let typeData = contentTypeVsData[type];

  if (typeData.type == "PRE_CATEGORIZED") {
    newState.classification = typeData.category;
  }

  let categoryExempted = ["LIST_ITEM", "PAGE", "SOCIAL_LINK"];

  if (!categoryExempted.includes(type)) {
    if (typeData.type !== "UN_CATEGORIZED") {
      if (!newState.classification)
        return window.doAlert("Please select a category.");
    }
  }

  newState = { ...newState };
  newState.type = type;
  newState.published = true;

  newState = addTitleToLiterature(newState);

  setLoading(true);

  newState = await serverLine.post("/content", { values: newState });

  console.log("NEW STATE", newState);
  setLoading(false);

  onAdd(newState);

  popupAlert("Item Added");
  goTo(`/edit/${path}`)();

  setState({
    draftState: {
      title: "",
      richText: null,
      tagline: "",
      githubLink: "",
      description: "",
      course: "",
      role: "",
      from: "",
      to: "",
      externalLink: "",

      videoLink: "",
    },
  });
}
