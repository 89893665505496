import { styled } from "styled-components";
import CustomButton from "../helperComponents/CustomButton.js";
import { AiOutlineRight, AiOutlineUp } from "react-icons/ai";
import capitalizeFirstLetter from "../../controllers/capitalizeFirstLetter.js";
import limitStringLength from "../../controllers/limitStringLength.js";
import { MdOutlineDragIndicator } from "react-icons/md";
import { useContext } from "react";
import Context from "../../Context.js";
import ContentItemEditor from "./ContentItemEditor.js";

//translucentInteraction

const Collapsed = styled.div`
  border-radius: var(--borderRadius1);
  background: var(--translucentInteraction);
  border: 1px solid var(--translucentHard);
  backdrop-filter: blur(50px);
  padding: 20px 25px;
  flex: 1;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  &:hover {
    background-color: var(--translucentHard);
  }
`;

const CollapsedLeft = styled.div`
  display: flex;
  flex-direction: row;
  gap: 15px;
  align-items: center;
`;

const CollapsedRight = styled.div``;

const CollapsedTitle = styled.div`
  font-size: 18px;
  font-weight: 900;
`;

const Row = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  gap: 10px;
`;

export default function CollapsedItemEditor({ title, onClick }) {
  return (
    <Row>
      <Collapsed onClick={onClick}>
        <CollapsedLeft>
          <CollapsedTitle>
            {capitalizeFirstLetter(limitStringLength(title, 35))}
          </CollapsedTitle>
        </CollapsedLeft>

        <CollapsedRight>
          <AiOutlineRight />
        </CollapsedRight>
      </Collapsed>
      <CustomButton
        variant="outlined-filled"
        style={{
          width: "20px",
        }}
        icon={<MdOutlineDragIndicator />}
      />
    </Row>
  );
}
