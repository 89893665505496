import { styled } from "styled-components";
import NavigateProfileEditor from "../../editors/profileEditor/NavigateProfileEditor";
import { useContext, useEffect, useState } from "react";

import CustomButton from "../../helperComponents/CustomButton";
import goTo from "../../../controllers/goTo";
import { FiHome } from "react-icons/fi";
import { RiHome2Line } from "react-icons/ri";
import {
  AiOutlineEdit,
  AiOutlineHome,
  AiOutlineLeft,
  AiOutlineProfile,
} from "react-icons/ai";
import LogoSVG from "../../brand/LogoSVG";

import ContentListEditor from "../../editors/ContentListEditor";

import Context from "../../../Context";
import getSubPath from "../../../controllers/getSubPath";
import LoginButton from "../../helperComponents/LoginButton";

import { MdKeyboardArrowRight, MdOutlineArrowBackIosNew } from "react-icons/md";
import { siteEditorSectionList } from "../../../data/websiteEditorSections";
import { GoHome } from "react-icons/go";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: var(--websiteEditorPanelWidth);
  border-right: 1px solid var(--translucentHard);
  position: relative;
  height: 100vh;
  background-color: var(--bgColorDark);
  align-items: center;

  padding: 0 0;
  gap: 50px;
  overflow: hidden;
  overflow-y: auto;
  padding-bottom: 50px;

  @media (max-width: 900px) {
    padding: 30px 0;
    /* padding: 25px; */
  }
`;

const TopPart = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
`;

const EditSection = styled.div`
  width: 100%;
`;

const Main = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 50px;
  padding: var(--websiteEditorPanelPadding);

  @media (max-width: 900px) {
    width: 90vw;
    /* padding: 25px; */
  }
`;

const Buttons = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;

  border-radius: 5px;
  overflow: hidden;
`;

const PathButton = styled.div`
  cursor: pointer;
  padding: 15px 20px;
  background-color: var(--bgColorDark);

  &:hover {
    background-color: var(--translucentHard);
  }
`;

const NavDivider = styled.div`
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0.5;
  font-size: 20px;
`;

export default function WebsiteEditorPanel() {
  const { loggedInUser } = useContext(Context);

  let path = getSubPath(1);
  let pathData = getSubPath(2);

  if (!path)
    return (
      <Container>
        <Main>
          <NavigateProfileEditor
            currentSection={path}
            setSection={changePath}
          />
        </Main>
      </Container>
    );

  let section = getSectionData(path);
  let comp = getSectionComponent(section);
  let subComp = getSectionSubComponent(section, pathData);

  let navButtonStyle = {
    padding: "7px 18px",
    gap: "10px",
    fontSize: "16px",
  };

  let navButtonType = "outlined";

  if (!loggedInUser) return <LoginButton />;

  let navDivider = (
    <NavDivider>
      <MdKeyboardArrowRight />
    </NavDivider>
  );

  return (
    <Container>
      <Main>
        <Buttons>
          <CustomButton
            href={"/edit"}
            icon={<RiHome2Line />}
            variant={navButtonType}
            style={navButtonStyle}
          >
            Home
          </CustomButton>

          {getPathButton()}
          {getSubPathButton()}
        </Buttons>

        <EditSection>{subComp ? subComp : comp}</EditSection>
      </Main>
    </Container>
  );

  function getPathButton() {
    if (!path) return null;

    return (
      <>
        {navDivider}
        <CustomButton
          icon={section.icon}
          style={navButtonStyle}
          variant={navButtonType}
          href={`/edit/${path}`}
        >
          {section.label}
        </CustomButton>
      </>
    );
  }

  function getSubPathButton() {
    if (!pathData) return null;

    return (
      <>
        {navDivider}
        <CustomButton
          icon={section.icon}
          style={navButtonStyle}
          href={`/edit/${path}/${pathData}`}
          variant={navButtonType}
        >
          Item
        </CustomButton>{" "}
      </>
    );
  }

  function getBackButtonLabel() {
    if (pathData) {
      return `${section.label} Item`;
    } else {
      return `${section.label}`;
    }
  }

  function getBackButtonPath() {
    if (pathData) {
      return `/edit/${path}`;
    } else {
      return `/edit`;
    }
  }

  function getSectionComponent(data) {
    if (data.contentListType) {
      return (
        <ContentListEditor initialType={data.contentListType} path={path} />
      );
    }

    return data.component;
  }

  function getSectionSubComponent(data, pathData) {
    if (!pathData) return null;

    if (data.contentListType) {
      return (
        <ContentListEditor
          pathData={pathData}
          path={path}
          initialType={data.contentListType}
        />
      );
    }

    return data.subComponent;
  }

  function getSectionData() {
    for (let item of siteEditorSectionList) {
      if (item.value == path) return item;
    }
    return null;
  }

  function changePath(newPath) {
    goTo(`/edit/${newPath}`)();
  }
}
