import styled from "styled-components";
import HorizontalBarGraph from "../../stat/HorizontalBarGraph";
import { useEffect, useState } from "react";

import { serverLine } from "../../../controllers/serverLine";
import hozStatDataProcessor from "../../../controllers/stat/hozStatDataProcessor";
import StatContentBox from "../../stat/StatContentBox";
import LoadingSection from "../../helperComponents/LoadingSection";
import WithHeader from "../WithHeader";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 100px;
  padding-top: 100px;
  height: 100%;
  overflow-y: scroll;
  padding: 100px 200px;
  width: 100%;
`;

const Main = styled.div`
  display: flex;
  flex-direction: column;
  gap: 50px;

  width: 100%;
`;

const Title = styled.h1``;
const Section = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const TableHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  padding-bottom: 20px;
  border-bottom: 1px solid var(--translucentHard);
`;
const SubHeader = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;
`;

const MainTableHeading = styled.div`
  width: 200px;
  opacity: 0.5;
  font-size: 18px;
`;

const ColHeading = styled.div`
  width: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0.5;
  font-size: 18px;
`;

const List = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

export default function StatPage() {
  const [data, setData] = useState(null);

  useEffect(() => {
    serverLine.get("/stat/?type=USER").then(setData);
  }, []);

  if (!data)
    return (
      <WithHeader>
        <LoadingSection />
      </WithHeader>
    );

  let fields = ["postViewCount", "externalPostViewCount"];
  let fields2 = ["profileViewCount", "externalProfileViewCount"];

  return (
    <WithHeader>
      <Container>
        <Main>
          <Title>Stat</Title>

          <HorizontalBarGraph
            fields={fields2}
            title="Profile Views Stat"
            dataProcessor={hozStatDataProcessor}
            rawData={data.stat}
          />

          <HorizontalBarGraph
            fields={fields}
            title="Content Views Stat"
            dataProcessor={hozStatDataProcessor}
            rawData={data.stat}
          />

          <br />
          <br />

          <Section>
            <TableHeader>
              <MainTableHeading>Title</MainTableHeading>
              <SubHeader>
                <ColHeading>Views</ColHeading>
                <ColHeading>Ext. Views</ColHeading>
                <ColHeading>Likes</ColHeading>
                <ColHeading>Dislikes</ColHeading>
              </SubHeader>
            </TableHeader>
            <List>{renderContent()}</List>
          </Section>
        </Main>
      </Container>
    </WithHeader>
  );

  function renderContent() {
    let toReturn = [];

    for (let item of data.publishedPosts) {
      toReturn.push(<StatContentBox key={item._id} item={item} />);
    }

    return toReturn;
  }
}
