import { styled } from "styled-components";
import { useContext } from "react";
import { serverLine } from "../../controllers/serverLine.js";
import Context from "../../Context.js";
import TagsEditor from "./TagsEditor.js";
import LoadingSection from "../helperComponents/LoadingSection.js";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 0;
  width: 100%;
  gap: 100px;
  align-items: center;
`;

export default function ProfileTagsEditorParent({ value, onChange }) {
  const { loggedInUser } = useContext(Context);

  if (!loggedInUser) return <LoadingSection />;

  return (
    <Container>
      <TagsEditor
        disableTagLimit={true}
        classification={loggedInUser.classification}
        onChange={onChange}
        value={value}
        title="Add Skills"
      />
    </Container>
  );
}
