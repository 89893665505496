import styled from "styled-components";
import VideoEmbed from "../components/misc/VideoEmbed";
import getImageURL from "./getImageURL";
import getPublishedOrDraftContent from "./getPublishedOrDraftContent";

const SlideshowImage = styled.img`
  border-radius: 10px;
`;

export default function getSlideshowList(value) {
  let toReturn = [];
  let contentHeightOnDesktop = 400;

  let content = getPublishedOrDraftContent(value);

  if (content.videoLink)
    toReturn.push(
      <VideoEmbed
        theVideoUrl={content.videoLink}
        height={contentHeightOnDesktop}
      />
    );

  let imageHeight = contentHeightOnDesktop;
  let imageWidth = "auto";

  if (window.innerWidth < 900) {
    let videoWidth = window.innerWidth * 0.9;
    imageHeight = videoWidth * 0.56;
  }

  if (content.image) {
    toReturn.push(
      <SlideshowImage
        style={{ height: imageHeight, width: imageWidth }}
        src={getImageURL(content.image)}
      />
    );
  }

  if (content.images) {
    for (let itm of content.images) {
      toReturn.push(
        <SlideshowImage
          style={{ height: imageHeight + "px", width: imageWidth + "px" }}
          src={getImageURL(itm)}
        />
      );
    }
  }

  return toReturn;
}
