import { styled } from "styled-components";
import { useContext } from "react";
import Context from "../../Context.js";
import { AiOutlineDown } from "react-icons/ai/index.js";
import PopupLocationSelector from "./PopupLocationSelector.js";
import PopupSelect from "./PopupSelect.js";
import CustomButton from "./CustomButton.js";
import {
  MdOutlineClose,
  MdOutlineDelete,
  MdOutlineDragIndicator,
} from "react-icons/md";

const Container = styled.div`
  display: flex;
  flex-direction: row;
  padding: 10px;
  padding-left: 20px;
  width: 100%;
  height: 50px;
  background-color: var(--translucentInteraction);
  backdrop-filter: blur(20px);
  border: 1px solid var(--translucentHard);
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  border-radius: var(--borderRadius1);

  &:hover {
    background-color: var(--translucentHard);
  }
`;

const Label = styled.div`
  width: 100%;
  opacity: 0.5;
  text-transform: capitalize;
`;

const Left = styled.div`
  display: flex;
  gap: 5px;
  flex-direction: column;
`;

const SelectInputText = styled.div`
  font-size: var(--fontSize1);
`;
const SelectInputButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  justify-content: space-between;
`;

export default function DropDownSectionSelector(props) {
  const { setForm } = useContext(Context);
  let { value, options, onChange, placeholder, isMain, onDelete } = props;

  let buttonType = "outlined-filled";

  let content = (
    <Container
      onClick={() => {
        setForm({
          title: placeholder,
          component: (
            <PopupSelect options={options} value={value} onChange={onChange} />
          ),
        });
      }}
    >
      <Left>
        <SelectInput value={parseValue()} placeholder={placeholder} />
      </Left>
      <SelectInputButton>
        <AiOutlineDown />
      </SelectInputButton>
    </Container>
  );

  if (isMain) {
    return (
      <Row>
        <CustomButton
          variant={buttonType}
          icon={<MdOutlineDragIndicator />}
          width="20px"
        />
        {content}
        <CustomButton
          variant={buttonType}
          onClick={onDelete}
          icon={<MdOutlineClose />}
          width="20px"
        />
      </Row>
    );
  }

  return content;

  function parseValue() {
    if (!value) return null;
    for (let item of options) {
      if (item.value == value) return item.label;
    }

    return null;
  }
}

function SelectInput({ value, placeholder }) {
  return <SelectInputText>{value ? value : placeholder}</SelectInputText>;
}
