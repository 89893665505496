import styled from "styled-components";
import goTo from "../../controllers/goTo";

const Row = styled.div`
  display: flex;
  padding: 20px 0;
  flex-direction: row;
  justify-content: space-between;
  cursor: pointer;
  border-bottom: 3px solid var(--translucent);

  &:hover {
    border-bottom: 3px solid var(--translucentHard);
  }
`;
const Title = styled.div`
  white-space: nowrap;
  font-size: 24px;
  font-weight: 900;
  max-width: 30vw;
  overflow: hidden;
`;
const NumCol = styled.div`
  font-weight: 900;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 21px;
  width: 200px;
`;

const Stat = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;
`;

export default function StatContentBox({ item }) {
  let { _id, title, viewCount, externalViewCount, likeCount, dislikeCount } =
    item;

  return (
    <Row onClick={goTo("/content-stat/" + _id)}>
      <Title>{title}</Title>

      <Stat>
        <NumCol>{viewCount}</NumCol>
        <NumCol>{externalViewCount}</NumCol>
        <NumCol>{likeCount}</NumCol>
        <NumCol>{dislikeCount}</NumCol>
      </Stat>
    </Row>
  );
}
