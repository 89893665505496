import styled from "styled-components";
import CustomButton from "../helperComponents/CustomButton";
import { useContext } from "react";
import Context from "../../Context";
import { MdCheck } from "react-icons/md";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  height: 50px;
  cursor: pointer;
  width: 50px;
  border-radius: 100px;
  overflow: hidden;
  overflow: hidden;

  transition: 0.05s ease-in-out;
  position: relative;
  &:hover {
    transform: scale(0.95);
  }
`;

const CheckMark = styled.div`
  display: flex;
  position: absolute;
  left: 25%;
  top: 25%;
  width: 50%;
  border-radius: 100px;
  height: 50%;

  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.7);
`;

export default function ColorPreview({ colorID, data, onClick }) {
  let { profileMetaData } = useContext(Context);
  let selectedColorID = profileMetaData.themeColorID;
  if (!selectedColorID) selectedColorID = "DARK";
  let style = { background: data.previewColor };

  if (data.previewImage) {
    style.backgroundImage = `url("${data.previewImage}")`;
    style.backgroundImageSize = "cover";
  }

  return (
    <Container style={style} onClick={onClick}>
      {selectedColorID == colorID ? (
        <CheckMark>
          <MdCheck />
        </CheckMark>
      ) : null}
    </Container>
  );
}
