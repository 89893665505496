import { styled } from "styled-components";
import { useContext, useEffect, useState } from "react";
import { AiOutlineSend } from "react-icons/ai";
import RenderComments from "./RenderComments";
import OverlayLoading from "../helperComponents/OverlayLoading";
import openLoginPopup from "../../controllers/openLoginPopup";
import MaterialInput from "../helperComponents/MaterialInput";
import { serverLine } from "../../controllers/serverLine";
import CustomButton from "../helperComponents/CustomButton";
import Context from "../../Context";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 50px;
`;

const InputSection = styled.div`
  display: flex;
  flex-direction: row;
  gap: 15px;
  align-items: flex-start;
  position: relative;
  padding: 0;
  border-radius: 10px;
  /* background: var(--translucent); */
`;

export default function CommentsSection({ post }) {
  const { loggedInUserID } = useContext(Context);
  const [commentText, setCommentText] = useState("");
  const [allComments, setAllComments] = useState([]);
  const [myComments, setMyComments] = useState([]);
  const [postingComment, setPostingComment] = useState(false);

  useEffect(() => {
    loadComments();
  }, []);

  return (
    <Container>
      <InputSection>
        <MaterialInput
          multiline={true}
          value={commentText}
          maxRows={5}
          label="Type Comment Here"
          onEnter={postComment}
          onChange={updateText}
        />
        <CustomButton
          style={{ flexDirection: "row-reverse" }}
          // width="100px"
          variant="outlined"
          onClick={postComment}
          icon={<AiOutlineSend />}
        >
          Comment
        </CustomButton>

        {postingComment ? <OverlayLoading /> : null}
      </InputSection>

      <RenderComments list={[...myComments, ...allComments]} />
    </Container>
  );

  function updateText(e) {
    setCommentText(e.target.value);
  }

  async function postComment() {
    if (!loggedInUserID) return openLoginPopup();

    if (!commentText) return;
    if (!commentText.trim()) return;

    try {
      setPostingComment(true);
      let newComment = await serverLine.post("/comment", {
        contentID: post._id,
        commentText: commentText,
      });

      setMyComments([newComment, ...myComments]);
      setCommentText("");
      setPostingComment(false);
    } catch (e) {
      console.log(e);
      setPostingComment(false);
    }
  }

  function loadComments() {
    serverLine.get("/comments/?contentID=" + post._id).then(setAllComments);
  }
}
