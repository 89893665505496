import styled from "styled-components";
import { useContext } from "react";
import Context from "../../../../Context.js";
import { useNavigate } from "react-router-dom";
import goTo from "../../../../controllers/goTo.js";
import CustomButton from "../../../helperComponents/CustomButton.js";
import { AiOutlineEdit, AiOutlinePlus, AiOutlineYoutube } from "react-icons/ai";
import FollowButton from "../../../helperComponents/FollowButton.js";
import LoadingSection from "../../../helperComponents/LoadingSection.js";
import getProfileLink from "../../../../controllers/getProfileLink.js";
import getLocationString from "../../../../controllers/getLocationString.js";
import SocialLink from "../../SocialLink.js";
import { BsInstagram, BsTiktok, BsTwitterX } from "react-icons/bs";
import { GrGithub } from "react-icons/gr";
import { RiLinkedinLine, RiUnsplashFill } from "react-icons/ri";
import { LiaArtstation } from "react-icons/lia";
import { BiLogoUpwork } from "react-icons/bi";
import ProfilePicture from "../../../helperComponents/ProfilePicture.js";
import rearrangeContent from "../../../../controllers/profile/rearrangeContent.js";
import SocialLinks from "../../SocialLinks.js";
import renderSocialLinks from "../../../../controllers/renderSocialLinks.js";
import ProfilePageDescription from "../../ProfilePageDescription.js";

const TopHeader = styled.div`
  display: flex;
  flex-direction: row;
  gap: 40px;
  width: 100vw;
  height: auto;
  /* background-color: var(--bgColorDark); */
  /* backdrop-filter: blur(20px); */
  z-index: 10;
  justify-content: center;
  /* align-items: center; */
  padding: 0 30px;

  padding-top: 0;
  color: var(--headerTextColor);
  /* border-bottom: 1px solid var(--translucent); */

  @media (max-width: 900px) {
    padding: 20px;
    padding-top: 0;
    padding-bottom: 64px;
    height: auto;
    flex-direction: column;
    gap: 20px;
  }
`;

const Name = styled.div`
  font-size: 35px;
  /* text-align: center; */
  font-family: "Inter", sans-serif;

  /* font-family: "Montserrat", sans-serif; */
  /* font-style: italic; */

  /* line-height: 35px; */
  font-weight: 900;
  display: flex;
  gap: 10px;
  flex-direction: row;
  margin-bottom: 15px;

  cursor: pointer;

  @media (max-width: 900px) {
    font-size: 23px;
  }
`;

const Links = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 20px;
  gap: 20px;
  align-items: center;
  width: 90vw;
  flex-direction: wrap;
  justify-content: center;
  @media (max-width: 900px) {
    gap: 10px;
  }
`;

const RowDivider = styled.div`
  width: 2px;
  height: 75%;
  height: 60px;
  background-color: var(--translucentHard);
`;

const RowOne = styled.div`
  display: flex;
  width: 62vw;
  gap: 20px;
  align-items: center;
  gap: 40px;
  flex-direction: row;
  justify-content: space-between;
`;

const RowTwo = styled.div`
  display: flex;
  /* width: 62vw; */
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-top: 25px;

  gap: 20px;

  @media (max-width: 900px) {
    flex-wrap: wrap;
  }
`;

const FirstName = styled.div``;

const LastName = styled.div``;

let btnSize = "45px";

const Main = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  gap: 25px;
  width: 62vw;
  padding-bottom: 50px;
  padding-top: 80px;
  /* border-bottom: 1px solid var(--translucent); */

  @media (max-width: 900px) {
    flex-direction: column;
    align-items: center;
    width: 90vw;
  }
`;

const MainColumn = styled.div`
  display: flex;
  flex-direction: column;
  background-color: var(--translucentInteraction);
  border: 1px solid var(--translucent);
  height: 250px;
  border-radius: 10px;
  padding: 20px;
  /* flex: 1; */
  gap: 00px;

  @media (max-width: 900px) {
    width: 100%;
    height: auto;
  }
`;

export default function VerticalThreeHeader({ profileData }) {
  let buttonHeight = "40px";

  let textButtonStyle = { height: buttonHeight };
  let followButtonStyle = textButtonStyle;

  let locationStr = getLocationString(profileData);

  let socialLinkStyle = {
    height: btnSize,
    backdropFilter: "unset",
    width: btnSize,
  };

  let socialLinks = renderSocialLinks(profileData.profileMetaData.socialLinks, {
    style: socialLinkStyle,
    variant: "outlined",
  });

  if (!profileData) return <LoadingSection />;

  let followBtn = (
    <FollowButton style={followButtonStyle} userID={profileData.user._id} />
  );

  let linkButtonWidth = "175px";

  let firstName = "";
  let lastName = "";

  let theName = profileData.user.name;
  theName = theName.split(" ");

  firstName = theName[0];

  theName.shift();

  lastName = theName.join(" ");

  return (
    <TopHeader>
      <Main>
        <ProfilePicture
          style={{
            borderRadius: "10px",
            // boxShadow: "0 0 50px 1px var(--accentShadow)",
            border: `1px solid var(--translucent)`,
          }}
          height={"250px"}
          width={"250px"}
          imageObj={profileData.profileMetaData.profileImage}
        />

        <MainColumn>
          <Name onClick={goTo(getProfileLink(profileData.user.username))}>
            <FirstName>{firstName}</FirstName>
            <LastName>{lastName}</LastName>
          </Name>

          <ProfilePageDescription
            style={{ fontSize: "18px", lineHeight: "25px", width: "100%" }}
            profileData={profileData}
          />

          <RowTwo>
            {followBtn}
            {renderLinks()}
            {socialLinks}
          </RowTwo>
        </MainColumn>
      </Main>
    </TopHeader>
  );

  function renderLinks() {
    let toReturn = [];

    if (!profileData.LINK) return [];

    let rearrangedContent = rearrangeContent({
      profileMetaData: profileData.profileMetaData,
      list: profileData.LINK,
      type: "LINK",
    });

    for (let item of rearrangedContent) {
      toReturn.push(
        <CustomButton
          key={item._id}
          hoverVariant="SCALE_DOWN"
          circular={true}
          style={{
            color: "var(--headerTextColor)",
            padding: "0 30px",
            backdropFilter: "unset",
          }}
          height={btnSize}
          // width={linkButtonWidth}
          onClick={goTo(item.content.externalLink, { isAbsolute: true })}
        >
          {item.title}
        </CustomButton>
      );
    }

    return toReturn;
  }
}
