import { useContext, useEffect, useState } from "react";
import { styled } from "styled-components";
import selectFile from "../../controllers/selectFile.js";
import compressAndUploadFile from "../../controllers/compressAndUploadFile.js";
import Context from "../../Context.js";
import { serverLine } from "../../controllers/serverLine.js";
import ImagesEditorWithDND from "./ImagesEditorWithDND.js";
import lexicalObjToString from "../../controllers/lexical/lexicalObjToString.js";
import ClassificationEditor from "./ClassificationEditor.js";
import TagsEditor from "./TagsEditor.js";
import contentTypeVsData from "../../data/contentTypeVsData.js";
import LoadingSection from "../helperComponents/LoadingSection.js";
import goTo from "../../controllers/goTo.js";
import ListItemEditorContainer from "./itemEditor/ListItemEditorContainer.js";
import ListItemEditorInputs from "./itemEditor/ListItemEditorInputs.js";
import getContentItemInputs from "../../controllers/contentItemEditor/getContentItemInputs.js";
import getContentItemButtons from "../../controllers/contentItemEditor/getContentItemButtons.js";
import getProfileLink from "../../controllers/getProfileLink.js";
import getItemEditorTopSection from "../../controllers/contentItemEditor/getItemEditorTopSection.js";
import createContentItem from "../../controllers/contentItemEditor/createContentItem.js";
import checkRequiredFields from "../../controllers/contentItemEditor/checkRequiredFields.js";
import onChangeStop from "../../controllers/onChangeStop.js";

const Buttons = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 25px;
  flex-direction: row;

  /* background-color: var(--translucent); */
  z-index: 10;
  width: 100%;
  /* padding: 25px; */
  backdrop-filter: blur(50px);
`;

const LoadingMessage = styled.div`
  font-size: 21px;
  text-align: center;
`;

const LoadingPart = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 10px;
`;

let defaultValue = { draftState: {} };

export default function ContentItemEditor({
  initialState,
  onAdd,
  onMoveUp,
  onDelete,
  index,
  onChange,
  path,
  type,
}) {
  const { popupAlert, loggedInUser, profileMetaData } = useContext(Context);

  let [state, setState] = useState(initialState ? initialState : defaultValue);

  const [loading, setLoading] = useState(false);

  state = { ...state };

  if (!state.draftState) state.draftState = {};

  let isNew = initialState ? false : true;

  let buttons = getContentItemButtons({
    initialState,
    addItem,
    onSave,
    openItem,
    doMoveUp,
    onDelete,
    index,
    onMoveUp,
  });

  if (loading) {
    let loadingMessage = getLoadingMessage();

    return (
      <ListItemEditorContainer>
        <LoadingPart>
          {loadingMessage ? (
            <LoadingMessage>{loadingMessage}</LoadingMessage>
          ) : null}
          <LoadingSection />
        </LoadingPart>
      </ListItemEditorContainer>
    );
  }

  return (
    <ListItemEditorContainer>
      {getItemEditorTopSection({
        type,
        state: state.draftState,
        selectAnImage,
        profileMetaData,
      })}
      <ListItemEditorInputs>
        {getContentItemInputs({ type, state, setState, makeChange, isNew })}
        {selectClassification()}
      </ListItemEditorInputs>
      {getImagesEditorWithDND()}
      {getTagsEditor()}
      <Buttons>{buttons}</Buttons>
    </ListItemEditorContainer>
  );

  function getTagsEditor() {
    if (type == "LINK") return null;
    if (type == "PAGE") return null;
    if (type == "LIST") return null;
    if (type == "CARD") return null;
    return (
      <TagsEditor
        classification={[getClassification()]}
        value={state.tags}
        onChange={(newValue) => {
          let newState = { ...state };
          newState.tags = newValue;
          setState(newState);
        }}
      />
    );
  }

  function openItem() {
    let base = getProfileLink(loggedInUser.username);
    let config = { isAbsolute: true };

    goTo(base + "/content/" + initialState._id, config)();
  }

  function getLoadingMessage() {
    return "Saving...";
  }

  function doMoveUp() {
    onMoveUp(index, state)();
  }

  function getClassification() {
    if (state.classification) return state.classification;
    let typeData = contentTypeVsData[type];
    if (typeData.category) return typeData.category;
  }

  function getImagesEditorWithDND() {
    if (
      type == "PROJECT_PROGRAMMING" ||
      type == "PROJECT_ART" ||
      type == "PROJECT_GRAPHIC_DESIGN" ||
      type == "PROJECT_PHOTOGRAPHY" ||
      type == "SALEABLE" ||
      type == "SERVICE"
    ) {
      return (
        <ImagesEditorWithDND
          setLoading={setLoading}
          setImages={updateProjectImages}
          images={state.draftState.images}
        />
      );
    }
  }

  function updateProjectImages(newImages) {
    let newState = { ...state };
    newState.draftState.images = newImages;
    setState(newState);
    onSave(newState);
  }

  function selectClassification() {
    if (
      type === "PROJECT_VIDEO" ||
      type === "PROJECT_VIDEO_TUTORIAL" ||
      type === "VIDEO" ||
      type === "SALEABLE" ||
      type === "SERVICE"
    )
      return (
        <ClassificationEditor
          value={state.classification}
          onChange={makeChange("classification", {
            isNotEvent: true,
            isTopLevel: true,
          })}
        />
      );
  }

  async function onSave(theState) {
    if (!theState) theState = state;

    let requiredFieldError = checkRequiredFields({
      state: theState,
      type,
    });

    if (requiredFieldError) return window.doAlert(requiredFieldError);

    let newState = theState;

    newState = addTitleToLiterature(newState);

    setState(newState);

    if (onChange) onChange(index, newState);
  }

  function addTitleToLiterature(oldState) {
    let newState = { ...oldState };

    if (type == "PROJECT_LITERATURE") {
      if (newState.draftState)
        newState.title = lexicalObjToString(newState.draftState.richText);
    }

    return newState;
  }

  async function addItem() {
    createContentItem({
      type,
      state,
      isNew,
      addTitleToLiterature,
      setLoading,
      onAdd,
      popupAlert,
      setState,
      path,
    });
  }

  async function uploadImageAndUpdateState({ files, currentState, field }) {
    setLoading(true);
    let newState = { ...currentState };
    let selectedFiles = files;

    if (selectedFiles) {
      let fileData = await compressAndUploadFile(null, selectedFiles[0]);

      if (fileData) {
        if (fileData.fileName) {
          newState.draftState[field] = {
            type: "USER_UPLOAD",
            data: fileData.fileName,
          };

          setState(newState);
          setLoading(false);
          return newState;
        }
      }
    } else {
      setLoading(false);
      return newState;
    }

    setLoading(false);
    return newState;
  }

  function selectAnImage(imageField) {
    return async () => {
      let selectedFiles = await selectFile({ onlyImage: true });

      if (selectedFiles) {
        let newState = await uploadImageAndUpdateState({
          currentState: state,
          files: selectedFiles,
          field: imageField,
        });

        setState(newState);
        if (!onAdd) onSave(newState);
      }
    };
  }

  function makeChange(fieldName, options) {
    if (!options) options = { isNotEvent: false, isTopLevel: false };

    let { isNotEvent, isTopLevel } = { ...options };

    return (e) => {
      if (isNotEvent) {
        let theVal = e;
        e = { target: { value: theVal } };
      }
      let newState = { ...state };

      if (isTopLevel) {
        newState[fieldName] = e.target.value;
      } else {
        if (!newState.draftState) newState.draftState = {};
        newState.draftState[fieldName] = e.target.value;
      }

      setState(newState);

      if (!onAdd) {
        onChangeStop(
          state._id + "change",
          () => {
            onSave(newState);
          },
          300
        );
      }
    };
  }
}
