export default function getPublishedOrDraftMetaData(item) {
  item = { ...item };
  if (!item.draftState) item.draftState = {};
  if (!item.publishedState) item.publishedState = {};

  if (window.self !== window.top) {
    // The script is running inside an iframe

    return item.draftState;
  } else {
    // The script is running in the main window
    return item.publishedState;
  }
}
