import styled from "styled-components";
import { useContext, useState } from "react";
import CustomMasonry from "../../helperComponents/CustomMasonry.js";
import Context from "../../../Context.js";
import ProfileSectionBlank from "../ProfileSectionBlank.js";
import LoadingSection from "../../helperComponents/LoadingSection.js";
import CustomButton from "../../helperComponents/CustomButton.js";
import isImageTypeContent from "../../isImageTypeContent.js";

const NoMasonry = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: 25px;
  flex-wrap: wrap;
  justify-content: space-between;
`;

export default function ProfileSectionBoilerplate({
  id,
  label,
  type,
  Component,
  loadedList,
  disableMasonry,
  maxColumns = 4,
}) {
  const { updateGlobalLikeStatus } = useContext(Context);
  const [seeMore, setSeeMore] = useState(false);
  const [loading, setLoading] = useState(false);

  useState(() => {
    updateGlobalLikeStatus(loadedList);
  }, []);

  let colsOnMobile = 1;

  if (isImageTypeContent(type)) colsOnMobile = 2;

  // if (!profileMetaData) return "missing profileMetaData";

  if (loading) return <LoadingSection />;

  if (!loadedList.length) return null;

  let defaultView = null;

  if (!loadedList) return defaultView;

  if (!loadedList.length) return defaultView;

  let toRender = loadedList.map((item) => (
    <Component key={item._id} item={item} />
  ));

  let seeMoreButton = (
    <CustomButton
      variant={"outlined"}
      circular
      width={"150px"}
      onClick={() => {
        setSeeMore(true);
      }}
    >
      See More
    </CustomButton>
  );

  let maxContent = 4;
  let moreCardsPresent = false;

  if (toRender.length > maxContent) moreCardsPresent = true;

  if (!moreCardsPresent) seeMoreButton = null;

  if (!seeMore) toRender = toRender.slice(0, maxContent);

  let theList = (
    <CustomMasonry
      colsOnMobile={colsOnMobile}
      maxColumns={maxColumns}
      list={toRender}
    />
  );

  if (disableMasonry) theList = <NoMasonry>{toRender}</NoMasonry>;

  return (
    <ProfileSectionBlank label={label} id={id}>
      {theList}
      {seeMore ? null : seeMoreButton}
    </ProfileSectionBlank>
  );
}
