import styled from "styled-components";
import getImageURL from "../../controllers/getImageURL";

import LikeDislikeButtons from "../contentPage/LikeDislikeButtons";

import ContentCardBoilerplate from "./ContentCardBoilerplate";

const FeedItemContainer = styled.div`
  display: flex;
  text-decoration: none;
  width: 100%;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 10px;

  gap: 0;
  cursor: pointer;
  align-items: center;

  &:hover {
    background-color: var(--translucent);
  }
`;

const Lines = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 10px;
  font-size: 18px;
  padding: 15px 15px;
  padding-top: 20px;
  border-radius: 0 0 10px 10px;
  background-color: var(--translucent);
  margin-top: -5px;
  /* border: 1px solid var(--translucentHard); */
  border-top: none;

  @media (max-width: 900px) {
    padding: 10px 10px;
    padding-top: 20px;
    gap: 5px;
  }
`;
const Line1 = styled.div`
  font-size: 18px;
  text-transform: capitalize;
  font-weight: 600;

  @media (max-width: 900px) {
    font-size: 15px;
  }
`;

const Line3 = styled.div`
  opacity: 0.5;
  font-size: 18px;
  @media (max-width: 900px) {
    font-size: 15px;
  }
`;

const Image = styled.img`
  border-radius: 5px;
  max-height: 500px;

  width: 100%;
  object-fit: cover;
`;

const MainContainer = styled.div`
  position: relative;
`;

const OverlayLike = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
  margin: 5px;
  background-color: rgba(0, 0, 0, 0.3);
  backdrop-filter: blur(20px);
  border-radius: 100px;
`;

export default function CardLikeOverlay({ item, children }) {
  return (
    <MainContainer>
      {children}

      <OverlayLike>
        <LikeDislikeButtons
          type="CONTENT"
          isMinimal={true}
          contentID={item._id}
          likeStatus={item.likeStatus}
          likeCount={item.likeCount}
          dislikeCount={item.dislikeCount}
        />
      </OverlayLike>
    </MainContainer>
  );
}
