import styled from "styled-components";

import Header from "./Header.js";
import { useContext } from "react";
import Context from "../../Context.js";

const Container = styled.div`
  display: flex;
  flex-direction: row;
  overflow: hidden;
  height: 100vh;
  height: 100dvh;
  width: 100vw;

  background-size: cover;

  @media (max-width: 900px) {
    flex-direction: column-reverse;
  }
`;

const Main = styled.div`
  padding: 0;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  width: calc(100vw - var(--headerWidth));

  ${({ loggedInUserID }) => {
    if (!loggedInUserID) {
      return `width: calc(100vw - var(--headerWidthLoggedOut));`;
    }
  }}
  height: 100vh;
  overflow-y: scroll;

  gap: 50px;
  justify-content: flex-start;
  align-items: center;

  @media (max-width: 900px) {
    width: 100vw;
    height: calc(100vh - var(--headerHeight));
  }
`;

export default function WithHeader({ children }) {
  const { loggedInUserID, colorMode } = useContext(Context);

  let path = window.location.pathname;
  if (!loggedInUserID) {
    if (path == "/") return children;
    if (path == "/auth-redirect") return children;
  }

  return (
    <Container>
      <Header />
      <Main loggedInUserID={loggedInUserID}>{children}</Main>
    </Container>
  );
}
