import styled from "styled-components";

import LogoSVG from "./LogoSVG.js";
import Link from "../helperComponents/Link.js";

const BrandContainerMain = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;
`;

const H1 = styled.h1`
  font-weight: 500;
  margin: 0;
  margin-top: -3px;
  font-size: 30px;
  width: auto;
  font-family: "Raleway";
  /* width: 100%; */
`;

export default function BrandContainer() {
  return (
    <Link href="/">
      <BrandContainerMain>
        <LogoSVG />
        <H1>Inwire</H1>
      </BrandContainerMain>
    </Link>
  );
}
