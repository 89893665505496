import styled from "styled-components";
import { useContext } from "react";
import Context from "../../../../Context.js";
import goTo from "../../../../controllers/goTo.js";
import CustomButton from "../../../helperComponents/CustomButton.js";
import FollowButton from "../../../helperComponents/FollowButton.js";
import LoadingSection from "../../../helperComponents/LoadingSection.js";
import getProfileLink from "../../../../controllers/getProfileLink.js";
import getLocationString from "../../../../controllers/getLocationString.js";
import ProfilePicture from "../../../helperComponents/ProfilePicture.js";
import rearrangeContent from "../../../../controllers/profile/rearrangeContent.js";
import renderSocialLinks from "../../../../controllers/renderSocialLinks.js";
import ProfilePageDescription from "../../ProfilePageDescription.js";

const TopHeader = styled.div`
  display: flex;
  flex-direction: row;
  gap: 40px;
  width: 100vw;
  height: auto;
  z-index: 10;
  justify-content: center;
  padding: 40px 30px;
  color: var(--headerTextColor);
  padding-bottom: 24px;
  padding-top: 80px;

  @media (max-width: 900px) {
    padding: 20px;

    padding-bottom: 64px;
    height: auto;
    flex-direction: column;
    gap: 20px;
  }
`;

const TopRow = styled.div`
  /* font-family: "playfair-display", serif; */
  /* font-family: "Inter", sans-serif; */
  height: 70px;
  font-family: "Montserrat", sans-serif;
  /* font-style: italic; */

  border-bottom: 1px solid var(--translucentHard);
  font-weight: 900;
  display: flex;
  font-size: 37px;
  gap: 10px;
  margin-bottom: 0;
  flex-direction: row;
  /* justify-content: center; */
  flex: 1;

  cursor: pointer;

  @media (max-width: 900px) {
    font-size: 23px;
    flex-direction: column;
    /* border: no; */
    align-items: center;
  }
`;

const SocialLinks = styled.div`
  display: flex;
  flex-direction: row;
  height: 100%;
  gap: 20px;
  padding-left: 20px;
  align-items: center;

  @media (max-width: 900px) {
    padding: 20px 0;
  }
`;

const Name = styled.div`
  height: 100%;
  display: flex;
  padding-left: 20px;
  font-size: 25px;
  flex-direction: column;
  justify-content: center;
  border-right: 1px solid var(--translucentHard);
  /* font-family: "playfair-display", serif; */
  /* font-family: "Inter", sans-serif; */
  padding: 10px 20px;

  @media (max-width: 900px) {
    border: none;
    border-bottom: 1px solid var(--translucentHard);
    width: 90vw;
    justify-content: center;
    align-items: center;
    padding: 20px 0;
  }
`;

const Description = styled.div`
  font-size: 22px;
  opacity: 0.9;
  line-height: 35px;
  width: 90%;
  font-family: "Montserrat", sans-serif;

  @media (max-width: 900px) {
    text-align: center;
    font-size: 18px;
    margin-top: 0;
    margin-bottom: 0;
    width: 80vw;
  }
`;

const RowOne = styled.div`
  display: flex;

  gap: 50px;
  align-items: center;
  gap: 0px;
  border: 1px solid var(--translucentHard);
  border-radius: 10px;
  overflow: hidden;
  justify-content: space-between;
  flex-direction: row;

  @media (max-width: 900px) {
    flex-direction: column-reverse;
  }
`;

const RowTwo = styled.div`
  display: flex;
  padding: 5px 20px;
  flex-direction: row;
  flex: 1;
  justify-content: flex-start;
  align-items: center;
  margin-top: 0;
  gap: 25px;

  @media (max-width: 900px) {
    flex-wrap: wrap;
  }
`;

const Main = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
  width: 62vw;
  padding-bottom: 30px;

  @media (max-width: 900px) {
    width: 90vw;
  }
`;

const MainColumn = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 30px;
  gap: 20px;
`;

const HelloText = styled.div`
  display: flex;
  font-weight: 100;
  font-size: 25px;
  /* flex-direction: ; */
`;

const MainText = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  /* font-family: "playfair-display"; */
  gap: 5px;
  font-size: 35px;

  @media (max-width: 900px) {
    align-items: center;
  }
`;

export default function VerticalFourHeader({ profileData }) {
  let { setForm, loggedInUser } = useContext(Context);

  let buttonHeight = "40px";

  let textButtonStyle = { height: buttonHeight };
  let followButtonStyle = textButtonStyle;

  let locationStr = getLocationString(profileData);

  let btnSize0 = "25px";

  let btnSize = "45px";

  let socialLinkStyle = {
    height: btnSize0,
    border: "none",
    fontSize: "19px",
    backdropFilter: "unset",
    width: btnSize0,
  };

  let socialLinks = renderSocialLinks(profileData.profileMetaData.socialLinks, {
    style: socialLinkStyle,
    variant: "outlined",
  });

  if (!profileData) return <LoadingSection />;

  let followBtn = (
    <FollowButton style={followButtonStyle} userID={profileData.user._id} />
  );

  return (
    <TopHeader>
      <Main>
        <RowOne>
          <MainText>
            <TopRow>
              <Name onClick={goTo(getProfileLink(profileData.user.username))}>
                {profileData.profileMetaData.name}
              </Name>
              <SocialLinks>{socialLinks}</SocialLinks>
            </TopRow>

            <ProfilePageDescription
              style={{
                padding: "20px 20px",
                paddingRight: "50px",
                borderBottom: `1px solid var(--translucentHard)`,
                width: "100%",
                flex: 1,
              }}
              mobileStyle={`
              padding:50px !important;
              width:90% !important;
              `}
              profileData={profileData}
            />

            <RowTwo>
              {followBtn}
              {renderLinks()}
            </RowTwo>
          </MainText>

          <ProfilePicture
            height={"300px"}
            width={"300px"}
            style={{ borderRadius: 0 }}
            mobileStyle={`width:100% !important`}
            imageObj={profileData.profileMetaData.profileImage}
          />
        </RowOne>
      </Main>
    </TopHeader>
  );

  function renderLinks() {
    let toReturn = [];

    if (!profileData.LINK) return [];

    let rearrangedContent = rearrangeContent({
      profileMetaData: profileData.profileMetaData,
      list: profileData.LINK,
      type: "LINK",
    });

    for (let item of rearrangedContent) {
      toReturn.push(
        <CustomButton
          hoverVariant="SCALE_DOWN"
          circular={true}
          style={{
            color: "var(--headerTextColor)",
            padding: "0 30px",
            backdropFilter: "unset",
          }}
          height={btnSize}
          // width={linkButtonWidth}
          onClick={goTo(item.content.externalLink, { isAbsolute: true })}
        >
          {item.title}
        </CustomButton>
      );
    }

    return toReturn;
  }
}
