import styled from "styled-components";
import getImageURL from "../../controllers/getImageURL";
import goTo from "../../controllers/goTo";
import getStaticImageURL from "../../controllers/getStaticImageURL";
import capitalizeFirstLetter from "../../controllers/capitalizeFirstLetter";
import { useContext } from "react";
import Context from "../../Context";
import ContentCardBoilerplate from "./ContentCardBoilerplate";

const Container = styled.div`
  width: 100%;

  padding: 25px;
  flex-direction: column;
  border-radius: 10px;
  display: flex;
  cursor: pointer;
  gap: 25px;
  font-size: 21px;
  line-height: 30px;

  @media (max-width: 900px) {
    gap: 10px;
    font-size: 18px;
    padding: 15px;
  }
`;

const Branding = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 15px;
`;

const Image = styled.img`
  border-radius: 5px;
  height: 40px;
  width: 40px;
  object-fit: cover;
`;

const ProjectName = styled.div`
  font-size: 23px;
  display: flex;
  font-weight: 700;
  justify-content: center;
  text-transform: capitalize;
  align-items: center;

  @media (max-width: 900px) {
    font-size: 18px;
  }
`;

const ProjectTagline = styled.div``;

const ProjectDescription = styled.div`
  opacity: 0.5;
`;

const MainContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  font-size: 19px;

  @media (max-width: 900px) {
    gap: 0px;
    font-size: 15px;
  }
`;

export default function ServiceCard({ item, variant }) {
  const { setPopupFeedItem } = useContext(Context);

  let theImage = null;

  if (item.images) {
    if (item.images[0]) {
      theImage = <Image src={getImageURL(item.images[0])} />;
    }
  }

  return (
    <ContentCardBoilerplate isService={true} item={item} variant={variant}>
      <Container>
        {theImage}
        <MainContent>
          <ProjectTagline> {item.title} </ProjectTagline>
          <ProjectDescription>{item.content.description}</ProjectDescription>

          {variant == "FEED" ? (
            <ProjectDescription>
              By {capitalizeFirstLetter(item.author.username)}
            </ProjectDescription>
          ) : null}
        </MainContent>
      </Container>
    </ContentCardBoilerplate>
  );
}
