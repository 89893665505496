import { styled } from "styled-components";

import { AiOutlineSend } from "react-icons/ai";
import { useContext, useEffect, useState } from "react";

import MessageBox from "./MessageBox";

import Context from "../../Context";
import getImageURL from "../../controllers/getImageURL";
import reachChatPanelEnd from "../../controllers/reachChatPanelEnd";
import { nanoid } from "nanoid";
import getOppositeMemberID from "../../controllers/getOppositeMemberID";
import OnlineOfflineStatus from "./OnlineOfflineStatus";
import onChangeStop from "../../controllers/onChangeStop";
import selectFile from "../../controllers/selectFile";
import compressAndUploadFile from "../../controllers/compressAndUploadFile";
import { GoImage } from "react-icons/go";
import goTo from "../../controllers/goTo";
import { BsChevronLeft } from "react-icons/bs";
import MaterialInput from "../helperComponents/MaterialInput";
import CustomButton from "../helperComponents/CustomButton";
import LoadingSection from "../helperComponents/LoadingSection";
import { serverLine } from "../../controllers/serverLine";
import goToProfile from "../../controllers/goToProfile";
import getProfileLink from "../../controllers/getProfileLink";
import SemanticButton from "../helperComponents/SemanticButton";

const Container = styled.div`
  height: 100vh;
  display: flex;
  position: relative;
  flex-direction: column;

  border-right: 1px solid var(--translucentHard);

  @media (max-width: 900px) {
    height: calc(100vh - var(--headerHeight));
    height: calc(100dvh - var(--headerHeight));

    border: none;
    ${({ groupID }) => {
      if (!groupID) return `display: none;`;
    }}
  }
`;
const TopPart = styled.div`
  padding: 20px 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 30px;
  border-bottom: 1px solid var(--translucentHard);
  width: 100%;
`;

const TopProfile = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;
  transition: all 0.15s ease-in;
  &:hover {
    text-decoration: underline;
    transform: scale(0.9);
  }
`;

const Messages = styled.div`
  display: flex;
  flex: 1;
  width: 100%;
  overflow-y: scroll;
  flex-direction: column;
  gap: 25px;
  padding: 20px 20px;
`;
const BottomPart = styled.div`
  /* height: 100px; */
  width: 100%;
  padding: 10px;
  align-items: center;
  gap: 10px;

  justify-content: space-between;
  min-height: var(--headerHeight);
  padding: 0 20px;
  display: flex;
  flex-direction: row;
  border-top: 1px solid var(--translucentHard);

  @media (max-width: 900px) {
    position: fixed;
    padding: 5px;
    bottom: 00;
    z-index: 6;
    /* background: var(--translucent); */
    backdrop-filter: blur(100px);
  }
`;
const Image = styled.img`
  height: 47px;
  width: 47px;
  object-fit: cover;
  border-radius: 50px;
`;
const Name = styled.div`
  font-size: 15px;
  text-transform: capitalize;
  font-weight: 900;
`;

const Notice = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  font-size: 27px;
  opacity: 0.7;
  font-size: 900;
  justify-content: center;
  align-items: center;
`;

const TextSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const SubHeading = styled.div`
  display: flex;
  flex-direction: row;
  opacity: 0.5;
  gap: 15px;
  font-size: 13px;
`;

const Typing = styled.div``;

const Username = styled.div``;

const BackButton = styled.div`
  opacity: 0.5;
  @media (min-width: 900px) {
    display: none;
  }
`;

export default function ChatPanel({
  groupID,
  selectedGroupData,
  addMessage,
  markAsSent,
  friendsLastSeenAt,
  oppositeMemberLastTypedAt,
}) {
  const [messageText, setMessageText] = useState("");
  const { loggedInUserID } = useContext(Context);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    console.log("Scrolling to the end");
    reachChatPanelEnd();
  }, [selectedGroupData]);

  if (selectedGroupData == "LOADING")
    return (
      <Container groupID={true}>
        <LoadingSection />
      </Container>
    );

  if (!selectedGroupData)
    return (
      <Container groupID={groupID}>
        <Notice> Please select a chat to start messaging </Notice>
      </Container>
    );

  let group = selectedGroupData.group;
  let oppositeMember = selectedGroupData.oppositeMember;
  let messages = selectedGroupData.messages;

  let theBottomPart = (
    <>
      <MaterialInput
        onEnter={postMessage}
        label={"Type Message Here"}
        onChange={updateMessage}
        value={messageText}
        maxRows={5}
        multiline={true}
      />

      <CustomButton
        width="50px"
        height="50px"
        variant="outlined"
        onClick={uploadImage}
        icon={<GoImage />}
      />

      <CustomButton
        width="50px"
        height="50px"
        variant="outlined"
        onClick={postMessage}
        icon={<AiOutlineSend />}
      />
    </>
  );

  return (
    <Container groupID={groupID}>
      <TopPart>
        <BackButton>
          <CustomButton
            variant="minimal"
            height="50px"
            fontSize="20px"
            onClick={goTo(-1)}
            icon={<BsChevronLeft />}
          />
        </BackButton>

        <SemanticButton
          semanticHref={getProfileLink(oppositeMember.username)}
          onClick={goToProfile(oppositeMember.username)}
        >
          <TopProfile>
            <Image src={getImageURL(oppositeMember.profileImage, true)} />
            <TextSection>
              <Name>{oppositeMember.name}</Name>
              <SubHeading>
                <OnlineOfflineStatus
                  groupID={selectedGroupData.group.groupID}
                  friendsLastSeenAt={friendsLastSeenAt}
                />
                <Username> @{oppositeMember.username}</Username>

                {getTypingStatus()}
              </SubHeading>
            </TextSection>
          </TopProfile>
        </SemanticButton>
      </TopPart>

      <Messages id="chat-panel">
        {messages.map((item) => (
          <MessageBox
            group={group}
            message={item}
            oppositeMember={oppositeMember}
          />
        ))}
      </Messages>

      <BottomPart>{loading ? <LoadingSection /> : theBottomPart}</BottomPart>
    </Container>
  );

  function getTypingStatus() {
    if (!oppositeMemberLastTypedAt) return null;

    let theDate = new Date(oppositeMemberLastTypedAt).getTime();
    let now = new Date().getTime();

    if (now - theDate < 3000) return <Typing>Typing...</Typing>;
  }

  function updateMessage(e) {
    setMessageText(e.target.value);
    sendTypingMessage();
    onChangeStop(selectedGroupData.group.groupID, () => {
      sendTypingMessage(true);
    });
  }

  function sendTypingMessage(hasStopped = false) {
    if (window.chatSocket) {
      window.chatSocket.emit("typing", {
        hasStopped: hasStopped,
        receiverUserID: getOppositeMemberID(
          selectedGroupData.group.groupID.split("-"),
          loggedInUserID
        ),
      });
    }
  }

  async function uploadImage() {
    let pseudoID = nanoid();

    let images = await selectFile({ onlyImage: true });

    if (!images) return null;

    if (!window.localImages) {
      window.localImages = {};
    }

    let theImage = images[0];

    setLoading(true);

    window.localImages[pseudoID] = theImage;

    if (theImage) {
      let fileData = await compressAndUploadFile(null, theImage);

      let theImageData = { type: "USER_UPLOAD", data: fileData.fileName };

      addMessage({
        message: { image: theImageData },
        pseudoID: pseudoID,
        isNew: true,
        authorUserID: loggedInUserID,
      });

      setLoading(false);

      reachChatPanelEnd();

      serverLine
        .post("/message", {
          message: { image: theImageData },
          groupID: group.groupID,
        })
        .then(() => {
          markAsSent(pseudoID);
        });
    }
  }

  function postMessage() {
    if (!messageText) return window.doAlert("Message can't be empty");
    if (!messageText.trim()) return window.doAlert("Message can't be empty");

    let pseudoID = nanoid();

    serverLine
      .post("/message", {
        message: { text: messageText },
        groupID: group.groupID,
      })
      .then(() => {
        markAsSent(pseudoID);
      });

    addMessage({
      message: { text: messageText },
      pseudoID: pseudoID,
      isNew: true,
      authorUserID: loggedInUserID,
    });

    reachChatPanelEnd();
    setMessageText("");
  }
}

/*
    TODO
    * Show My Messages along with other messages
    * Edit the message area

*/
