import { styled } from "styled-components";
import { useContext, useEffect, useState } from "react";
import Context from "../../Context";
import CustomButton from "../helperComponents/CustomButton";
import { BiCodeAlt } from "react-icons/bi";
import { PiBooks, PiSneakerMove } from "react-icons/pi";
import { serverLine } from "../../controllers/serverLine";
import { RiBrushLine, RiShape2Line } from "react-icons/ri";
import { AiOutlineVideoCamera } from "react-icons/ai";
import capitalizeFirstLetter from "../../controllers/capitalizeFirstLetter";
import { FiMusic } from "react-icons/fi";
import LoadingSection from "../helperComponents/LoadingSection";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 50px 0;
  gap: 100px;
  align-items: center;
`;

const Main = styled.div`
  display: flex;
  flex-direction: column;
  width: 38vw;
  gap: 25px;
  align-items: center;

  @media (max-width: 900px) {
    width: 90vw;
  }
`;

const Tagline = styled.h1``;

const Options = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
`;

let classificationTypes = {
  programming: <BiCodeAlt />,
  art: <RiBrushLine />,
  "graphic-design": <RiShape2Line />,
  animation: <PiSneakerMove />,
  literature: <PiBooks />,
  music: <FiMusic />,
  "video-editing": <AiOutlineVideoCamera />,
  filmmaking: <AiOutlineVideoCamera />,
  photography: <AiOutlineVideoCamera />,
  // "audio-production": <AiOutlineAudio />,
};

const Tagline2 = styled.div`
  margin-bottom: 30px;
  margin-top: -10px;
  font-size: 15px;
  opacity: 0.5;
`;

export default function AskClassificationMain() {
  const { loggedInUser, setLoggedInUser } = useContext(Context);
  const [classifications, setClassifications] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (loggedInUser) {
      if (loggedInUser.classification) {
        setClassifications(loggedInUser.classification);
      }
    }
  }, [loggedInUser]);

  console.log(classifications);

  if (loading)
    return (
      <Container>
        <LoadingSection />
      </Container>
    );

  let options = [];

  for (let key in classificationTypes) {
    options.push(
      <CustomButton
        style={{ flex: 1 }}
        variant={shouldHighlight(key)}
        icon={classificationTypes[key]}
        onClick={choose(key)}
      >
        {capitalizeFirstLetter(key)}
      </CustomButton>
    );
  }

  return (
    <Main>
      <Tagline> Please select your field of interest</Tagline>
      <Tagline2> You can select more than one</Tagline2>
      <Options>{options}</Options>
      <br />
      <CustomButton onClick={done}>Save</CustomButton>
    </Main>
  );

  function shouldHighlight(type) {
    if (classifications.includes(type)) return "STANDARD";
    return "OUTLINED";
  }

  function choose(type) {
    return () => {
      let theNewList = [...classifications];

      if (theNewList.includes(type)) {
        let theIndex = theNewList.indexOf(type);
        theNewList.splice(theIndex, 1);
      } else {
        theNewList.push(type);
      }

      setClassifications(theNewList);
    };
  }

  async function done() {
    if (!classifications.length)
      return window.doAlert("Please select at least one interest.");
    setLoading(true);
    try {
      await serverLine.patch("/profile", {
        changes: { classification: classifications },
      });

      setLoading(false);
      window.doAlert("Interest saved");
      let newLoggedInUser = { ...loggedInUser };
      newLoggedInUser.classification = classifications;
      setLoggedInUser(newLoggedInUser);
    } catch (e) {
      window.doAlert(e.message);
      setLoading(false);
    }
  }
}
