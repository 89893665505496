import styled from "styled-components";
import getImageURL from "../../../../controllers/getImageURL";
import CardButton from "./CardButton";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const Description = styled.div``;

const Title = styled.div`
  font-size: 37px;

  font-weight: 900;
`;

const Row = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  /* align-items: center; */
  gap: 25px;
`;

const Image = styled.img`
  max-height: 450px;
  object-fit: cover;
  width: 100%;
  border-radius: 15px;
`;

export default function CardVariation1({ content }) {
  return (
    <Row>
      {content.hideThumbnail ? null : (
        <Image src={getImageURL(content.image)} />
      )}

      <Container>
        <Title>{content.title}</Title>
        <Description>{content.description}</Description>
        <CardButton content={content} />
      </Container>
    </Row>
  );
}
