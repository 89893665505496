import styled, { keyframes } from "styled-components";
import { useContext, useState } from "react";

import Context from "../../Context";
import GraphDurationPaginator from "./GraphDurationPaginator";
import GraphDurationSelect from "./GraphDurationSelect";
import shadeColor from "../../controllers/stat/shadeColor";
import getDateRange from "../../controllers/stat/getDateRange";
import getXAxisForHozGraph from "../../controllers/stat/getXAxisForHozGraph";

const Padding = styled.div`
  width: 100%;
`;

const TitleRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  margin-bottom: 25px;

  @media (max-width: 900px) {
    flex-direction: column;
    align-items: flex-start;
    gap: 25px;
  }
`;

const Title = styled.div`
  font-size: 18px;
  display: flex;
  /* opacity: 0.8; */
  font-weight: 300;
  justify-content: center;
  align-items: center;
  text-transform: capitalize;
`;

const Boxes = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  gap: 0;
  padding: 20px 25px;
  padding-left: 0;
  /* background-color: var(--translucent); */
  border-radius: 15px;
  position: relative;
  gap: 3px;
  height: 360px;
  width: calc(100% - 20px);
  margin-left: 50px;
  @media (max-width: 700px) {
    overflow-x: auto;
  }
`;

const Main = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  gap: 0;
  padding: 0;
  background-color: var(--translucent);
  border-radius: 15px;
  position: relative;
  height: 360px;
`;

const Item = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;

  align-items: center;
  gap: 5px;
`;

const YAxis = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  opacity: 0.3;
  gap: 15px;
  justify-content: space-between;
  position: absolute;
  left: 13px;
  height: 82%;
  top: 20px;
`;

const scaleBar = (height) => keyframes`
    0% {
        height : 0; 
    }
    100% {
      height : ${height};
    }
`;

const ColorfulBar = styled.div`
  width: 60px;
  background: steelblue;
  border-radius: 250px 250px 5px 5px;
  animation: ${(props) => scaleBar(props.height)} 0.25s ease-out;
  min-height: 5px;
  width: ${({ dataLength }) => {
    let gap = 5;
    return `calc((100vw - 500px - ${dataLength * gap}px) / ${dataLength} )`;
  }};

  @media (max-width: 700px) {
    width: ${({ dataLength }) => {
      let screenWidth = "90vw";

      let gap = 7;
      if (dataLength >= 30) {
        gap = 0;
        screenWidth = "150vw";
      }

      if (localStorage.getItem("extension-login")) screenWidth = "100vw";
      return `calc((${screenWidth} - 60px - ${
        dataLength * gap
      }px) / ${dataLength} )`;
    }};
  }
`;

const TopLabel = styled.div`
  opacity: 0.5;
`;

const BottomLabel = styled.div`
  opacity: 0.3;
  height: 10px;
  font-size: 13px;
  margin-bottom: 5px;

  @media (max-width: 900px) {
    font-size: 10px;
  }
`;

const AxisData = styled.div`
  font-size: 13px;
`;

const AxisUnit = styled.div`
  position: absolute;
  bottom: -25px;
  font-size: 13px;
`;

const Actions = styled.div`
  display: flex;
  gap: 15px;
`;

const Message = styled.div``;

const TotalTime = styled.div`
  display: flex;
  flex-direction: row;
  font-size: 15px;
  gap: 0;
  border: none;
  background: transparent;
  border-radius: 15px;
  padding: 0 15px;
  height: 40px;
  align-items: center;
  overflow: hidden;

  background: var(--translucent);

  @media (max-width: 700px) {
    display: none;
  }
`;

const selectOptions = [
  { value: "WEEK", label: "Week" },
  { value: "MONTH", label: "Month" },
  { value: "YEAR", label: "Year" },
  { value: "EVER", label: "All Time" },
];

export default function HorizontalBarGraph({
  dataProcessor = () => {},
  title = "Demo Stat",
  rawData,
  fields,
}) {
  const [timeSpan, setTimeSpan] = useState({ value: "MONTH", label: "Month" });
  const [pagination, setPagination] = useState(0);

  if (!rawData) return <Message>No data to show</Message>;

  let dateRange = getDateRange({ timeSpanType: timeSpan.value, pagination });

  let total = 0;

  let options = null;

  let data = null;

  let content = getActions();

  let xAxis = getXAxisForHozGraph(dateRange);
  let processedData = dataProcessor({ timeSpan, xAxis, rawData, fields });

  console.log(processedData, title);

  data = processedData.data;
  total = processedData.total;

  content = (
    <Main>
      {getYAxisComp()} <Boxes>{getStatBoxes()}</Boxes>{" "}
    </Main>
  );

  options = getActions();

  return (
    <Padding>
      <TitleRow>
        <Title>{title}</Title>

        {options}
      </TitleRow>

      {content}
    </Padding>
  );

  function getActions() {
    return (
      <Actions>
        <GraphDurationPaginator
          rangeLabel={dateRange.rangeLabel}
          setValue={setPagination}
          value={pagination}
        />
        {total ? <TotalTime>Total: {total}</TotalTime> : null}
        <GraphDurationSelect
          options={selectOptions}
          value={timeSpan}
          setValue={(val) => {
            setTimeSpan(val);
            setPagination(0);
          }}
        />
      </Actions>
    );
  }

  function getYAxisComp() {
    let maxVal = getMaxValue();

    let val1 = maxVal;

    let oneThird = Math.round((maxVal / 3) * 10) / 10;

    let val2 = oneThird * 2;
    let val3 = oneThird;
    let val4 = 0;

    return (
      <YAxis>
        <AxisData>{forceUnit(val1)}</AxisData>

        <AxisData>{forceUnit(val2)} </AxisData>
        <AxisData>{forceUnit(val3)}</AxisData>

        <AxisData>{forceUnit(val4)}</AxisData>
        <AxisUnit>Views</AxisUnit>
      </YAxis>
    );
  }

  function forceUnit(value) {
    if (value > 1000) {
      let newVal = value / 1000;
      newVal = Math.round(newVal * 10) / 10;
      return `${newVal}K`;
    }
    return value;
  }

  function getStatBoxes() {
    let theList = [];

    if (!data) return [];

    let maxVal = getMaxValue();

    let i = -1;
    for (let item of data) {
      i++;

      theList.push(
        <StatBox maxVal={maxVal} item={item} data={data} index={i} />
      );
    }

    return theList;
  }

  function getMaxValue() {
    let maxVal = 0;

    for (let item of data) {
      if (item.value > maxVal) maxVal = item.value;
    }

    return maxVal;
  }
}

const AltInfo = styled.div`
  position: absolute;
  left: 10%;
  z-index: 555;
  border-radius: 25px;
  font-size: 15px;
  width: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 5px;
  padding: 5px 15px;
  background: var(--bgColor2);
  color: var(--color);
`;

function StatBox({ item, maxVal, data, index }) {
  const [hoverStatus, setHoverStatus] = useState(false);

  let maxHeight = 300;
  let height = item.value / maxVal;
  height = height * maxHeight;

  if (item.value === 0) height = 5;

  let bgColor = "#B23B3B";

  bgColor = shadeColor(bgColor, (item.value / maxVal) * 100);

  let bottomLabelOmits = 1;
  if (data.length > 12 && window.innerWidth < 900) bottomLabelOmits = 2;

  let label = (
    <>
      {index % bottomLabelOmits === 0 ? (
        <BottomLabel>{item.bottomLabel}</BottomLabel>
      ) : (
        <BottomLabel></BottomLabel>
      )}
    </>
  );

  return (
    <Item
      onMouseOver={() => setHoverStatus(true)}
      onMouseOut={() => setHoverStatus(false)}
    >
      {hoverStatus ? <AltInfo>{item.altInfo}</AltInfo> : null}

      <ColorfulBar
        height={height + "px"}
        dataLength={data.length}
        style={{
          height: height + "px",
          backgroundColor: bgColor,
        }}
      />
      {label}
    </Item>
  );
}
