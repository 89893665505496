import styled from "styled-components";
import { useContext, useState } from "react";
import { useEffect } from "react";
import { serverLine } from "../../controllers/serverLine";
import Context from "../../Context";
import NotificationBox from "./NotificationBox";
import InfiniteScroll from "../helperComponents/InfiniteScroll";
import goTo from "../../controllers/goTo";
import LoadingSection from "../helperComponents/LoadingSection";
import goToProfile from "../../controllers/goToProfile";
import SemanticButton from "../helperComponents/SemanticButton";
import getProfileLink from "../../controllers/getProfileLink";

const Notification = styled.div`
  background: var(--translucent);
  padding: 25px 25px;
  /* cursor: pointer; */
  width: 100%;
  border-radius: 15px;

  /* text-transform: capitalize; */
  background: var(--translucent);
  color: var(--color);
  display: flex;
  flex-direction: column;
  gap: 25px;

  ${({ seen }) => {
    if (!seen)
      return `
      background:var(--color);
      color:var(--bgColor);`;
  }}
`;

const Button = styled.button`
  /* flex: 1; */
  position: relative;
  background-color: var(--translucent);
  border: none;
  font-size: 15px;
  padding: 10px 25px;
  color: var(--color);
  cursor: pointer;
  border-radius: 10px;
  transition: all 0.05s ease-in-out;

  &:hover {
    transform: scale(0.95);
  }

  ${({ seen }) => {
    if (!seen)
      return `
 

      color: var(--color);
      background-color: var(--bgColor);
      border: 1px solid var(--color);

  
      
      `;
  }}
`;

const Message = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 100px;
  opacity: 0.5;
`;

export default function NotificationsCore({ type, scrollData }) {
  const { loggedInUserID, loggedInUser, updateNotificationData } =
    useContext(Context);
  const [res, setRes] = useState([]);
  const [notificationsSeenAt, setNotificationsSeenAt] = useState(null);
  const [loading, setLoading] = useState(true);
  const [offset, setOffset] = useState(0);
  const [reachedTheEnd, setReachedTheEnd] = useState(false);

  let api = "/notifications";

  type = type.toLowerCase();

  if (type == "work") api = "/work-notifications";

  useEffect(() => {
    setOffset(0);
    setNotificationsSeenAt(null);
    setRes([]);
    setLoading(true);
  }, [type]);

  useEffect(() => {
    if (loggedInUserID) {
      doLoading(true).then(updateNotificationData);
    }
  }, [loggedInUserID, type]);

  async function doLoading(firstLoad, theOffset = offset) {
    if (firstLoad) theOffset = 0;
    setLoading(true);
    let newRes = await serverLine.get(`${api}/?offset=${theOffset}`);

    if (firstLoad) {
      setNotificationsSeenAt(newRes.notificationsSeenAt);
    }
    if (!newRes.notifs.length) {
      setReachedTheEnd(true);
    }

    if (firstLoad) {
      setRes([...newRes.notifs]);
    } else {
      setRes([...res, ...newRes.notifs]);
    }

    setLoading(false);

    setOffset(theOffset + 1);
    return true;
  }

  if (!loggedInUserID) return "Login to continue";

  if (!loggedInUser) return <LoadingSection />;

  let result = renderResult();

  if (!result.length && !loading)
    return <Message>No notification to show</Message>;

  return (
    <InfiniteScroll
      reachedTheEnd={reachedTheEnd}
      loading={loading}
      load={doLoading}
      scrollData={scrollData}
    >
      {result}
    </InfiniteScroll>
  );

  function renderResult() {
    if (!res) return [];
    if (!res.length) return [];
    return res.map((item) => {
      return getNotif({ item });
    });
  }

  function getNotif({ item }) {
    let seen = hasSeen(item.createdAt);

    // return JSON.stringify(item);

    let noSenderNotifs = ["CONTENT_BAN", "CONTENT_UN_BAN", "CONTENT_REPORT"];

    if (!item.sender && !noSenderNotifs.includes(item.type))
      return (
        <Notification seen={seen}>
          The Person involved has deleted the account. Type: {item.type}{" "}
        </Notification>
      );

    let notificationText = null;
    let notificationImage = null;
    let notificationKey = item._id;
    let createdAt = item.createdAt;
    let notificationButtons = null;

    if (item.type == "FOLLOW") {
      notificationImage = item.sender.profileImage;
      notificationButtons = (
        <>
          <SemanticButton
            semanticHref={getProfileLink(item.sender.username)}
            onClick={goToProfile(item.sender.username)}
          >
            <Button seen={seen}>Visit Profile</Button>
          </SemanticButton>
        </>
      );
      notificationText = `${item.sender.username} started following you`;
    } else if (item.type === "LIKE") {
      notificationImage = item.sender.profileImage;
      notificationButtons = (
        <>
          <SemanticButton
            semanticHref={getProfileLink(item.sender.username)}
            onClick={goToProfile(item.sender.username)}
          >
            <Button seen={seen}>Open Their Profile</Button>
          </SemanticButton>
          <SemanticButton
            semanticHref={"/content/" + item.contentID}
            onClick={goTo("/content/" + item.contentID)}
          >
            <Button seen={seen}>Open The Post</Button>
          </SemanticButton>
        </>
      );

      let contentTitle = "Untitled";

      if (item.postContent) {
        if (item.postContent.title) {
          contentTitle = item.postContent.title;
        }

        if (item.postContent.type == "LIFE_STORY") {
          contentTitle = "Life Story";
        }
      }

      notificationText = `${item.sender.username} has liked your post: ${contentTitle}`;
    } else if (item.type === "COMMENT") {
      notificationImage = item.sender.profileImage;
      notificationButtons = (
        <>
          <SemanticButton
            semanticHref={getProfileLink(item.sender.username)}
            onClick={goToProfile(item.sender.username)}
          >
            <Button seen={seen}>Open Their Profile</Button>
          </SemanticButton>
          <SemanticButton
            semanticHref={"/content/" + item.contentID}
            onClick={goTo("/content/" + item.contentID)}
          >
            <Button seen={seen}>Open The Post</Button>
          </SemanticButton>
        </>
      );

      let contentTitle = "Untitled";

      if (item.postContent) {
        if (item.postContent.title) {
          contentTitle = item.postContent.title;
        }

        if (item.postContent.type == "LIFE_STORY") {
          contentTitle = "Life Story";
        }
      }

      let commentText = "Comment not found";

      if (item.content) {
        if (item.content.text) {
          commentText = item.content.text;
        }
      }

      notificationText = `${item.sender.username} has commented your post: ${contentTitle}. Saying: ${commentText}`;
    } else if (item.type === "REPLY") {
      notificationImage = item.sender.profileImage;
      notificationButtons = (
        <>
          <SemanticButton
            semanticHref={getProfileLink(item.sender.username)}
            onClick={goToProfile(item.sender.username)}
          >
            <Button seen={seen}>Open Their Profile</Button>
          </SemanticButton>
          <SemanticButton
            semanticHref={"/content/" + item.contentID}
            onClick={goTo("/content/" + item.contentID)}
          >
            <Button seen={seen}>Open The Post</Button>
          </SemanticButton>
        </>
      );

      let contentTitle = "Untitled";

      if (item.postContent) {
        if (item.postContent.title) {
          contentTitle = item.postContent.title;
        }

        if (item.postContent.type == "LIFE_STORY") {
          contentTitle = "Life Story";
        }
      }

      let commentText = "Comment not found";

      if (item.content) {
        if (item.content.text) {
          commentText = item.content.text;
        }
      }

      notificationText = `${item.sender.username} has replied to your comment on the post: ${contentTitle}. Saying: ${commentText}`;
    } else if (item.type === "BID_RECEIVED") {
      notificationImage = item.sender.profileImage;
      notificationButtons = (
        <SemanticButton
          semanticHref={"/service/" + item.serviceID}
          onClick={goTo("/service/" + item.serviceID)}
        >
          <Button seen={seen}>Open</Button>
        </SemanticButton>
      );
      notificationText = `${item.sender.username} has sent you a bid`;
    } else if (item.type === "JOB_BID_RECEIVED") {
      notificationImage = item.sender.profileImage;

      notificationButtons = (
        <SemanticButton
          semanticHref={"/job/" + item.jobID}
          onClick={goTo("/job/" + item.jobID)}
        >
          <Button seen={seen}>Open</Button>
        </SemanticButton>
      );

      notificationText = `${item.sender.username} has sent you an application for the job you
      posted`;
    } else if (item.type === "OFFER_RECEIVED") {
      notificationImage = item.sender.profileImage;
      notificationButtons = (
        <SemanticButton
          semanticHref={"/service/" + item.serviceID}
          onClick={goTo("/service/" + item.serviceID)}
        >
          <Button seen={seen}>Open</Button>
        </SemanticButton>
      );
      notificationText = `${item.sender.username} has sent you an offer.`;
    } else if (item.type === "OFFER_ACCEPTED") {
      notificationImage = item.sender.profileImage;
      notificationButtons = (
        <SemanticButton
          onClick={goTo("/service/" + item.serviceID)}
          semanticHref={"/service/" + item.serviceID}
        >
          <Button seen={seen}>Open</Button>
        </SemanticButton>
      );
      notificationText = `${item.sender.username} has accepted your contract offer`;
    } else if (item.type === "OFFER_DECLINED") {
      notificationImage = item.sender.profileImage;
      notificationButtons = (
        <>
          <SemanticButton
            onClick={goTo("/service/" + item.serviceID)}
            semanticHref={"/service/" + item.serviceID}
          >
            <Button seen={seen}>Open</Button>
          </SemanticButton>
        </>
      );
      notificationText = `${item.sender.username} declined your contract offer`;
    } else if (item.type === "PAYMENT_CONFIRMED") {
      notificationImage = item.sender.profileImage;
      notificationButtons = (
        <>
          <SemanticButton
            onClick={goTo("/service/" + item.serviceID)}
            semanticHref={"/service/" + item.serviceID}
          >
            <Button seen={seen}>Open</Button>
          </SemanticButton>
        </>
      );
      notificationText = `${item.sender.username} has confirmed that payment has been received.`;
    } else if (item.type === "REVIEW_RECEIVED") {
      notificationImage = item.sender.profileImage;
      notificationButtons = (
        <>
          <SemanticButton
            onClick={goTo("/service/" + item.serviceID)}
            semanticHref={"/service/" + item.serviceID}
          >
            <Button seen={seen}>Show Review</Button>
          </SemanticButton>
        </>
      );
      notificationText = `${item.sender.username} sent you a review`;
    } else if (item.type === "CONTENT_REPORT") {
      notificationButtons = (
        <>
          <SemanticButton
            onClick={goTo("/content/" + item.contentID)}
            semanticHref={"/content/" + item.contentID}
          >
            <Button seen={seen}>Visit Content</Button>
          </SemanticButton>
        </>
      );

      if (item.content) {
        notificationText = `Your Content '${
          item.content.title
        }' got reported. Reason: ${item.data ? item.data.reason : ""}`;
      } else {
        notificationText = `Your Content got reported.`;
      }
    } else if (item.type === "CONTENT_UN_BAN") {
      notificationButtons = (
        <>
          <SemanticButton
            onClick={goTo("/content/" + item.contentID)}
            semanticHref={"/content/" + item.contentID}
          >
            <Button seen={seen}>Visit Content</Button>
          </SemanticButton>
        </>
      );

      if (item.content) {
        notificationText = `Your Content '${item.content.title}' has been un-banned.`;
      } else {
        notificationText = `Your Content got un-banned.`;
      }
    } else if (item.type === "CONTENT_BAN") {
      notificationButtons = (
        <>
          <SemanticButton
            onClick={goTo("/content/" + item.contentID)}
            semanticHref={"/content/" + item.contentID}
          >
            <Button seen={seen}>Visit Content</Button>
          </SemanticButton>
        </>
      );

      if (item.content) {
        notificationText = `Your Content '${item.content.title}' has been banned.`;
      } else {
        notificationText = `Your Content got banned.`;
      }
    } else {
      // notificationImage = item.sender.profileImage;
      notificationButtons = null;
      notificationText = `${item.sender ? item.sender.username : null} - ${
        item.type
      }`;
    }

    return (
      <NotificationBox
        key={notificationKey}
        createdAt={createdAt}
        seen={seen}
        text={notificationText}
        buttons={notificationButtons}
        image={notificationImage}
      />
    );
  }

  function hasSeen(createdAt) {
    let createdAtEpochs = new Date(createdAt).valueOf();
    let notificationsSeenAtDate = new Date(
      notificationsSeenAt ? notificationsSeenAt : 0
    ).valueOf();
    // console.log(createdAtEpochs, notificationsSeenAt);

    return createdAtEpochs < notificationsSeenAtDate;
  }
}
