import { styled } from "styled-components";
import BrandContainer from "../brand/BrandContainer.js";
import CustomButton from "../helperComponents/CustomButton.js";
import { AiOutlineCheck, AiOutlineNodeExpand } from "react-icons/ai";
// import BasicInfoEditor from "../BasicInfoEditor.js";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 50px 0;
  gap: 25px;
  height: 100vh;
  overflow-y: scroll;
  align-items: center;
`;

const Main = styled.div`
  width: 38vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 25px;

  @media (max-width: 900px) {
    width: 90vw;
  }
`;

const SubmitButton = styled.div`
  background: var(--translucent);
  padding: 15px 50px;
  border-radius: 15px;
  cursor: pointer;
  display: flex;
  font-size: 21px;
  margin-top: 50px;
  justify-content: center;
  align-items: center;

  @media (max-width: 900px) {
    width: 90vw;
  }
`;

const Title = styled.h2`
  margin: 0;
  text-align: center;
  font-size: 18px;
  padding: 0;
  font-weight: 100;
  margin: 20px 0;
`;

export default function OnboardingBoilerplate({ onNext, title, children }) {
  return (
    <Container>
      <BrandContainer />

      <Main>
        {/* <br /> */}
        {/* <OnboardingProgress currentSection={currentSection} /> */}
        {/* <br /> */}
        {title ? (
          <>
            <Title>{title}</Title>
          </>
        ) : null}

        {children}

        <br />
        {onNext ? (
          <CustomButton
            icon={<AiOutlineCheck />}
            width="100%"
            onClick={onNext}
            variant={"outlined"}
          >
            Next
          </CustomButton>
        ) : null}

        <br />
      </Main>
    </Container>
  );
}
