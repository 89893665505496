import MaterialInput from "../helperComponents/MaterialInput";
import DropDownSectionSelector from "../helperComponents/DropDownSectionSelector";
import { useState } from "react";
import CustomButton from "../helperComponents/CustomButton";
import { nanoid } from "nanoid";
import styled from "styled-components";
import ItemAdderByPopup from "../helperComponents/ItemAdderByPopup";
import {
  MdOutlineClose,
  MdOutlineDragHandle,
  MdOutlineDragIndicator,
} from "react-icons/md";
import { socialMediaLinkTypes } from "../../data/socialMediaLinkTypes";
import CustomListImageUploader from "./CustomListImageUploader";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  /* background-color: var(--translucent); */
  /* padding: 10px; */
  border-radius: 5px;
  /* border: 1px solid var(--translucentHard); */
`;
const Inputs = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const MainRow = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
`;

const types = [
  { label: "Skills", value: "SKILLS" },
  { label: "Coding Projects", value: "PROJECT_PROGRAMMING" },
  { label: "List", value: "LIST" },
  { label: "Card", value: "CARD" },
  { label: "Article", value: "ARTICLE" },
  { label: "Photography", value: "PROJECT_PHOTOGRAPHY" },
  { label: "Art", value: "PROJECT_ART" },
  { label: "Graphic Design", value: "PROJECT_GRAPHIC_DESIGN" },
  { label: "Literature", value: "LITERATURE" },
  { label: "Videos", value: "VIDEO" },
  { label: "Store", value: "SALEABLE" },
  { label: "My Story", value: "MY_STORY" },
];

export default function DragAndDropItem({
  data,
  onChange,
  lists,
  cards,
  adder,
  onAdd,
  type,
  onDelete,
}) {
  data = { ...data };

  if (!data.content) data.content = {};

  if (type == "SOCIAL_MEDIA_LINKS") return getInputsForSocialMediaLink();

  if (type == "PAGE_SECTIONS") return getInputsForPageSectionItem();

  function getInputsForPageSectionItem() {
    if (adder) {
      return (
        <Container>
          <ItemAdderByPopup
            title={"Add Section"}
            options={types}
            onChange={(newType) => {
              onAdd({ type: newType, subType: null, _id: nanoid() });
            }}
          />
        </Container>
      );
    } else {
      return (
        <Container>
          <DropDownSectionSelector
            onDelete={onDelete}
            placeholder="Select Type"
            isMain={true}
            title={"Section Type"}
            value={data.type}
            options={types}
            onChange={(newValue) => {
              onChange(data._id, { type: newValue });
            }}
          />

          {selectListSectionType()}
        </Container>
      );
    }
  }

  function getInputsForSocialMediaLink() {
    if (adder) {
      return (
        <ItemAdderByPopup
          title={"Add Social Media Link"}
          options={socialMediaLinkTypes}
          onChange={(newType) => {
            onAdd({ type: newType, handle: "", _id: nanoid() });
          }}
        />
      );
    } else {
      return (
        <Container>
          <MainRow>
            <CustomButton width="20px" icon={<MdOutlineDragIndicator />} />
            <MaterialInput
              value={data.handle}
              label={getPlaceholderForSocialLink(data.type)}
              onChange={(e) => {
                onChange(data._id, { handle: e.target.value });
              }}
            />
            <CustomButton icon={<MdOutlineClose />} onClick={onDelete} />
          </MainRow>
        </Container>
      );
    }
  }

  function getPlaceholderForSocialLink(type) {
    let foundType = null;

    for (let item of socialMediaLinkTypes) {
      if (item.value == type) foundType = item;
    }

    return `${foundType.label} Username`;
  }

  function getListOptions() {
    let options = [];

    for (let item of lists) {
      options.push({ label: item.title, value: item._id });
    }

    console.log(options);

    return (
      <DropDownSectionSelector
        placeholder="Select List"
        title={"Select List"}
        value={data.listID}
        options={options}
        onChange={(newValue) => {
          onChange(data._id, { listID: newValue });
        }}
      />
    );
  }

  function getCardOptions() {
    let options = [];

    if (cards) {
      for (let item of cards) {
        options.push({ label: item.title, value: item._id });
      }
    }

    return (
      <DropDownSectionSelector
        placeholder="Select Card"
        title={"Select Card"}
        value={data.cardID}
        options={options}
        onChange={(newValue) => {
          onChange(data._id, { cardID: newValue });
        }}
      />
    );
  }

  function selectListSectionType() {
    if (!data) return;
    if (data.type == "LIST") {
      return getListOptions();
    } else if (data.type == "CARD") {
      return getCardOptions();
    }
  }
}
