import {
  forwardRef,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import {
  $createParagraphNode,
  $getNodeByKey,
  $getSelection,
  $isRangeSelection,
  FORMAT_TEXT_COMMAND,
} from "lexical";
import { LuHeading1, LuHeading2 } from "react-icons/lu";

import { BsTextParagraph } from "react-icons/bs";
import { BiHeading, BiListOl, BiListUl } from "react-icons/bi";
import styled from "styled-components";
import CustomButton from "../../helperComponents/CustomButton";
import {
  AiOutlineBold,
  AiOutlineCode,
  AiOutlineDown,
  AiOutlineHighlight,
  AiOutlineItalic,
  AiOutlineOrderedList,
  AiOutlineStrikethrough,
  AiOutlineUnderline,
  AiOutlineUnorderedList,
} from "react-icons/ai";
import { TbCode } from "react-icons/tb";
import { $getNearestNodeOfType, mergeRegister } from "@lexical/utils";
import { $isLinkNode, TOGGLE_LINK_COMMAND } from "@lexical/link";
import {
  $isParentElementRTL,
  $wrapLeafNodesInElements,
  $isAtNodeEnd,
  $setBlocksType,
} from "@lexical/selection";
import {
  INSERT_ORDERED_LIST_COMMAND,
  INSERT_UNORDERED_LIST_COMMAND,
  REMOVE_LIST_COMMAND,
  $isListNode,
  ListNode,
} from "@lexical/list";
import {
  $createHeadingNode,
  $createQuoteNode,
  $isHeadingNode,
} from "@lexical/rich-text";
import {
  $createCodeNode,
  $isCodeNode,
  getDefaultCodeLanguage,
  getCodeLanguages,
} from "@lexical/code";
import { DEPRECATED_$isGridSelection } from "lexical";
import capitalizeFirstLetter from "../../../controllers/capitalizeFirstLetter";
import Context from "../../../Context";
import { PiListBullets, PiListNumbers } from "react-icons/pi";
import { MdFormatListBulleted, MdFormatListNumbered } from "react-icons/md";

const IconButton = styled.div``;

const Container = styled.div`
  display: flex;
  gap: 2px;
  flex-direction: row;
  background-color: var(--translucent);
  backdrop-filter: blur(20px);
  /* margin-top: -20px; */
  border-radius: 100px;
  position: absolute;
  z-index: 10;

  @media (max-width: 900px) {
    bottom: 0 !important;
    top: unset !important;
    height: var(--headerHeight);
    /* background-color: transparent; */
    /* backdrop-filter: blur(25px); */
    align-items: center;
    width: 100vw;
    border-radius: 0;
    padding: 10px;
    left: 0 !important;
  }
`;

let FloatingToolbarUI = forwardRef(FloatingToolbarComp);

export default FloatingToolbarUI;

let buttonVariant = "minimal";

let onActiveStyle = {
  background: "var(--translucentHard)",
  // transform: "scale(10)",
};

function FloatingToolbarComp(props, ref) {
  const { setForm } = useContext(Context);
  const { editor, coords } = props;
  const shouldShow = coords !== undefined;

  const [state, setState] = useState({
    isBold: false,
    isCode: false,
    isItalic: false,
    isStrikethrough: false,
    isUnderline: false,
  });

  const updateToolbar = useCallback(() => {
    const selection = $getSelection();
    if ($isRangeSelection(selection)) {
      const anchorNode = selection.anchor.getNode();
      const element =
        anchorNode.getKey() === "root"
          ? anchorNode
          : anchorNode.getTopLevelElementOrThrow();
      const elementKey = element.getKey();
      const elementDOM = editor.getElementByKey(elementKey);

      let newBlockType = "";
      let newElementKey = "";
      let newLanguageCode = "";

      if (elementDOM !== null) {
        newElementKey = elementKey;

        if ($isListNode(element)) {
          const parentList = $getNearestNodeOfType(anchorNode, ListNode);
          const type = parentList ? parentList.getTag() : element.getTag();
          newBlockType = type;
        } else {
          const type = $isHeadingNode(element)
            ? element.getTag()
            : element.getType();

          newBlockType = type;

          if ($isCodeNode(element)) {
            newLanguageCode = element.getLanguage() || getDefaultCodeLanguage();
          }
        }
      }

      let isLink = false;

      const node = getSelectedNode(selection);
      const parent = node.getParent();
      if ($isLinkNode(parent) || $isLinkNode(node)) {
        isLink = true;
      }

      let newState = {
        isLink: isLink,
        isBold: selection.hasFormat("bold"),
        isCode: selection.hasFormat("code"),
        isItalic: selection.hasFormat("italic"),
        isStrikethrough: selection.hasFormat("strikethrough"),
        isUnderline: selection.hasFormat("underline"),
        blockType: newBlockType,
        elementKey: newElementKey,
        languageCode: newLanguageCode,
      };

      setState(newState);
    }
  }, [editor]);

  useEffect(() => {
    const unregisterListener = editor.registerUpdateListener(
      ({ editorState }) => {
        editorState.read(() => {
          updateToolbar();

          // const selection = $getSelection();
          // if (!$isRangeSelection(selection)) return;

          // setState({
          //   isBold: selection.hasFormat("bold"),
          //   isCode: selection.hasFormat("code"),
          //   isItalic: selection.hasFormat("italic"),
          //   isStrikethrough: selection.hasFormat("strikethrough"),
          //   isUnderline: selection.hasFormat("underline"),
          // });
        });
      }
    );
    return unregisterListener;
  }, [editor]);

  const formatParagraph = () => {
    editor.update(() => {
      const selection = $getSelection();
      if (
        $isRangeSelection(selection) ||
        DEPRECATED_$isGridSelection(selection)
      ) {
        $setBlocksType(selection, () => $createParagraphNode());
      }
    });
  };

  const formatLargeHeading = (theType) => {
    return () => {
      if (state.blockType !== theType) {
        editor.update(() => {
          const selection = $getSelection();

          if ($isRangeSelection(selection)) {
            $setBlocksType(selection, () => $createHeadingNode(theType));
          }
        });
      }
    };
  };

  const formatBulletList = () => {
    if (state.blockType !== "ul") {
      editor.dispatchCommand(INSERT_UNORDERED_LIST_COMMAND);
    } else {
      editor.dispatchCommand(REMOVE_LIST_COMMAND);
    }
  };

  const formatNumberedList = () => {
    if (state.blockType !== "ol") {
      editor.dispatchCommand(INSERT_ORDERED_LIST_COMMAND);
    } else {
      editor.dispatchCommand(REMOVE_LIST_COMMAND);
    }
  };

  const formatCode = () => {
    if (state.blockType !== "code") {
      editor.update(() => {
        const selection = $getSelection();

        if ($isRangeSelection(selection)) {
          $setBlocksType(selection, () => $createCodeNode());
        }
      });
    }
  };

  // console.log(state);

  const codeLanguages = useMemo(() => getCodeLanguages(), []);

  const onCodeLanguageSelect = useCallback(
    (value) => {
      editor.update(() => {
        if (state.elementKey !== null) {
          const node = $getNodeByKey(state.elementKey);
          if ($isCodeNode(node)) {
            node.setLanguage(value);
          }
        }
      });
    },
    [editor, state.elementKey]
  );

  return (
    <Container
      ref={ref}
      className="flex items-center justify-between bg-slate-100 border-[1px] border-slate-300 rounded-md p-1 gap-1"
      aria-hidden={!shouldShow}
      style={{
        top: coords?.y,
        left: coords?.x,
        visibility: shouldShow ? "visible" : "hidden",
        opacity: shouldShow ? 1 : 0,
      }}
    >
      <CustomButton
        size="50px"
        variant={buttonVariant}
        onActiveStyle={onActiveStyle}
        icon={<BsTextParagraph />}
        isActive={state.blockType == "paragraph"}
        aria-label="Format text as paragraph"
        onClick={formatParagraph}
      />

      <CustomButton
        size="50px"
        variant={buttonVariant}
        onActiveStyle={onActiveStyle}
        icon={<LuHeading1 />}
        isActive={state.blockType == "h1"}
        aria-label="Format text as paragraph"
        onClick={formatLargeHeading("h1")}
      />

      <CustomButton
        size="50px"
        variant={buttonVariant}
        onActiveStyle={onActiveStyle}
        icon={<LuHeading2 />}
        isActive={state.blockType == "h2"}
        aria-label="Format text as paragraph"
        onClick={formatLargeHeading("h2")}
      />

      <CustomButton
        size="50px"
        variant={buttonVariant}
        onActiveStyle={onActiveStyle}
        icon={<MdFormatListBulleted />}
        isActive={state.blockType == "ul"}
        aria-label="Format text as paragraph"
        onClick={formatBulletList}
      />

      <CustomButton
        size="50px"
        variant={buttonVariant}
        onActiveStyle={onActiveStyle}
        icon={<MdFormatListNumbered />}
        isActive={state.blockType == "ol"}
        aria-label="Format text as paragraph"
        onClick={formatNumberedList}
      />

      <CustomButton
        size="50px"
        variant={buttonVariant}
        onActiveStyle={onActiveStyle}
        icon={<AiOutlineBold />}
        aria-label="Format text as bold"
        isActive={state.isBold}
        onClick={() => {
          editor.dispatchCommand(FORMAT_TEXT_COMMAND, "bold");
        }}
      />
      <CustomButton
        size="50px"
        variant={buttonVariant}
        onActiveStyle={onActiveStyle}
        icon={<AiOutlineItalic />}
        aria-label="Format text as italics"
        isActive={state.isItalic}
        onClick={() => {
          editor.dispatchCommand(FORMAT_TEXT_COMMAND, "italic");
        }}
      />
      <CustomButton
        size="50px"
        variant={buttonVariant}
        onActiveStyle={onActiveStyle}
        icon={<AiOutlineUnderline />}
        aria-label="Format text to underlined"
        isActive={state.isUnderline}
        onClick={() => {
          editor.dispatchCommand(FORMAT_TEXT_COMMAND, "underline");
        }}
      />
      <CustomButton
        size="50px"
        variant={buttonVariant}
        onActiveStyle={onActiveStyle}
        icon={<AiOutlineStrikethrough />}
        aria-label="Format text with a strikethrough"
        isActive={state.isStrikethrough}
        onClick={() => {
          editor.dispatchCommand(FORMAT_TEXT_COMMAND, "strikethrough");
        }}
      />
      <CustomButton
        size="50px"
        variant={buttonVariant}
        onActiveStyle={onActiveStyle}
        icon={<AiOutlineHighlight />}
        aria-label="Format text with inline code"
        isActive={state.isCode}
        onClick={() => {
          editor.dispatchCommand(FORMAT_TEXT_COMMAND, "code");
        }}
      />

      <CustomButton
        size="50px"
        variant={buttonVariant}
        onActiveStyle={onActiveStyle}
        icon={<TbCode />}
        aria-label="Format text with inline code"
        isActive={state.blockType == "code"}
        onClick={formatCode}
      />

      {state.blockType === "code" ? (
        <CustomButton
          style={{ margin: "0 20px" }}
          variant={buttonVariant}
          icon={<AiOutlineDown />}
          onClick={showLanguageOptions}
          aria-label="Format text with inline code"
        >
          {capitalizeFirstLetter(state.languageCode)}
        </CustomButton>
      ) : null}
    </Container>
  );

  function showLanguageOptions() {
    let options = [];

    for (let item of codeLanguages) {
      options.push({
        name: capitalizeFirstLetter(item),
        onClick: () => {
          onCodeLanguageSelect(item);
          setForm(null);
        },
      });
    }

    console.log(options);

    setForm({ options: options });
  }
}

function getSelectedNode(selection) {
  const anchor = selection.anchor;
  const focus = selection.focus;
  const anchorNode = selection.anchor.getNode();
  const focusNode = selection.focus.getNode();
  if (anchorNode === focusNode) {
    return anchorNode;
  }
  const isBackward = selection.isBackward();
  if (isBackward) {
    return $isAtNodeEnd(focus) ? anchorNode : focusNode;
  } else {
    return $isAtNodeEnd(anchor) ? focusNode : anchorNode;
  }
}
