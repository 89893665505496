import { styled } from "styled-components";
import { useContext, useEffect, useState } from "react";
import Context from "../../Context.js";
import { serverLine } from "../../controllers/serverLine.js";
import LoadingSection from "../helperComponents/LoadingSection.js";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import DragAndDropItem from "./DragAndDropItem.js";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  /* background-color: var(--translucent); */
  padding: 0;
  width: 100%;
  gap: 50px;
  margin-top: 40px;
  /* padding-top: 20px; */
  /* border-top: 1px solid var(--translucentHard); */
`;

const Items = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  /* gap: 30px; */
`;

const DraggableItem = styled.div`
  width: 100%;
  margin-bottom: 30px;
`;

const List = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const Title = styled.div`
  font-size: 18px;
  opacity: 0.5;
  /* font-weight: 900; */
`;

const TitleSection = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
`;

const Divider = styled.div`
  height: 1px;
  flex: 1;

  background-color: var(--translucentHard);
`;

export default function DragAndDropItems({ data, onChange, type }) {
  const [lists, setLists] = useState(null);
  const [cards, setCards] = useState(null);

  const { loggedInUser } = useContext(Context);

  useEffect(() => {
    if (type == "PAGE_SECTIONS") {
      loadLists();
      loadCards();
    }
  }, [type]);

  console.log(data);

  if (!loggedInUser) return <LoadingSection />;

  if (type === "PAGE_SECTIONS" && !lists) return <LoadingSection />;

  let title = getTitle();
  let adderTitle = getAddButtonText();

  return (
    <Container>
      <List>
        <TitleSection>
          <Title>{adderTitle}</Title>
          <Divider />
        </TitleSection>
        <DragAndDropItem
          type={type}
          onAdd={onAdd}
          adder={true}
          lists={lists}
          cards={cards}
        />
      </List>

      <List>
        <TitleSection>
          <Title>{title}</Title>
          <Divider />
        </TitleSection>
        {renderItems()}
      </List>
    </Container>
  );

  function getAddButtonText() {
    if (type == "PAGE_SECTIONS") return "Add Section";
    if (type == "LIST_ITEMS") return "Add Link";
    if (type == "SOCIAL_MEDIA_LINKS") return "Add Social Link";
  }

  function getTitle() {
    if (type == "PAGE_SECTIONS") return "Sections";
    if (type == "LIST_ITEMS") return "Links";
    if (type == "SOCIAL_MEDIA_LINKS") return "Social Media Links";
  }

  function onAdd(toAdd) {
    let newData = [toAdd, ...data];
    onChange(newData);
  }

  function updateItem(itemID, changes) {
    let newData = [...data];

    for (let item of newData) {
      if (item._id == itemID) {
        for (let field in changes) {
          item[field] = changes[field];
        }
      }
    }
    onChange(newData);
  }

  async function loadLists() {
    let theList = await serverLine.get("/content-list/?type=LIST").then();
    setLists(theList);
  }

  async function loadCards() {
    let newData = await serverLine.get("/content-list/?type=CARD").then();
    setCards(newData);
  }

  function moveItem(initialIndex, finalIndex) {
    let newData = [...data];
    let [itemRecorder] = newData.splice(initialIndex, 1);
    newData.splice(finalIndex, 0, itemRecorder);
    onChange(newData);
  }

  function onDelete(targetIndex) {
    return () => {
      let newData = [...data];
      newData.splice(targetIndex, 1);

      onChange(newData);
    };
  }

  function renderItems() {
    let items = [];

    for (let i = 0; i < data.length; i++) {
      let theItem = data[i];

      items.push({
        comp: (
          <DragAndDropItem
            onDelete={onDelete(i)}
            type={type}
            lists={lists}
            cards={cards}
            data={theItem}
            onChange={updateItem}
          />
        ),
        itemData: theItem,
      });
    }

    let draggableItems = [];

    let index = 0;
    for (let item of items) {
      draggableItems.push(
        <Draggable
          key={item.itemData._id}
          draggableId={item.itemData._id}
          index={index}
        >
          {(provided) => {
            return (
              <div>
                <DraggableItem
                  ref={provided.innerRef}
                  {...provided.draggableProps}
                  {...provided.dragHandleProps}
                >
                  {item.comp}
                </DraggableItem>
                {provided.placeholder}
              </div>
            );
          }}
        </Draggable>
      );

      index++;
    }

    return (
      <DragDropContext onDragEnd={handleOnDragEnd}>
        <Droppable droppableId="droppable">
          {(provided) => (
            <Items {...provided.droppableProps} ref={provided.innerRef}>
              {draggableItems}
              {provided.placeholder}
            </Items>
          )}
        </Droppable>
      </DragDropContext>
    );
  }

  function handleOnDragEnd(result) {
    console.log(result);
    if (result.destination) {
      moveItem(result.source.index, result.destination.index);
    }
  }
}
