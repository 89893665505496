import styled from "styled-components";
import getImageURL from "../../controllers/getImageURL";
import goTo from "../../controllers/goTo";
import getStaticImageURL from "../../controllers/getStaticImageURL";
import getDateString from "../../controllers/getDateString";
import ContentCardBoilerplate from "./ContentCardBoilerplate";

const Container = styled.div`
  width: 100%;
  padding: 25px;
  flex-direction: column;
  border-radius: 5px;
  display: flex;
  cursor: pointer;
  gap: 25px;
  font-size: 21px;
  line-height: 30px;
  max-width: 500px;
  cursor: pointer;

  @media (max-width: 900px) {
    font-size: 18px;
    gap: 15px;
  }
`;

const Branding = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 15px;
`;

const Logo = styled.img`
  border-radius: 5px;
  height: 30px;
  width: 30px;
  object-fit: cover;
`;
const ProjectName = styled.div``;

const ProjectTagline = styled.div``;

const ProjectDescription = styled.div`
  opacity: 0.5;
`;

const MainContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;

  @media (max-width: 900px) {
    gap: 10px;
  }
`;

export default function EducationCard({ item, variant }) {
  return (
    <ContentCardBoilerplate item={item} variant={variant}>
      <Container>
        <Branding>
          <Logo
            src={
              item.image
                ? getImageURL(item.image)
                : getStaticImageURL("default/default-education-logo.png")
            }
          ></Logo>
          <ProjectName>{item.title}</ProjectName>
        </Branding>

        <MainContent>
          <ProjectTagline>{item.content.course}</ProjectTagline>
          <ProjectTagline>
            {getDateString(item.content.from)} -{" "}
            {item.content.to ? getDateString(item.content.to) : "On Going"}
          </ProjectTagline>

          <ProjectDescription>{item.content.description}</ProjectDescription>
        </MainContent>
      </Container>
    </ContentCardBoilerplate>
  );
}
