import { useContext } from "react";
import { styled } from "styled-components";
import ContentActionButtons from "../ContentActionButtons";
import getImageURL from "../../../controllers/getImageURL";
import Context from "../../../Context";
import getPublishedOrDraftContent from "../../../controllers/getPublishedOrDraftContent";

const Container = styled.div`
  width: 50vw;
  display: flex;
  font-size: 21px;
  flex-direction: column;
  gap: 50px;

  @media (max-width: 900px) {
    width: 90vw;
  }
`;

const TopPart = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;
const MainInfo = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 25px;
`;
const Logo = styled.img`
  height: 80px;
  border-radius: 10px;
  width: 80px;
  object-fit: cover;
`;
const MainText = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 10px;
`;
const ProjectName = styled.div`
  font-size: 26px;
  font-weight: 900;
`;
const Buttons = styled.div`
  display: flex;
  gap: 15px;
  align-items: center;
  flex-direction: row;
`;
const Content = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 50px;
`;

const Description = styled.div``;

const SlideshowImage = styled.img`
  border-radius: 10px;
  width: 100%;
  /* max-height: 50vw; */
  object-fit: cover;
`;

const ImagesColumn = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 20px;
  align-items: center;
`;

export default function RenderArt({ value, setValue }) {
  const { loggedInUserID } = useContext(Context);

  let content = getPublishedOrDraftContent(value);
  content = { ...content };

  if (!content.description) {
    content.description = "Project Description Missing";
  }

  if (!content.tagline) {
    content.tagline = "Project Tagline Missing";
  }

  console.log(value);
  return (
    <Container>
      <TopPart>
        <ProjectName>{content.title}</ProjectName>
        <Description>{content.description}</Description>
      </TopPart>

      <Content>
        <ImagesColumn>{getImages()}</ImagesColumn>
      </Content>

      <br />

      <ContentActionButtons content={value} setContent={setValue} />
    </Container>
  );

  function getImages() {
    let toReturn = [<SlideshowImage src={getImageURL(content.image)} />];

    if (content.images) {
      for (let itm of content.images) {
        toReturn.push(
          <SlideshowImage
            // style={{ height: "400px", width: "auto" }}
            src={getImageURL(itm)}
          />
        );
      }
    }

    return toReturn;
  }
}
