import styled from "styled-components";
import goTo from "../../controllers/goTo";
import RenderLiteratureCore from "../editors/RenderLiteratureCore";
import { useContext } from "react";
import Context from "../../Context";
import FeedActionButtons from "../helperComponents/FeedActionButtons";
import ContentCardBoilerplate from "./ContentCardBoilerplate";
import CardLikeOverlay from "./CardLikeOverlay";

const Container = styled.div`
  width: 100%;
  /* border: 1px solid var(--translucentHard); */
  padding: 0;
  flex-direction: column;
  border-radius: 10px;
  display: flex;
  cursor: pointer;
  overflow: hidden;
  gap: 25px;
  font-size: 21px;
  line-height: 30px;
`;

const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
`;

export default function LiteratureCard({ item, variant }) {
  return (
    <CardLikeOverlay item={item}>
      <ContentCardBoilerplate item={item} variant={variant}>
        <Container>
          <RenderLiteratureCore item={item} />
        </Container>
      </ContentCardBoilerplate>
    </CardLikeOverlay>
  );
}
