import { useContext, useEffect, useState } from "react";
import WithHeader from "../WithHeader";

import { serverLine } from "../../../controllers/serverLine";
import { useParams } from "react-router-dom";

import goTo from "../../../controllers/goTo";
import { styled } from "styled-components";

import Context from "../../../Context";
import reachChatPanelEnd from "../../../controllers/reachChatPanelEnd";
import { io } from "socket.io-client";
import getOppositeMemberID from "../../../controllers/getOppositeMemberID";

import getSubPath from "../../../controllers/getSubPath";
import ChatGroupBox from "../../chat/ChatGroupBox";
import ChatPanel from "../../chat/ChatPanel";
import PopupLogin from "../../auth/PopupLogin";
import LoadingSection from "../../helperComponents/LoadingSection";
import getServerURL from "../../../controllers/getServerURL";

const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr 3fr;

  height: 100vh;
  /* padding: 0 200px; */
  width: 100%;

  @media (max-width: 900px) {
    padding: 0;
    grid-template-columns: 1fr;
    height: calc(100vh - 100px);
    height: calc(100dvh - 100px);
  }
`;
const LeftPanel = styled.div`
  height: 100%;
  width: 100%;
  overflow-y: auto;
  border-right: 1px solid var(--translucentHard);
  /* border-left: 1px solid var(--translucentHard); */

  @media (max-width: 900px) {
    border: none;
    ${({ groupID }) => {
      if (groupID) return `display:none;`;
    }}
  }
`;

const Info = styled.div`
  width: 100%;
  padding: 20px;
`;

export default function ChatPage() {
  const { updateNotificationData } = useContext(Context);
  const [loading, setLoading] = useState(true);
  const [selectedGroupData, setSelectedGroupData] = useState("LOADING");
  const [groups, setGroups] = useState(null);
  const [isConnected, setIsConnected] = useState(false);
  const [friendsLastSeenAt, setFriendsLastSeenAt] = useState(null);
  const [oppositeMemberLastTypedAt, setOppositeMemberLastTypedAt] =
    useState(null);

  const { loggedInUserID } = useContext(Context);
  window.loggedInUserID = loggedInUserID;

  let groupID = getSubPath(1);

  window.groups = groups;
  window.setGroups = setGroups;
  window.selectedGroupData = selectedGroupData;
  window.setSelectedGroupData = setSelectedGroupData;

  useEffect(() => {
    if (loggedInUserID) {
      let serverURL = getServerURL();

      console.log("IO ServerURL", serverURL);

      let socket = io(serverURL, {
        withCredentials: true,
        path: "/inwire-socket",
      });

      function onConnect() {
        setIsConnected(true);
      }

      function onDisconnect() {
        setIsConnected(false);
      }

      window.chatSocket = socket;

      socket.on("typing", (data) => {
        if (window.selectedGroupData) {
          let oppositeMemberID = getOppositeMemberID(
            window.selectedGroupData.group.groupID.split("-"),
            window.loggedInUserID
          );
          if (data.senderUserID == oppositeMemberID) {
            if (data.hasStopped) return setOppositeMemberLastTypedAt(null);
            setOppositeMemberLastTypedAt(new Date().toISOString());
          }
        }
      });

      socket.on("error", (data) => {
        window.doAlert(data.data);
      });

      socket.on("success", () => {
        if (window.pingInterval) window.clearInterval(window.pingInterval);

        window.pingInterval = setInterval(() => {
          socket.emit("ping", { friends: getFriends() });
        }, 5 * 1000);

        if (groupID) {
          sendSeenEverythingReq(groupID);
        }
      });

      socket.on("pingResponse", onPingResponse);

      socket.on("seen", markAsSeen);
      socket.on("connect", onConnect);
      socket.on("disconnect", onDisconnect);
      socket.on("message", onMessageReceived);

      return () => {
        socket.off("connect", onConnect);
        socket.off("disconnect", onDisconnect);
        socket.off("message", onMessageReceived);
      };
    }
  }, [loggedInUserID]);

  useEffect(() => {
    if (!groups && loggedInUserID) {
      console.log("Loading chat data ##########");
      setLoading(true);

      let url = "/chat/?groupID=" + groupID;

      if (!groupID) {
        url = "/chat";
      }

      serverLine.get(url).then((newData) => {
        setGroups(newData.chatGroups);

        if (newData.selectedGroupData) {
          setSelectedGroupData(newData.selectedGroupData);
        } else {
          setSelectedGroupData(null);
        }

        setLoading(false);
      });
    }
  }, [groupID, loggedInUserID]);

  if (!loggedInUserID) return <PopupLogin />;
  if (loading) return <LoadingSection />;

  let groupBoxes = groups.map((item) => (
    <ChatGroupBox
      friendsLastSeenAt={friendsLastSeenAt}
      item={item}
      onClick={selectNewGroup}
    />
  ));

  console.log(groups);

  return (
    <WithHeader>
      <Container>
        <LeftPanel groupID={groupID}>
          {groupBoxes.length ? groupBoxes : <Info> 0 Contacts Found</Info>}
        </LeftPanel>
        <ChatPanel
          groupID={groupID}
          oppositeMemberLastTypedAt={oppositeMemberLastTypedAt}
          friendsLastSeenAt={friendsLastSeenAt}
          markAsSent={markAsSent}
          addMessage={addMessage}
          selectedGroupData={selectedGroupData}
        />
      </Container>
    </WithHeader>
  );

  function onPingResponse(data) {
    if (data.lastSeenAt) {
      setFriendsLastSeenAt(data.lastSeenAt);
    }
  }

  function getFriends() {
    if (!window.groups) return [];

    let friends = [];

    for (let item of window.groups) {
      friends.push(
        getOppositeMemberID(item.groupID.split("-"), loggedInUserID)
      );
    }

    return friends;
  }

  function markAsSeen({ groupID }) {
    console.log("Has seen is received!!!", groupID);
    if (!window.selectedGroupData) return;

    if (window.selectedGroupData.group.groupID !== groupID) return;

    let newSelectedGrp = { ...window.selectedGroupData };

    for (let item of newSelectedGrp.messages) {
      if (item.isNew === true) {
        item.isSeen = true;
      }
    }

    console.log(newSelectedGrp);

    window.setSelectedGroupData(newSelectedGrp);
  }

  function markAsSent(pseudoID) {
    let newSelectedGrp = { ...window.selectedGroupData };

    for (let item of newSelectedGrp.messages) {
      if (item.pseudoID == pseudoID) {
        item.isSent = true;
      }
    }

    console.log(newSelectedGrp);
    window.setSelectedGroupData(newSelectedGrp);
  }

  function onMessageReceived(message) {
    console.log("Message received:------", message);

    let content = message.content;
    let groupID = message.groupID;
    let sender = message.sender;

    let messageIsForSelectedGroup = false;

    if (
      window.selectedGroupData !== "LOADING" &&
      window.selectedGroupData !== null
    ) {
      if (window.selectedGroupData.group.groupID == groupID) {
        messageIsForSelectedGroup = true;

        window.chatSocket.emit("seen", { receiverUserID: sender._id });

        let oldSelectedGrpData = { ...window.selectedGroupData };

        oldSelectedGrpData.messages.push({
          message: content,
          authorUserID: sender._id,
        });

        window.setSelectedGroupData(oldSelectedGrpData);
      }
    }

    if (!messageIsForSelectedGroup) {
      let groupFound = false;

      let newGroups = [...window.groups];

      for (let item of newGroups) {
        if (item.groupID == groupID) {
          groupFound = true;
          item.lastMessageSentAt = new Date().toISOString();
          item.notificationData[loggedInUserID].unreadMessages += 1;
        }
      }

      if (!groupFound) {
        newGroups = [
          {
            oppositeMember: sender,
            groupID,
            lastMessageSentAt: new Date().toISOString(),
            notificationData: { [loggedInUserID]: { unreadMessages: 1 } },
          },
          ...newGroups,
        ];
      }

      window.setGroups(rankGroups(newGroups));
    }
  }

  function rankGroups(theArray) {
    return theArray.sort(function (a, b) {
      a = new Date(a.lastMessageSentAt).getTime();
      b = new Date(b.lastMessageSentAt).getTime();

      return b - a;
    });
  }

  function addMessage(newMessage) {
    let oldSelectedGrp = { ...window.selectedGroupData };
    oldSelectedGrp.messages.push(newMessage);
    setSelectedGroupData(oldSelectedGrp);

    window.chatSocket.emit("message", {
      content: newMessage.message,
      receiverUserID: getOppositeMemberID(
        oldSelectedGrp.group.memberIDs,
        loggedInUserID
      ),
    });
  }

  function selectNewGroup(groupData) {
    setOppositeMemberLastTypedAt(null);

    let gotoOptions = null;
    if (groupID) {
      gotoOptions = { isReplace: true };
    }
    goTo("/chat/" + groupData.groupID, gotoOptions)();
    setSelectedGroupData("LOADING");

    loadNewMessages(groupData.groupID).then(() => {
      updateNotificationData();
      sendSeenEverythingReq(groupData.groupID);
      reachChatPanelEnd();
      markMessagesAsRead(groupData.groupID);
    });
  }

  function sendSeenEverythingReq(groupID) {
    window.chatSocket.emit("seen", {
      receiverUserID: getOppositeMemberID(groupID.split("-"), loggedInUserID),
    });
  }

  function markMessagesAsRead(groupID) {
    let initialGroups = [...groups];

    for (let item of initialGroups) {
      if (item.groupID == groupID) {
        item.notificationData[loggedInUserID].unreadMessages = 0;
      }
    }

    setGroups(initialGroups);
  }

  function loadNewMessages(newGroupID) {
    return serverLine
      .get("/messages/?groupID=" + newGroupID)
      .then(setSelectedGroupData);
  }
}
