import { LiaFeatherAltSolid } from "react-icons/lia";

import { BiAward, BiCube, BiShapeSquare } from "react-icons/bi";
import { AiOutlineCamera } from "react-icons/ai";

import { BsBook, BsBrush } from "react-icons/bs";
import { HiOutlineVideoCamera } from "react-icons/hi";
import { GrArticle, GrServices } from "react-icons/gr";

import RenderVideo from "../components/contentPage/cardForContentPage/RenderVideo";
import CertificateCard from "../components/cardForProfile/CertificateCard";
import RenderArt from "../components/contentPage/cardForContentPage/RenderArt";
import EducationCard from "../components/cardForProfile/EducationCard";
import RenderEducation from "../components/contentPage/cardForContentPage/RenderEducation";
import ExperienceCard from "../components/cardForProfile/ExperienceCard";
import RenderExperience from "../components/contentPage/cardForContentPage/RenderExperience";
import ArtCard from "../components/cardForProfile/ArtCard";
import LiteratureCard from "../components/cardForProfile/LiteratureCard";
import RenderLiterature from "../components/contentPage/cardForContentPage/RenderLiterature";
import PostCard from "../components/cardForProfile/PostCard";
import ArticleCard from "../components/cardForProfile/ArticleCard";
import RenderProgrammingProject from "../components/contentPage/cardForContentPage/RenderProgrammingProject";
import RenderArticle from "../components/contentPage/cardForContentPage/RenderArticle";
import ArticleSection from "../components/profilePage/profileSections/ArticleSection";
import SaleableCard from "../components/cardForProfile/SaleableCard";
import VideoCard from "../components/cardForProfile/VideoCard";
import RenderSaleable from "../components/contentPage/cardForContentPage/RenderSaleable";

/*

  Types
    * PRE_CATEGORIZED
      -> Only Shown in feed if user has selected the category as interest
    * USER_CATEGORIZED
      -> Always shown in feed
    * UN_CATEGORIZED
      -> CATEGORY is not asked

*/

let requiredFields = ["title"];

let requiredFieldsForArt = [
  ...requiredFields,
  { field: "images", type: "ARRAY", minArrayLength: 1, isContentField: true },
];

let requiredFieldsForVideo = [
  ...requiredFields,
  "image",
  { field: "images", type: "ARRAY", minArrayLength: 1, isContentField: true },
];

let contentTypeVsData = {
  //UN_CATEGORIZED
  PAGE: {
    icon: <GrServices />,
    label: "Services",
    name: "Pages",
    type: "USER_CATEGORIZED",
    requiredFields: ["title"],
  },
  LIST: {
    icon: <GrServices />,
    label: "List",
    name: "Pages",
    type: "UN_CATEGORIZED",
    requiredFields: ["title"],
  },
  SERVICE: {
    defaultVariation: "THREE",
    icon: <GrServices />,
    label: "Services",
    name: "Services",
    type: "USER_CATEGORIZED",
    cardForProfile: CertificateCard,
    cardForContentPage: RenderArt,
    cardForFeed: PostCard,
    requiredFields: ["title", "description"],
  },
  SALEABLE: {
    defaultVariation: "THREE",
    icon: <GrServices />,
    label: "Store",
    name: "Store",
    type: "USER_CATEGORIZED",
    cardForProfile: SaleableCard,
    cardForContentPage: RenderSaleable,
    cardForFeed: SaleableCard,
    requiredFields: ["title", "description"],
  },
  CERTIFICATE: {
    defaultVariation: "THREE",
    icon: <BiAward />,
    label: "Certificates / Awards",
    name: "Certificate / Award",
    type: "USER_CATEGORIZED",
    cardForProfile: CertificateCard,
    cardForContentPage: RenderArt,
    cardForFeed: PostCard,
    requiredFields: requiredFields,
  },
  EDUCATION: {
    defaultVariation: "THREE",
    icon: <LiaFeatherAltSolid />,
    label: "Education",
    name: "Education",
    type: "UN_CATEGORIZED",
    disableFeed: true,
    cardForProfile: EducationCard,
    cardForContentPage: RenderEducation,
    requiredFields: requiredFields,
  },
  EXPERIENCE: {
    defaultVariation: "THREE",
    icon: <LiaFeatherAltSolid />,
    label: "Experience",
    name: "Experience",
    type: "UN_CATEGORIZED",
    disableFeed: true,
    cardForProfile: ExperienceCard,
    cardForContentPage: RenderExperience,
    requiredFields: requiredFields,
  },

  //PRE_CATEGORIZED
  PROJECT_PROGRAMMING: {
    defaultVariation: "FOUR",
    icon: <BiCube />,
    label: "Coding Projects",
    name: "Coding Project",
    type: "PRE_CATEGORIZED",
    category: "programming",

    cardForContentPage: RenderProgrammingProject,
    requiredFields: requiredFields,
    supportProjectEditor: true,
    alwaysPresentInProjectEditor: true,
    projectType: "PRODUCT",
  },
  PROJECT_ART: {
    defaultVariation: "SEVEN",
    icon: <BsBrush />,
    label: "Arts",
    name: "Art",
    type: "PRE_CATEGORIZED",
    category: "art",
    cardForProfile: ArtCard,
    cardForFeed: ArtCard,
    cardForContentPage: RenderArt,
    requiredFields: requiredFieldsForArt,
    supportProjectEditor: true,
    alwaysPresentInProjectEditor: true,
    projectType: "ART",
  },

  PROJECT_GRAPHIC_DESIGN: {
    defaultVariation: "SEVEN",
    icon: <BiShapeSquare />,
    label: "Graphic Designs",
    name: "Graphic Design",
    type: "PRE_CATEGORIZED",
    category: "graphic-design",
    cardForProfile: ArtCard,
    cardForFeed: ArtCard,
    cardForContentPage: RenderArt,
    requiredFields: requiredFieldsForArt,
    supportProjectEditor: true,
    alwaysPresentInProjectEditor: true,
    projectType: "ART",
  },
  PROJECT_PHOTOGRAPHY: {
    defaultVariation: "SEVEN",
    icon: <AiOutlineCamera />,
    label: "Photography",
    name: "Photography",
    type: "PRE_CATEGORIZED",
    category: "photography",
    cardForProfile: ArtCard,
    cardForFeed: ArtCard,
    cardForContentPage: RenderArt,
    requiredFields: requiredFieldsForArt,
    supportProjectEditor: true,
    alwaysPresentInProjectEditor: true,
    projectType: "ART",
  },

  PROJECT_LITERATURE: {
    icon: <LiaFeatherAltSolid />,
    label: "Literature",
    name: "Literature",
    type: "PRE_CATEGORIZED",
    category: "literature",
    cardForProfile: LiteratureCard,
    cardForFeed: LiteratureCard,
    cardForContentPage: RenderLiterature,
    supportProjectEditor: true,
    alwaysPresentInProjectEditor: true,
    alwaysPresentInFeed: true,
    projectType: "LITERATURE",
  },
  ARTICLE: {
    defaultVariation: "ONE",
    icon: <GrArticle />,
    label: "Articles",
    name: "Article",
    type: "USER_CATEGORIZED",
    cardForProfile: ArticleCard,
    profileSectionComponent: ArticleSection,
    cardForFeed: ArticleCard,
    cardForContentPage: RenderArticle,
    requiredFields: requiredFields,
  },
  CARD: {
    icon: <GrArticle />,
    label: "Card",
    name: "Card",
    type: "UN_CATEGORIZED",
    cardForProfile: ArticleCard,
    profileSectionComponent: ArticleSection,
    cardForFeed: ArticleCard,
    cardForContentPage: RenderArticle,
    requiredFields: requiredFields,
  },
  LIFE_STORY: {
    label: "Life Story",
    name: "Life Story",
    type: "USER_CATEGORIZED",
    disableProfileSection: true,
    cardForFeed: ArticleCard,
    cardForContentPage: RenderArticle,
    requiredFields: requiredFields,
  },
  LINK: {
    label: "Links",
    name: "Link",
    type: "UN_CATEGORIZED",
    requiredFields: requiredFields,
  },
  VIDEO: {
    icon: <HiOutlineVideoCamera />,
    label: "Videos",
    name: "Video",
    type: "USER_CATEGORIZED",
    cardForProfile: VideoCard, // We  not use video card? because captions on thumbnail can be obstructed.
    cardForFeed: VideoCard,
    cardForContentPage: RenderVideo,
    supportProjectEditor: true,
    alwaysPresentInProjectEditor: true,
    projectType: "VIDEO",
  },
};

//There are 4 types that are user-categorized
/*
 * Video
 * Tutorial
 * Article
 * Post
 */

export default contentTypeVsData;
