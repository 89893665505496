import styled from "styled-components";
import WithHeader from "../WithHeader";
import WebsitePreview from "../../WebsitePreview";
import WebsiteEditorPanel from "./WebsiteEditorPanel";
import isLoggedIn from "../../../controllers/isLoggedIn";
import LoggedOutHomePage from "../LoggedOutHomePage";

const Container = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`;

export default function WebsiteEditor() {
  if (!isLoggedIn()) return <LoggedOutHomePage />;

  return (
    <WithHeader>
      <Container>
        <WebsiteEditorPanel />
        <WebsitePreview />
      </Container>
    </WithHeader>
  );
}
