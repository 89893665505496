import { useContext, useState } from "react";
import getProfileLink from "../controllers/getProfileLink";
import Context from "../Context";
import styled from "styled-components";
import CustomToggle from "./helperComponents/CustomToggle";
import { CiMobile2 } from "react-icons/ci";
import { LuLaptop2 } from "react-icons/lu";
import CustomButton from "./helperComponents/CustomButton";
import { AiOutlineRocket } from "react-icons/ai";
import { GoLinkExternal } from "react-icons/go";
import { MoonLoader, PulseLoader } from "react-spinners";
import { serverLine } from "../controllers/serverLine";
import goToProfile from "../controllers/goToProfile";

const Preview = styled.iframe`
  position: absolute;

  ${({ height, width, scale }) => {
    return `

    width: ${width};
    height: ${height};
    left: calc((var(--websitePreviewWidth) - (${width} * ${scale}  )) / 2);

  top: calc(
    (100vh - (${height} * ${scale} )) / 2
  );
        
  transform: scale( ${scale} );

        
        `;
  }}

  transform-origin: top left;

  border: 1px solid var(--translucentHard);
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 1px 1px 30px 1px rgba(0, 0, 0, 90%);
`;

const Container = styled.div`
  position: relative;
  width: var(--websitePreviewWidth);
  height: 100vh;
  display: flex;
`;

const Buttons = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 30px 0;
`;

export default function WebsitePreview() {
  const { loggedInUser, popupAlert } = useContext(Context);

  const [publishing, setPublishing] = useState(false);
  const [type, setType] = useState("DESKTOP");

  let width = "100vw";
  let height = "100vh";
  let scale = 0.5;

  if (type == "MOBILE") {
    height = "844px";
    width = "390px";
    scale = 0.9;
  }

  let options = [
    { label: <CiMobile2 />, value: "MOBILE" },
    { label: <LuLaptop2 />, value: "DESKTOP" },
  ];

  let buttonHeight = "40px";

  return (
    <Container>
      <Buttons>
        <CustomToggle
          style={{ height: buttonHeight }}
          value={type}
          options={options}
          onChange={setType}
        />
        {getPublishButton()}
        <CustomButton
          style={{ height: buttonHeight }}
          icon={<GoLinkExternal />}
          href={getProfileLink(loggedInUser.username)}
          onClick={goToProfile(loggedInUser.username, "", true)}
        >
          Visit
        </CustomButton>
      </Buttons>
      <Preview
        height={height}
        width={width}
        type={type}
        scale={scale}
        src={getProfileLink(loggedInUser.username)}
      ></Preview>
    </Container>
  );

  function getPublishButton() {
    let icon = <MoonLoader />;

    if (!publishing) icon = <AiOutlineRocket />;

    if (publishing)
      return (
        <CustomButton
          disabled={true}
          style={{ height: buttonHeight }}
          icon={<PulseLoader size="5px" color="var(--color)" />}
        ></CustomButton>
      );

    return (
      <CustomButton
        style={{ height: buttonHeight }}
        onClick={publish}
        icon={icon}
      >
        Publish
      </CustomButton>
    );
  }

  async function publish() {
    setPublishing(true);
    await serverLine.post("/publish");
    popupAlert("Website Published 🎉");
    setPublishing(false);
  }
}
