import { useContext } from "react";
import { styled } from "styled-components";
import Context from "../../Context";
import getOppositeMemberID from "../../controllers/getOppositeMemberID";

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
`;
const GreenBubble = styled.div`
  background: #a8df8e;
  height: 15px;
  width: 15px;
  border-radius: 100px;
`;
const Text = styled.div``;

export default function OnlineOfflineStatus({ groupID, friendsLastSeenAt }) {
  const { loggedInUserID } = useContext(Context);

  let isOnline = checkOnline();

  if (!isOnline) return false;

  return (
    <Container>
      <GreenBubble />
      <Text> Online</Text>
    </Container>
  );

  function checkOnline() {
    if (!friendsLastSeenAt) return false;

    let oppositeMemberID = getOppositeMemberID(
      groupID.split("-"),
      loggedInUserID
    );

    if (friendsLastSeenAt[oppositeMemberID]) {
      let time = new Date(friendsLastSeenAt[oppositeMemberID]).getTime();
      let now = new Date().getTime();

      if (now - time < 10000) {
        return true;
      }
    }

    return false;
  }
}
