import styled from "styled-components";
import getImageURL from "../../controllers/getImageURL";
import goTo from "../../controllers/goTo";

const Container = styled.div`
  width: 100%;
  border: 1px solid var(--translucentHard);

  flex-direction: column;
  border-radius: 5px;
  display: flex;

  font-size: 21px;
  line-height: 30px;
  max-width: 500px;
  cursor: pointer;

  &:hover {
    background-color: var(--translucent);
  }
`;

const Content = styled.div`
  width: 100%;

  padding: 15px;

  flex-direction: column;

  display: flex;
  gap: 10px;
`;

const Image = styled.img`
  border-radius: 5px;
  height: auto;
  width: 100%;
  object-fit: cover;
`;

const ProjectName = styled.h2`
  font-size: 21px;
  margin: 0;
  margin-bottom: 0;
`;

const ProjectDescription = styled.div`
  opacity: 0.5;
`;

export default function CertificateCard({ item }) {
  if (!item.content) return null;
  return (
    <Container
      onClick={goTo("/content/" + item._id, {
        // isAbsolute: true,
        openInSamePage: true,
      })}
    >
      {item.content.images ? (
        <Image src={getImageURL(item.content.images[0])} />
      ) : null}
      <Content>
        <ProjectName>{item.title}</ProjectName>
        <ProjectDescription>{item.content.description}</ProjectDescription>
      </Content>
    </Container>
  );
}
