import { GrArticle } from "react-icons/gr";
import { GoFileMedia } from "react-icons/go";
import { LiaCubeSolid } from "react-icons/lia";
import CustomButton from "../helperComponents/CustomButton";
import { styled } from "styled-components";
import goTo from "../../controllers/goTo";
import { serverLine } from "../../controllers/serverLine";
import { useContext, useEffect, useState } from "react";

import CustomMasonry from "../helperComponents/CustomMasonry";
import Context from "../../Context";
import FeedSection from "./FeedSection";
import InfiniteScroll from "../helperComponents/InfiniteScroll";
import FeedItem from "../cardForFeed/FeedItem";
import CustomSelect from "../helperComponents/CustomSelect";
import FeedHr from "./FeedHr";
import LoadingSection from "../helperComponents/LoadingSection";
import isImageTypeContent from "../isImageTypeContent";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: flex-start;
  gap: 40px;
`;
const Message = styled.div`
  font-size: 21px;
  /* background-color: var(--translucent); */
  padding: 0;
  border-radius: 10px;
  font-weight: 300;
  text-transform: capitalize;
  flex-direction: row;
  align-items: center;
  display: flex;
  opacity: 0.7;
  gap: 10px;
`;

const List = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 20px;
`;

const TitleIcon = styled.div`
  display: flex;
  justify-content: center;

  align-items: center;
`;
const TitleText = styled.div``;

const Top = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 100px;
`;
const Left = styled.div`
  font-size: 31px;
  font-weight: 100;
`;

export default function ContentFeed({
  type,
  title,
  mode = "COMPACT",
  initialFilterType = "MIX",
  scrollData,
  maxColumns = 3,
  disabledInfiniteScroll,
  loadSecondIndexAsWell,
}) {
  const { loggedInUser, updateGlobalLikeStatus } = useContext(Context);
  const [filterType, setFilterType] = useState(initialFilterType);
  const [list, setList] = useState([]);

  const [offset, setOffset] = useState(0);
  const [loading, setLoading] = useState(true);
  const [reachedTheEnd, setReachedTheEnd] = useState(false);

  useEffect(() => {
    setOffset(0);
    setList([]);
    doLoading(filterType);
  }, [filterType]);

  let seeMore = (
    <CustomButton
      style={{ fontSize: "13px", width: "100px", padding: "10px" }}
      variant="outlined"
      onClick={goTo(`/feed/?type=${type}&filterType=${filterType}`)}
    >
      See More
    </CustomButton>
  );

  let content = null;
  if (loading) {
    content = <LoadingSection />;
  } else {
    if (mode !== "COMPACT") seeMore = null;

    let items = list.map((item) => <FeedItem key={item._id} item={item} />);

    let colsOnMobile = 1;

    if (isImageTypeContent(type)) {
      colsOnMobile = 2;
      maxColumns = 4;
    }

    content = (
      <CustomMasonry
        colsOnMobile={colsOnMobile}
        maxColumns={maxColumns}
        list={items}
      />
    );

    if (!list.length && filterType == "MIX") return null;

    if (!list.length)
      content = (
        <CustomButton variant="minimal" disabled={true}>
          Nothing Found
        </CustomButton>
      );
  }

  let options = [
    { label: "Mix", value: "MIX" },
    { label: "Following", value: "FOLLOWING_USERS" },
    { label: "Trending", value: "TRENDING" },
  ];

  if (loggedInUser.type === "WORKER") {
    options = [
      { label: "Mix", value: "MIX" },
      { label: "Following", value: "FOLLOWING_USERS" },
      { label: "Trending", value: "TRENDING" },
    ];
  }

  let customToggle = (
    <CustomSelect
      value={filterType}
      onChange={setFilterType}
      options={options}
    />
  );

  if (type == "project" && loggedInUser.type == "CLIENT") {
    customToggle = null;
    title = "Trending Projects";
  }

  let core = (
    <>
      {content}

      {list.length ? seeMore : null}
    </>
  );

  let toRender = core;

  if (!disabledInfiniteScroll)
    toRender = (
      <InfiniteScroll
        loading={loading}
        load={doLoading}
        scrollData={scrollData}
        reachedTheEnd={reachedTheEnd}
      >
        {core}
      </InfiniteScroll>
    );

  return (
    <>
      <FeedSection title={title} buttons={customToggle}>
        {toRender}
      </FeedSection>

      <FeedHr />
    </>
  );

  async function loadData(filterType, offset) {
    let loadedData = await serverLine.get(
      `/feed/?filterType=${filterType}&type=${type}&offset=${offset}`
    );

    loadedData = removeRepetition(loadedData);
    updateGlobalLikeStatus(loadedData);
    loadedData = excludeContentWithoutAuthor(loadedData);

    return loadedData;
  }

  async function doLoading(newFilterType) {
    if (!newFilterType) newFilterType = filterType;
    setLoading(true);

    let loadedData = await loadData(filterType, offset);

    let newOffset = offset + 1;
    let newList = [];

    if (!loadedData.length) {
      if (offset !== 0) setReachedTheEnd(true);
    } else {
      setOffset(newOffset);
      newList = [...list, ...loadedData];
      setList(newList);
    }

    setLoading(false);

    if (loadSecondIndexAsWell) {
      if (newOffset == 1) {
        let newData = await loadData(filterType, newOffset);
        newList = [...newList, ...newData];
        setList(newList);
        setOffset(newOffset + 1);
      }
    }
  }

  function removeRepetition(newList) {
    let ids = list.map((item) => item._id);

    let toReturn = [];

    for (let item of newList) {
      if (!ids.includes(item._id)) {
        toReturn.push(item);
      }
    }

    return toReturn;
  }

  function excludeContentWithoutAuthor(items) {
    //In case author account has been deleted
    let newItems = [];

    for (let item of items) {
      if (item.author) newItems.push(item);
    }

    return newItems;
  }
}
