import styled from "styled-components";

import getCardTagline from "../../controllers/cardViewControllers/getCardTagline";
import getImageURL from "../../controllers/getImageURL";
import ContentCardBoilerplate from "../cardForProfile/ContentCardBoilerplate";
import getPublishedOrDraftContent from "../../controllers/getPublishedOrDraftContent";

const Container = styled.div`
  width: 100%;
  /* border: 1px solid var(--translucentLight); */
  flex-direction: row;
  align-items: center;
  border-radius: 5px;
  display: flex;
  cursor: pointer;
  gap: 0;
  font-size: 21px;
  line-height: 30px;
`;

const ContainerInterior = styled.div`
  padding: 25px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: flex-start;

  @media (max-width: 900px) {
    gap: 10px;
    font-size: 18px;
    padding: 15px;
  }
`;

const Branding = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 15px;
`;

const Logo = styled.img`
  border-radius: 5px;
  height: 25px;
  width: 25px;
  object-fit: cover;
`;

const Thumbnail = styled.img`
  border-radius: 0;
  height: auto;
  width: 130px;
  height: 130px;
  object-fit: cover;
  object-fit: cover;
`;

const ProjectName = styled.div`
  font-size: 21px;
  display: flex;
  color: var(--color);
  font-weight: 700;
  justify-content: center;
  text-transform: capitalize;
  align-items: center;

  @media (max-width: 900px) {
    font-size: 18px;
  }
`;

const ProjectTagline = styled.div``;

const ProjectDescription = styled.div`
  opacity: 0.5;
`;

const MainContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  color: var(--color);
  font-size: 19px;

  @media (max-width: 900px) {
    gap: 0px;
    font-size: 15px;
  }
`;

const Title = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
`;

export default function CardViewDetailedList({ item }) {
  let logoComp = null;

  item = { ...item };

  let content = getPublishedOrDraftContent(item);

  if (!content.description) {
    content.description = "Description goes here";
  }

  if (content.image2) {
    logoComp = <Logo src={getImageURL(content.image2)} />;
  }

  if (content.hideLogo) {
    logoComp = null;
  }

  let desc = <ProjectDescription>{content.description}</ProjectDescription>;

  let mainImage = null;

  if (content.image) mainImage = <Thumbnail src={getImageURL(content.image)} />;

  if (content.hideThumbnail) {
    mainImage = null;
  }

  if (item.type == "PROJECT_PROGRAMMING") {
    desc = getCardTagline({ item, Component: ProjectDescription });
  }

  return (
    <ContentCardBoilerplate item={item}>
      <Container>
        {mainImage}
        <ContainerInterior>
          <Title>
            {logoComp}
            <ProjectName>{content.title}</ProjectName>
          </Title>

          <MainContent>{desc}</MainContent>
        </ContainerInterior>
      </Container>
    </ContentCardBoilerplate>
  );
}
