import MaterialInput from "../../helperComponents/MaterialInput";
import DropDownSectionSelector from "../../helperComponents/DropDownSectionSelector";
import { useState } from "react";
import CustomButton from "../../helperComponents/CustomButton";
import { nanoid } from "nanoid";
import styled from "styled-components";
import ItemAdderByPopup from "../../helperComponents/ItemAdderByPopup";
import {
  MdOutlineClose,
  MdOutlineDragHandle,
  MdOutlineDragIndicator,
} from "react-icons/md";
import { socialMediaLinkTypes } from "../../../data/socialMediaLinkTypes";
import CustomListImageUploader from "../CustomListImageUploader";
import LoadingSection from "../../helperComponents/LoadingSection";
import { serverLine } from "../../../controllers/serverLine";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  /* background-color: var(--translucent); */
  /* padding: 10px; */
  border-radius: 5px;
  /* border: 1px solid var(--translucentHard); */
`;
const Inputs = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const MainRow = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
`;

const types = [
  { label: "Skills", value: "SKILLS" },
  { label: "Coding Projects", value: "PROJECT_PROGRAMMING" },
  { label: "List", value: "LIST" },
  { label: "Card", value: "CARD" },
  { label: "Article", value: "ARTICLE" },
  { label: "Photography", value: "PROJECT_PHOTOGRAPHY" },
  { label: "Graphic Design", value: "PROJECT_GRAPHIC_DESIGNS" },
  { label: "Literature", value: "LITERATURE" },
  { label: "Videos", value: "VIDEO" },
  { label: "Store", value: "SALEABLE" },
  { label: "My Story", value: "MY_STORY" },
];

export default function CustomListItemEditor({
  data,
  onChange,
  adder,
  onAdd,
  onDelete,
  variation,
}) {
  const [title, setTitle] = useState(null);
  const [link, setLink] = useState(null);
  const [loading, setLoading] = useState(false);

  if (loading) return <LoadingSection />;

  data = { ...data };

  if (!data.content) data.content = {};

  return render();

  function render() {
    if (adder) {
      return (
        <Container>
          <Inputs>
            <MaterialInput
              value={title}
              label={"Title"}
              onChange={(e) => {
                setTitle(e.target.value);
              }}
            />
            <MaterialInput
              value={link}
              label={"Link"}
              onChange={(e) => {
                setLink(e.target.value);
              }}
            />
          </Inputs>
          <CustomButton onClick={createItem}>Add</CustomButton>
        </Container>
      );
    } else {
      return (
        <Container>
          <MainRow>
            <CustomButton width="20px" icon={<MdOutlineDragIndicator />} />
            <MaterialInput
              value={data.draftState.title}
              label="title"
              onChange={makeChange({ field: "title" })}
            />
            <CustomButton icon={<MdOutlineClose />} onClick={onDelete} />
          </MainRow>

          <MaterialInput
            value={data.draftState.description}
            label="Description"
            onChange={makeChange({ field: "description" })}
          />

          <CustomListImageUploader
            variation={variation}
            state={data}
            onChange={onChange}
          />

          <MaterialInput
            value={data.draftState.link}
            label="Link"
            onChange={makeChange({ field: "link" })}
          />
        </Container>
      );
    }
  }

  function makeChange({ field, isNotEvent }) {
    return (e) => {
      if (isNotEvent) {
        let newE = { target: { value: e } };
        e = newE;
      }

      let newState = { ...data };
      if (!newState.draftState) newState.draftState = {};
      newState.draftState[field] = e.target.value;

      onChange(newState);
    };
  }

  async function createItem() {
    let newState = { type: "LIST_ITEM", draftState: { title, link } };

    setLoading(true);

    newState = await serverLine.post("/content", { values: newState });

    console.log("NEW STATE", newState);
    setLoading(false);

    onAdd(newState);

    setTitle("");
    setLink("");
  }
}
