import styled from "styled-components";
import { useContext, useState } from "react";
import LoadingSection from "../../helperComponents/LoadingSection.js";
import CustomButton from "../../helperComponents/CustomButton.js";
import CustomMasonry from "../../helperComponents/CustomMasonry.js";
import isImageTypeContent from "../../isImageTypeContent.js";
import Context from "../../../Context.js";
import ProfileSectionBlank from "../ProfileSectionBlank.js";

const NoMasonry = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: 25px;
  flex-wrap: wrap;
  justify-content: space-between;
`;

const SerialNumber = styled.div`
  font-weight: 500;
`;

const ArticleTitle = styled.div`
  transition: 0.25s ease-in-out;
  transform-origin: center left;
`;

const ArticleItem = styled.div`
  display: flex;
  flex-direction: row;
  gap: 5px;
  font-size: 19px;
  align-items: center;
  padding: 0 0;
  height: 70px;
  /* border-bottom: 1px solid var(--translucentHard); */
  border: 1px solid var(--translucentInteraction);
  background: var(--translucentInteraction);
  /* border: 1px solid var(--translucentLight); */
  padding: 0 30px;
  border-radius: 10px;

  font-family: "Inter", sans-serif;
  cursor: pointer;
  transition: 0.05s ease-in-out;
  font-weight: 700;

  &:hover {
    background: var(--translucent);
  }

  backdrop-filter: blur(20px);

  ${({ theme }) => {
    if (theme.cardStyle === "LINES") {
      return `
        border: 1px solid var(--translucentHard);
        background-color: transparent;
        backdrop-filter:  blur(5px);
      `;
    }
  }}/* &:hover ${ArticleTitle} {
    transform: scale(0.95);
  } */
`;

const ArticleList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
`;

let maxArticles = 5;

export default function ArticleSection({ loadedList }) {
  const { updateGlobalLikeStatus } = useContext(Context);
  const [seeMore, setSeeMore] = useState(false);
  const [loading, setLoading] = useState(false);

  useState(() => {
    updateGlobalLikeStatus(loadedList);
  }, []);

  if (loading) return <LoadingSection />;
  if (!loadedList.length) return null;
  let defaultView = null;
  if (!loadedList) return defaultView;
  if (!loadedList.length) return defaultView;

  let toRender = renderItems(loadedList);

  let seeMoreButton = (
    <CustomButton
      variant={"outlined"}
      circular
      width={"200px"}
      onClick={() => {
        setSeeMore(true);
      }}
    >
      See More
    </CustomButton>
  );

  let maxReached = false;
  if (toRender.length > maxArticles) maxReached = true;
  if (!maxReached) seeMoreButton = null;
  if (!seeMore) toRender = toRender.slice(0, maxArticles);
  let theList = <ArticleList>{toRender}</ArticleList>;

  return (
    <ProfileSectionBlank label={"Articles"} id={"Articles"}>
      {theList}
      {seeMore ? null : seeMoreButton}
    </ProfileSectionBlank>
  );

  function renderItems(list) {
    let toRender = [];

    toRender.map((item) => (
      <ArticleItem key={item._id}>{item.title}</ArticleItem>
    ));

    let serialNumber = 1;
    for (let item of list) {
      toRender.push(
        <ArticleItem key={item._id}>
          <SerialNumber>{serialNumber}.</SerialNumber>
          <ArticleTitle>{item.title}</ArticleTitle>
        </ArticleItem>
      );

      serialNumber++;
    }

    return toRender;
  }
}
