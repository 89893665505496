import { styled } from "styled-components";
import CustomButton from "../helperComponents/CustomButton";
import { AiOutlineUpload } from "react-icons/ai";
import React from "react";
// import useDrag and useDrop hooks from react-dnd

const type = "Image";

const Container = styled.div`
  /* display: grid; */
  /* grid-template-columns: 1fr 1fr 1fr; */
  /* display: flex; */
  /* flex-direction: row; */
  /* flex-wrap: wrap; */
  /* justify-content: space-between; */
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 20px;
`;
const Image = styled.img`
  border-radius: 10px;
  /* ${({ width }) => {
    if (width)
      return `
      width:${width};
  `;
  }} */

  object-fit: cover;
  width: 100%;

  ${({ height }) => {
    if (height)
      return `
   
      height:${height};
  `;
  }}
`;
const ImageItemContainer = styled.div`
  position: relative;
  width: 100%;
`;

const Symbol = styled.div`
  display: flex;
  position: absolute;
  right: 10px;
  bottom: 10px;
  background-color: rgba(0, 0, 0, 0.5);
  padding: 10px;
  backdrop-filter: blur(25px);
  border-radius: 10px;
  color: var(--color);
  font-size: 15px;
`;

const DeleteButton = styled.div`
  display: flex;
  position: absolute;
  right: -10px;
  top: -10px;
  background-color: var(--color);
  padding: 5px;
  backdrop-filter: blur(25px);
  border-radius: 100px;
  color: var(--bgColor);
  cursor: pointer;
  font-size: 18px;
  transition: 0.15s ease-in-out;

  &:hover {
    transform: scale(1.5);
  }
`;

export default function ProjectImagesDND({ renderList, selectImage, size }) {
  return (
    <Container>
      {renderList}
      <CustomButton
        key="Add Item"
        onClick={selectImage}
        height={size}
        width={size}
        icon={<AiOutlineUpload />}
        variant="outlined-filled"
      >
        Image
      </CustomButton>
    </Container>
  );
}
