import styled from "styled-components";
import UsernameEditor from "../../onboarding/UsernameEditor";
import WithHeader from "../WithHeader";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  /* height: 100vh; */
  margin-top: 100px;
  justify-content: center;
  align-items: center;
`;

export default function ChangeUsername() {
  return (
    <WithHeader>
      <Container>
        <UsernameEditor title="Change Username" />
      </Container>
    </WithHeader>
  );
}
