import styled from "styled-components";
import getImageURL from "../../controllers/getImageURL";
import goTo from "../../controllers/goTo";
import { useContext } from "react";
import Context from "../../Context";
import capitalizeFirstLetter from "../../controllers/capitalizeFirstLetter";
import getLikeString from "../../controllers/getLikeString";
import FeedActionButtons from "../helperComponents/FeedActionButtons";
import LikeDislikeButtons from "../contentPage/LikeDislikeButtons";
import getContentLink from "../../controllers/getContentLink";
import SemanticButton from "../helperComponents/SemanticButton";
import getPublishedOrDraftContent from "../../controllers/getPublishedOrDraftContent";

const HoverEffect = styled.div`
  transition: 0s ease-in;
  border-radius: 10px;
  border: 1px solid var(--translucentInteraction);
  background-color: var(--translucentInteraction);
  overflow: hidden;
  display: flex;
  backdrop-filter: blur(20px);
  &:hover {
    background-color: var(--translucent);
  }

  ${({ theme }) => {
    if (theme.cardStyle === "LINES") {
      return `
        border: 1px solid var(--translucentHard);
        background-color: transparent;
        backdrop-filter:  blur(5px);
      `;
    }
  }}
`;

export default function ContentCardBoilerplate({ item, children }) {
  const { setPopupFeedItem } = useContext(Context);

  return (
    <SemanticButton semanticHref={getContentLink(item._id)} onClick={openPost}>
      <HoverEffect>{children}</HoverEffect>
    </SemanticButton>
  );

  function openPost() {
    if (item.type == "ARTICLE") {
      let content = getPublishedOrDraftContent(item);
      window.open(content.externalLink);
    } else {
      goTo("/content/" + item._id)();
      setPopupFeedItem(item);
    }
  }
}
