import styled from "styled-components";
import ProfileSkills from "./ProfileSkills";
import ProfileSectionBoilerplate from "./profileSections/ProfileSectionBoilerplate";
import rearrangeContent from "../../controllers/profile/rearrangeContent";
import contentTypeVsData from "../../data/contentTypeVsData";
import ArticleSection from "./profileSections/ArticleSection";
import RenderLifeStory from "./RenderLifeStory";
import RenderList from "./profileSections/RenderList";
import { useContext } from "react";
import Context from "../../Context";
import RenderCard from "./profileSections/RenderCard";
import ProcessProfileCards from "../cardViews/ProcessProfileCards";
import getPublishedOrDraftContent from "../../controllers/getPublishedOrDraftContent";

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

let defaultSections = [
  { type: "SKILLS" },
  { type: "PROJECT_PROGRAMMING" },
  { type: "ARTICLE" },
  { type: "PROJECT_PHOTOGRAPHY" },
  { type: "PROJECT_GRAPHIC_DESIGN" },
  { type: "SALEABLE" },
  { type: "MY_STORY" },
];

export default function ProfileSections({
  currentPageID = "home",
  profileData,
  gap = "100px",
}) {
  let pages = profileData.PAGE;
  let currentPageSections = getCurrentPage();

  let isHome = true;

  return <Container style={{ gap }}>{getItems()}</Container>;

  function getItems() {
    let items = [];

    for (let item of currentPageSections) {
      items.push(getComp(item));
    }

    return items;
  }

  function getComp(item) {
    let directRenderSupport = [
      "ARTICLE",
      "PROJECT_PROGRAMMING",
      "PROJECT_ART",
      "PROJECT_GRAPHIC_DESIGN",
      "PROJECT_PHOTOGRAPHY",
      "VIDEO",
      "SALEABLE",
    ];

    if (item.type == "SKILLS") {
      return (
        <ProfileSkills
          key={item._id}
          skillTags={profileData.profileMetaData.skillTags}
        />
      );
    } else if (directRenderSupport.includes(item.type)) {
      return (
        <ProcessProfileCards
          profileData={profileData}
          contentType={item.type}
          key={item._id}
        />
      );
    } else if (item.type == "LIST") {
      let listID = item.listID;
      return (
        <RenderList key={item._id} profileData={profileData} listID={listID} />
      );
    } else if (item.type == "CARD") {
      let cardID = item.cardID;
      return (
        <RenderCard key={item._id} profileData={profileData} cardID={cardID} />
      );
    } else if (item.type == "MY_STORY") {
      return <RenderLifeStory lifeStory={profileData.lifeStory} />;
    }
  }

  function getCurrentPage() {
    let pageIdVsData = {};

    if (!pages) {
      return defaultSections;
    }

    if (!pages.length) {
      return defaultSections;
    }

    for (let item of pages) {
      let pageContent = getPublishedOrDraftContent(item);

      if (pageContent.isHome) {
        if (pageContent.sectionArrangementType == "MANUAL") {
          if (pageContent.sections) {
            return pageContent.sections;
          }
        }
      }

      if (pageContent.isHome) {
        return defaultSections;
      }

      pageIdVsData[item.pageID] = pageContent;
    }

    if (!pageIdVsData[currentPageID]) {
      return defaultSections;
    }

    return pageIdVsData[currentPageID].sections;
  }
}
