import styled from "styled-components";

import getCardTagline from "../../controllers/cardViewControllers/getCardTagline";
import getImageURL from "../../controllers/getImageURL";
import ContentCardBoilerplate from "../cardForProfile/ContentCardBoilerplate";
import getPublishedOrDraftContent from "../../controllers/getPublishedOrDraftContent";

const Container = styled.div`
  width: 100%;
  color: var(--color);
  flex-direction: row;
  border-radius: 5px;
  display: flex;
  cursor: pointer;
  gap: 15px;
  font-size: 21px;
  padding: 15px 20px;
  line-height: 30px;
`;

const ContainerInterior = styled.div`
  min-height: 220px;
  padding: 25px;
  display: flex;
  flex-direction: column;
  gap: 15px;

  @media (max-width: 900px) {
    gap: 10px;
    font-size: 18px;
    padding: 15px;
  }
`;

const Branding = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 15px;
`;

const Logo = styled.img`
  border-radius: 5px;
  height: 30px;
  width: 30px;
  object-fit: cover;
`;

const Thumbnail = styled.img`
  border-radius: 0;
  height: auto;
  width: 170px;
  height: 170px;
  object-fit: cover;
  object-fit: cover;
`;

const ProjectName = styled.div`
  font-size: 20px;
  display: flex;
  color: var(--color);
  font-weight: 700;
  justify-content: center;
  text-transform: capitalize;
  align-items: center;

  @media (max-width: 900px) {
    font-size: 18px;
  }
`;

export default function CardViewListZero({ item }) {
  let logoComp = null;

  let content = getPublishedOrDraftContent(item);

  if (content.image2) {
    logoComp = <Logo src={getImageURL(content.image2)} />;
  }

  if (content.hideLogo) {
    logoComp = null;
  }

  return (
    <ContentCardBoilerplate item={item}>
      <Container>
        <Branding>
          {logoComp}
          <ProjectName>{content.title}</ProjectName>
        </Branding>
      </Container>
    </ContentCardBoilerplate>
  );
}
