import styled from "styled-components";
import { useContext, useState } from "react";
import { useEffect } from "react";
import WithHeader from "../WithHeader";
import Context from "../../../Context";
import CustomToggle from "../../helperComponents/CustomToggle";

import parseScrollData from "../../../controllers/parseScrollData";
import NotificationsCore from "../../notifications/NotificationsCore";
import LoadingSection from "../../helperComponents/LoadingSection";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 50px;
  width: 100%;
  gap: 25px;
  align-items: center;
  height: 100%;
  overflow-y: scroll;
`;

const Title = styled.div``;

const Main = styled.div`
  width: 38vw;
  display: flex;
  flex-direction: column;
  gap: 50px;

  @media (max-width: 900px) {
    width: 90vw;
  }
`;

export default function Notifications() {
  let { notificationData, loggedInUser } = useContext(Context);
  const [scrollData, setScrollData] = useState(null);

  const [type, setType] = useState(null);

  useEffect(() => {
    if (loggedInUser.type == "WORKER") {
      setType("GENERAL");
    } else {
      setType("WORK");
    }
  }, [loggedInUser]);

  if (!type || !loggedInUser) return <LoadingSection />;

  let generalLabel = "General";
  let workLabel = "Work";

  if (notificationData.general) {
    generalLabel = `General (${notificationData.general})`;
  }

  if (notificationData.work) {
    workLabel = `Work (${notificationData.work})`;
  }

  let options = [
    {
      label: generalLabel,
      value: "GENERAL",
    },
    { label: workLabel, value: "WORK" },
  ];

  if (loggedInUser.type == "CLIENT") {
    options = options.reverse();
  }

  return (
    <WithHeader>
      <Container onScroll={parseScrollData(setScrollData)}>
        <Main>
          <Title>
            <CustomToggle value={type} onChange={setType} options={options} />
          </Title>
          <NotificationsCore scrollData={scrollData} type={type} />
          <br /> <br /> <br />
        </Main>
      </Container>
    </WithHeader>
  );
}
