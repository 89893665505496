import styled from "styled-components";
import LogoSVG from "../brand/LogoSVG";
import SemanticButton from "./SemanticButton";

const Container = styled.a`
  display: flex;
  background-color: var(--bgColorDark);
  padding: 15px;
  border-radius: 20px;
  box-shadow: 1px 1px 10px 1px rgba(0, 0, 0, 20%);
`;

const Main = styled.div`
  position: absolute;
  bottom: 50px;
  right: 50px;
`;

const Filter = styled.div`
  filter: var(--logoFilter);
`;

export default function BackToHome() {
  return (
    <Main>
      <Container href={getHomeLink()}>
        <Filter>
          <LogoSVG size={"35px"} />
        </Filter>
      </Container>
    </Main>
  );

  function getHomeLink() {
    let link = "https://www.inwire.app";

    if (window.location.hostname.indexOf("localhost") !== -1) {
      link = "http://localhost.com:3000";
    }

    return link;
  }
}
