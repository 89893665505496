function getServerURL() {
  let base = "https://www.inwire.app";

  if (window.location.origin.indexOf("inwire.app") == -1) {
    if (process.env.REACT_APP_SERVER) base = process.env.REACT_APP_SERVER;
  }

  // console.log(base);
  return base;
}

export default getServerURL;
