import { useContext } from "react";
import styled from "styled-components";

import { SlGlobeAlt } from "react-icons/sl";
import { AiOutlineUser } from "react-icons/ai/index.js";
import { HiMenu } from "react-icons/hi/index.js";
// import goToAuthScreen from "../controllers/goToAuthScreen.js";

import { GrGoogle } from "react-icons/gr/index.js";
import { GoPeople } from "react-icons/go";

import {
  MdOutlineChatBubbleOutline,
  MdOutlineCreate,
} from "react-icons/md/index.js";

import { RiHome2Line, RiNotificationLine } from "react-icons/ri/index.js";

import getSubdomain from "../../controllers/getSubdomain.js";
import goToProfile from "../../controllers/goToProfile.js";

import Link from "../helperComponents/Link.js";
import Context from "../../Context.js";
import goToAuthScreen from "../../controllers/goToAuthScreen.js";
import LogoSVG from "../brand/LogoSVG.js";
import goTo from "../../controllers/goTo.js";
import CustomButton from "../helperComponents/CustomButton.js";
import SemanticButton from "../helperComponents/SemanticButton.js";
import getProfileLink from "../../controllers/getProfileLink.js";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  /* border-right: 2px solid var(--translucent); */
  background-color: var(--bgColorDark);
  justify-content: space-between;
  align-items: center;
  border-right: 1px solid var(--translucentHard);

  width: var(--headerWidth);

  ${({ loggedInUser }) => {
    if (!loggedInUser) {
      return `width: var(--headerWidthLoggedOut);`;
    }
  }}
  padding:0;
  padding-top: 20px;

  height: 100vh;

  @media (max-width: 900px) {
    height: var(--headerHeight);
    width: 100vw;
    flex-direction: row;
    border-bottom: none;
    border-top: 2px solid var(--translucent);

    ${({ loggedInUser }) => {
      if (!loggedInUser) {
        return `padding: 0 20px`;
      }
    }}
  }
`;

const Links = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0px;
  /* justify-content: space-between; */
  gap: 50px;
  padding: 0 0;
  border-radius: 0;
  border-radius: 0;
  width: 100%;
  /* padding: 10px 0; */
  padding-bottom: 13px;
  border-bottom: 1px solid var(--translucentHard);
`;

const LinkItem = styled.div`
  cursor: pointer;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const NotifIndication = styled.div`
  height: 8px;
  width: 8px;
  border-radius: 50px;
  background-color: tomato;
  position: absolute;
  bottom: 3px;
  left: calc(50% - 4px);
`;

const LoginButton = styled.button`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 50px;
  color: var(--bgColor);
  justify-content: center;
  font-size: 15px;
  font-weight: 500;
  cursor: pointer;
  border-radius: 172px;
  border: 1px solid;
  padding: 10px 0;
  gap: 15px;
  background-color: var(--translucentHard);
  cursor: pointer;
  transition: 0.25s ease-in-out;
  color: var(--color);
  border: none;
  &:hover {
    transform: scale(0.9);
  }

  @media (min-width: 950px) {
    width: 200px;
  }
`;

const Button = styled.div`
  display: flex;
  height: 40px;
  /* width: 45px; */
  font-size: 21px;
  gap: 15px;
  align-items: center;
  position: relative;
  border-radius: 10px;
  cursor: pointer;
  /* background: var(--translucentHard); */

  /* width: 100px; */
  justify-content: center;
  /* height: 50px; */
  padding: 0 15px;
  /* border: 1px solid var(--translucentHard); */

  @media (max-width: 900px) {
    ${({ onlyDesktop }) => {
      if (onlyDesktop) return `display:none;`;
    }}
  }
`;

const ButtonText = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ButtonIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Top = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
`;

const Buttons = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  gap: 15px;

  @media (max-width: 700px) {
    /* align-items: center; */
    justify-content: space-between;
    width: 100%;
  }
`;

const CustomLink = styled(Link)`
  display: flex;
  flex: 1;
`;

const OnlyDesktop = styled.div`
  @media (max-width: 900px) {
    display: none;
  }
`;

const Bottom = styled.div``;

const MobileContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 25px;
  width: 90vw;
  margin-bottom: 50px;
  margin-top: 25px;
`;
const MobileRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;
const MobileRow2 = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  width: 100%;
`;

const Items = styled.div`
  display: flex;
  gap: 0;
  flex-direction: column;
  width: 100%;

  @media (max-width: 900px) {
    flex-direction: row;
    padding: 0 10px;
    gap: 0;
    justify-content: space-between;
    width: 100%;
  }
`;

const NotificationBubble = styled.div`
  display: flex;
  position: absolute;
  right: -3px;
  top: -5px;
  padding: 5px 10px;
  border-radius: 10px;
  font-size: 12px;
  background-color: var(--accentColor);
  color: var(--bgColor);
`;

const Logo = styled.div`
  display: flex;
  flex-direction: row;
  margin-left: -10px;
  gap: 10px;
  width: 100%;
  justify-content: center;
  align-items: center;

  @media (max-width: 900px) {
    flex-direction: row;
  }
`;

const LogoName = styled.div`
  font-weight: 400;
  font-size: 22px;
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  cursor: pointer;
  width: 100%;
  color: var(--color);
  padding: 15px 0;
  border-top: 1px solid var(--translucent);
  align-items: center;
  padding-left: 17px;

  &:hover {
    background-color: var(--translucent);
  }

  ${({ highlight }) => {
    if (highlight)
      return `
    &:after {
      content: "";
      position: absolute;
      height: 30px;
      border-radius: 20px;
      width: 10px;
      left: -5px;
      background-color: var(--color);
    }
    `;
  }}
`;

const ButtonName = styled.div`
  opacity: 0.7;
`;

const TheLogo = styled.div`
  @media (max-width: 900px) {
    display: none;

    ${({ loggedInUser }) => {
      if (!loggedInUser) {
        return `display: block`;
      }
    }}
  }
`;

export default function Header() {
  const { loggedInUser, notificationData, currentRoute } = useContext(Context);

  if (!notificationData) return null;

  let logoComp = (
    <TheLogo loggedInUser={loggedInUser}>
      <CustomButton
        style={{ padding: 0 }}
        onClick={goTo("/")}
        variant="minimal"
      >
        <Logo>
          <LogoSVG size={"50px"} />
          <LogoName>Inwire</LogoName>
        </Logo>
      </CustomButton>
    </TheLogo>
  );

  if (!loggedInUser)
    return (
      <Container loggedInUser={loggedInUser}>
        {logoComp}

        <CustomButton onClick={goToAuthScreen} icon={<GrGoogle />}>
          Login
        </CustomButton>
      </Container>
    );

  let subDomain = getSubdomain();

  let totalNotifications = notificationData.general + notificationData.work;

  return (
    <Container loggedInUser={loggedInUser}>
      {logoComp}

      <Items>
        <ProperButton
          currentRoute={currentRoute}
          link={"/"}
          additionalPath="/edit"
          onClick={goHome}
          name="Edit Website"
        >
          <RiHome2Line />
        </ProperButton>

        <ProperButton
          currentRoute={currentRoute}
          name="Visit Website"
          link={getProfileLink(loggedInUser.username)}
          onClick={goToProfile(loggedInUser.username)}
        >
          <SlGlobeAlt />
        </ProperButton>

        <ProperButton
          currentRoute={currentRoute}
          link={"/notifications"}
          name="Notifications"
        >
          <RiNotificationLine />

          {totalNotifications ? (
            <NotificationBubble>{totalNotifications}</NotificationBubble>
          ) : null}
        </ProperButton>

        {/* <ProperButton
          currentRoute={currentRoute}
          link={`/following`}
          name="Following"
        >
          <GoPeople />
        </ProperButton> */}

        <ProperButton
          currentRoute={currentRoute}
          link={"/chat"}
          name="Messages"
        >
          <MdOutlineChatBubbleOutline />

          {notificationData.chat ? (
            <NotificationBubble>{notificationData.chat}</NotificationBubble>
          ) : null}
        </ProperButton>

        <ProperButton
          currentRoute={currentRoute}
          link={`/more-menu`}
          name="Options"
        >
          <HiMenu />
        </ProperButton>
      </Items>
    </Container>
  );

  function goHome() {
    if (subDomain) {
      goTo(window.homeLink, { isAbsolute: true, openInSamePage: true })();
    } else {
      goTo("/")();
    }
  }
}

function ProperButton({
  link,
  onClick,
  children,
  name,
  currentRoute,
  additionalPath,
}) {
  // return ;

  let highlight = currentRoute == link;

  if (additionalPath) {
    if (currentRoute.indexOf(additionalPath) !== -1) highlight = true;
  }

  return (
    <SemanticButton
      semanticHref={link}
      onClick={onClick ? onClick : goTo(link)}
    >
      <ButtonContainer highlight={highlight}>
        <Button>{children}</Button>
        <ButtonName>{name}</ButtonName>
      </ButtonContainer>
    </SemanticButton>
  );
}
