import styled from "styled-components";
import VerticalTheme from "../VerticalTheme";
import VerticalTwoHeader from "./OneLineHeader";
import OneLineHeader from "./OneLineHeader";

const MainContent = styled.div`
  padding: 60px 0px;
  /* padding-top: 150px; */
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 100px;

  @media (max-width: 900px) {
    padding: 5vw;
  }
`;

export default function OneLineTheme({ profileData, contentPageData }) {
  return (
    <VerticalTheme
      profileData={profileData}
      contentPageData={contentPageData}
      Header={OneLineHeader}
    />
  );
}
