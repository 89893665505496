import { styled } from "styled-components";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: flex-start;
  gap: 20px;

  /* border-bottom: 1px solid var(--translucent); */
  position: relative;
`;

const Top = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 100px;

  @media (max-width: 900px) {
    /* flex-direction: column; */
    /* gap: 20px; */
    /* justify-content: flex-start; */
    /* align-items: flex-start; */
  }
`;
const Left = styled.div`
  font-size: 25px;
  font-weight: 100;
  text-transform: capitalize;

  @media (max-width: 900px) {
    font-size: 20px;
  }
`;

export default function FeedSection({ children, title, buttons }) {
  return (
    <Container>
      <Top>
        <Left>{title}</Left>
        {/* {buttons} */}
      </Top>

      {children}
    </Container>
  );
}
