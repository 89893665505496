import styled from "styled-components";
import goTo from "../../controllers/goTo";
import { PiPlayLight } from "react-icons/pi";
import getImageURL from "../../controllers/getImageURL";
import ContentCardBoilerplate from "./ContentCardBoilerplate";

const Container = styled.div`
  width: 100%;
  /* border: 1px solid var(--translucentHard); */
  /* padding: 25px; */
  flex-direction: column;
  border-radius: 5px;
  overflow: hidden;
  display: flex;
  cursor: pointer;

  gap: 0;
  font-size: 21px;
  line-height: 30px;
`;

const Image = styled.img`
  height: 250px;
  width: 100%;
  object-fit: cover;
`;

const ThumbnailContainer = styled.div`
  position: relative;
  width: 100%;
`;

const Overlay = styled.div`
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  justify-content: center;
  align-items: center;
  font-size: 30px;
  height: 100%;
  width: 100%;
  z-index: 6;
`;

const Overlay2 = styled.div`
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  z-index: 5;
`;

const OverlayCenter = styled.div`
  min-height: 50px;
  min-width: 50px;
  border-radius: 100px;
  background-color: var(--translucent);

  backdrop-filter: blur(20px);
`;

const Lines = styled.div`
  display: flex;

  flex-direction: column;
  gap: 10px;
  padding: 20px;
  padding-top: 10px;
  border-radius: 0 0 10px 10px;
  /* border: 1px solid var(--translucentHard); */
  border-top: none;
`;

const Line1 = styled.div`
  font-size: 18px;
  text-transform: capitalize;
  color: var(--color);
  font-weight: 800;
`;

export default function VideoCard({ item, variant }) {
  if (!item.content.videoLink) return null;
  if (!item.image) return null;

  return (
    <ContentCardBoilerplate variant={variant} item={item}>
      <Container>
        <ThumbnailContainer>
          <Image src={getImageURL(item.image)} />

          <Overlay>
            <PiPlayLight />
          </Overlay>

          <Overlay2>
            <OverlayCenter></OverlayCenter>
          </Overlay2>
        </ThumbnailContainer>

        <Lines>
          <Line1>{item.title}</Line1>
        </Lines>
      </Container>
    </ContentCardBoilerplate>
  );
}
