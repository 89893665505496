import { styled } from "styled-components";
import { useContext, useEffect, useRef, useState } from "react";

import selectFile from "../../controllers/selectFile.js";
import compressAndUploadFile from "../../controllers/compressAndUploadFile.js";
import { serverLine } from "../../controllers/serverLine.js";

import Context from "../../Context.js";

import { AiOutlineCheck, AiOutlineUpload } from "react-icons/ai/index.js";
import CustomToggle from "../helperComponents/CustomToggle.js";
import ProfilePicture from "../helperComponents/ProfilePicture.js";
import ShowSelectedImage from "../helperComponents/ShowSelectedImage.js";
import DropDownLocationInput from "../helperComponents/DropDownLocationInput.js";
import MaterialInput from "../helperComponents/MaterialInput.js";
import CustomButton from "../helperComponents/CustomButton.js";
import ProfileTagsEditorParent from "./ProfileTagsEditorParent.js";
import LoadingSection from "../helperComponents/LoadingSection.js";
import sendPostMessage from "../../controllers/sendPostMessage.js";
import DragAndDropItems from "./DragAndDropItems.js";
import onChangeStop from "../../controllers/onChangeStop.js";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 0;
  width: 100%;

  gap: 30px;
  align-items: center;
`;

const Inputs = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  width: 100%;
  gap: 10px;
  justify-content: center;

  @media (max-width: 900px) {
    width: 90vw;
  }
`;

const Button = styled.div`
  width: 100%;
  font-size: 18px;
  border: 1px solid var(--translucent);
  background: var(--translucent);
  border-radius: 15px;
  color: var(--color);
  text-align: center;
  cursor: pointer;
  padding: 20px 15px;
`;

export default function SocialLinksEditor() {
  const { profileMetaData, setProfileMetaData, popupAlert } =
    useContext(Context);
  const [socialLinks, setSocialLinks] = useState(null);

  useEffect(() => {
    if (profileMetaData && !socialLinks) {
      if (profileMetaData.socialLinks) {
        setSocialLinks(profileMetaData.socialLinks);
      } else {
        setSocialLinks([]);
      }
    }
  }, [profileMetaData]);

  if (!socialLinks) return <LoadingSection />;

  return (
    <Container>
      <DragAndDropItems
        data={socialLinks}
        onChange={makeChangeAndSave}
        type="SOCIAL_MEDIA_LINKS"
      />
      <br /> <br />
    </Container>
  );

  function makeChangeAndSave(newLinks) {
    setSocialLinks(newLinks);

    onChangeStop("socialLinksEditor", () => {
      save(newLinks);
    });
  }

  async function save(newSocialLinks) {
    if (!newSocialLinks) newSocialLinks = socialLinks;

    try {
      await serverLine.patch("/my-profile-meta", { changes: { socialLinks } });
      let newData = { ...profileMetaData };
      newData.socialLinks = socialLinks;
      sendPostMessage({ profileMetaData: { draftState: newData } });

      setProfileMetaData(newData);

      // popupAlert("Saved!");
    } catch (e) {
      popupAlert(e.message);
    }
  }
}
