import rearrangeContent from "../../controllers/profile/rearrangeContent";
import contentTypeVsData from "../../data/contentTypeVsData";
import RenderProfileCards from "./RenderProfileCards";

let exemptVariatonFor = ["LIST"];

export default function ProcessProfileCards({ profileData, contentType }) {
  let profileMetaData = profileData.profileMetaData;
  let variation = getVariation();

  let loadedList = rearrangeContent({
    profileMetaData: profileMetaData,
    type: contentType,
    list: profileData[contentType],
  });

  let contentTypeData = contentTypeVsData[contentType];

  if (!contentTypeData) return `Invalid contentType ${contentType}`;

  return (
    <RenderProfileCards
      items={loadedList}
      variation={variation}
      label={contentTypeData.label}
    />
  );

  function getVariation() {
    if (profileMetaData) {
      if (profileMetaData.sectionVariation) {
        if (profileMetaData.sectionVariation[contentType]) {
          return profileMetaData.sectionVariation[contentType];
        }
      }
    }

    return contentTypeVsData[contentType].defaultVariation;
  }
}
