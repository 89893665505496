import { styled } from "styled-components";
import OnboardingProgress from "./OnboardingProgress";
import BrandContainer from "../brand/BrandContainer";
import BasicInfoEditor from "../editors/BasicInfoEditor";
import { useContext, useState } from "react";
import Context from "../../Context";
import OnboardingBoilerplate from "./OnboardingBoilerplate";
import MaterialInput from "../helperComponents/MaterialInput";
import CustomButton from "../helperComponents/CustomButton";
import { AiOutlineCheck } from "react-icons/ai";
import LoadingSection from "../helperComponents/LoadingSection";
import { serverLine } from "../../controllers/serverLine";
import UsernameEditor from "./UsernameEditor";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 50px 0;
  gap: 25px;
  height: 100vh;
  overflow-y: scroll;
  align-items: center;
`;

const Main = styled.div`
  width: 38vw;
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;
  /* justify-content: center; */
  padding-top: 100px;
  gap: 25px;

  @media (max-width: 900px) {
    width: 90vw;
  }
`;

const Heading = styled.h2`
  margin: 0;
  text-align: center;
  font-size: 18px;
  padding: 0;
  font-weight: 100;
  margin: 20px 0;
`;

const InputSection = styled.div`
  display: flex;
  font-size: 40px;
  flex-direction: row;
`;

const Input = styled.input`
  background-color: transparent;
  color: var(--color);
  border: none;
  font-size: 40px;
  border-bottom: 1px solid var(--color);
`;

const InputText = styled.div`
  opacity: 0.5;
`;

export default function AskUsername() {
  const [loading, setLoading] = useState(false);

  if (loading)
    return (
      <Container>
        <LoadingSection />
      </Container>
    );

  return (
    <Container>
      <BrandContainer />
      <UsernameEditor title="Please choose your username." />
    </Container>
  );
}

function isAlphaNumeric(str) {
  return /^[a-zA-Z0-9]+$/.test(str);
}
