import { styled } from "styled-components";
import { useState } from "react";

import { PiSmileySadThin } from "react-icons/pi";
import logout from "../../controllers/logout";
import LoadingSection from "../helperComponents/LoadingSection";
import WithHeader from "../core/WithHeader";
import CustomButton from "../helperComponents/CustomButton";

const Success = styled.div`
  margin-top: 100px;
  width: 38vw;
  padding: 50px 20px;
  border-radius: 10px;
  background-color: var(--translucent);
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;

  @media (max-width: 900px) {
    width: 90vw;
  }
`;
const SuccessIcon = styled.div`
  background-color: var(--translucent);
  height: 100px;
  width: 100px;
  display: flex;

  justify-content: center;
  align-items: center;
  font-size: 30px;
  border-radius: 100px;
`;
const SuccessText = styled.h2``;
const SuccessButtons = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;
`;

export default function YouAreBanned() {
  const [loading, setLoading] = useState(false);
  if (!loading) return <LoadingSection />;
  return (
    <WithHeader>
      <Success>
        <SuccessIcon>
          <PiSmileySadThin />
        </SuccessIcon>

        <SuccessText>Sorry! You have been banned</SuccessText>

        <SuccessButtons>
          <CustomButton variant="outlined" onClick={doLogout}>
            Logout
          </CustomButton>
        </SuccessButtons>
      </Success>
    </WithHeader>
  );

  function doLogout() {
    setLoading(true);
    logout();
  }
}
