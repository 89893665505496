import { styled } from "styled-components";
import { useContext } from "react";
import Context from "../../Context.js";
import { AiOutlineDown } from "react-icons/ai/index.js";
import PopupLocationSelector from "./PopupLocationSelector.js";

const Container = styled.div`
  display: flex;
  flex-direction: row;
  padding: 10px;
  width: 100%;
  /* background-color: var(--translucent); */
  background-color: var(--translucentInteraction);
  border: 1px solid var(--translucentHard);
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  border-radius: var(--borderRadius1);
`;

const Label = styled.div`
  width: 100%;
  opacity: 0.5;
  text-transform: capitalize;
  font-size: var(--fontSizeSmall);
`;

const Left = styled.div`
  display: flex;
  gap: 5px;
  flex-direction: column;
`;

const SelectInputText = styled.div`
  font-size: var(--fontSize1);
`;
const SelectInputButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
`;

export default function DropDownLocationInput(props) {
  const { setForm } = useContext(Context);

  let {
    value,
    placeholder,
    onChange,

    type,
    country,
    state,
  } = props;

  return (
    <Container
      onClick={() => {
        setForm({
          title: placeholder,
          component: (
            <PopupLocationSelector
              state={state}
              country={country}
              type={type}
              value={value}
              onChange={onChange}
            />
          ),
        });
      }}
    >
      <Left>
        <Label>{props.type.toLowerCase()}</Label>
        <SelectInput {...props} />
      </Left>
      <SelectInputButton>
        <AiOutlineDown />
      </SelectInputButton>
    </Container>
  );
}

function SelectInput({ value, placeholder }) {
  return <SelectInputText>{value ? value : placeholder}</SelectInputText>;
}
