import styled from "styled-components";

const ListItemEditorInputs = styled.div`
  display: flex;
  flex-direction: column;
  color: var(--color);
  gap: 10px;
`;

export default ListItemEditorInputs;
