import { styled } from "styled-components";

import { useContext, useState } from "react";
import Context from "../../Context.js";

import LoadingSection from "../helperComponents/LoadingSection.js";
import ContentItemEditor from "./ContentItemEditor.js";

import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import CollapsedItemEditor from "./CollapsedItemEditor.js";
import CustomButton from "../helperComponents/CustomButton.js";
import goTo from "../../controllers/goTo.js";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 30px;
`;

const Items = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  /* gap: 30px; */
`;

const DraggableItem = styled.div`
  width: 100%;
  margin-bottom: 30px;
  /* height: 100px; */
  /* background-color: red; */
`;

export default function ContentItemsEditor({
  type,
  onChange,
  moveItemUp,
  moveItem,
  addItem,
  deleteItem,
  initialData,
  path,
  pathData,
}) {
  const { loggedInUser, setForm } = useContext(Context);

  if (!loggedInUser) return <LoadingSection />;

  if (!initialData) return <LoadingSection />;

  let adderComponent = (
    <ContentItemEditor type={type} key="adder" onAdd={addItem} path={path} />
  );
  let linkToAdderComponent = (
    <CustomButton
      height={"50px"}
      variant="outlined-filled"
      onClick={goTo(`/edit/${path}/new`)}
    >
      Create Item
    </CustomButton>
  );

  if (pathData) {
    if (pathData == "new") {
      return adderComponent;
    } else {
      let i = 0;
      for (let item of initialData) {
        if (item._id == pathData) {
          return (
            <ContentItemEditor
              path={path}
              type={type}
              key={item._id}
              onChange={onChange}
              onMoveUp={moveItemUp}
              onDelete={deleteItem}
              index={i}
              initialState={initialData[i]}
            />
          );
        }

        i++;
      }
    }
  }

  return (
    <Container>
      {linkToAdderComponent}
      {renderItems()}
    </Container>
  );

  function openItem(itemID) {
    return goTo(`/edit/${path}/${itemID}`);
  }

  function renderItems() {
    let items = [];

    for (let i = 0; i < initialData.length; i++) {
      let theItem = initialData[i];

      theItem = { ...theItem };

      if (!theItem.draftState) theItem.draftState = {};

      items.push({
        comp: (
          <CollapsedItemEditor
            title={theItem.draftState.title}
            onClick={openItem(initialData[i]._id)}
          />
        ),
        itemData: theItem,
      });
    }

    let draggableItems = [];

    let index = 0;
    for (let item of items) {
      draggableItems.push(
        <Draggable
          key={item.itemData._id.toString()}
          draggableId={item.itemData._id.toString()}
          index={index}
        >
          {(provided) => {
            return (
              <div>
                <DraggableItem
                  ref={provided.innerRef}
                  {...provided.draggableProps}
                  {...provided.dragHandleProps}
                >
                  {item.comp}
                </DraggableItem>
                {provided.placeholder}
              </div>
            );
          }}
        </Draggable>
      );

      index++;
    }

    return (
      <DragDropContext onDragEnd={handleOnDragEnd}>
        <Droppable droppableId="droppable">
          {(provided) => (
            <Items {...provided.droppableProps} ref={provided.innerRef}>
              {draggableItems}
              {provided.placeholder}
            </Items>
          )}
        </Droppable>
      </DragDropContext>
    );
  }

  function handleOnDragEnd(result) {
    console.log(result);
    if (result.destination) {
      moveItem(result.source.index, result.destination.index);
    }
  }
}
