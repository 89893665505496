import styled from "styled-components";
import getImageURL from "../../controllers/getImageURL";
import goTo from "../../controllers/goTo";
import ContentCardBoilerplate from "./ContentCardBoilerplate";
import capitalizeFirstLetter from "../../controllers/capitalizeFirstLetter";
import getLikeString from "../../controllers/getLikeString";

const Container = styled.div`
  width: 100%;
  position: relative;
  cursor: pointer;
  overflow: hidden;
  border-radius: 10px;
  border: 1px solid var(--translucentHard);
  background-color: var(--translucent);
`;

const TheImage = styled.img`
  width: 100%;
  pointer-events: none;
`;

const Title = styled.div`
  font-weight: 500;
  font-size: 20px;

  @media (max-width: 900px) {
    font-size: 18px;
  }
`;

const Lines = styled.div`
  width: 100%;

  padding: 20px 25px;

  color: var(--color);
  display: flex;
  flex-direction: column;
  gap: 10px;

  @media (max-width: 900px) {
    padding: 20px 10px;
    font-size: 15px;
    gap: 10px;
  }
`;

const Line3 = styled.div`
  opacity: 0.5;
  font-size: 13px;
  font-weight: 300;
  @media (max-width: 900px) {
    font-size: 13px;
  }
`;

export default function ArticleCard({ item, variant }) {
  let title = item.title;

  if (item.type === "LIFE_STORY") title = "Story of " + item.author.name;

  let theChild = null;

  let theCore = (
    <Lines>
      <Title>{title}</Title>

      {variant === "FEED" ? (
        <Line3>
          By {capitalizeFirstLetter(item.author.username)} .{" "}
          {getLikeString(item)}
        </Line3>
      ) : null}
    </Lines>
  );

  if (!item.image) {
    theChild = <Container>{theCore}</Container>;
  } else {
    theChild = (
      <Container>
        <TheImage src={getImageURL(item.image)} />
        {theCore}
      </Container>
    );
  }

  return (
    <ContentCardBoilerplate item={item} variant={variant}>
      {theChild}
    </ContentCardBoilerplate>
  );
}
