import { styled } from "styled-components";

import { useEffect, useState } from "react";
import { serverLine } from "../../../controllers/serverLine";
import WithHeader from "../WithHeader";
import DraftItem from "../../draft/DraftItem";
import CustomButton from "../../helperComponents/CustomButton";
import LoadingSection from "../../helperComponents/LoadingSection";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 50px;
  /* width: 1 */
`;

const Main = styled.div`
  width: 38vw;
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 25px;

  @media (max-width: 900px) {
    width: 90vw;
  }
`;
const Title = styled.h2``;
const TheList = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 25px;
`;

export default function Drafts() {
  const [items, setItems] = useState(null);

  useEffect(() => {
    serverLine.get("/drafts").then(setItems);
  }, []);

  if (!items) return <LoadingSection />;

  let list = renderItems();

  return (
    <WithHeader>
      <Container>
        <Main>
          <Title>Drafts</Title>
          <TheList>
            {list.length ? (
              list
            ) : (
              <CustomButton style={{ opacity: 0.5 }} variant="minimal">
                Nothing Found
              </CustomButton>
            )}
          </TheList>
        </Main>
      </Container>
    </WithHeader>
  );

  function renderItems() {
    let toReturn = [];

    for (let item of items) {
      toReturn.push(<DraftItem item={item} />);
    }

    return toReturn;
  }
}
