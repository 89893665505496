import styled from "styled-components";
import VerticalTheme from "../VerticalTheme";
import VerticalFourHeader from "./VerticalFourHeader";

const MainContent = styled.div`
  padding: 60px 0px;
  /* padding-top: 150px; */
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 100px;

  @media (max-width: 900px) {
    padding: 5vw;
  }
`;

export default function VerticalFourTheme({ profileData, contentPageData }) {
  return (
    <VerticalTheme
      profileData={profileData}
      contentPageData={contentPageData}
      Header={VerticalFourHeader}
    />
  );
}
