import { AiOutlineArrowRight } from "react-icons/ai";
import { BsArrowBarRight } from "react-icons/bs";
import { styled } from "styled-components";
import CustomToggle from "../../helperComponents/CustomToggle";
import { LuPopsicle } from "react-icons/lu";
import { siteEditorSectionGroups } from "../../../data/websiteEditorSections";

const Arrow = styled.div`
  opacity: 0.5;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const SectionName = styled.div`
  height: 50px;
  padding: 10px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-radius: 0;
  flex: 1;
  background: var(--translucent);

  &:hover {
    background: var(--translucentHard);
  }

  ${({ isActive }) => {
    if (isActive)
      return `
        background: var(--translucentHard);
    `;
  }}
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0;
  width: 100%;
  background-color: var(--translucentHard);
  border-radius: 5px;
  overflow: hidden;
  border: 1px solid var(--translucent);
  gap: 1px;
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  padding: 20px;
  gap: 20px;
  cursor: pointer;
  justify-content: flex-start;
  background-color: var(--bgColorDark);
  width: 100%;
  align-items: center;
  /* padding-left: 0; */

  &:hover {
    background-color: var(--bgColorLight);
  }
`;

const Icon = styled.div`
  display: flex;
  justify-content: center;
  font-size: 20px;
  align-items: center;
`;

const ButtonText = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

const ButtonTitle = styled.div`
  /* font-weight: 900; */
  font-size: var(--fontSize1);
`;

const Main = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;
  width: 100%;
`;

const ContainerParent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  width: 100%;
`;

const ContainerName = styled.div`
  /* padding-left: 10px; */
  opacity: 0.7;
`;

export default function NavigateProfileEditor({ setSection }) {
  return <Main>{getGroups()}</Main>;

  function getGroups() {
    let toReturn = [];
    let i = 0;
    for (let item of siteEditorSectionGroups) {
      toReturn.push(
        <ContainerParent key={i}>
          <ContainerName>{item.name}</ContainerName>
          <Container>{getOptions(item.items)}</Container>
        </ContainerParent>
      );
      i++;
    }

    return toReturn;
  }

  function getOptions(theList) {
    let list = [];

    for (let item of theList) {
      list.push(
        <ButtonContainer
          onClick={() => {
            setSection(item.value);
          }}
        >
          <Icon>{item.icon}</Icon>

          <ButtonTitle>{item.label}</ButtonTitle>
        </ButtonContainer>
      );
    }

    return list;
  }
}
