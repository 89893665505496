import styled from "styled-components";
import CustomMasonry from "../helperComponents/CustomMasonry";
import CustomButton from "../helperComponents/CustomButton";
import { useEffect, useState } from "react";
import CardViewListZero from "./CardViewListZero";
import CardViewDetailedList from "./CardViewDetailedList";
import CardViewVCard from "./CardViewVCard";
import CardViewHCard from "./CardViewHCard";
import CardViewSleek from "./CardViewSleek";
import CardViewBig from "./CardViewBig";
import CardViewJustThumbnail from "./CardViewJustThumbnail";
import CardViewList from "./CardViewList";

const Column = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const Main = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;
const Label = styled.div`
  font-size: 18px;
  opacity: 0.5;
`;

const Main2 = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
`;

let variationSettings = {
  ZERO: { maxColumns: 1, maxItems: 7 }, // List
  ONE: { maxColumns: 1, maxItems: 7 }, // List
  TWO: { maxColumns: 1, maxItems: 7 }, // Detailed List
  THREE: { maxColumns: 4, maxItems: 4 }, //V Card
  FOUR: { maxColumns: 2, maxItems: 4 }, // H Card
  FIVE: { maxColumns: 3, maxItems: 6 }, // Sleek
  SIX: { maxColumns: 1, maxItems: 6 }, // Full Width Card
  SEVEN: { maxColumns: 3, maxItems: 8 }, // Masonry Thumbnails
};

let variationVsComponent = {
  ZERO: CardViewListZero,
  ONE: CardViewList,
  TWO: CardViewDetailedList,
  THREE: CardViewVCard,
  FOUR: CardViewHCard,
  FIVE: CardViewSleek,
  SIX: CardViewBig,
  SEVEN: CardViewJustThumbnail,
};

export default function RenderProfileCards({ items, variation, label }) {
  const [seeMore, setSeeMore] = useState(false);
  const [innerWidth, setInnerWidth] = useState(window.innerWidth);

  useEffect(() => {
    window.addEventListener("resize", () => {
      setInnerWidth(window.innerWidth);
    });
  }, []);

  let settings = variationSettings[variation];

  let seeMoreButton = (
    <CustomButton
      variant={"outlined"}
      circular
      width={"150px"}
      onClick={() => {
        setSeeMore(true);
      }}
    >
      See More
    </CustomButton>
  );

  let maxContent = settings.maxItems;
  let moreCardsPresent = false;

  if (items.length > maxContent) moreCardsPresent = true;

  if (!moreCardsPresent) seeMoreButton = null;

  if (!seeMore) items = items.slice(0, maxContent);

  let renderedItem = renderItems(items);

  let content = null;

  if (innerWidth <= 900) {
    settings.maxColumns = 1;
  }

  if (settings.maxColumns == 1) {
    content = <Column>{renderedItem}</Column>;
  } else {
    content = (
      <CustomMasonry
        list={renderedItem}
        maxColumns={settings.maxColumns}
      ></CustomMasonry>
    );
  }

  return (
    <Main>
      <Main2>
        <Label>{label}</Label>
        {content}
      </Main2>

      {seeMoreButton}
    </Main>
  );

  function renderItems(newItems) {
    let toRender = [];

    for (let item of newItems) {
      let Comp = variationVsComponent[variation];

      item = limitTaglineAndDesc(item);

      toRender.push(<Comp key={item._id} item={item} />);
    }

    return toRender;
  }

  function limitTaglineAndDesc(item) {
    let newItem = { ...item };

    if (!newItem.content) newItem.content = {};

    let maxDesc = 90;
    let maxTagline = 70;
    let maxTitle = 70;
    if (item.type == "PROJECT_PROGRAMMING") {
      maxTitle = 15;
    }

    newItem.title = cutText(newItem.title, maxTitle);

    if (newItem.content) {
      newItem.content.description = cutText(
        newItem.content.description,
        maxDesc
      );
      newItem.content.tagline = cutText(newItem.content.tagline, maxTagline);
    }

    return newItem;
  }
}

function cutText(content, max) {
  if (!content) return;
  if (content.length > max) return content.slice(0, max) + "...";
  return content;
}
