import styled from "styled-components";
import goTo from "../../controllers/goTo";
import CustomButton from "../helperComponents/CustomButton";
import SocialLink from "./SocialLink";
import { socialTypeVsData } from "../../data/socialMediaLinkTypes";
import renderSocialLinks from "../../controllers/renderSocialLinks";

const Container = styled.div`
  display: flex;
  flex-direction: row;
  gap: 7px;
  flex-wrap: wrap;
  width: 70%;
  justify-content: center;
  align-items: center;
`;

export default function SocialLinks({ data }) {
  if (!data) return null;

  return <Container>{renderSocialLinks(data)}</Container>;
}
