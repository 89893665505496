import { styled } from "styled-components";
import { useContext, useEffect, useState } from "react";
import Context from "../../../Context.js";
import { serverLine } from "../../../controllers/serverLine.js";
import onChangeStop from "../../../controllers/onChangeStop.js";
import CustomToggle from "../../helperComponents/CustomToggle.js";
import getProjectEditorTypeOptions from "../../../controllers/projectEditor/getProjectEditorTypeOptions.js";
import goTo from "../../../controllers/goTo.js";
import LoadingSection from "../../helperComponents/LoadingSection.js";
import DropDownInput from "../../helperComponents/DropDownInput.js";
import ContentItemEditor from "../ContentItemEditor.js";
import ContentItemsEditor from "../ContentItemsEditor.js";
import sendPostMessage from "../../../controllers/sendPostMessage.js";
import SetupPages from "../SetupPages.js";
import SectionVariationSelector from "../SectionVariationSelector.js";
import SectionAndLabel from "../../SectionAndLabel.js";
import CustomListDND from "./CustomListDND.js";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 0;
  width: 100%;
  gap: 30px;
  /* align-items: center; */

  @media (max-width: 900px) {
    width: 90vw;
  }
`;

export default function CustomListEditor({
  contentOrder,
  updateContentOrder,
  variation,
}) {
  const { loggedInUser } = useContext(Context);

  const [loadedData, setLoadedData] = useState(null);

  const [loading, setLoading] = useState(true);
  const type = "LIST_ITEM";

  useEffect(() => {
    if (!loadedData) {
      loadItems();
    }
  }, []);

  useEffect(() => {
    if (loadedData) {
      sendPostMessage({
        [type]: loadedData,
      });
    }
  }, [loadedData]);

  let rankedList = rankItems(loadedData);

  console.log(
    "contentOrder, rankedList,loadedData",
    contentOrder,
    rankedList,
    loadedData
  );

  if (loading || !loggedInUser || !loadedData)
    return (
      <Container>
        <LoadingSection />
      </Container>
    );

  return (
    <Container>
      <SectionAndLabel label={"List Items"}>
        <CustomListDND
          variation={variation}
          data={rankedList}
          onChange={onChange}
          moveItem={moveItem}
          onDelete={deleteItem}
          onAdd={addItem}
        />
      </SectionAndLabel>
    </Container>
  );

  async function loadItems() {
    setLoading(true);

    let newContent = await serverLine.get("/content-list/?type=" + type).then();

    setLoadedData(newContent);
    setLoading(false);
  }

  function rankItems(newData) {
    if (!contentOrder) return [];

    let rankedItems = [];
    let postMap = {};

    if (!newData) return [];

    for (let item of newData) {
      postMap[item._id] = item;
    }

    let theOrder = [];

    theOrder = contentOrder;

    for (let id of theOrder) {
      let theItem = postMap[id];
      if (theItem) {
        rankedItems.push(theItem);
      }
    }

    return rankedItems;
  }

  function saveItemDataOnTheServer(postContent) {
    console.log(postContent, "postContent");

    let route = "/content";

    console.log("updating postContent...");

    let changes = {
      tags: postContent.tags,
      classification: postContent.classification,
      draftState: postContent.draftState,
    };

    serverLine.patch(route, {
      contentID: postContent._id,
      changes: changes,
    });
  }

  function updateItems(theNewItems) {
    let newOrder = [];

    for (let item of theNewItems) {
      newOrder.push(item._id);
    }

    updateContentOrder(newOrder);
  }

  function onChange(newItem) {
    let theIndex = getItemIndex(loadedData, newItem._id);

    let newLoadedList = [...loadedData];
    newLoadedList[theIndex] = newItem;

    setLoadedData(newLoadedList);

    return saveItemDataOnTheServer(newItem);
  }

  function getItemIndex(items, id) {
    let i = 0;

    for (let item of items) {
      if (item._id == id) return i;
      i++;
    }

    return null;
  }

  function moveItem(oldIndex, newIndex) {
    let newInitialData = [...rankedList];
    let [recordedItem] = newInitialData.splice(oldIndex, 1);
    newInitialData.splice(newIndex, 0, recordedItem);

    updateItems(newInitialData);
  }

  function deleteItem(index) {
    return () => {
      let newInitialData = [...rankedList];
      let theItem = newInitialData[index];

      serverLine.delete("/content/?contentID=" + theItem._id);

      newInitialData.splice(index, 1);
      updateItems(newInitialData);
    };
  }

  function addItem(newItem) {
    let newInitialData = [newItem, ...loadedData];
    updateItems(newInitialData);
  }
}
