import styled from "styled-components";
import WithProfileHeader from "../WithProfileHeader";
import ProfileSections from "../ProfileSections";
import BackToHome from "../../helperComponents/BackToHome";

const MainContent = styled.div`
  padding: 60px 100px;
  /* padding-top: 150px; */
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 100px;

  @media (max-width: 900px) {
    padding: 5vw;
  }
`;

export default function DefaultTheme({ profileData, contentPageData }) {
  return (
    <WithProfileHeader profileData={profileData}>
      <MainContent>
        {contentPageData ? (
          contentPageData
        ) : (
          <ProfileSections profileData={profileData} />
        )}
      </MainContent>
      <BackToHome />
    </WithProfileHeader>
  );
}
