import styled from "styled-components";
import getPublishedOrDraftContent from "../getPublishedOrDraftContent";

const Container = styled.div``;

let taglineSupports = ["SALEABLE", "PROJECT_PROGRAMMING", "SERVICE"];

export default function getCardTagline({ Component, item }) {
  if (!taglineSupports.includes(item.type)) return;

  if (!Component) Component = Container;

  let content = getPublishedOrDraftContent(item);

  if (item.type == "SALEABLE") return <Component>${content.price}</Component>;

  if (item.type == "SERVICE")
    return (
      <Component>
        ${content.price1} - ${content.price2}
      </Component>
    );

  if (item.type == "PROJECT_PROGRAMMING")
    return <Component>{content.tagline}</Component>;
}
