import { styled } from "styled-components";
import { useContext, useEffect, useState } from "react";
import Context from "../../Context.js";
import { serverLine } from "../../controllers/serverLine.js";
import onChangeStop from "../../controllers/onChangeStop.js";
import CustomToggle from "../helperComponents/CustomToggle.js";
import getProjectEditorTypeOptions from "../../controllers/projectEditor/getProjectEditorTypeOptions.js";
import goTo from "../../controllers/goTo.js";
import LoadingSection from "../helperComponents/LoadingSection.js";
import DropDownInput from "../helperComponents/DropDownInput.js";
import ContentItemEditor from "./ContentItemEditor.js";
import ContentItemsEditor from "./ContentItemsEditor.js";
import sendPostMessage from "../../controllers/sendPostMessage.js";
import SetupPages from "./SetupPages.js";
import SectionVariationSelector from "./SectionVariationSelector.js";
import SectionAndLabel from "../SectionAndLabel.js";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 0;
  width: 100%;
  gap: 30px;
  /* align-items: center; */

  @media (max-width: 900px) {
    width: 90vw;
  }
`;

const Section = styled.div``;

const Label = styled.div``;

export default function ContentListEditor({
  initialType,

  path,
  pathData,
}) {
  const { loggedInUser, profileMetaData, setProfileMetaData } =
    useContext(Context);
  const [loadedData, setLoadedData] = useState(null);
  const [loading, setLoading] = useState(true);

  const [type, setType] = useState(initialType);

  useEffect(() => {
    if (!loadedData && profileMetaData) {
      loadItems();
    }
  }, [type, profileMetaData]);

  useEffect(() => {
    if (loadedData && profileMetaData) {
      sendPostMessage({
        [type]: loadedData,
        profileMetaData: { draftState: profileMetaData },
      });
    }
  }, [loadedData, type, profileMetaData]);

  async function loadItems() {
    setLoading(true);

    let newContent = await serverLine.get("/content-list/?type=" + type).then();

    setLoadedData(rankItems(newContent, profileMetaData));
    setLoading(false);
  }

  if (loading || !loggedInUser || !profileMetaData || !loadedData)
    return (
      <Container>
        <LoadingSection />
      </Container>
    );

  if (!loadedData.length && type == "PAGE")
    return <SetupPages callback={addItem} />;

  let variationSelector = <SectionVariationSelector sectionType={type} />;
  let editSectionLabel = "Edit Section";

  if (pathData) {
    variationSelector = null;
    editSectionLabel = null;
  }

  return (
    <Container>
      {variationSelector}

      <SectionAndLabel label={editSectionLabel}>
        <ContentItemsEditor
          type={type}
          initialData={loadedData}
          onChange={onChange}
          moveItem={moveItem}
          moveItemUp={moveItemUp}
          deleteItem={deleteItem}
          addItem={addItem}
          path={path}
          pathData={pathData}
        />
      </SectionAndLabel>
    </Container>
  );

  function rankItems(newData, profileMetaData) {
    if (!profileMetaData) return newData;

    let rankedItems = [];
    let postMap = {};

    for (let item of newData) {
      postMap[item._id] = item;
    }

    let theOrder = [];

    if (!profileMetaData.contentOrder) return newData;

    if (!profileMetaData.contentOrder[type]) return newData;

    theOrder = profileMetaData.contentOrder[type];

    for (let id of theOrder) {
      let theItem = postMap[id];
      if (theItem) {
        rankedItems.push(theItem);
      }
    }

    return rankedItems;
  }

  function saveItemDataOnTheServer(postContent) {
    console.log(postContent, "postContent");

    let route = "/content";

    console.log("updating postContent...");

    let changes = {
      tags: postContent.tags,
      classification: postContent.classification,
      draftState: postContent.draftState,
    };

    serverLine.patch(route, {
      contentID: postContent._id,
      changes: changes,
    });
  }

  function saveItemsArrangementOnTheServer(theNewItems) {
    let newContentOrder = {};

    if (profileMetaData.contentOrder) {
      newContentOrder = { ...profileMetaData.contentOrder };
    }

    if (!newContentOrder[type]) newContentOrder[type] = [];

    let newOrder = [];

    console.log(theNewItems);

    setLoadedData(JSON.parse(JSON.stringify(theNewItems)));

    for (let item of theNewItems) {
      newOrder.push(item._id);
    }

    newContentOrder[type] = newOrder;

    let newProfileMetaData = { ...profileMetaData };
    newProfileMetaData.contentOrder = newContentOrder;

    setProfileMetaData(newProfileMetaData);

    onChangeStop("profileEditor", () => {
      serverLine.patch("/my-profile-meta", {
        changes: { contentOrder: newContentOrder },
      });
    });
  }

  function onChange(index, newItem) {
    let newInitialData = [...loadedData];
    newInitialData[index] = newItem;
    setLoadedData(newInitialData);

    onChangeStop("Editor" + type, () => {
      return saveItemDataOnTheServer(newItem);
    });
  }

  function moveItemUp(index, newState) {
    return () => {
      if (index === 0) return;
      let newInitialData = [...loadedData];
      let theItem = newState;
      newInitialData.splice(index, 1);

      let leftPart = newInitialData.slice(0, index - 1);
      let rightPart = newInitialData.slice(index - 1, newInitialData.length);
      newInitialData = [...leftPart, theItem, ...rightPart];
      saveItemsArrangementOnTheServer(newInitialData);
    };
  }

  function moveItem(oldIndex, newIndex) {
    let newInitialData = [...loadedData];
    let [recordedItem] = newInitialData.splice(oldIndex, 1);
    newInitialData.splice(newIndex, 0, recordedItem);

    saveItemsArrangementOnTheServer(newInitialData);
  }

  function deleteItem(index) {
    return () => {
      let newInitialData = [...loadedData];
      let theItem = newInitialData[index];

      serverLine.delete("/content/?contentID=" + theItem._id);

      newInitialData.splice(index, 1);
      saveItemsArrangementOnTheServer(newInitialData);
    };
  }

  function addItem(newItem) {
    let newInitialData = [newItem, ...loadedData];
    saveItemsArrangementOnTheServer(newInitialData);
  }
}
