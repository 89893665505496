import { styled } from "styled-components";
import ContentActionButtons from "../ContentActionButtons";
import RenderLiteratureCore from "../../editors/RenderLiteratureCore";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 50px;
`;

const Main = styled.div`
  display: flex;
  flex-direction: column;
  width: 38vw;
  gap: 50px;

  @media (max-width: 900px) {
    width: 90vw;
  }
`;

const Container2 = styled.div`
  width: 100%;
  flex-direction: column;
  overflow: hidden;
  border-radius: 10px;
`;

export default function RenderLiterature({ value, setValue }) {
  return (
    <Container>
      <Main>
        <Container2>
          <RenderLiteratureCore item={value} />
        </Container2>

        <ContentActionButtons content={value} setContent={setValue} />
      </Main>
    </Container>
  );
}
