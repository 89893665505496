export default function hozStatDataProcessor({
  timeSpan,
  xAxis,
  rawData,
  fields,
}) {
  let processedData = [];
  let totalValue = 0;

  let newRawData = mergeDataPoints(timeSpan.value, rawData, fields);

  console.log(newRawData);

  for (let xAxisDataPoint of xAxis) {
    let theDate = xAxisDataPoint.when;

    let item = newRawData[theDate];

    console.log(theDate, item);

    let theValue = 0;

    let bottomLabel = xAxisDataPoint.label;

    if (item) {
      for (let field of fields) {
        if (item[field]) theValue += item[field];
      }

      totalValue += totalValue;

      if (isNaN(theValue)) theValue = 0;
    }

    let altInfo = `${theValue} Views`;

    if (theValue < 2) altInfo = `${theValue} View`;

    processedData.push({
      value: theValue,
      altInfo: altInfo,
      bottomLabel: bottomLabel,
    });
  }

  console.log(processedData);

  return { data: processedData, total: totalValue };
}

function mergeDataPoints(timeSpanType, rawData, fields) {
  rawData = JSON.parse(JSON.stringify(rawData));

  if (timeSpanType === "WEEK" || timeSpanType === "MONTH" || !timeSpanType) {
    return rawData;
  } else if (timeSpanType === "YEAR") {
    let newRawData = {};

    for (let date in rawData) {
      let item = rawData[date];
      let dateObj = new Date(date);

      let monthAndYear = `${dateObj.getMonth() + 1}/${dateObj.getFullYear()}`;

      if (newRawData[monthAndYear]) {
        for (let field of fields) {
          if (item[field]) newRawData[monthAndYear][field] += item[field];
        }
      } else {
        newRawData[monthAndYear] = {};

        for (let field of fields) {
          newRawData[monthAndYear][field] = item[field] ? item[field] : 0;
        }
      }
    }

    return newRawData;
  } else if (timeSpanType === "EVER") {
    let newRawData = {};

    for (let date in rawData) {
      let item = rawData[date];
      let dateObj = new Date(date);

      let year = dateObj.getFullYear();

      if (newRawData[year]) {
        for (let field of fields) {
          if (item[field]) newRawData[year][field] += item[field];
        }
      } else {
        newRawData[year] = {};

        for (let field of fields) {
          newRawData[year][field] = item[field] ? item[field] : 0;
        }
      }
    }

    return newRawData;
  }
}
