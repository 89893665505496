import { styled } from "styled-components";
import getDateString from "../../controllers/getDateString";
import goTo from "../../controllers/goTo";
import SemanticButton from "../helperComponents/SemanticButton";

const Container = styled.div`
  display: flex;
  width: 100%;
  padding: 25px;
  flex-direction: column;
  border-radius: 10px;
  gap: 20px;
  border: 1px solid var(--translucentHard);
  cursor: pointer;

  &:hover {
    background-color: var(--translucent);
  }
`;
const Title = styled.h2`
  margin: 0;
`;
const SubTitle = styled.div`
  font-size: 18px;
  text-transform: capitalize;
  opacity: 0.8;
`;

export default function DraftItem({ item }) {
  if (item.type == "GIG")
    return (
      <SemanticButton
        semanticHref={`/edit-gig/` + item._id}
        onClick={goTo(`/edit-gig/` + item._id)}
      >
        <Container>
          <Title>{item.draftTitle ? item.draftTitle : "Untitled"}</Title>
          <SubTitle>{getSubtitle()}</SubTitle>
        </Container>
      </SemanticButton>
    );

  if (item.type == "JOB")
    return (
      <SemanticButton
        semanticHref={`/edit-job/` + item._id}
        onClick={goTo(`/edit-job/` + item._id)}
      >
        <Container>
          <Title>{item.draftTitle ? item.draftTitle : "Untitled"}</Title>
          <SubTitle>{getSubtitle()}</SubTitle>
        </Container>
      </SemanticButton>
    );

  return (
    <SemanticButton
      semanticHref={`/edit-content/` + item._id}
      onClick={goTo(`/edit-content/` + item._id)}
    >
      <Container>
        <Title>{item.draftTitle ? item.draftTitle : "Untitled"}</Title>
        <SubTitle>{getSubtitle()}</SubTitle>
      </Container>
    </SemanticButton>
  );

  function getSubtitle() {
    return `${item.type.toLowerCase()} . ${getDateString(
      new Date(item.createdAt)
    )}`;
  }
}
