import CustomButton from "../helperComponents/CustomButton";
import { styled } from "styled-components";
import goTo from "../../controllers/goTo";
import { serverLine } from "../../controllers/serverLine";
import { useEffect, useState } from "react";
import capitalizeFirstLetter from "../../controllers/capitalizeFirstLetter";
import FeedSection from "./FeedSection";
import FeedHr from "./FeedHr";
import LoadingSection from "../helperComponents/LoadingSection";

const List = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 15px;
`;

export default function TrendingTags() {
  const [tags, setTags] = useState(null);

  useEffect(() => {
    serverLine.get("/trending-tags").then(setTags);
  }, []);

  if (!tags) {
    return <LoadingSection />;
  }

  if (!tags.length) return [];

  return (
    <>
      <FeedSection title={"Trending"}>
        <List>
          {tags.map((item) => (
            <CustomButton
              semanticHref={"/tag-feed/" + item.tagName}
              key={item._id}
              // style={{ fontSize: "17px", padding: "10px 20px" }}
              variant="filled"
              onClick={goTo("/tag-feed/" + item.tagName)}
            >
              {capitalizeFirstLetter(item.tagName)}
            </CustomButton>
          ))}
        </List>
      </FeedSection>
      <FeedHr />
    </>
  );
}
