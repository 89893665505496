import styled from "styled-components";
import getImageURL from "../../../../controllers/getImageURL";
import CardButton from "./CardButton";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 20px;
`;

const Description = styled.div``;

const Title = styled.div`
  font-size: 37px;

  font-weight: 900;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  /* align-items: center; */
  gap: 25px;
`;

const Image = styled.img`
  height: 250px;
  width: auto;
  border-radius: 15px;
`;

export default function CardVariation3({ content }) {
  return (
    <Row>
      <Container>
        <Title>{content.title}</Title>
        <Description>{content.description}</Description>
        <CardButton content={content} />
      </Container>
      {content.hideThumbnail ? null : (
        <Image src={getImageURL(content.image)} />
      )}
    </Row>
  );
}
