import styled from "styled-components";
import CustomButton from "../helperComponents/CustomButton";
import { useContext, useState } from "react";
import LoadingSection from "../helperComponents/LoadingSection";
import { serverLine } from "../../controllers/serverLine";
import Context from "../../Context";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;
const Text = styled.div`
  opacity: 0.7;
  font-size: var(--fontSize1);
`;

export default function SetupPages({ callback }) {
  const { profileMetaData } = useContext(Context);
  const [loading, setLoading] = useState(false);

  if (loading) return <LoadingSection />;

  return (
    <Container>
      <Text>
        This setting allows you to divide your portfolio into multiple page and
        decide what section will be on what page.
      </Text>

      <CustomButton onClick={setupPages}>Setup Pages</CustomButton>
    </Container>
  );

  async function setupPages() {
    let newState = {
      type: "PAGE",
      draftState: {
        title: "Home",
        isHome: true,
        pageID: "home",
        sections: [],
        sectionArrangementType: "DEFAULT",
      },
    };
    newState.published = true;

    setLoading(true);

    newState = await serverLine.post("/content", { values: newState });

    console.log("NEW STATE", newState);
    setLoading(false);

    callback(newState);
  }
}
