import { styled } from "styled-components";
import { useContext } from "react";

import Context from "../../Context";

import RegisterView from "../contentPage/RegisterView";
import contentTypeVsData from "../../data/contentTypeVsData";
import LoadingSection from "../helperComponents/LoadingSection";

const Info = styled.div`
  margin-top: 50px;
  opacity: 0.5;
`;

export default function RenderContent({ postData, setPostData }) {
  const { loggedInUserID } = useContext(Context);

  if (!postData) return null;

  if (postData.loading) return <LoadingSection />;

  if (postData.archived) return <Info>Content has been deleted.</Info>;

  let typeData = contentTypeVsData[postData.type];

  let Comp = typeData.cardForContentPage;

  let content = <Comp value={postData} setValue={setPostData} />;

  if (postData.banned) {
    if (postData.authorUserID === loggedInUserID) {
      content = (
        <>
          <Info>Your content has been banned.</Info>
          <br />
          <br /> <br /> <br />
          {content}
        </>
      );
    } else {
      content = (
        <>
          <Info>{postData.title}</Info>
          <Info>This content has been banned.</Info>
        </>
      );
    }
  }

  return [
    content,
    <RegisterView subjectType={"POST"} subjectID={postData._id} />,
  ];
}
