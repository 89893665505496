export default function getSubdomain() {
  let hostNameSplit = window.location.hostname.split(".");

  // console.log(hostNameSplit);

  if (hostNameSplit.length < 3) return false;

  if (hostNameSplit[0] === "www") return false;

  return hostNameSplit[0];
}
