import { styled } from "styled-components";
import { useContext, useEffect, useRef, useState } from "react";
import { serverLine } from "../../controllers/serverLine.js";
import Context from "../../Context.js";
import { AiOutlineCheck, AiOutlineUpload } from "react-icons/ai/index.js";
import MaterialInput from "../helperComponents/MaterialInput.js";
import CustomButton from "../helperComponents/CustomButton.js";
import LoadingSection from "../helperComponents/LoadingSection.js";
import sendPostMessage from "../../controllers/sendPostMessage.js";
import { nanoid } from "nanoid";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 0;
  width: 100%;

  gap: 30px;
  align-items: center;
`;

const LinkEditorContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 10px;
`;

const AddLinkSection = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 10px;
`;

const LinkList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
  width: 100%;
`;

export default function LinksEditor() {
  const { loggedInUser, setLoggedInUser, popupAlert } = useContext(Context);
  const [links, setLinks] = useState({ items: {}, order: [] });
  const [tempName, setTempName] = useState("");
  const [tempLink, setTempLink] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    serverLine.get("/logged-in-user").then((theData) => {
      if (theData.links) {
        setLinks(theData.links);
      }
    });
  }, [loggedInUser]);

  if (!links || loading) return <LoadingSection />;

  return (
    <Container>
      <AddLinkSection>
        <MaterialInput
          value={tempName}
          onChange={updateTempName}
          label={"Link Name"}
        />
        <MaterialInput
          value={tempLink}
          onChange={updateTempLink}
          label={"Link URL"}
        />

        <CustomButton onClick={addLink}>Add</CustomButton>
      </AddLinkSection>
      <LinkList>{renderAllLinks()}</LinkList>
      <CustomButton
        style={{ width: "100%" }}
        icon={<AiOutlineCheck />}
        onClick={save}
        variant="outlined"
      >
        Save
      </CustomButton>
      <br /> <br />
    </Container>
  );

  function updateTempName(e) {
    setTempName(e.target.value);
  }

  function updateTempLink(e) {
    setTempLink(e.target.value);
  }

  function makeChange(linkID, field) {
    return (e) => {
      let linkState = { ...links };
      linkState.items[linkID][field] = e.target.value;
      setLinks(linkState);
    };
  }

  function addLink() {
    let linkState = { items: {}, order: [] };

    if (links) {
      linkState = { ...links };
    }

    let newID = nanoid();

    linkState.items[newID] = { name: tempName, link: tempLink };
    linkState.order.push(newID);
    setLinks(linkState);

    setTempName("");
    setTempLink("");
  }

  function renderAllLinks() {
    let toRender = [];

    for (let linkID of links.order) {
      let link = links.items[linkID];

      toRender.push(
        <LinkEditorContainer>
          <MaterialInput
            value={link.name}
            onChange={makeChange(linkID, "name")}
            label={"Type Button Name"}
          />

          <MaterialInput
            value={link.link}
            onChange={makeChange(linkID, "link")}
            label={"Type url here"}
          />

          <CustomButton onClick={deleteLink(linkID)}>Delete Link</CustomButton>
        </LinkEditorContainer>
      );
    }

    return toRender;
  }

  function deleteLink(linkID) {
    return () => {
      let newLinkState = { ...links };
      delete newLinkState.items[linkID];

      let orderIndex = newLinkState.order.indexOf(linkID);
      let newOrder = [
        ...newLinkState.order.slice(0, orderIndex),
        ...newLinkState.order.slice(orderIndex + 1, newLinkState.order.length),
      ];
      newLinkState.order = newOrder;
      setLinks(newLinkState);
    };
  }

  async function save() {
    let toSend = {
      changes: { links },
    };

    setLoading(true);

    try {
      await serverLine.patch("/profile", toSend);

      let newLoggedInUser = { ...loggedInUser };

      newLoggedInUser.links = links;

      sendPostMessage({ user: newLoggedInUser });

      setLoggedInUser(newLoggedInUser);
      setLoading(false);

      popupAlert("Saved!");
    } catch (e) {
      setLoading(false);
      popupAlert(e.message);
    }
  }
}
