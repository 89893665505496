import { styled } from "styled-components";
import { useContext, useState } from "react";

import { AiOutlineSend } from "react-icons/ai";

import openLoginPopup from "../../controllers/openLoginPopup";
import OverlayLoading from "../helperComponents/OverlayLoading";
import { serverLine } from "../../controllers/serverLine";
import MaterialInput from "../helperComponents/MaterialInput";
import Context from "../../Context";
import CustomButton from "../helperComponents/CustomButton";

const InputSection = styled.div`
  display: flex;
  flex-direction: row;
  gap: 15px;
  align-items: flex-start;
  position: relative;
  padding: 0;
  border-radius: 10px;
  margin-top: 25px;
`;

export default function ReplyBox({ commentAddress, myReplies, setMyReplies }) {
  const { loggedInUserID } = useContext(Context);
  const [commentText, setCommentText] = useState("");
  const [postingComment, setPostingComment] = useState(false);

  return (
    <InputSection>
      <MaterialInput
        multiline={true}
        value={commentText}
        maxRows={5}
        label="Type Reply Here"
        onChange={updateText}
        onEnter={post}
      />
      <CustomButton
        style={{ flexDirection: "row-reverse" }}
        // width="100px"
        variant="outlined"
        onClick={post}
        icon={<AiOutlineSend />}
      >
        Reply
      </CustomButton>

      {postingComment ? <OverlayLoading /> : null}
    </InputSection>
  );

  function updateText(e) {
    setCommentText(e.target.value);
  }

  async function post() {
    if (!loggedInUserID) return openLoginPopup();
    if (!commentText) return;
    if (!commentText.trim()) return;

    try {
      setPostingComment(true);
      let newComment = await serverLine.post("/reply", {
        commentAddress,
        commentText,
      });

      setMyReplies([newComment, ...myReplies]);
      setCommentText("");
      setPostingComment(false);
    } catch (e) {
      console.log(e);
      setPostingComment(false);
    }
  }
}
