import styled from "styled-components";

import { useContext } from "react";
import Context from "../../../Context.js";
import getStaticImageURL from "../../../controllers/getStaticImageURL";

const Container = styled.div`
  height: 100vh;
  height: 100dvh;
  width: 100vw;
  overflow: hidden;
  background-color: var(--bgColor);
`;

const Main = styled.div`
  height: 100vh;
  height: 100dvh;
  width: 100vw;
  overflow: hidden;
  position: absolute;
  top: 0;
  /* background-color: rgba(0, 0, 0, 0.7); */
  /* backdrop-filter: blur(80px); */
  left: 0;
  z-index: 2;
`;

const Background = styled.img`
  height: 100vh;
  height: 100dvh;
  transform: scale(2);
  width: 100vw;
  object-fit: cover;
`;

export default function WithBackground({ children }) {
  const { loggedInUserID, colorMode } = useContext(Context);

  // return children;

  return (
    <Container>
      {/* <Background src={getStaticImageURL("blurred-background.jpeg")} /> */}
      <Main>{children}</Main>
    </Container>
  );
}
