import getPublishedOrDraftContent from "../../../controllers/getPublishedOrDraftContent";
import ProfileSectionBlank from "../ProfileSectionBlank";
import CardVariation1 from "./cardVariations/CardVariation1";
import CardVariation2 from "./cardVariations/CardVariation2";
import CardVariation3 from "./cardVariations/CardVariation3";

export default function RenderCard({ profileData, cardID }) {
  let theCard = getTheCard();

  if (!cardID) return "Please! Select a card";

  if (!theCard) return `Card not found. ${cardID}`;

  let content = getPublishedOrDraftContent(theCard);

  if (!content) return "Card lacks content";

  return (
    <ProfileSectionBlank label={""} id={""}>
      {getCardVariation()}
    </ProfileSectionBlank>
  );

  function getCardVariation() {
    let variations = {
      ONE: <CardVariation1 content={content} />,
      TWO: <CardVariation2 content={content} />,
      THREE: <CardVariation3 content={content} />,
    };

    if (!content.variation) return variations.ONE;

    return variations[content.variation];
  }

  function getTheCard() {
    for (let item of profileData["CARD"]) {
      if (item._id == cardID) return item;
    }

    return null;
  }
}
