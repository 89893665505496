import styled from "styled-components";
import { CustomLexicalEditor } from "../richTextEditor/CustomLexicalEditor";
import { useContext } from "react";
import Context from "../../Context";
import getStaticImageURL from "../../controllers/getStaticImageURL";

const Main = styled.div`
  width: 80%;
  display: flex;
  flex-direction: column;

  gap: 20px;
`;

let themeStyleVsBg = {
  PAPER: "literature-paper-background2.jpg",
  PAINT: "literature-paint-background.jpeg",
  SKY: "literature-sky-background7.jpg",
  BLACK: "literature-black-background.jpeg",
};

function getBackgroundImage(themeStyle) {
  let theImage = themeStyleVsBg[themeStyle];
  if (!theImage) return null;
  return `url("${getStaticImageURL("literature/" + theImage)}")`;
}

const Container = styled.div`
  width: 100%;
  padding: 50px 0;
  display: flex;
  flex-direction: column;
  min-height: 200px;

  justify-content: center;
  align-items: center;

  gap: 20px;
  background: ${({ themeStyle }) => {
    return getBackgroundImage(themeStyle);
  }};

  background-size: cover;
`;

const Author = styled.div`
  text-transform: capitalize;
  font-size: 18px;
`;

export default function RenderLiteratureCore({ item }) {
  let author = item.author;

  if (!item.author) return "Author not defined";

  if (!item.content) return null;
  if (!item.content.richText) return null;

  let themeStyle = "PAPER";

  if (item.content.themeStyle) themeStyle = item.content.themeStyle;

  let fontStyle = "HAND";

  if (item.content.fontStyle) fontStyle = item.content.fontStyle;

  let textStyle = getTextStyle();

  return (
    <Container themeStyle={themeStyle} style={textStyle}>
      <Main>
        <CustomLexicalEditor
          style={{ fontSize: "25px", fontWeight: 500, ...textStyle }}
          initialEditorState={item.content.richText}
          readOnly={true}
        />

        <Author>By {author.username}</Author>
      </Main>
    </Container>
  );

  function getTextStyle() {
    let theStyle = {};

    if (themeStyle == "PAPER") {
      theStyle.color = "#111";
    }

    if (themeStyle == "PAINT") {
      theStyle.color = "#222";
    }

    if (themeStyle == "BLACK") {
      theStyle.color = "#fff";
    }

    if (themeStyle == "SKY") {
      theStyle.color = "#fff";
    }

    if (fontStyle == "SERIF") {
      theStyle.fontFamily = "'playfair-display', serif";
    }

    if (fontStyle == "SANS") {
      theStyle.fontFamily = " 'Raleway', sans-serif";
    }

    if (fontStyle == "MONO") {
      theStyle.fontFamily = "'Inconsolata', monospace";
    }

    if (fontStyle == "HAND") {
      theStyle.fontFamily = "'Caveat', cursive";
    }

    return theStyle;
  }
}
