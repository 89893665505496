import { styled } from "styled-components";

import contentTypeVsData from "../../data/contentTypeVsData";

const Container = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 10px;

  gap: 0;
  cursor: pointer;
  align-items: center;

  &:hover {
    background-color: var(--translucent);
  }
`;

const Lines = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 10px;
  font-size: 18px;
  padding: 25px 20px;
  border-radius: 0 0 10px 10px;
  border: 1px solid var(--translucentHard);
  border-top: none;
`;
const Line1 = styled.div`
  font-size: 21px;
  text-transform: capitalize;
  font-weight: 800;
`;
const Line2 = styled.div``;
const Icon = styled.div``;

const Image = styled.img`
  width: 100%;
  border-radius: 10px 10px 0 0;
`;

const Line3 = styled.div`
  opacity: 0.5;
`;

export default function FeedItem({ item }) {
  let typeData = contentTypeVsData[item.type];

  if (!typeData) return <h1>typeData not found for type {item.type}</h1>;

  let Comp = typeData.cardForFeed;

  if (!Comp) return <h1>Comp data not found for type {item.type}</h1>;

  return <Comp variant="FEED" item={item} />;
}
