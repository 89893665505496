import { styled } from "styled-components";
import { useContext, useEffect, useRef, useState } from "react";

import selectFile from "../../controllers/selectFile.js";
import compressAndUploadFile from "../../controllers/compressAndUploadFile.js";
import { serverLine } from "../../controllers/serverLine.js";

import Context from "../../Context.js";

import { AiOutlineCheck, AiOutlineUpload } from "react-icons/ai/index.js";
import CustomToggle from "../helperComponents/CustomToggle.js";
import ProfilePicture from "../helperComponents/ProfilePicture.js";
import ShowSelectedImage from "../helperComponents/ShowSelectedImage.js";
import DropDownLocationInput from "../helperComponents/DropDownLocationInput.js";
import MaterialInput from "../helperComponents/MaterialInput.js";
import CustomButton from "../helperComponents/CustomButton.js";
import ProfileTagsEditorParent from "./ProfileTagsEditorParent.js";
import LoadingSection from "../helperComponents/LoadingSection.js";
import sendPostMessage from "../../controllers/sendPostMessage.js";

import ThemePreview from "./ThemePreview.js";
import { themeList } from "../../data/themeList.js";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 50px;
`;

export default function ThemeSelector() {
  const { profileMetaData, setProfileMetaData, setLoggedInUser, popupAlert } =
    useContext(Context);

  if (!profileMetaData) return <LoadingSection />;

  return <Container>{renderThemes()}</Container>;

  function renderThemes() {
    let items = [];

    for (let item of themeList) {
      items.push(
        <ThemePreview
          key={item._id}
          data={item}
          onClick={selectTheme(item._id, item.colorVariation)}
        />
      );
    }

    return items;
  }

  function selectTheme(themeID, colorVariation) {
    return async () => {
      let toSend = {
        changes: {},
      };

      toSend.changes.themeID = themeID;

      let newConfig = { themeID: themeID, themeColorID: colorVariation };

      try {
        let newMetaData = {
          ...profileMetaData,
          ...newConfig,
        };

        sendPostMessage({ profileMetaData: { draftState: newMetaData } });

        await serverLine.patch("/my-profile-meta", toSend);
        setProfileMetaData(newMetaData);
      } catch (e) {
        popupAlert(e.message);
      }
    };
  }
}
