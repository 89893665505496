import { styled } from "styled-components";
import goTo from "../../controllers/goTo";

import { useContext, useEffect, useState } from "react";

import Context from "../../Context";

import capitalizeFirstLetter from "../../controllers/capitalizeFirstLetter";
import getImageURL from "../../controllers/getImageURL";
import getLikeString from "../../controllers/getLikeString";
import ContentCardBoilerplate from "./ContentCardBoilerplate";
import FeedActionButtons from "../helperComponents/FeedActionButtons";

const Container = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 10px;

  gap: 0;
  cursor: pointer;
  align-items: center;

  &:hover {
    background-color: var(--translucent);
  }
`;

const Lines = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 10px;
  font-size: 18px;
  padding: 25px 20px;
  border-radius: 10px;
  /* border: 1px solid var(--translucentHard); */
  background-color: var(--translucent);
  border-top: none;

  ${({ image }) => {
    if (image)
      return `
        border-radius: 0 0 10px 10px;
        margin-top: -3px;
        padding-top: 28px;
    `;
  }}

  @media (max-width: 900px) {
    padding: 25px 15px;
  }
`;

const Line1 = styled.div`
  font-size: 21px;
  text-transform: capitalize;
  font-weight: 700;

  @media (max-width: 900px) {
    font-size: 18px;
  }
`;

const Line2 = styled.div`
  font-size: 18px;
  opacity: 0.8;

  @media (max-width: 900px) {
    font-size: 15px;
  }
`;

const Icon = styled.div``;

const Image = styled.img`
  width: 100%;
  max-height: 500px;
  object-fit: cover;
  border-radius: 10px;
`;

const Main = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export default function PostCard({ item, variant }) {
  if (!item.content) return null;
  if (!item.content.images) return null;
  if (!item.content.images.length) return null;

  let desc = null;

  if (item.content.description) {
    desc = <Line2>{item.content.description}</Line2>;
  }

  let theImage = getImageURL(item.content.images[0]);

  return (
    <Main>
      <ContentCardBoilerplate item={item} variant={variant}>
        <Container>
          <Image src={theImage} />
          <Lines image={theImage}>
            <Line1>{item.title}</Line1>
            {desc}
          </Lines>
        </Container>
      </ContentCardBoilerplate>

      {variant == "FEED" ? <FeedActionButtons item={item} /> : null}
    </Main>
  );
}
