let sectionVariationOptions = [
  { value: "ZERO", label: "List 0" },
  { value: "ONE", label: "List 1" },
  { value: "TWO", label: "List 2" },
  { value: "THREE", label: "V Card" },
  { value: "FOUR", label: "H Card" },
  { value: "FIVE", label: "Sleek" },
  // { value: "SIX", label: "Full Width" },
  { value: "SEVEN", label: "Masonry Thumbnails" },
];

export default sectionVariationOptions;
