import styled from "styled-components";
import SocialLink from "../components/profilePage/SocialLink";
import { socialTypeVsData } from "../data/socialMediaLinkTypes";

const Container = styled.div`
  display: flex;
  flex-direction: row;
  gap: 7px;
  flex-wrap: wrap;
  width: 70%;
  justify-content: center;
  align-items: center;
`;

export default function renderSocialLinks(data, settings) {
  if (!data) return null;
  let items = [];

  let style = {};
  let variant = undefined;

  if (settings) {
    style = settings.style;
    variant = settings.variant;
  }

  style.fontSize = "20px";

  console.log(data, "data error");

  let key = 0;
  for (let item of data) {
    let typeData = socialTypeVsData[item.type];

    items.push(
      <SocialLink
        key={key}
        style={style}
        type={item.type}
        icon={typeData.icon}
        baseLink={typeData.baseLink}
        value={item.handle}
        variant={variant}
      />
    );

    key++;
  }

  return items;
}
