import { styled } from "styled-components";

import getImageURL from "../../controllers/getImageURL";
import { useContext } from "react";
import Context from "../../Context";
import OnlineOfflineStatus from "./OnlineOfflineStatus";
import getDateString from "../../controllers/getDateString";

const Container = styled.div`
  display: flex;
  flex-direction: row;
  padding: 20px;
  align-items: center;
  gap: 15px;
  cursor: pointer;
  border-bottom: 1px solid var(--translucentHard);

  &:hover {
    background-color: var(--translucent);
  }
`;
const Image = styled.img`
  height: 47px;
  width: 47px;
  object-fit: cover;
  border-radius: 500px;
`;
const Text = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 21px;
  gap: 7px;
`;
const Name = styled.div`
  font-weight: 800;
  font-size: 17px;
  text-transform: capitalize;
`;
const Subtext = styled.div`
  font-size: 16px;
  opacity: 0.5;
  display: flex;
  flex-direction: row;
  gap: 15px;
`;
const UnreadMessages = styled.div`
  background-color: var(--accentColor);
  padding: 5px 10px;
  border-radius: 20px;
  color: var(--bgColor);
  font-size: 15px;
`;

export default function ChatGroupBox({ item, onClick, friendsLastSeenAt }) {
  const { loggedInUserID } = useContext(Context);

  return (
    <Container
      onClick={() => {
        onClick(item);
      }}
    >
      <Image src={getImageURL(item.oppositeMember.profileImage, true)} />
      <Text>
        <Name>{item.oppositeMember.name}</Name>
        <Subtext>
          {getUnreadMessages()}
          <OnlineOfflineStatus
            groupID={item.groupID}
            friendsLastSeenAt={friendsLastSeenAt}
          />
        </Subtext>
      </Text>
    </Container>
  );

  function getUnreadMessages() {
    let initial = `${getDateString(item.lastMessageSentAt)}`;

    if (item.notificationData) {
      let notifData = item.notificationData[loggedInUserID];
      if (notifData) {
        if (notifData.unreadMessages) {
          return (
            <UnreadMessages>
              {notifData.unreadMessages} unread Messages
            </UnreadMessages>
          );
        }
      }
    }

    return initial;
  }
}
