import styled from "styled-components";
import { useContext, useEffect, useState } from "react";
import Context from "../../Context.js";
import { serverLine } from "../../controllers/serverLine.js";
import LoadingSection from "../helperComponents/LoadingSection.js";
import PageNotFound from "../helperComponents/PageNotFound.js";
import RenderTheme from "./RenderTheme.js";
import getPublishedOrDraftMetaData from "../../controllers/getPublishedOrDraftMetaData.js";

export default function ProfilePage({ username }) {
  let { profilePageData, setProfilePageData } = useContext(Context);

  const [error, setError] = useState(null);

  window.profilePageData = profilePageData;

  useEffect(() => {
    window.addEventListener("message", (postData) => {
      console.log("received post message", postData.data.changes);
      if (window.profilePageData) {
        if (postData.data) {
          if (postData.data.changes) {
            let newData = { ...window.profilePageData };
            for (let field in postData.data.changes) {
              newData[field] = postData.data.changes[field];
            }

            console.log("NEW DATA", newData);
            setProfilePageData(newData);
          }
        }
      }
    });
  }, []);

  useEffect(() => {
    if (username) {
      serverLine
        .get("/profile/?username=" + username)
        .then(setProfilePageData)
        .catch((err) => {
          setError(err.message);
        });
    }
  }, [username]);

  // console.log("isIframe", window.self !== window.top);

  if (!profilePageData) return <LoadingSection />;

  //get draft or published version of profileMetaData
  profilePageData = { ...profilePageData };

  profilePageData.profileMetaData = getPublishedOrDraftMetaData(
    profilePageData.profileMetaData
  );

  if (!profilePageData.profileMetaData.name)
    profilePageData.profileMetaData.name = "Unnamed";
  if (!profilePageData.profileMetaData.tagline)
    profilePageData.profileMetaData.tagline = "Tagline Goes Here";
  if (!profilePageData.profileMetaData.description)
    profilePageData.profileMetaData.description = "Description Goes Here";

  if (error == "User not found")
    return <PageNotFound message="User not found" />;

  if (error) return JSON.stringify(error);

  if (!profilePageData) return <LoadingSection />;

  if (!profilePageData.user.tagline)
    profilePageData.user.tagline = "Hi I am new to Inwire";

  if (!profilePageData.user.description)
    profilePageData.user.description = "I am new to Inwire";

  return <RenderTheme profileData={profilePageData} />;
}
