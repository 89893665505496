import { useEffect, useState } from "react";
import styled from "styled-components";

const MultiColumn = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  display: grid;
`;

const Col = styled.div`
  display: flex;
  flex-direction: column;
  /* width: 48%; */
  flex: 1;
`;

export default function CustomMasonry({
  list,
  maxColumns,
  gap,
  colsOnMobile,
  type,
}) {
  let numberOfSplits = 2;

  const [innerWidth, setInnerWidth] = useState(window.innerWidth);

  useEffect(() => {
    window.addEventListener("resize", () => {
      setInnerWidth(window.innerWidth);
    });
  }, []);

  if (innerWidth > 900) numberOfSplits = maxColumns;

  if (innerWidth <= 900 && colsOnMobile) numberOfSplits = colsOnMobile;

  let split = doSplit(numberOfSplits);
  let toRender = [];

  let defaultGap = "15px";

  if (innerWidth < 900) {
    gap = defaultGap;
  }

  if (!gap) gap = defaultGap;

  let i = 0;
  for (let item of split) {
    let subList = [];
    for (let subItem of item) {
      subList.push(subItem);
    }
    toRender.push(
      <Col style={{ gap }} key={i}>
        {subList}
      </Col>
    );
    i++;
  }

  return (
    <MultiColumn style={{ gridTemplateColumns: getGridStyle(), gap }}>
      {toRender}
    </MultiColumn>
  );

  function getGridStyle() {
    let a = "";

    for (let i = 0; i < numberOfSplits; i++) {
      a += "1fr ";
    }

    return a;
  }

  function doSplit(numberOfSplits) {
    let splits = [];

    let i = 0;
    for (let item of list) {
      let remainder = i % numberOfSplits;
      if (!splits[remainder]) splits[remainder] = [];
      splits[remainder].push(item);
      i++;
    }

    return splits;
  }
}
