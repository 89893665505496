import "./richTextEditorStyle.css";
import "./prismModified.css";
// import "./prism.css";
// import "prismjs";
// import "prismjs/components/prism-jsx";
import LexicalClickableLinkPlugin from "@lexical/react/LexicalClickableLinkPlugin";
import LinkPlugin from "./plugins/LinkPlugin.js";
import { $getRoot, $getSelection } from "lexical";
import { useEffect } from "react";
import { AutoLinkNode, LinkNode } from "@lexical/link";
import { ListItemNode, ListNode } from "@lexical/list";
import { LexicalComposer } from "@lexical/react/LexicalComposer";
import { PlainTextPlugin } from "@lexical/react/LexicalPlainTextPlugin";
import { HeadingNode, QuoteNode } from "@lexical/rich-text";
import { ContentEditable } from "@lexical/react/LexicalContentEditable";
import { HistoryPlugin } from "@lexical/react/LexicalHistoryPlugin";
import { OnChangePlugin } from "@lexical/react/LexicalOnChangePlugin";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import LexicalErrorBoundary from "@lexical/react/LexicalErrorBoundary";
import { RichTextPlugin } from "@lexical/react/LexicalRichTextPlugin";
import { ListPlugin } from "@lexical/react/LexicalListPlugin";
import { TabIndentationPlugin } from "@lexical/react/LexicalTabIndentationPlugin";
import { AutoFocusPlugin } from "@lexical/react/LexicalAutoFocusPlugin";
// import ListMaxIndentLevelPlugin from "./plugins/ListMaxIndentLevelPlugin";
import { MarkdownShortcutPlugin } from "@lexical/react/LexicalMarkdownShortcutPlugin";
import { TRANSFORMERS } from "@lexical/markdown";
import { styled } from "styled-components";
import CodeHighlightPlugin from "./plugins/CodeHighlightPlugin";
import ToolbarPlugin from "./plugins/ToolbarPlugin";
import { CodeHighlightNode, CodeNode, $createCodeNode } from "@lexical/code";
import lexicalTheme from "./lexicalTheme";
import { FloatingMenuPlugin } from "./plugins/FloatingMenuPlugin";
import ListMaxIndentLevelPlugin from "./plugins/ListMaxIndentLevelPlugin";
import { FloatingAddButton } from "./plugins/FloatingAddButton";
import AutoLinkPlugin from "./plugins/AutoLinkPlugin";
import { ImageNode } from "./nodes/ImageNode";
const theme = lexicalTheme;
// Lexical React plugins are React components, which makes them
// highly composable. Furthermore, you can lazy load plugins if
// desired, so you don't pay the cost for plugins until you
// actually use them.
function MyCustomAutoFocusPlugin() {
  const [editor] = useLexicalComposerContext();

  useEffect(() => {
    // Focus the editor when the effect fires!
    editor.focus();
  }, [editor]);

  return null;
}

let defaultState = {
  root: {
    children: [
      {
        children: [],
        direction: null,
        format: "",
        indent: 0,
        type: "paragraph",
        version: 1,
      },
    ],
    direction: null,
    format: "",
    indent: 0,
    type: "root",
    version: 1,
  },
};

function StoreInitalValue({ initialEditorState, readOnly }) {
  const [editor] = useLexicalComposerContext();

  useEffect(() => {
    doIt();
  }, []);

  useEffect(() => {
    //For literature render.
    if (readOnly) doIt();
  }, [initialEditorState, readOnly]);

  function doIt() {
    if (initialEditorState) {
      const parsed = editor.parseEditorState(initialEditorState);
      // console.log(JSON.stringify(initialEditorState), parsed);
      editor.setEditorState(parsed);
    } else {
      const parsed = editor.parseEditorState(defaultState);
      // console.log(JSON.stringify(initialEditorState), parsed);
      editor.setEditorState(parsed);
    }
  }

  return null;
}

const Container = styled.div`
  position: relative;
  width: 100%;
  font-size: 20px;
  font-weight: 300;
  color: rgb(255 255 255 / 90%);
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica,
    Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  /* font-family: "playfair-display", serif; */
`;

const Placeholder = styled.div`
  position: absolute;
  pointer-events: none;
  padding: 0 0;
  opacity: 0.5;
  top: 0;
  left: 0;
`;

// Catch any errors that occur during Lexical updates and log them
// or throw them as needed. If you don't throw them, Lexical will
// try to recover gracefully without losing user data.
function onError(error) {
  console.error(error);
}

const EDITOR_NODES = [
  AutoLinkNode,
  CodeNode,
  CodeHighlightNode,
  HeadingNode,
  LinkNode,
  ListNode,
  ListItemNode,
  QuoteNode,
  ImageNode,
];

export function CustomLexicalEditor({
  initialEditorState,
  onEditorChange = () => {},
  readOnly,
  addButtonPosX,
  placeholder = "Type Here",
  style,
  placeholderStyle,
}) {
  const initialConfig = {
    namespace: "MyEditor",
    editable: readOnly ? false : true,
    theme,
    onError,
    nodes: EDITOR_NODES,
  };

  const onChange = (editorState) => {
    editorState.read(() => {
      const json = editorState.toJSON();
      // console.log(json);
      onEditorChange(json);
      // console.log(JSON.stringify(json));
    });
  };

  if (readOnly && !initialEditorState) {
    return "Text Missing";
  }

  return (
    <Container style={style}>
      <LexicalComposer initialConfig={initialConfig}>
        {/* <PlainTextPlugin
        contentEditable={<ContentEditable />}
        placeholder={<div>Enter some text...</div>}
        ErrorBoundary={LexicalErrorBoundary}
      /> */}
        {/* <ToolbarPlugin /> */}

        <RichTextPlugin
          contentEditable={<ContentEditable />}
          placeholder={
            readOnly ? null : (
              <Placeholder style={placeholderStyle}>
                {placeholder}...
              </Placeholder>
            )
          }
          ErrorBoundary={LexicalErrorBoundary}
        />

        {!readOnly ? (
          <>
            <FloatingMenuPlugin />
            <FloatingAddButton addButtonPosX={addButtonPosX} />
          </>
        ) : null}
        <AutoFocusPlugin />

        {readOnly ? <LexicalClickableLinkPlugin /> : null}
        {/* <LexicalClickableLinkPlugin /> */}
        <AutoLinkPlugin />
        <LinkPlugin />

        <CodeHighlightPlugin />
        <OnChangePlugin onChange={onChange} />
        <MarkdownShortcutPlugin transformers={TRANSFORMERS} />
        <ListMaxIndentLevelPlugin maxDepth={7} />
        <ListPlugin />
        <TabIndentationPlugin />
        <StoreInitalValue
          readOnly={readOnly}
          initialEditorState={initialEditorState}
        />
        <HistoryPlugin />
        <MyCustomAutoFocusPlugin />
      </LexicalComposer>
    </Container>
  );
}
