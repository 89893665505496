import CustomButton from "../../helperComponents/CustomButton";
import { styled } from "styled-components";
import goTo from "../../../controllers/goTo";
import { serverLine } from "../../../controllers/serverLine";
import { useContext, useState } from "react";
import WithHeader from "../WithHeader";
import { MdPersonRemove } from "react-icons/md";

import { PiIntersectDuotone } from "react-icons/pi";
import Context from "../../../Context";
import { AiOutlineBarChart, AiOutlineUser } from "react-icons/ai";
import openPaymentPopup from "../../../controllers/payment/openPaymentPopup";
import { RiCoinLine } from "react-icons/ri";
import adminAccess from "../../../data/adminAccess";
import LoadingSection from "../../helperComponents/LoadingSection";
import logout from "../../../controllers/logout";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 30px;
  height: 100%;
`;
const Title = styled.div`
  font-size: 18px;
  font-weight: 100;
`;
const Types = styled.div`
  display: grid;

  grid-template-columns: 1fr;
  gap: 25px;
`;

const Message = styled.div`
  margin-top: 200px;
`;

export default function MoreMenu() {
  const { loggedInUser, loggedInUserID } = useContext(Context);
  const [loading, setLoading] = useState(false);
  const [loadingLogout, setLoadingLogout] = useState(false);

  if (!loggedInUserID)
    return (
      <WithHeader>
        <Message>Login to continue</Message>
      </WithHeader>
    );

  if (loading || !loggedInUser)
    return (
      <WithHeader>
        <LoadingSection />
      </WithHeader>
    );

  return (
    <WithHeader>
      <Container>
        <Title>Options</Title>
        <Types>
          <CustomButton
            variant={"outlined"}
            href="/change-username"
            icon={<AiOutlineUser />}
          >
            Change Username
          </CustomButton>

          <CustomButton
            variant={"outlined"}
            isLoading={loadingLogout}
            onClick={doLogout}
            icon={<MdPersonRemove />}
          >
            Logout
          </CustomButton>
        </Types>
      </Container>
    </WithHeader>
  );

  async function doLogout() {
    setLoadingLogout(true);
    logout();
  }

  async function switchAccount() {
    setLoading(true);
    await serverLine.post("/switch-account");
    window.location = "/";
  }

  function getOppositeAccount() {
    let currentType = loggedInUser.type;
    return currentType == "WORKER" ? "Client" : "Freelancer";
  }
}
