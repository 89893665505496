import styled from "styled-components";
import CustomButton from "../helperComponents/CustomButton";
import goTo from "../../controllers/goTo";

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 25px;
`;

const Button = styled.div`
  background-color: var(--translucent);
  padding: 15px 25px;
  width: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-radius: 50px;

  &:hover {
    background-color: var(--translucentHard);
  }
`;

const ProjectDescription = styled.div`
  width: 62vw;
  font-size: 21px;

  @media (max-width: 900px) {
    width: 100%;
  }
`;

export default function LifeStoryCard({ content }) {
  if (content.length > 100) {
    content = content.substr(0, 100) + "...";
  }

  return (
    <Container>
      <ProjectDescription>{content}</ProjectDescription>
      <CustomButton
        variant="outlined"
        width="150px"
        circular={true}
        onClick={goTo("/life-story")}
      >
        Read More
      </CustomButton>
    </Container>
  );
}
