import { GrArticle } from "react-icons/gr";
import { GoFileMedia } from "react-icons/go";
import { LiaCubeSolid } from "react-icons/lia";
import CustomButton from "../../helperComponents/CustomButton";
import { styled } from "styled-components";
import goTo from "../../../controllers/goTo";
import { serverLine } from "../../../controllers/serverLine";
import { useContext, useState } from "react";
import WithHeader from "../WithHeader";
import {
  MdOutlineArticle,
  MdOutlineBeachAccess,
  MdWorkOutline,
} from "react-icons/md";

import { PiCertificateLight } from "react-icons/pi";
import Context from "../../../Context";
import { TbBeach } from "react-icons/tb";
import openPaymentPopup from "../../../controllers/payment/openPaymentPopup";
import { BiBook } from "react-icons/bi";
import contentTypeVsData from "../../../data/contentTypeVsData";
import goToProfile from "../../../controllers/goToProfile";
import LoadingSection from "../../helperComponents/LoadingSection";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 50px;
  height: 100%;
`;
const Title = styled.h3``;

const Types = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  width: 500px;
  gap: 15px;

  @media (max-width: 900px) {
    width: 90vw;
  }
`;

const SecondaryButtons = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;
`;

const Box = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
`;

export default function CreateContent() {
  const { loggedInUser } = useContext(Context);
  const [loading, setLoading] = useState(false);

  if (loading || !loggedInUser)
    return (
      <WithHeader>
        <LoadingSection />
      </WithHeader>
    );

  let viewDraftsButton = (
    <CustomButton
      semanticHref={"/drafts"}
      onClick={goTo("/drafts")}
      variant="minimal-underlined"
    >
      Show Drafts
    </CustomButton>
  );

  let projectButtons = getProjectButtons();

  let projectSection = (
    <Box>
      <Title>Showcase your work</Title>
      <Types>{projectButtons}</Types>
    </Box>
  );

  if (!projectButtons.length) projectSection = null;

  let buttons = [
    <CustomButton
      variant={"outlined"}
      onClick={create("ARTICLE")}
      icon={<MdOutlineArticle />}
    >
      Article
    </CustomButton>,

    <CustomButton
      variant={"outlined"}
      onClick={create("BOOK")}
      icon={<BiBook />}
    >
      Book
    </CustomButton>,

    <CustomButton
      variant={"outlined"}
      onClick={create("POST")}
      icon={<GoFileMedia />}
    >
      Post
    </CustomButton>,
    <CustomButton
      variant={"outlined"}
      onClick={create("SOCIAL")}
      icon={<GoFileMedia />}
    >
      Social Post
    </CustomButton>,

    <CustomButton
      variant={"outlined"}
      onClick={create("CERTIFICATE")}
      icon={<PiCertificateLight />}
    >
      Certificate / Award
    </CustomButton>,
  ];

  if (loggedInUser.type === "CLIENT") {
    projectSection = null;

    buttons = [];
  }

  return (
    <WithHeader>
      <Container>
        <Box>
          <Title>Create Content</Title>
          <Types>{buttons}</Types>
        </Box>
        {projectSection}
        <br /> <br />
        <SecondaryButtons>{viewDraftsButton}</SecondaryButtons>
      </Container>
    </WithHeader>
  );

  function create(type) {
    return () => {
      if (type == "PROJECT") return goTo("/edit-profile/projects")();

      if (type == "CERTIFICATE") return goTo("/edit-profile/certificates")();

      setLoading(true);
      if (type == "FREELANCE_GIG") {
        serverLine.post("/gig").then((newData) => {
          setLoading(false);
          goTo("/edit-gig/" + newData._id)();
        });
      } else if (type == "JOB") {
        serverLine.post("/job").then((newData) => {
          setLoading(false);
          goTo("/edit-job/" + newData._id)();
        });
      } else {
        serverLine
          .post("/content", { values: { type: type } })
          .then((newData) => {
            setLoading(false);
            goTo("/edit-content/" + newData._id)();
          });
      }
    };
  }

  function getProjectButtons() {
    let buttons = [];

    for (let type in contentTypeVsData) {
      let typeData = contentTypeVsData[type];

      if (!typeData.supportProjectEditor) {
        continue;
      }

      if (!loggedInUser.classification) continue;

      if (
        typeData.category &&
        !loggedInUser.classification.includes(typeData.category)
      )
        continue;
      buttons.push(
        <CustomButton
          onClick={goTo("/edit-profile/projects/" + type.toLowerCase())}
          icon={typeData.icon}
        >
          {typeData.name}
        </CustomButton>
      );
    }

    return buttons;
  }
}
