import styled from "styled-components";
import { HiOutlineDocument } from "react-icons/hi/index.js";
import { GrGithub } from "react-icons/gr/index.js";
import { useContext, useEffect, useState } from "react";
import Context from "../../Context.js";

import LifeStoryCard from "../cardForProfile/LifeStoryCard.js";
import ReviewCard from "../cardForProfile/ReviewCard.js";
import {
  BsInstagram,
  BsTiktok,
  BsTwitter,
  BsTwitterX,
} from "react-icons/bs/index.js";
import { useNavigate } from "react-router-dom";
import goTo from "../../controllers/goTo.js";

import { serverLine } from "../../controllers/serverLine.js";
import ProfilePicture from "../helperComponents/ProfilePicture.js";
import { LuMessageSquare } from "react-icons/lu";
import CustomButton from "../helperComponents/CustomButton.js";
import { RiLinkedinLine, RiTwitterLine, RiUnsplashFill } from "react-icons/ri";

import SocialLink from "./SocialLink.js";
import getLinkToGCP from "../../controllers/getLinkToGCP.js";
import getLocationString from "../../controllers/getLocationString.js";

import getLexicalString from "../../controllers/getLexicalString.js";
import userIDsToGroupID from "../../controllers/userIDsToGroupID.js";
import ProfileSkills from "./ProfileSkills.js";
import capitalizeFirstLetter from "../../controllers/capitalizeFirstLetter.js";
import openLoginPopup from "../../controllers/openLoginPopup.js";
import getUrlParams from "../../controllers/getUrlParams.js";
import contentTypeVsData from "../../data/contentTypeVsData.js";
import contentOrderOnProfile from "../../data/ContentOrderOnProfile.js";
import WithProfileHeader from "./WithProfileHeader.js";
import LoadingSection from "../helperComponents/LoadingSection.js";
import { AiOutlineYoutube } from "react-icons/ai";
import { LiaArtstation } from "react-icons/lia";
import { TbBrandUpwork } from "react-icons/tb";
import { BiLogoUpwork } from "react-icons/bi";
import PageNotFound from "../helperComponents/PageNotFound.js";
import WithHeader from "../core/WithHeader.js";
import ServiceCard from "../cardForProfile/ServiceCard.js";
import BackToHome from "../helperComponents/BackToHome.js";
import rearrangeContent from "../../controllers/profile/rearrangeContent.js";
import ProfileSectionBoilerplate from "./profileSections/ProfileSectionBoilerplate.js";

const MainContent = styled.div`
  padding: 60px 100px;
  /* padding-top: 150px; */
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 100px;

  @media (max-width: 900px) {
    padding: 5vw;
  }
`;

const Section = styled.div`
  display: flex;
  flex-direction: column;
  gap: 35px;
`;

const SectionTitle = styled.div`
  font-size: 22px;
  opacity: 0.5;
`;

const SectionList = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
`;

const Button = styled.div`
  display: flex;
  flex-direction: row;
  padding: 15px 25px;
  gap: 15px;
  border-radius: 25px;
  cursor: pointer;
  border: 1px solid var(--translucentHard);
  align-items: center;
`;

const HeroButtons = styled.div`
  display: flex;
  flex-direction: row;
  gap: 25px;

  align-items: center;

  @media (max-width: 900px) {
    justify-content: center;
  }
`;

export default function RenderLifeStory({ lifeStory }) {
  let storySection = null;

  if (lifeStory) {
    if (lifeStory) {
      if (lifeStory.content) {
        let textString = getLexicalString(lifeStory.content);
        if (textString) textString = textString.trim();
        if (textString) {
          storySection = (
            <Section id="story">
              <SectionTitle>My Story</SectionTitle>
              <SectionList>
                <LifeStoryCard content={textString} />
              </SectionList>
            </Section>
          );
        }
      }
    }
  }

  return storySection;
}
