import { useContext } from "react";

import {
  AiOutlineEdit,
  AiOutlineGithub,
  AiOutlineGlobal,
} from "react-icons/ai";
import ProjectSlideshow from "../ProjectSlideshow";

import { styled } from "styled-components";

import ContentActionButtons from "../ContentActionButtons";
import getImageURL from "../../../controllers/getImageURL";
import VideoEmbed from "../../misc/VideoEmbed";
import Context from "../../../Context";
import goTo from "../../../controllers/goTo";
import CustomButton from "../../helperComponents/CustomButton";
import getStaticImageURL from "../../../controllers/getStaticImageURL";
import getSlideshowList from "../../../controllers/getSlideshowList";
import getPublishedOrDraftContent from "../../../controllers/getPublishedOrDraftContent";

const Container = styled.div`
  width: 62vw;
  display: flex;
  font-size: 21px;
  flex-direction: column;

  gap: 50px;

  @media (max-width: 900px) {
    width: 90vw;
  }
`;

const TopPart = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  @media (max-width: 900px) {
    flex-direction: column;
    gap: 20px;
    justify-content: flex-start;
  }
`;
const MainInfo = styled.div`
  display: flex;
  flex-direction: row;
  gap: 25px;
`;
const Logo = styled.img`
  height: 80px;
  border-radius: 10px;
  width: 80px;
  object-fit: cover;
`;
const MainText = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 10px;
`;
const ProjectName = styled.div`
  font-size: 21px;
  font-weight: 900;
`;
const Buttons = styled.div`
  display: flex;
  gap: 15px;
  align-items: center;
  flex-direction: row;
`;
const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 50px;
`;
const Tagline = styled.div``;
const Description = styled.div``;

const SlideshowImage = styled.img`
  border-radius: 10px;
`;

export default function RenderSaleable({ value, setValue }) {
  const { loggedInUserID } = useContext(Context);

  let content = getPublishedOrDraftContent(value);
  content = { ...content };

  if (!content.description) {
    content.description = "Description Missing";
  }

  console.log(value);
  return (
    <Container>
      <TopPart>
        <MainInfo>
          <MainText>
            <ProjectName>{content.title}</ProjectName>
            <Tagline>{content.tagline}</Tagline>
          </MainText>
        </MainInfo>

        <Buttons>
          {content.githubLink ? (
            <CustomButton
              height={"50px"}
              variant="outlined"
              icon={<AiOutlineGithub />}
              onClick={goTo(content.githubLink, { isAbsolute: true })}
            >
              Github
            </CustomButton>
          ) : null}
          {content.externalLink ? (
            <CustomButton
              height={"50px"}
              // icon={<AiOutlineGlobal />}
              onClick={goTo(content.externalLink, { isAbsolute: true })}
            >
              Buy - ${content.price1}
            </CustomButton>
          ) : null}
        </Buttons>
      </TopPart>

      <Content>
        <Description>{content.description}</Description>

        <ProjectSlideshow
          list={getSlideshowList(value, { showMainImage: true })}
        ></ProjectSlideshow>
      </Content>

      <br />
      <br />

      <ContentActionButtons content={value} setContent={setValue} />
    </Container>
  );
}
