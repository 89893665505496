export default function getOppositeMemberID(userIDs, loggedInUserID) {
  loggedInUserID = loggedInUserID.toString();

  for (let item of userIDs) {
    item = item.toString();
    if (item !== loggedInUserID) return item;
  }

  return null;
}
