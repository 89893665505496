import styled from "styled-components";
import { useContext } from "react";
import Context from "../../../../Context.js";
import goTo from "../../../../controllers/goTo.js";
import CustomButton from "../../../helperComponents/CustomButton.js";
import FollowButton from "../../../helperComponents/FollowButton.js";
import LoadingSection from "../../../helperComponents/LoadingSection.js";
import getProfileLink from "../../../../controllers/getProfileLink.js";
import getLocationString from "../../../../controllers/getLocationString.js";
import ProfilePicture from "../../../helperComponents/ProfilePicture.js";
import rearrangeContent from "../../../../controllers/profile/rearrangeContent.js";
import renderSocialLinks from "../../../../controllers/renderSocialLinks.js";
import ProfilePageDescription from "../../ProfilePageDescription.js";

const TopHeader = styled.div`
  display: flex;
  flex-direction: row;
  gap: 40px;
  width: 100vw;
  height: auto;
  z-index: 10;
  justify-content: center;
  padding: 40px 30px;
  color: var(--headerTextColor);
  padding-bottom: 0;
  margin-top: 30px;

  @media (max-width: 900px) {
    padding: 20px;
    padding-top: 64px;
    padding-bottom: 64px;
    height: auto;
    flex-direction: column;
    gap: 20px;
  }
`;

const Name = styled.div`
  /* font-family: "playfair-display", serif; */
  font-weight: 900;
  display: flex;
  font-size: 55px;
  gap: 10px;
  flex-direction: row;

  cursor: pointer;

  @media (max-width: 900px) {
    font-size: 23px;
  }
`;

const Description = styled.div`
  font-family: "Montserrat", sans-serif;
  font-size: 22px;
  opacity: 0.9;
  line-height: 35px;

  @media (max-width: 900px) {
    text-align: center;
    font-size: 18px;
    margin-top: 0;
    margin-bottom: 0;
    width: 80vw;
  }
`;

const RowOne = styled.div`
  display: flex;
  gap: 20px;
  align-items: center;
  gap: 30px;
  flex-direction: row;
`;

const RowTwo = styled.div`
  display: flex;
  width: 62vw;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  /* margin-top: 35px; */
  gap: 20px;
`;

let btnSize = "45px";

const Main = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0px;
  width: 62vw;
  padding-bottom: 30px;
  /* border-bottom: 1px solid var(--translucentHard); */
`;

const MainColumn = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 20px;
  gap: 30px;
`;

const HelloText = styled.div`
  display: flex;
  /* font-style: italic; */
  font-weight: 300;
  font-size: 23px;
  /* font-size: 25px; */
  /* flex-direction: ; */
`;

const MainText = styled.div`
  display: flex;
  flex-direction: column;
  font-family: "Noto Serif", serif;
  /* font-family: "playfair-display"; */

  /* font-family: "Cormorant Garamond", serif; */
  /* font-style: italic; */

  /* font-family: "playfair-display", serif; */

  /* font-family: "Inter", sans-serif; */

  font-family: "Montserrat", sans-serif;
  /* font-style: italic; */
  gap: 5px;
`;

const Bold = styled.b``;

export default function VerticalTwoHeader({ profileData }) {
  let { setForm, loggedInUser } = useContext(Context);

  let buttonHeight = "40px";

  let textButtonStyle = { height: buttonHeight };
  let followButtonStyle = textButtonStyle;

  let locationStr = getLocationString(profileData);

  let socialLinkStyle = {
    height: btnSize,
    backdropFilter: "unset",
    width: btnSize,
  };

  let socialLinks = renderSocialLinks(profileData.profileMetaData.socialLinks, {
    style: socialLinkStyle,
    variant: "outlined",
  });

  if (!profileData) return <LoadingSection />;

  let followBtn = (
    <FollowButton style={followButtonStyle} userID={profileData.user._id} />
  );

  let imageSize = "60px";

  return (
    <TopHeader>
      <Main>
        <RowOne>
          <ProfilePicture
            // style={{ borderRadius: "20px" }}
            size={imageSize}
            imageObj={profileData.profileMetaData.profileImage}
          />
          <MainText>
            {/* <HelloText>Hello! I'am</HelloText> */}
            <Name onClick={goTo(getProfileLink(profileData.user.username))}>
              {profileData.user.name}
            </Name>
          </MainText>
        </RowOne>
        <MainColumn>
          <ProfilePageDescription
            style={{ fontSize: "22px", lineHeight: "35px", width: "100%" }}
            profileData={profileData}
          />
          <RowTwo>
            {followBtn}
            {renderLinks()}
            {socialLinks}
          </RowTwo>
        </MainColumn>
      </Main>
    </TopHeader>
  );

  function renderLinks() {
    let toReturn = [];

    if (!profileData.LINK) return [];

    let rearrangedContent = rearrangeContent({
      profileMetaData: profileData.profileMetaData,
      list: profileData.LINK,
      type: "LINK",
    });

    for (let item of rearrangedContent) {
      toReturn.push(
        <CustomButton
          hoverVariant="SCALE_DOWN"
          circular={true}
          style={{
            color: "var(--headerTextColor)",
            padding: "0 30px",
            backdropFilter: "unset",
          }}
          height={btnSize}
          // width={linkButtonWidth}
          onClick={goTo(item.content.externalLink, { isAbsolute: true })}
        >
          {item.title}
        </CustomButton>
      );
    }

    return toReturn;
  }
}
