import CustomButton from "../../../helperComponents/CustomButton";

export default function CardButton({ content }) {
  //   return content.actionButtonName;
  if (!content.actionButtonName) return null;

  return (
    <CustomButton
      href={content.actionButtonLink}
      circular={true}
      style={{ marginTop: "10px" }}
    >
      {content.actionButtonName}
    </CustomButton>
  );
}
