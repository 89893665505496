import { styled } from "styled-components";
import { useContext } from "react";
import Context from "../../Context.js";
import PopupSelect from "./PopupSelect.js";

const Container = styled.div`
  display: flex;
  flex-direction: row;
  padding: 10px;
  width: 100%;
  height: 50px;
  background-color: var(--translucentInteraction);
  border: 1px solid var(--translucentHard);
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-radius: var(--borderRadius1);

  &:hover {
    background-color: var(--translucentHard);
  }
`;

const Label = styled.div`
  width: 100%;
  opacity: 0.5;
  text-transform: capitalize;
`;

const Left = styled.div`
  display: flex;
  gap: 5px;
  flex-direction: column;
`;

const SelectInputText = styled.div`
  font-size: var(--fontSize1);
`;
const SelectInputButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
`;

export default function ItemAdderByPopup(props) {
  const { setForm } = useContext(Context);
  let { value, options, onChange, placeholder, title } = props;

  return (
    <Container
      onClick={() => {
        setForm({
          title: placeholder,
          component: (
            <PopupSelect options={options} value={value} onChange={onChange} />
          ),
        });
      }}
    >
      {title ? title : "Add Item"}
    </Container>
  );
}
