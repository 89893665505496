import styled from "styled-components";
import getCardTagline from "../../controllers/cardViewControllers/getCardTagline";
import ContentCardBoilerplate from "../cardForProfile/ContentCardBoilerplate";
import getImageURL from "../../controllers/getImageURL";
import getPublishedOrDraftContent from "../../controllers/getPublishedOrDraftContent";

const Container = styled.div`
  width: 100%;
  /* border: 1px solid var(--translucentLight); */
  flex-direction: row;
  border-radius: 5px;
  display: flex;
  cursor: pointer;
  gap: 0;
  font-size: 21px;
  line-height: 30px;

  @media (max-width: 900px) {
    flex-direction: column;
  }
`;

const ContainerInterior = styled.div`
  min-height: 220px;
  max-height: 250px;
  overflow: hidden;
  padding: 25px;
  display: flex;
  flex-direction: column;
  gap: 15px;

  @media (max-width: 900px) {
    gap: 10px;
    font-size: 18px;
    padding: 15px;
  }
`;

const Branding = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 15px;
`;

const Logo = styled.img`
  border-radius: 5px;
  height: 30px;
  width: 30px;
  object-fit: cover;
`;

const Thumbnail = styled.img`
  border-radius: 0;
  height: auto;
  width: 250px;
  height: 250px;
  object-fit: cover;
  object-fit: cover;

  @media (max-width: 900px) {
    width: 100%;
  }
`;

const ProjectName = styled.div`
  font-size: 20px;
  display: flex;
  color: var(--color);
  font-weight: 700;
  justify-content: center;
  text-transform: capitalize;
  align-items: center;

  @media (max-width: 900px) {
    font-size: 18px;
  }
`;

const ProjectTagline = styled.div``;

const ProjectDescription = styled.div`
  opacity: 0.5;
`;

const MainContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  color: var(--color);
  font-size: 19px;

  @media (max-width: 900px) {
    gap: 0px;
    font-size: 15px;
  }
`;

export default function CardViewHCard({ item }) {
  let logoComp = null;

  let content = getPublishedOrDraftContent(item);

  if (content.image2) {
    logoComp = <Logo src={getImageURL(content.image2)} />;
  }

  if (content.hideLogo) {
    logoComp = null;
  }

  return (
    <ContentCardBoilerplate item={item}>
      <Container>
        <Thumbnail src={getImageURL(content.image)} />

        <ContainerInterior>
          <Branding>
            {logoComp}
            <ProjectName>{content.title}</ProjectName>
          </Branding>

          <MainContent>
            {getCardTagline({ item, Component: ProjectTagline })}
            <ProjectDescription>{content.description}</ProjectDescription>
          </MainContent>
        </ContainerInterior>
      </Container>
    </ContentCardBoilerplate>
  );
}
