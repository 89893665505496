import { styled } from "styled-components";
import { useContext, useState } from "react";
import { AiOutlineEdit, AiOutlineMessage } from "react-icons/ai";
import LikeDislikeButtons from "./LikeDislikeButtons";
import CommentsSection from "./CommentsSection";
import RenderContentTags from "./RenderContentTags";
import RenderContentUser from "./RenderContentUser";
import { HiOutlineDotsVertical } from "react-icons/hi";
import goTo from "../../controllers/goTo";
import CustomButton from "../helperComponents/CustomButton";
import { serverLine } from "../../controllers/serverLine";
import Context from "../../Context";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 50px;
`;

const Parent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 50px;
  width: 100%;
`;

const Buttons = styled.div`
  display: flex;
  flex-direction: row;
  /* gap: 50px; */
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 20px;
`;

const LeftButtons = styled.div`
  display: flex;
  gap: 10px;
  flex-direction: row;
  /* gap: 50px; */
  /* justify-content: space-between; */
`;

let btnSize = "50px";

export default function ContentActionButtons({ content, setContent }) {
  const { loggedInUserID, setForm } = useContext(Context);
  const [showComments, setShowComments] = useState(false);

  // return null;
  let moreMenuButton = (
    <CustomButton
      size={btnSize}
      variant="outlined"
      width={"30px"}
      onClick={openMenu}
      icon={<HiOutlineDotsVertical />}
    ></CustomButton>
  );

  if (content.type == "LIFE_STORY") {
    moreMenuButton = null;
  }

  let leftButtons = [
    moreMenuButton,
    <CustomButton
      size={btnSize}
      onClick={() => {
        setShowComments(!showComments);
      }}
      variant="outlined"
      icon={<AiOutlineMessage />}
    ></CustomButton>,
  ];

  if (loggedInUserID == content.authorUserID)
    leftButtons.push(
      <CustomButton
        size={btnSize}
        variant="outlined"
        onClick={edit}
        semanticHref={getEditPageLink()}
        icon={<AiOutlineEdit />}
      ></CustomButton>
    );

  return (
    <Parent>
      <RenderContentUser author={content.author} />
      <RenderContentTags content={content} />
      <Container>
        <Buttons>
          <LeftButtons>{leftButtons}</LeftButtons>

          <LikeDislikeButtons
            type="CONTENT"
            likeStatus={content.likeStatus}
            likeCount={content.likeCount}
            dislikeCount={content.dislikeCount}
            contentID={content._id}
          />
        </Buttons>

        {showComments ? <CommentsSection post={content} /> : null}
      </Container>
    </Parent>
  );

  function edit() {
    return goTo(getEditPageLink())();
  }

  function getEditPageLink() {
    if (content.type == "LIFE_STORY") return "/edit-profile/story";

    return "/edit-content/" + content._id;
  }

  async function deletePost() {
    if (content.type == "BOOK") {
      if (content.content)
        if (content.content.chapters) {
          if (content.content.chapters.length) {
            return window.doAlert(
              "Remove Chapters from this book before deleting it."
            );
          }
        }
    }

    let newPost = { ...content };
    newPost.loading = true;

    setContent(newPost);

    await serverLine.delete("/content/?contentID=" + content._id, {
      action: "ARCHIVE",
    });

    newPost = JSON.parse(JSON.stringify(newPost));

    newPost.loading = false;
    newPost.archived = true;

    setContent(newPost);

    goTo(-1)();
    //Remove the popup
  }

  function openMenu() {
    let options = [];

    if (loggedInUserID == content.authorUserID) {
      options.push({ name: "Delete", onClick: deletePost });
    } else {
      options.push({
        name: "Report",
        onClick: goTo("/report/?subjectType=CONTENT&subjectID=" + content._id),
      });
    }

    setForm({ options: options });
  }
}
