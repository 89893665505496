import styled from "styled-components";
import { useContext } from "react";
import Context from "../../../../Context.js";
import { useNavigate } from "react-router-dom";
import goTo from "../../../../controllers/goTo.js";
import CustomButton from "../../../helperComponents/CustomButton.js";
import { AiOutlineEdit, AiOutlinePlus, AiOutlineYoutube } from "react-icons/ai";
import FollowButton from "../../../helperComponents/FollowButton.js";
import LoadingSection from "../../../helperComponents/LoadingSection.js";
import getProfileLink from "../../../../controllers/getProfileLink.js";
import getLocationString from "../../../../controllers/getLocationString.js";
import ProfilePicture from "../../../helperComponents/ProfilePicture.js";
import rearrangeContent from "../../../../controllers/profile/rearrangeContent.js";
import SocialLinks from "../../SocialLinks.js";
import renderSocialLinks from "../../../../controllers/renderSocialLinks.js";
import ProfilePageDescription from "../../ProfilePageDescription.js";

const TopHeader = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;
  width: 100vw;
  height: auto;
  background-color: var(--bgColorDark);
  backdrop-filter: blur(20px);
  z-index: 10;
  align-items: center;
  padding: 50px 30px;
  padding-top: 64px;
  margin-bottom: 30px;
  color: var(--headerTextColor);
  border-bottom: 1px solid var(--translucent);

  @media (max-width: 900px) {
    padding: 20px;
    padding-top: 64px;
    padding-bottom: 64px;
    height: auto;
    flex-direction: column;
    gap: 20px;
  }
`;

const Name = styled.div`
  font-size: 30px;
  /* text-align: center; */
  line-height: 35px;
  font-weight: 800;
  display: flex;
  flex-direction: column;
  gap: 5px;
  font-family: "Raleway";

  cursor: pointer;

  @media (max-width: 900px) {
    font-size: 23px;
    flex-direction: row;
  }
`;

const Links = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 20px;
  gap: 20px;
  align-items: center;
  width: 90vw;
  flex-direction: wrap;
  justify-content: center;
  @media (max-width: 900px) {
    gap: 10px;
  }
`;

const Description = styled.div`
  font-size: 20px;
  opacity: 0.7;
  font-family: "Montserrat", sans-serif;
  line-height: 26px;
  width: 62vw;

  @media (max-width: 900px) {
    text-align: center;
    font-size: 18px;
    margin-top: 0;
    margin-bottom: 0;
    width: 80vw;
  }
`;

const RowDivider = styled.div`
  width: 2px;
  height: 75%;
  height: 60px;
  background-color: var(--translucentHard);

  @media (max-width: 900px) {
    display: none;
  }
`;

const RowOne = styled.div`
  display: flex;
  width: 62vw;
  gap: 20px;
  align-items: center;
  gap: 40px;
  flex-direction: row;
  justify-content: space-between;

  @media (max-width: 900px) {
    flex-direction: column;
  }
`;

const RowTwo = styled.div`
  display: flex;
  width: 62vw;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 25px;
`;

const FirstName = styled.div`
  /* font-weight: 900; */
`;

const LastName = styled.div``;

let btnSize = "45px";

const Bold = styled.b``;

export default function VerticalOneHeader({ profileData }) {
  let { setForm, loggedInUser } = useContext(Context);

  let buttonHeight = "40px";

  let textButtonStyle = { height: buttonHeight };
  let followButtonStyle = textButtonStyle;

  let socialLinkStyle = {
    height: btnSize,
    backdropFilter: "unset",
    width: btnSize,
  };

  let socialLinks = renderSocialLinks(profileData.profileMetaData.socialLinks, {
    style: socialLinkStyle,
    variant: "outlined",
  });

  if (!profileData) return <LoadingSection />;

  let followBtn = (
    <FollowButton style={followButtonStyle} userID={profileData.user._id} />
  );

  let firstName = "";
  let lastName = "";

  let theName = profileData.profileMetaData.name;
  theName = theName.split(" ");

  firstName = theName[0];

  theName.shift();

  lastName = theName.join(" ");

  return (
    <TopHeader>
      <RowOne>
        <ProfilePicture
          height={"90px"}
          width={"90px"}
          imageObj={profileData.profileMetaData.profileImage}
        />
        <RowDivider />
        <Name onClick={goTo(getProfileLink(profileData.user.username))}>
          <FirstName>{firstName}</FirstName>
          <LastName>{lastName}</LastName>
        </Name>
        <RowDivider />

        <ProfilePageDescription profileData={profileData} />
      </RowOne>
      <RowTwo>
        {followBtn}
        {renderLinks()}
        {socialLinks}
      </RowTwo>
    </TopHeader>
  );

  function renderLinks() {
    let toReturn = [];

    if (!profileData.LINK) return [];

    let rearrangedContent = rearrangeContent({
      profileMetaData: profileData.profileMetaData,
      list: profileData.LINK,
      type: "LINK",
    });

    for (let item of rearrangedContent) {
      toReturn.push(
        <CustomButton
          hoverVariant="SCALE_DOWN"
          circular={true}
          style={{
            color: "var(--headerTextColor)",
            padding: "0 30px",
            backdropFilter: "unset",
          }}
          height={btnSize}
          // width={linkButtonWidth}
          onClick={goTo(item.content.externalLink, { isAbsolute: true })}
        >
          {item.title}
        </CustomButton>
      );
    }

    return toReturn;
  }
}
