import CustomButton from "../../components/helperComponents/CustomButton";
import {
  AiOutlineDelete,
  AiOutlinePlus,
  AiOutlineSave,
  AiOutlineUp,
} from "react-icons/ai";
import { FiExternalLink, FiMinimize2 } from "react-icons/fi";
import CollapsedProfileItem from "../../components/editors/CollapsedItemEditor";
import styled from "styled-components";
import getImageURL from "../getImageURL";
import ShowSelectedImage from "../../components/helperComponents/ShowSelectedImage";
import RenderLiteratureCore from "../../components/editors/RenderLiteratureCore";
import { RiUpload2Line } from "react-icons/ri";

const ThumbnailEditor = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  /* justify-content: space-between; */
`;

const LiteratureDemo = styled.div`
  border-radius: 10px;
  overflow: hidden;
`;

const Logo = styled.img`
  height: 50px;
  width: 50px;
  border-radius: 100px;
  object-fit: cover;
`;

const ThumbnailImage = styled.img`
  height: auto;
  width: 100%;
  border-radius: 10px;
  object-fit: cover;
`;

const LogoEditor = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;
  /* justify-content: space-between; */
`;

export default function getItemEditorTopSection({
  type,
  state,
  selectAnImage,
  profileMetaData,
}) {
  if (
    type === "PROJECT_ART" ||
    type === "PROJECT_GRAPHIC_DESIGN" ||
    type === "PROJECT_ART" ||
    type === "VIDEO" ||
    type === "CERTIFICATE" ||
    type === "SALEABLE" ||
    type === "CARD"
  ) {
    let imageTitle = type == "VIDEO" ? "Video Thumbnail" : "Image";

    return (
      <ThumbnailEditor>
        {getImageObj({
          state,
          field: "image",
          viewStyle: "THUMBNAIL",
        })}
        <CustomButton
          variant="filled"
          onClick={selectAnImage("image")}
          icon={<RiUpload2Line />}
        >
          {imageTitle}
        </CustomButton>
      </ThumbnailEditor>
    );
  }

  if (type === "PROJECT_PROGRAMMING")
    return (
      <>
        <LogoEditor>
          {getImageObj({
            state,
            viewStyle: "LOGO",
            field: "image2",
          })}
          <CustomButton
            variant="outlined-filled"
            style={{ borderRadius: "10px" }}
            onClick={selectAnImage("image2")}
            icon={<RiUpload2Line />}
          >
            Logo
          </CustomButton>
        </LogoEditor>

        <ThumbnailEditor>
          {getImageObj({
            state,
            field: "image",
            viewStyle: "THUMBNAIL",
          })}
          <CustomButton
            variant="filled"
            onClick={selectAnImage("image")}
            icon={<RiUpload2Line />}
          >
            Thumbnail
          </CustomButton>
        </ThumbnailEditor>
      </>
    );

  if (type === "EDUCATION" || type === "EXPERIENCE")
    return (
      <LogoEditor>
        {getImageObj({ state, field: "image2", viewStyle: "THUMBNAIL" })}
        <CustomButton
          variant="outlined-filled"
          style={{ borderRadius: "10px" }}
          onClick={selectAnImage("image2")}
          icon={<RiUpload2Line />}
        >
          Logo
        </CustomButton>
      </LogoEditor>
    );

  if (type === "PROJECT_LITERATURE")
    return (
      <LiteratureDemo>
        <RenderLiteratureCore item={state} />
      </LiteratureDemo>
    );

  if (type == "ARTICLE") {
    if (profileMetaData.sectionVariation) {
      let articleVariation = profileMetaData.sectionVariation.ARTICLE;
      if (!articleVariation) return null;
      if (articleVariation !== "ONE") {
        return (
          <ThumbnailEditor>
            {getImageObj({
              state,
              field: "image",
              viewStyle: "THUMBNAIL",
            })}
            <CustomButton
              variant="filled"
              onClick={selectAnImage("image")}
              icon={<RiUpload2Line />}
            >
              Thumbnail
            </CustomButton>
          </ThumbnailEditor>
        );
      }
    }
  }
}

function getImageObj({ state, field = "image", viewStyle = "LOGO" }) {
  if (!state[field]) return null;

  let Comp = Logo;

  if (viewStyle == "THUMBNAIL") {
    Comp = ThumbnailImage;
  }

  let imageObj = null;

  if (state[field]) imageObj = <Comp src={getImageURL(state[field])} />;

  return imageObj;
}
