import { styled } from "styled-components";
import { serverLine } from "../../../controllers/serverLine";
import { useContext, useEffect, useState } from "react";
import WithHeader from "../WithHeader";
import CustomToggle from "../../helperComponents/CustomToggle";
import getSubPath from "../../../controllers/getSubPath";
import FeedItem from "../../cardForFeed/FeedItem";
import contentTypeVsData from "../../../data/contentTypeVsData";
import contentOrderOnSearch from "../../../data/ContentOrderOnSearch";
import TrendingTags from "../../feed/TrendingTags";
import LoadingSection from "../../helperComponents/LoadingSection";
import Context from "../../../Context";

const Container = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  height: 100%;
  overflow-y: scroll;

  width: 100%;
  padding-top: 150px;

  gap: 70px;
`;

const Main = styled.div`
  width: 38vw;

  display: flex;

  flex-direction: column;

  gap: 70px;
`;

const List = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;

  gap: 20px;
`;

const Heading = styled.h1`
  margin: 0;
  padding: 0;
  text-transform: capitalize;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  gap: 50px;
  justify-content: space-between;
`;

const Col = styled.div`
  display: flex;
  flex-direction: column;
  gap: 50px;
`;

export default function TagContentFeed() {
  const { nonPopupRoute } = useContext(Context);
  const [type, setType] = useState("ALL");
  const [list, setList] = useState(null);

  let tag = getSubPath(1, nonPopupRoute);

  useEffect(() => {
    if (tag) {
      setList(null);
      serverLine
        .get(`/feed/?filterType=TAGS&tagName=${tag}&type=${type}`)
        .then(setList);
    }
  }, [tag, type]);

  let options = getContentTypes();

  let content = null;

  if (!list) {
    content = <LoadingSection />;
  } else {
    content = (
      <Content>
        <Col>
          <Heading>{tag}</Heading>
          <CustomToggle
            variant={"fluid"}
            onChange={setType}
            value={type}
            options={options}
          />
        </Col>

        <List>
          {list.map((item) => (
            <FeedItem item={item} />
          ))}
        </List>
      </Content>
    );
  }

  return (
    <WithHeader>
      <Container>
        <Main>
          <TrendingTags />

          {content}
        </Main>

        <br />
        <br />
        <br />
      </Container>
    </WithHeader>
  );

  function getContentTypes() {
    let toReturn = [{ value: "ALL", label: "All" }];

    for (let item of contentOrderOnSearch) {
      let data = contentTypeVsData[item];
      toReturn.push({ value: item, label: data.name });
    }
    return toReturn;
  }
}
