import { styled } from "styled-components";

const Iframe = styled.iframe`
  border: none;
  border-radius: 10px;
  min-width: ${({ width }) => {
    return `
      ${width}px
    `;
  }};
`;

let protocol = "https://";

export default function VideoEmbed({ theVideoUrl, height }) {
  if (!theVideoUrl) return null;

  if (theVideoUrl.indexOf("watch?v=") !== -1) {
    theVideoUrl = theVideoUrl.replace("/watch?v=", "/embed/");
  }

  if (
    theVideoUrl.indexOf("vimeo.com") !== -1 &&
    theVideoUrl.indexOf("player.vimeo.com") === -1
  ) {
    theVideoUrl = theVideoUrl.replace("vimeo.com", "player.vimeo.com/video");
  }

  theVideoUrl = theVideoUrl.replace("http://", "https://");

  if (theVideoUrl.substr(0, protocol.length) !== "https://") {
    theVideoUrl = protocol + theVideoUrl;
  }

  let theWidth = height * 1.78;
  let actualHeight = height;

  if (window.innerWidth < 900) {
    theWidth = window.innerWidth * 0.9;
    actualHeight = theWidth * 0.56;
  }

  return (
    <Iframe
      width={theWidth}
      height={actualHeight}
      src={theVideoUrl}
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen
      title="Embedded youtube"
    />
  );
}
