import { styled } from "styled-components";
import { serverLine } from "../../../controllers/serverLine";
import { useContext, useEffect, useState } from "react";
import ReportPostBox from "../../report/ReportPostBox";
import LoadingSection from "../../helperComponents/LoadingSection";
import WithHeader from "../WithHeader";

const Container = styled.div`
  width: 62vw;
  margin-top: 100px;
  display: flex;
  flex-direction: column;
  gap: 50px;

  @media (max-width: 900px) {
    width: 100%;
  }
`;

const Title = styled.h1``;
const Section = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const TableHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  padding-bottom: 20px;
  border-bottom: 1px solid var(--translucentHard);
`;
const SubHeader = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;
`;

const MainTableHeading = styled.div`
  width: 200px;
  opacity: 0.5;
  font-size: 18px;
`;

const ColHeading = styled.div`
  width: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0.5;
  font-size: 18px;
`;

const List = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

export default function ReportDashboard() {
  const [posts, setPosts] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    serverLine.get("/report-dashboard").then((newData) => {
      setPosts(newData);
      setLoading(false);
    });
  }, []);

  if (loading) return <LoadingSection />;

  return (
    <WithHeader>
      <Container>
        <Title>Report Dashboard</Title>

        <Section>
          <TableHeader>
            <MainTableHeading>Title</MainTableHeading>
            <SubHeader>
              <ColHeading>New Reports</ColHeading>
              <ColHeading>Old Reports</ColHeading>
            </SubHeader>
          </TableHeader>
          <List>{renderContent()}</List>
        </Section>
      </Container>
    </WithHeader>
  );

  function renderContent() {
    let toReturn = [];

    for (let item of posts) {
      toReturn.push(<ReportPostBox key={item._id} item={item} />);
    }

    return toReturn;
  }
}
