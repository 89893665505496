import { useEffect, useReducer, useState } from "react";
import reducer from "../../../reducer.js";
import Context from "../../../Context.js";
import DynamicForm from "../../helperComponents/dynamicForm/DynamicForm.js";

import getUserID from "../../../controllers/getUserID.js";
import { RiGoogleFill } from "react-icons/ri/index.js";
import goToAuthScreen from "../../../controllers/goToAuthScreen.js";

import { useNavigate } from "react-router-dom";

import { serverLine } from "../../../controllers/serverLine.js";
import logout from "../../../controllers/logout.js";

import isLoggedIn from "../../../controllers/isLoggedIn.js";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AskLifeStory from "../../onboarding/AskLifeStory.js";

import AskCertificates from "../../onboarding/AskCertificates.js";

import AskBasicInfoFromClient from "../../onboarding/AskBasicInfoFromClient.js";
import goTo from "../../../controllers/goTo.js";

import getPopupType from "../../../controllers/getPopupType.js";
import updateRouteState from "../../../controllers/updateRouteState.js";

import WithBackground from "./WithBackground.js";
import AskClassification from "../../onboarding/AskClassification.js";
import YouAreBanned from "../../report/YouAreBanned.js";
import LoadingSection from "../../helperComponents/LoadingSection.js";
import getHostName from "../../../controllers/getHostName.js";
import AskUsername from "../../onboarding/AskUsername.js";

window.nonReactLikeStatus = {};

function Boilerplate({ children }) {
  const [loggedInUserID, setLoggedInUserID] = useState(null);
  const [formData, setFormData] = useState(false);
  const [globalLikeStatus, setGlobalLikeStatus] = useState({});
  const [profileMetaData, setProfileMetaData] = useState();
  const [notificationData, setNotificationData] = useState({
    general: 0,
    work: 0,
  });
  const [profilePageData, setProfilePageData] = useState(null);
  const [popupFeedItem, setPopupFeedItem] = useState(null);
  const navigate = useNavigate();
  const alert = (message) => {
    toast(message);
  };

  const [colorMode, setColorMode] = useState("DARK");
  const [loggedInUser, setLoggedInUser] = useState(null);
  const [state, dispatch] = useReducer(reducer, {});

  let currentPath = window.location.pathname + window.location.search;

  const [currentRoute, setCurrentRoute] = useState(currentPath);

  let initialNonPopupRoute = currentPath;

  const [nonPopupRoute, setNonPopupRoute] = useState(initialNonPopupRoute);

  window.setNonPopupRoute = setNonPopupRoute;

  window.setCurrentRoute = setCurrentRoute;

  window.setForm = setForm;
  window.navigate = navigate;
  window.doAlert = alert;
  window.loggedInUserID = loggedInUserID;
  window.loggedInUser = loggedInUser;
  window.homeLink = window.location.protocol + "//" + getHostName();

  window.updateLoggedInUser = updateLoggedInUser;

  useState(() => {
    let theFunc = (theEvent) => {
      let newURl = window.location.pathname + window.location.search;
      updateRouteState(newURl);
    };

    window.addEventListener("popstate", theFunc);

    return () => {
      window.removeEventListener("popstate", theFunc);
    };
  }, []);

  useEffect(() => {
    if (loggedInUserID) {
      loadProfileMetaData();
      updateNotificationData();
      if (window.notifInterval) {
        window.clearInterval(window.notifInterval);
      }
      window.notifInterval = window.setInterval(() => {
        updateNotificationData();
      }, 30 * 1000);
    }
  }, [loggedInUserID]);

  useEffect(() => {
    let userID = getUserID();

    console.log(userID);

    if (userID) {
      setLoggedInUserID(userID);
      updateLoggedInUser();
    } else {
      setLoggedInUserID(false);
    }
  }, []);

  useEffect(() => {
    removeFormQuery();
    backFeatures();

    let data = {};
    dispatch({ type: "NEW_STATE", value: data });

    let newStatus = localStorage.getItem("inwire-color-mode");
    if (!newStatus) newStatus = "DARK";
    updateDarkMode(newStatus);
  }, []);

  // console.log(loggedInUser);

  if (isLoggedIn()) {
    if (!loggedInUser) {
      children = <LoadingSection />;
    } else {
      if (loggedInUser.banned) children = <YouAreBanned />;

      if (!loggedInUser.onboarding) {
        children = <AskUsername />;
      } else {
        if (!loggedInUser.onboarding.usernameSelected)
          children = <AskUsername />;
      }
    }
  }

  return (
    <Context.Provider
      value={{
        profilePageData,
        setProfilePageData,
        profileMetaData,
        setProfileMetaData,
        globalLikeStatus,
        updateGlobalLikeStatus,
        nonPopupRoute,
        setNonPopupRoute,
        currentRoute,
        setLoggedInUser,
        promptLogin,
        loggedInUserID,
        popupFeedItem,
        setPopupFeedItem,
        notificationData,
        setNotificationData,
        updateNotificationData,
        state,
        popupAlert: alert,
        updateLoggedInUser: updateLoggedInUser,
        loggedInUser: loggedInUser,
        dispatch,
        updateDarkMode,
        colorMode,
        setForm: setForm,
      }}
    >
      <DynamicForm setForm={setForm} data={formData} />

      <WithBackground> {children}</WithBackground>

      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={true}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />
    </Context.Provider>
  );

  function updateNotificationData() {
    console.log(loggedInUser);
    serverLine.get("/notification-count").then(setNotificationData);
  }

  async function loadProfileMetaData() {
    let newMetaData = await serverLine.get("/my-profile-meta");

    setProfileMetaData(newMetaData.draftState ? newMetaData.draftState : {});
  }

  function updateGlobalLikeStatus(items) {
    let oldStatus = { ...window.nonReactLikeStatus };

    for (let item of items) {
      oldStatus[item._id] = {
        likeStatus: item.likeStatus,
        likeCount: item.likeCount,
        dislikeCount: item.dislikeCount,
      };
    }

    window.nonReactLikeStatus = oldStatus;
    setGlobalLikeStatus(oldStatus);
    //This makes sure that between frequent react updates value is not lost
  }

  function updateDarkMode(newStatus) {
    setColorMode(newStatus);
    localStorage.setItem("inwire-color-mode", newStatus);
    setColors(newStatus);
  }

  function updateLoggedInUser() {
    try {
      serverLine.get("/logged-in-user").then(setLoggedInUser);
    } catch (e) {
      alert.show(e.message);
      console.log("First Fetch Error----------");
      logout();
    }
  }

  function setColors(newVal) {
    let colors = {
      bgColor: "#ffffff",
      bgColorDark: "#e9e9e9",
      bgColor2: "#ffffff",
      color: "#111111",

      logoFilter: "invert(1)",
      translucentInteraction: "rgba(0,0,0,0.05)",
      translucentLight: "rgba(0,0,0,0.1)",
      translucent: "rgba(0,0,0,0.3)",
      accentColor: "#4caf50",
      translucentMedium: "rgba(0,0,0,0.1)",
      translucentHard: "rgba(0,0,0,0.2)",
    };

    if (newVal) {
      if (newVal === "DARK")
        colors = {
          bgColor: "#111111",
          logoFilter: "invert(0)",
          bgColorDark: "#000000",
          bgColorLight: "#222222",
          bgColor2: "#000000",
          color: "#ffffff",
          accentColor: "#4caf50",
          translucentLight: "#222",
          translucentInteraction: "rgba(255,255,255,0.05)",
          translucent: "rgb(212 226 227 / 10%)",
          translucentMedium: "rgba(255,255,255,0.1)",
          translucentHard: "rgba(255,255,255,0.2)",
        };
    }

    document.documentElement.style.setProperty(
      "--logoFilter",
      colors.translucentInteraction
    );

    document.documentElement.style.setProperty(
      "--translucentInteraction",
      colors.translucentInteraction
    );

    document.documentElement.style.setProperty(
      "--translucentMedium",
      colors.translucentMedium
    );

    document.documentElement.style.setProperty(
      "--accentColor",
      colors.accentColor
    );

    document.documentElement.style.setProperty(
      "--bgColorDark",
      colors.bgColorDark
    );

    document.documentElement.style.setProperty(
      "--translucentLight",
      colors.translucentLight
    );

    document.documentElement.style.setProperty("--bgColor", colors.bgColor);
    document.documentElement.style.setProperty(
      "--bgColorLight",
      colors.bgColorLight
    );
    document.documentElement.style.setProperty("--bgColor2", colors.bgColor2);
    document.documentElement.style.setProperty("--color", colors.color);
    document.documentElement.style.setProperty(
      "--translucent",
      colors.translucent
    );
    document.documentElement.style.setProperty(
      "--translucentHard",
      colors.translucentHard
    );
  }

  function promptLogin() {
    setFormData({
      title: "Please Login",
      buttons: [
        {
          icon: <RiGoogleFill />,
          name: "Login / Signup",
          onClick: goToAuthScreen,
        },
      ],
    });
  }

  function backFeatures() {
    window.addEventListener("popstate", (event) => {
      removeFormOnBack();
    });
  }

  function removeFormOnBack() {
    const formPage = getUrlQuery("formPage");
    if (!formPage) {
      setFormData(null);
    }
  }

  function getUrlQuery(field) {
    if (typeof window == "undefined") return null;
    const queryString = window.location.search;
    const urlParamsForm = new URLSearchParams(queryString);
    return urlParamsForm.get(field);
  }

  function setForm(data, noPathChange) {
    if (noPathChange) {
      return setFormData({ data, noPathChange });
    }

    let path = window.location.pathname;
    let queryObj = getUrlQueryObject();

    if (getUrlQuery("formPage") && !data) {
      closeForm();
    } else if (!getUrlQuery("formPage")) {
      queryObj.formPage = "true";

      let thePath = removeLastSlash(path) + queryObjToUrl(queryObj);

      goTo(thePath)();

      setFormData({ data, noPathChange });
    } else {
      setFormData({ data, noPathChange });
    }
  }

  function removeLastSlash(urlString) {
    if (urlString[urlString.length - 1] == "/") {
      return urlString.slice(0, urlString.length - 1);
    } else {
      return urlString;
    }
  }

  function queryObjToUrl(queryInput) {
    let newQueryUrl = "/?";
    let i = 0;
    let queryLen = Object.keys(queryInput).length;
    for (let key in queryInput) {
      if (queryInput[key]) {
        if (i == queryLen - 1) {
          newQueryUrl += `${key}=${queryInput[key]}`;
        } else {
          newQueryUrl += `${key}=${queryInput[key]}&`;
          i++;
        }
      }
    }

    return newQueryUrl;
  }

  function getUrlQueryObject() {
    let data = {};
    let raw = window.location.search.replace(/\?/gi, "").replace(/\//gi, "");
    if (!raw) return data;
    raw = raw.split("&");
    for (let itm of raw) {
      if (!itm) continue;
      itm = itm.split("=");
      if (itm.length == 2) {
        if (itm[1].trim()) {
          data[itm[0]] = itm[1];
        }
      }
    }
    return data;
  }

  function removeFormQuery() {
    //this prevents bug
    //what bug?
    //if url already has form query then
    //on setFormData query won't be pushed

    if (getUrlQuery("formPage")) {
      let path = window.location.pathname;
      let queryObj = getUrlQueryObject();
      delete queryObj.formPage;
      let newPath = "/";

      if (path === "/") {
        newPath = queryObjToUrl(queryObj);
      } else {
        newPath = path + queryObjToUrl(queryObj);
      }
      console.log(newPath);
      goTo(newPath, { isReplace: true })();
    }
  }

  function closeForm() {
    //sometimes it takes a few seconds to push the router
    //if user presses back before then user might go 2 pages back
    if (getUrlQuery("formPage")) {
      goTo(-1)();
    }
  }
}

export default Boilerplate;
