import styled from "styled-components";
import CustomToggle from "../../components/helperComponents/CustomToggle";
import MaterialInput from "../../components/helperComponents/MaterialInput";
import { CustomLexicalEditor } from "../../components/richTextEditor/CustomLexicalEditor";
import DragAndDropItems from "../../components/editors/DragAndDropItems";
import PageItemsArranger from "../../components/PageItemsArranger";
import ListVariationSelector from "../../components/editors/ListVariationSelector";
import CustomListEditor from "../../components/editors/customList/CustomListEditor";

const RichTextContainer = styled.div`
  display: flex;
  background: var(--translucent);
  border-radius: 10px;
  padding: 20px;
`;

function setHasEnded(state, setState) {
  return (newValue) => {
    let newState = { ...state };
    newState.draftState["hasEnded"] = newValue;
    setState(newState);
  };
}

const Spacing = styled.div`
  width: 100%;
  height: 40px;
`;

let customToggleStyle1 = {
  marginTop: "40px",
  flexDirection: "column",
  width: "100%",
  alignItems: "flex-start",
  gap: "15px",
};

export default function getContentItemInputs({
  type,
  state,
  makeChange,
  setState,
  isNew,
}) {
  state = { ...state };

  if (!state.draftState) state.draftState = {};

  let customToggleStyle = {
    padding: "10px",
    color: "#999",
    paddingLeft: "18px",
    border: "1px solid var(--translucentHard)",
    borderRadius: "var(--borderRadius1)",
  };

  let input1 = { label: "Institute Name", id: "name" };

  if (type === "EXPERIENCE") input1 = { label: "Company Name", id: "name" };

  if (type === "LIST") {
    return (
      <>
        <ListVariationSelector
          value={state.draftState.variation}
          onChange={makeChange("variation", {
            isNotEvent: true,
            autoSave: true,
          })}
        />
        <MaterialInput
          value={state.draftState.title}
          onChange={makeChange("title")}
          placeholder=""
          label={"Title"}
        />

        {isNew ? null : (
          <CustomListEditor
            variation={state.draftState.variation}
            contentOrder={state.draftState.contentOrder}
            updateContentOrder={(newData) => {
              makeChange("contentOrder", { isNotEvent: true })(newData);
            }}
          />
        )}
      </>
    );
  }

  let homePageSectionType = [
    { label: "Default", value: "DEFAULT" },
    { label: "Manual", value: "MANUAL" },
  ];

  let cardStyles = [
    { label: "Center", value: "ONE" },
    { label: "Left", value: "TWO" },
    { label: "Right", value: "THREE" },
  ];

  let pageDragAndDropItems = (
    <PageItemsArranger
      data={state.draftState.sections ? state.draftState.sections : []}
      makeChange={makeChange}
    />
  );

  if (isNew) pageDragAndDropItems = null;

  if (state.draftState.isHome) {
    if (state.draftState.sectionArrangementType == "DEFAULT")
      pageDragAndDropItems = <Spacing />;
  }

  if (type === "PAGE") {
    return (
      <>
        <MaterialInput
          value={state.draftState.title}
          onChange={makeChange("title")}
          placeholder=""
          label={"Title"}
        />
        {!state.draftState.isHome ? (
          <MaterialInput
            value={state.draftState.pageID}
            onChange={makeChange("pageID")}
            placeholder="Page ID (Without Space)"
            label="pageID"
          />
        ) : null}

        {state.draftState.isHome ? (
          <CustomToggle
            style={customToggleStyle1}
            value={state.draftState.sectionArrangementType}
            options={homePageSectionType}
            onChange={makeChange("sectionArrangementType", {
              isNotEvent: true,
            })}
            placeholder="Section Arrangement"
            name="Section Arrangement"
          />
        ) : null}

        {pageDragAndDropItems}
      </>
    );
  }

  if (type === "CERTIFICATE") {
    return (
      <>
        <MaterialInput
          value={state.title}
          onChange={makeChange("title")}
          placeholder=""
          label={"Certificate Or Award Title"}
        />
        <MaterialInput
          value={state.draftState.date}
          type="date"
          onChange={makeChange("date")}
          placeholder=""
          label="date"
        />
        <MaterialInput
          multiline
          maxRows={6}
          value={state.draftState.description}
          onChange={makeChange("description")}
          placeholder=""
          label="Description"
        />
      </>
    );
  }

  if (type === "EXPERIENCE" || type === "EDUCATION") {
    return (
      <>
        <MaterialInput
          value={state.title}
          onChange={makeChange("title")}
          placeholder=""
          label={input1.label}
          variant="filled"
        />
        <MaterialInput
          value={state.draftState.from}
          type="date"
          // onlyYear={true}
          onChange={makeChange("from")}
          placeholder=""
          label="From"
          variant="filled"
        />
        <CustomToggle
          style={customToggleStyle}
          name={"Has Ended?"}
          value={state.draftState.hasEnded}
          onChange={setHasEnded(state, setState)}
        />

        {state.draftState.hasEnded ? (
          <MaterialInput
            value={state.draftState.to}
            type="date"
            // onlyYear={true}
            onChange={makeChange("to")}
            placeholder=""
            label="To"
            variant="filled"
          />
        ) : (
          false
        )}

        {type == "EXPERIENCE" ? (
          <MaterialInput
            value={state.draftState.role}
            onChange={makeChange("role")}
            placeholder=""
            label="Role"
            variant="filled"
          />
        ) : null}

        {type == "EDUCATION" ? (
          <MaterialInput
            value={state.draftState.course}
            onChange={makeChange("course")}
            placeholder=""
            label="Course"
            variant="filled"
          />
        ) : null}

        <MaterialInput
          multiline
          maxRows={6}
          value={state.draftState.description}
          onChange={makeChange("description")}
          placeholder=""
          label="Description"
          variant="filled"
        />
      </>
    );
  }

  if (type === "LINK") {
    return (
      <>
        <MaterialInput
          value={state.draftState.title}
          onChange={makeChange("title")}
          placeholder="Button Name"
          label={"Button Name"}
          variant="filled"
        />

        <MaterialInput
          multiline
          maxRows={6}
          value={state.draftState.externalLink}
          onChange={makeChange("externalLink")}
          placeholder=""
          label="External Link"
          variant="filled"
        />
      </>
    );
  }

  if (type === "CARD") {
    return (
      <>
        <CustomToggle
          style={customToggleStyle}
          name={"Hide Thumbnail"}
          value={state.draftState.hideThumbnail}
          onChange={makeChange("hideThumbnail", { isNotEvent: true })}
        />
        <MaterialInput
          value={state.draftState.title}
          onChange={makeChange("title")}
          placeholder=""
          label={"Title"}
          variant="filled"
          multiline
          maxRows={6}
        />
        <MaterialInput
          multiline
          maxRows={6}
          value={state.draftState.description}
          onChange={makeChange("description")}
          placeholder=""
          label="Description"
          variant="filled"
        />
        <MaterialInput
          multiline
          maxRows={6}
          value={state.draftState.actionButtonName}
          onChange={makeChange("actionButtonName")}
          placeholder=""
          label="Button Name (Optional) "
          variant="filled"
        />

        <MaterialInput
          multiline
          maxRows={6}
          value={state.draftState.actionButtonLink}
          onChange={makeChange("actionButtonLink")}
          placeholder=""
          label="Button Link (Optional)"
          variant="filled"
        />

        <CustomToggle
          style={{
            ...customToggleStyle1,
            marginTop: "20px",
            marginBottom: "20px",
          }}
          value={
            state.draftState.variation ? state.draftState.variation : "ONE"
          }
          options={cardStyles}
          onChange={makeChange("variation", {
            isNotEvent: true,
          })}
          placeholder="Variation"
          name="Variation"
        />
      </>
    );
  }

  if (type === "ARTICLE") {
    return (
      <>
        <MaterialInput
          value={state.draftState.title}
          onChange={makeChange("title")}
          placeholder=""
          label={"Title"}
          variant="filled"
        />

        <MaterialInput
          multiline
          maxRows={6}
          value={state.draftState.description}
          onChange={makeChange("description")}
          placeholder=""
          label="Description"
          variant="filled"
        />

        <MaterialInput
          multiline
          maxRows={6}
          value={state.draftState.externalLink}
          onChange={makeChange("externalLink")}
          placeholder=""
          label="External Link "
          variant="filled"
        />
      </>
    );
  }

  if (type === "SALEABLE") {
    return (
      <>
        <MaterialInput
          value={state.draftState.title}
          onChange={makeChange("title")}
          placeholder=""
          label={"Title"}
          variant="filled"
        />
        <MaterialInput
          value={state.draftState.tagline}
          onChange={makeChange("tagline")}
          placeholder=""
          label={"Short Description"}
          variant="filled"
        />
        <MaterialInput
          type="number"
          value={state.draftState.price1}
          onChange={makeChange("price1")}
          placeholder=""
          label={"Price"}
          variant="filled"
        />
        <MaterialInput
          multiline
          maxRows={6}
          value={state.draftState.description}
          onChange={makeChange("description")}
          placeholder=""
          label="Long Description"
          variant="filled"
        />
        <MaterialInput
          multiline
          maxRows={6}
          value={state.draftState.externalLink}
          onChange={makeChange("externalLink")}
          placeholder=""
          label="External Link (Optional)"
          variant="filled"
        />
        <MaterialInput
          multiline
          maxRows={6}
          value={state.draftState.videoLink}
          onChange={makeChange("videoLink")}
          placeholder=""
          label="Video Link (Optional)"
          variant="filled"
        />
      </>
    );
  }

  if (type === "SERVICE") {
    return (
      <>
        <MaterialInput
          value={state.draftState.title}
          onChange={makeChange("title")}
          placeholder=""
          label={"Title"}
          variant="filled"
        />
        <MaterialInput
          type="number"
          value={state.draftState.price1}
          onChange={makeChange("price1")}
          placeholder=""
          label={"Min Price"}
          variant="filled"
        />
        <MaterialInput
          type="number"
          value={state.draftState.price2}
          onChange={makeChange("price2")}
          placeholder=""
          label={"Max Price"}
          variant="filled"
        />
        <MaterialInput
          multiline
          maxRows={6}
          value={state.draftState.description}
          onChange={makeChange("description")}
          placeholder=""
          label="Description"
          variant="filled"
        />
        <MaterialInput
          multiline
          maxRows={6}
          value={state.draftState.externalLink}
          onChange={makeChange("externalLink")}
          placeholder=""
          label="External Link (Optional)"
          variant="filled"
        />
        <MaterialInput
          multiline
          maxRows={6}
          value={state.draftState.videoLink}
          onChange={makeChange("videoLink")}
          placeholder=""
          label="Video Link (Optional)"
          variant="filled"
        />
      </>
    );
  }

  if (type === "PROJECT_PROGRAMMING") {
    return (
      <>
        <MaterialInput
          value={state.draftState.title}
          onChange={makeChange("title")}
          placeholder=""
          label={"Title"}
          variant="filled"
        />
        <MaterialInput
          multiline
          maxRows={6}
          value={state.draftState.tagline}
          onChange={makeChange("tagline")}
          placeholder=""
          label="Tagline"
          variant="filled"
        />
        <MaterialInput
          multiline
          maxRows={6}
          value={state.draftState.description}
          onChange={makeChange("description")}
          placeholder=""
          label="Description"
          variant="filled"
        />

        <MaterialInput
          multiline
          maxRows={6}
          value={state.draftState.externalLink}
          onChange={makeChange("externalLink")}
          placeholder=""
          label="Live Link"
          variant="filled"
        />
        <MaterialInput
          multiline
          maxRows={6}
          value={state.draftState.githubLink}
          onChange={makeChange("githubLink")}
          placeholder=""
          label="Github Link (Optional)"
          variant="filled"
        />
        <MaterialInput
          multiline
          maxRows={6}
          value={state.draftState.videoLink}
          onChange={makeChange("videoLink")}
          placeholder=""
          label="Demo Video Link (Optional)"
          variant="filled"
        />

        <CustomToggle
          style={customToggleStyle}
          name={"Hide Logo"}
          value={state.draftState.hideLogo}
          onChange={makeChange("hideLogo", { isNotEvent: true })}
        />

        <CustomToggle
          style={customToggleStyle}
          name={"Hide Thumbnail"}
          value={state.draftState.hideThumbnail}
          onChange={makeChange("hideThumbnail", { isNotEvent: true })}
        />
      </>
    );
  }

  if (
    type === "PROJECT_ART" ||
    type === "PROJECT_GRAPHIC_DESIGN" ||
    type === "PROJECT_PHOTOGRAPHY"
  ) {
    return (
      <>
        <MaterialInput
          value={state.draftState.title}
          onChange={makeChange("title")}
          placeholder=""
          label={"Title"}
          variant="filled"
        />

        <MaterialInput
          multiline
          maxRows={6}
          value={state.draftState.description}
          onChange={makeChange("description")}
          placeholder=""
          label="Description"
          variant="filled"
        />

        {type !== "PROJECT_PHOTOGRAPHY" ? (
          <MaterialInput
            multiline
            maxRows={6}
            value={state.draftState.videoLink}
            onChange={makeChange("videoLink")}
            placeholder=""
            label="Video Link (Optional)"
            variant="filled"
          />
        ) : null}
      </>
    );
  }

  let literatureThemeOptions = [
    { label: "Paper", value: "PAPER" },
    { label: "Paint", value: "PAINT" },
    { label: "Black", value: "BLACK" },
    { label: "Sky", value: "SKY" },
  ];

  let literatureFontOptions = [
    { label: "Hand", value: "HAND" },
    { label: "Mono", value: "MONO" },
    { label: "Sans", value: "SANS" },
    { label: "Serif", value: "SERIF" },
  ];

  let themeStyle = state.draftState.themeStyle;
  if (!themeStyle) themeStyle = literatureThemeOptions[0].value;

  let fontStyle = state.draftState.fontStyle;
  if (!fontStyle) fontStyle = literatureFontOptions[0].value;

  let literatureTheme = (
    <CustomToggle
      options={literatureThemeOptions}
      value={themeStyle}
      onChange={makeChange("themeStyle", { isNotEvent: true })}
    />
  );

  let literatureFont = (
    <CustomToggle
      options={literatureFontOptions}
      value={fontStyle}
      onChange={makeChange("fontStyle", { isNotEvent: true })}
    />
  );

  if (type === "PROJECT_LITERATURE") {
    return (
      <>
        <RichTextContainer>
          <CustomLexicalEditor
            addButtonPosX={"calc(31vw - 50px)"}
            initialEditorState={state.draftState.richText}
            onEditorChange={makeChange("richText", { isNotEvent: true })}
          />
        </RichTextContainer>

        {literatureTheme}

        {literatureFont}
      </>
    );
  }

  if (type === "VIDEO") {
    return (
      <>
        <MaterialInput
          value={state.draftState.title}
          onChange={makeChange("title")}
          placeholder=""
          label={"Title"}
          variant="filled"
        />

        <MaterialInput
          multiline
          maxRows={6}
          value={state.draftState.description}
          onChange={makeChange("description")}
          placeholder=""
          label="Description"
          variant="filled"
        />

        <MaterialInput
          multiline
          maxRows={6}
          value={state.draftState.videoLink}
          onChange={makeChange("videoLink")}
          placeholder=""
          label="Video Link"
          variant="filled"
        />
      </>
    );
  }

  if (type === "PROJECT_CONTENT_WRITING") {
    return (
      <>
        <MaterialInput
          value={state.draftState.title}
          onChange={makeChange("title")}
          placeholder=""
          label={"Title"}
          variant="filled"
        />

        <MaterialInput
          multiline
          maxRows={6}
          value={state.draftState.description}
          onChange={makeChange("description")}
          placeholder=""
          label="Description"
          variant="filled"
        />

        <MaterialInput
          multiline
          maxRows={6}
          value={state.draftState.externalLink}
          onChange={makeChange("externalLink")}
          placeholder=""
          label="Link"
          variant="filled"
        />

        <MaterialInput
          multiline
          maxRows={6}
          value={state.draftState.videoLink}
          onChange={makeChange("videoLink")}
          placeholder=""
          label="Video Link (Optional)"
          variant="filled"
        />
      </>
    );
  }
}
