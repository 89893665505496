import { styled } from "styled-components";
import CustomToggle from "../helperComponents/CustomToggle";
import sectionAndVariations from "../../data/sectionAndVariations";
import { useContext } from "react";
import Context from "../../Context";
import sendPostMessage from "../../controllers/sendPostMessage";
import { serverLine } from "../../controllers/serverLine";
import SectionAndLabel from "../SectionAndLabel";
import contentTypeVsData from "../../data/contentTypeVsData";
import sectionVariationOptions from "../../data/sectionVariationOptions";

const Container = styled.div`
  margin-bottom: 30px;
`;

export default function ListVariationSelector({ value, onChange }) {
  return (
    <Container>
      <SectionAndLabel label={"Styling"}>
        <CustomToggle
          value={value ? value : "ONE"}
          options={sectionVariationOptions}
          onChange={onChange}
        />
      </SectionAndLabel>{" "}
    </Container>
  );
}
