import styled from "styled-components";
import { useContext, useState } from "react";
import LoadingSection from "../../helperComponents/LoadingSection.js";
import CustomButton from "../../helperComponents/CustomButton.js";

import ProfileSectionBlank from "../ProfileSectionBlank.js";
import RenderProfileCards from "../../cardViews/RenderProfileCards.js";
import getPublishedOrDraftContent from "../../../controllers/getPublishedOrDraftContent.js";

const ItemItem = styled.div`
  display: flex;
  flex-direction: row;
  gap: 5px;
  font-size: 21px;
  padding: 25px 0;
  border-bottom: 1px solid var(--translucentHard);
  font-family: "Inter", sans-serif;
  cursor: pointer;

  &:hover {
    color: var(--accentColor);
    border-bottom: 1px solid var(--accentColor);
  }
`;

export default function RenderList({ profileData, listID }) {
  let item = getItem();

  if (!item) return `list item not found. ${listID} `;

  let content = getPublishedOrDraftContent(item);

  let loadedList = getItems();

  if (!loadedList.length) return `List has 0 content ${listID} `;
  let defaultView = null;
  if (!loadedList) return defaultView;
  if (!loadedList.length) return defaultView;

  let variation = content.variation;

  if (!variation) variation = "ONE";

  return (
    <RenderProfileCards
      items={loadedList}
      variation={variation}
      label={item.title}
    />
  );

  function getItems() {
    let allListItems = profileData["LIST_ITEM"];

    if (!allListItems) return [];

    let theList = [];
    let allListItemMap = {};

    for (let item of allListItems) {
      allListItemMap[item._id] = item;
    }

    for (let id of content.contentOrder) {
      if (allListItemMap[id]) theList.push(allListItemMap[id]);
    }

    return theList;
  }

  function getItem() {
    for (let item of profileData["LIST"]) {
      if (item._id == listID) return item;
    }

    return null;
  }
}
