let contentOrderOnSearch = [
  "ARTICLE",
  "BOOK",
  "PROJECT_PROGRAMMING",
  "PROJECT_ART",
  "PROJECT_GRAPHIC_DESIGN",
  "PROJECT_PHOTOGRAPHY",
  "PROJECT_VIDEO",
  "PROJECT_VIDEO_TUTORIAL",
  // "PROJECT_VIDEO_EDITING",
  // "PROJECT_FILMMAKING",
  "PROJECT_LITERATURE",
  "CERTIFICATE",
  "LIFE_STORY",
  "POST",
  "SOCIAL",
];

export default contentOrderOnSearch;
