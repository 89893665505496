import { styled } from "styled-components";
import { useContext, useState } from "react";

import { serverLine } from "../../controllers/serverLine.js";
import Context from "../../Context.js";
import { AiOutlineClose, AiOutlinePlus } from "react-icons/ai/index.js";
import MaterialInput from "../helperComponents/MaterialInput.js";
import onChangeStop from "../../controllers/onChangeStop.js";
import CustomButton from "../helperComponents/CustomButton.js";
import capitalizeFirstLetter from "../../controllers/capitalizeFirstLetter.js";
import LoadingSection from "../helperComponents/LoadingSection.js";

const Container = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 25px;
  /* padding: 20px; */
`;

const Title = styled.div`
  font-size: 18px;
  font-weight: 500;
  opacity: 0.5;
  text-transform: capitalize;
`;
const SelectedTags = styled.div`
  display: flex;
  flex-direction: row;
  row-gap: 25px;
  flex-wrap: wrap;
  gap: 10px;
`;
const SelectedTagsLabel = styled.div``;

const SearchResult = styled.div`
  display: flex;
  flex-direction: row;
  row-gap: 25px;
  flex-wrap: wrap;
  gap: 10px;
`;

let programmingTags = [
  "javascript",
  "flutter",
  "kotlin",
  "react-native",
  "react.js",
  "typescript",
  "webpack",
  "node.js",
  "mongoDB",
  "firebase",
  "mySQL",
  "postgreSQL",
  "python",
  "tensorflow",
  "pytorch",
  "flask",
  "django",
  "c#",
  "unity",
  "AWS",
  "EC2",
  "GCP",
  "Azure",
  "S3",
  "blender",
];

let artTags = [
  "afterAffects",
  "figma",
  "illustrator",
  "photoshop",
  "blender",
  "maya",
  "3dsMax",
  "cinema4D",
];

let literatureTags = [
  "quote",
  "humour",
  "poem",
  "short-story",
  "screenwriting",
  "song-writing",
  "journalism",
];

const SubHeading = styled.div`
  font-size: 18px;
  opacity: 0.7;
  margin-top: -10px;
`;

let commonBtnStyle = {
  fontSize: "15px",
  padding: "9px 16px",
  borderRadius: "var(--borderRadius2)",
  flexDirection: "row-reversed",
};

export default function TagsEditor({
  title,
  subHeading,
  value,
  onChange,
  classification,
  disableTagLimit,
}) {
  const { loggedInUser } = useContext(Context);
  const [searchQuery, setSearchQuery] = useState("");
  const [searchRes, setSearchRes] = useState(null);
  const [searchIsLoading, setSearchIsLoading] = useState(false);
  const [seeMore, setSeeMore] = useState(false);

  if (!loggedInUser) return null;

  let tagOptions = [];

  if (!classification) return null;

  // return JSON.stringify(classification);

  if (classification.includes("programming")) {
    tagOptions = [...tagOptions, ...programmingTags];
  }

  if (classification.includes("art")) {
    tagOptions = [...tagOptions, ...artTags];
  }

  if (classification.includes("literature")) {
    tagOptions = [...tagOptions, ...literatureTags];
  }

  let seeMoreButton = (
    <CustomButton
      underlined={true}
      variant="minimal"
      onClick={() => {
        setSeeMore(true);
      }}
    >
      See More
    </CustomButton>
  );

  if (seeMore) seeMoreButton = null;

  let selectedTags = renderSelectedTags();
  let tagsSuggestion = renderSearchRes();

  if (tagsSuggestion.length < 10) seeMoreButton = null;

  if (!seeMore && tagsSuggestion.length > 10) {
    tagsSuggestion = tagsSuggestion.slice(0, 10);
  }

  return (
    <Container>
      {title ? <Title>{title}</Title> : null}
      {subHeading ? <SubHeading>{subHeading}</SubHeading> : null}

      {selectedTags.length ? <SelectedTags>{selectedTags}</SelectedTags> : null}

      <MaterialInput
        label="Search Tags"
        value={searchQuery}
        onChange={updateSearchQuery}
      />

      <SearchResult>
        {tagsSuggestion} {searchIsLoading ? null : seeMoreButton}
      </SearchResult>
    </Container>
  );

  function renderSearchRes() {
    let toReturn = [];

    if (searchIsLoading) return <LoadingSection />;

    let theList = searchRes;

    if (!searchQuery || !searchRes)
      theList = tagOptions.map((item) => {
        return {
          tagName: item,
        };
      });

    let theManulButton = (
      <CustomButton
        key={searchQuery}
        variant="outlined"
        onClick={addTag(searchQuery)}
        style={commonBtnStyle}
        icon={<AiOutlinePlus />}
      >
        {capitalizeFirstLetter(searchQuery)}
      </CustomButton>
    );

    if (searchRes) {
      if (searchRes.length)
        if (searchQuery) {
          return [theManulButton];
        }
    }

    let searchQueryExists = false;

    for (let item of theList) {
      if (searchQuery) {
        if (item.tagName.toLowerCase() === searchQuery) {
          searchQueryExists = true;
        }
      }

      toReturn.push(
        <CustomButton
          fontSize={"15px"}
          key={item.tagName}
          disabled={shouldDisableButton(item.tagName)}
          variant="outlined"
          onClick={addTag(item.tagName)}
          style={commonBtnStyle}
          icon={<AiOutlinePlus />}
        >
          {capitalizeFirstLetter(item.tagName)}
        </CustomButton>
      );
    }

    if (!searchQueryExists && searchQuery) {
      toReturn.push(theManulButton);
    }

    return toReturn;
  }

  function shouldDisableButton(theTagName) {
    if (!value) return null;

    if (value.includes(theTagName)) return true;
    return false;
  }

  function updateSearchQuery(e) {
    setSearchQuery(e.target.value);

    onChangeStop(title, async () => {
      setSearchIsLoading(true);
      let data = await serverLine.get("/tags/?query=" + e.target.value);
      setSearchRes(data);
      setSearchIsLoading(false);
    });
  }

  function renderSelectedTags() {
    let toReturn = [];

    if (!value) return [];

    for (let item of value) {
      toReturn.push(
        <CustomButton
          onClick={removeTag(item)}
          style={commonBtnStyle}
          icon={<AiOutlineClose />}
        >
          {capitalizeFirstLetter(item)}
        </CustomButton>
      );
    }

    return toReturn;
  }

  function addTag(tagName) {
    return () => {
      let newTags = [];

      if (value) newTags = [...value];

      if (!disableTagLimit) {
        if (newTags.length >= 5)
          return window.doAlert("Only 5 tags are allowed");
      }

      if (newTags.includes(tagName))
        return window.doAlert("Tag already exists!!");

      newTags.push(tagName);
      onChange(newTags);
      serverLine.post("/tag", { tagName: tagName });
    };
  }

  function removeTag(tagName) {
    return () => {
      let newTags = [];

      if (value) newTags = [...value];

      let theIndex = newTags.indexOf(tagName);

      newTags.splice(theIndex, 1);

      onChange(newTags);
    };
  }
}
