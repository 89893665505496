import { useContext, useEffect, useState } from "react";

import ContentPage from "./routes/ContentPage.js";

import FeedPage from "./routes/FeedPage.js";
import SendAuthCode from "./routes/SendAuthCode.js";
import Drafts from "./routes/Drafts.js";
import ChatPage from "./routes/ChatPage.js";
import RenderLifeStory from "./routes/RenderLifeStory.js";
import TagContentFeed from "./routes/TagContentFeed.js";
import Notifications from "./routes/Notifications.js";
import CreateContent from "./routes/CreateContent.js";

import MoreMenu from "./routes/MoreMenu.js";
import CheckStripePaymentSuccess from "./routes/CheckStripePaymentSuccess.js";
import Context from "../../Context.js";
import getPopupType from "../../controllers/getPopupType.js";

import styled from "styled-components";
import StatPage from "./routes/StatPage.js";
import ContentStatPage from "./routes/ContentStatPage.js";
import ReportContent from "./routes/ReportContent.js";
import ReportDashboard from "./routes/ReportDashboard.js";
import ReportsPage from "./routes/ReportsPage.js";
import AskClassificationPage from "./routes/AskClassificationPage.js";
import Search from "./routes/Search.js";
import PopupFeedItem from "../feed/PopupFeedItem.js";
import ProfilePage from "../profilePage/ProfilePage.js";
import getSubPath from "../../controllers/getSubPath.js";

import ChangeUsername from "./routes/ChangeUsername.js";
import PageNotFound from "../helperComponents/PageNotFound.js";
import WebsiteEditor from "./routes/WebsiteEditor.js";

const Container = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`;

let pathVsComp = {
  "/": <WebsiteEditor />,
  "/edit": <WebsiteEditor />,
  "/change-username": <ChangeUsername />,
  "/change-classification": <AskClassificationPage />,
  "/stat": <StatPage />,
  "/content-stat": <ContentStatPage />,
  "/content": <ContentPage />,
  "/feed": <FeedPage />,
  "/stripe-payment-success": <CheckStripePaymentSuccess />,
  "/more-menu": <MoreMenu />,
  "/tag-feed": <TagContentFeed />,
  "/life-story": <RenderLifeStory />,
  "/chat": <ChatPage />,
  "/reports": <ReportsPage />,
  "/report-dashboard": <ReportDashboard />,
  "/report": <ReportContent />,
  "/notifications": <Notifications />,
  "/create-content": <CreateContent />,
  "/drafts": <Drafts />,
  "/auth-redirect": <SendAuthCode />,
  "/search": <Search />,
};

function getUsernameFromPath() {
  let hostname = window.location.hostname;
  hostname = hostname.split(".");
  if (hostname[0] !== "wwww") return hostname[0];
}

export default function CustomRouter() {
  const { currentRoute, nonPopupRoute } = useContext(Context);

  let popupComp = null;
  let baseComp = findCompOfPath(currentRoute);

  let popupType = getPopupType(currentRoute);

  if (popupType && nonPopupRoute) {
    if (currentRoute !== nonPopupRoute) {
      baseComp = findCompOfPath(nonPopupRoute);
      popupComp = getPopupComp(currentRoute);
    }
  }

  // console.log("nonPopupRoute", nonPopupRoute, currentRoute, popupType);

  let userPageParam = getUsernameFromPath();

  let containerStyle = null;

  if (popupComp) {
    containerStyle = { display: "none" };
  }

  let profileComp = <ProfilePage username={userPageParam} />;

  let compToRender = baseComp;

  let subdomain = getSubdomain();

  if (subdomain && nonPopupRoute == "/") {
    compToRender = profileComp;
  }

  if (subdomain && !baseComp) {
    compToRender = profileComp;
  }

  if (!baseComp) compToRender = <PageNotFound />;

  return (
    <>
      <Container style={containerStyle}>{compToRender}</Container>
      {popupComp}
    </>
  );

  function findCompOfPath(pathToScan) {
    // console.log("Scanning Path", pathToScan);

    pathToScan = pathToScan.split("?")[0];
    if (pathToScan == "/") return pathVsComp["/"];

    for (let aPath in pathVsComp) {
      if (aPath == "/") continue;
      let theComp = pathVsComp[aPath];
      let matchLength = aPath.length;
      let extracted = pathToScan.slice(0, matchLength);
      // console.log(extracted, aPath, extracted === aPath);
      if (extracted === aPath) return theComp;
    }

    return null;
  }

  function isNotForm(thePath) {
    return thePath.indexOf("formPage=true") === -1;
  }

  function getPopupComp(pathName) {
    let popupType = getPopupType(pathName);
    if (popupType) {
      return <PopupFeedItem type={popupType} />;
    } else {
      return false;
    }
  }
}

function getSubdomain() {
  let hostnames = window.location.hostname;

  hostnames = hostnames.split(".");

  if (hostnames.length < 3) return null;

  if (hostnames[0] === "www") return null;

  return hostnames[0];
}
