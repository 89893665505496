import { styled } from "styled-components";
import OnboardingProgress from "./OnboardingProgress";
import BrandContainer from "../brand/BrandContainer";
import BasicInfoEditor from "../editors/BasicInfoEditor";
import { useContext, useState } from "react";
import Context from "../../Context";
import OnboardingBoilerplate from "./OnboardingBoilerplate";
import MaterialInput from "../helperComponents/MaterialInput";
import CustomButton from "../helperComponents/CustomButton";
import { AiOutlineCheck } from "react-icons/ai";
import LoadingSection from "../helperComponents/LoadingSection";
import { serverLine } from "../../controllers/serverLine";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 50px 0;
  gap: 25px;
  height: 100vh;
  overflow-y: scroll;
  align-items: center;
`;

const Main = styled.div`
  width: 38vw;
  display: flex; 
  flex-direction: column;
  flex: 1;
  align-items: center;
  /* justify-content: center; */
  padding-top: 100px;
  gap: 25px;

  @media (max-width: 900px) {
    width: 90vw;
  }
`;

const Heading = styled.h2`
  margin: 0;
  text-align: center;
  font-size: 18px;
  padding: 0;
  font-weight: 100;
  margin: 20px 0;
`;

const InputSection = styled.div`
  display: flex;
  font-size: 40px;
  flex-direction: row;
`;

const Input = styled.input`
  background-color: transparent;
  color: var(--color);
  border: none;
  font-size: 40px;
  border-bottom: 1px solid var(--color);
`;

const InputText = styled.div`
  opacity: 0.5;
`;

export default function UsernameEditor({ title }) {
  const { loggedInUser, setLoggedInUser, popupAlert } = useContext(Context);
  const [loading, setLoading] = useState(false);
  const [username, setUsername] = useState(loggedInUser.username);

  if (loading)
    return (
      <Container>
        <LoadingSection />
      </Container>
    );

  return (
    <Main>
      <Heading>{title}</Heading>
      <InputSection>
        <Input
          value={username}
          onChange={onChange}
          placeholder="Your Username"
        />
        <InputText>.inwire.app</InputText>
      </InputSection>
      <br />
      <br />
      <CustomButton
      icon={<AiOutlineCheck />}
        // width="100%"
        onClick={save}
        variant={"outlined"}
      >
        Save
      </CustomButton>
    </Main>
  );

  function onChange(e) {
    setUsername(e.target.value);
  }

  async function save() {
    if (!username) return popupAlert("Tagline is missing");

    if (!isAlphaNumeric(username)) {
      return popupAlert("The username can only contain alphabets and numbers");
    }

    let toSend = {
      changes: {},
    };

    toSend.changes.onboarding = "usernameSelected";

    toSend.changes.username = username;

    setLoading(true);

    try {
      await serverLine.patch("/profile", toSend);

      let newLoggedInUser = { ...loggedInUser, username };

      if (newLoggedInUser.onboarding) {
        newLoggedInUser.onboarding.usernameSelected = true;
      } else {
        newLoggedInUser.onboarding = { usernameSelected: true };
      }

      console.log("newLoggedInUser", newLoggedInUser);
      setLoggedInUser(newLoggedInUser);

      setLoading(false);

      popupAlert("Saved!");
    } catch (e) {
      setLoading(false);
      popupAlert(e.message);
    }
  }
}

function isAlphaNumeric(str) {
  return /^[a-zA-Z0-9]+$/.test(str);
}
