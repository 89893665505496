import styled from "styled-components";

const ListItemEditorContainer = styled.div`
  /* border: 1px solid var(--translucentHard); */
  /* background-color: var(--bgColor); */
  border-radius: 5px;
  /* padding: 20px; */
  display: flex;
  flex-direction: column;
  gap: 25px;
  width: 100%;
  overflow-y: auto;
  /* position: relative; */

  @media (max-width: 900px) {
    width: 90vw;
  }
`;

export default ListItemEditorContainer;
