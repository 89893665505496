import styled from "styled-components";
import goToAuthScreen from "../../controllers/goToAuthScreen.js";
import { HiMenuAlt4 } from "react-icons/hi/index.js";
import { GrGoogle } from "react-icons/gr/index.js";
import { useContext } from "react";
import Context from "../../Context.js";
import { FaGooglePlay } from "react-icons/fa/index.js";
import LogoSVG from "../brand/LogoSVG.js";
import BrandContainer from "../brand/BrandContainer.js";
import CustomButton from "../helperComponents/CustomButton.js";
const ParentContainer = styled.div`
  display: flex;
  width: 100vw;
  height: 100vh;
  height: 100dvh;
  overflow: hidden;
  overflow-y: scroll;
  background-size: cover;
  font-family: "Raleway";
  flex-direction: column;
  gap: 0px;
  align-items: flex-start;
  padding: 0;
  @media (max-width: 700px) {
    background-image: unset;
    align-items: center;
    overflow-y: auto;
    flex-direction: column;
  }
`;
const TheImage = styled.img`
  width: auto;
  border: 0.5px solid rgba(255, 255, 255, 0.3);
  height: 500px;
  object-fit: cover;
  border-radius: 2.5px;
  overflow: hidden;
`;
const TopPart = styled.div`
  display: flex;
  flex-direction: column;
  padding: 100px;
  padding-bottom: 130px;
  background-color: var(--bgColorDark);
  align-items: flex-start;
  gap: 100px;
  width: 100vw;
`;

const BottomPart = styled.div`
  display: flex;
  flex-direction: row;
  padding: 100px;
  padding-top: 50px;
  /* background-color: var(--bgColorDark); */
  align-items: flex-start;
  gap: 50px;
`;

const Tagline = styled.div`
  font-weight: 900;
  font-size: 25px;
  /* background: linear-gradient(to bottom, var(--color), var(--color), #000); */
  /* -webkit-text-fill-color: transparent; */
  /* -webkit-background-clip: text; */
  width: 50vw;
  @media (max-width: 900px) {
    width: 100%;
    font-size: 35px;
  }
`;
const MainButtons = styled.div`
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  gap: 15px;
  height: 60px;
  align-items: center;
  border: none;
  width: 100%;
  @media (max-width: 700px) {
    width: 100%;
    height: auto;
    margin-bottom: 20px;
    justify-content: space-between;
    /* justify-content: center; */
    /* flex-direction: column; */
  }
`;
const Button = styled.button`
  width: auto;
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 100%;
  justify-content: center;
  font-size: 15px;
  font-weight: 500;
  cursor: pointer;
  border-radius: 15px;
  border: 1px solid;
  gap: 25px;
  background-color: var(--color);
  cursor: pointer;
  transition: 0.25s ease-in-out;
  color: var(--bgColor);
  &:hover {
    transform: scale(0.9);
  }
  @media (min-width: 950px) {
    width: 300px;
  }
`;
const BigButton = styled(Button)`
  width: auto;
  padding: 0 50px;
  color: #55789e;
  font-weight: 600;
  flex: unset;
  border: none;
  height: 60px;
  box-shadow: 1px 1px 11px 0px #00000042;
  @media (max-width: 700px) {
    width: calc(100vw - 50px);
  }
`;
const DownloadAppButton = styled(Button)`
  @media (min-width: 950px) {
    display: none;
  }
`;
const H1 = styled.h1`
  font-weight: 900;
  margin-top: 0;
  font-size: 24px;
  width: auto;
  width: 100%;
  margin: 0;
  @media (min-width: 920px) {
    font-size: 24px;
  }
`;
const Medium = styled.div`
  font-size: 21px;
  opacity: 0.8;
  font-weight: 400;
  width: 50vw;
  opacity: 0.5;
  line-height: 30px;

  @media (max-width: 900px) {
    width: 100%;
    font-size: 18px;
    line-height: 28px;
  }
`;
const Words = styled.div`
  margin-top: 0;
  display: flex;
  margin-top: 0;
  gap: 120px;
  flex-direction: column;
  @media (max-width: 700px) {
    gap: 50px;
  }
`;
const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 19px;
`;
//A devlog/studylog/artlog Platform
const Icon = styled.img`
  height: 18px;
`;
export default function LoggedOutHomePage() {
  let { setForm } = useContext(Context);
  return (
    <ParentContainer>
      <TopPart>
        <BrandContainer />
        <TextContainer>
          <Tagline>Imagine Linktree But For Developers & Artists</Tagline>
          <Medium>
            Get a beautiful portfolio and link to all of your content across all
            your social networks increasing reach. Grab your yourname.inwire.app
            domain now.
          </Medium>
          <MainButtons>
            <CustomButton
              style={{ color: "#344a61" }}
              variant="standard"
              height={"60px"}
              width={"300px"}
              icon={<GrGoogle />}
              onClick={goToAuthScreen}
            >
              Login With Google
            </CustomButton>
            <CustomButton
              size="60px"
              onClick={openMoreMenu}
              icon={<HiMenuAlt4 />}
            ></CustomButton>
          </MainButtons>
        </TextContainer>
      </TopPart>

      <BottomPart>
        <TheImage src="demo-desktop.jpg" />
        <TheImage src="demo-mobile.jpg" />
      </BottomPart>
    </ParentContainer>
  );
  function open(link) {
    return () => {
      return (window.location = link);
    };
  }
  function openMoreMenu() {
    setForm({
      options: [
        {
          name: "About us",
          onClick: open("https://arnav.upon.one"),
        },
      ],
    });
  }
}
