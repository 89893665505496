import styled from "styled-components";

import getCardTagline from "../../controllers/cardViewControllers/getCardTagline";
import getImageURL from "../../controllers/getImageURL";
import ContentCardBoilerplate from "../cardForProfile/ContentCardBoilerplate";
import getPublishedOrDraftContent from "../../controllers/getPublishedOrDraftContent";

const Container = styled.div`
  //To make the description end before the border
  width: 98%;
  color: var(--color);
  overflow: hidden;
  flex-direction: row;
  border-radius: 5px;
  display: flex;
  cursor: pointer;
  gap: 15px;
  font-size: 21px;
  padding: 15px 20px;
  line-height: 30px;

  @media (max-width: 900px) {
    flex-direction: column;
  }
`;

const ContainerInterior = styled.div`
  min-height: 220px;
  padding: 25px;
  display: flex;
  flex-direction: column;
  gap: 15px;

  @media (max-width: 900px) {
    gap: 10px;
    font-size: 18px;
    padding: 15px;
  }
`;

const Branding = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 15px;
`;

const Logo = styled.img`
  border-radius: 5px;
  height: 30px;
  width: 30px;
  object-fit: cover;
`;

const Thumbnail = styled.img`
  border-radius: 0;
  height: auto;
  width: 170px;
  height: 170px;
  object-fit: cover;
  object-fit: cover;
`;

const ProjectName = styled.div`
  font-size: 20px;
  display: flex;
  color: var(--color);
  font-weight: 700;
  justify-content: center;
  text-transform: capitalize;
  align-items: center;
  white-space: nowrap;

  @media (max-width: 900px) {
    font-size: 18px;
  }
`;

const ProjectTagline = styled.div`
  border-left: 1px solid var(--translucentHard);
  padding-left: 15px;
  opacity: 0.7;
  white-space: nowrap;

  @media (max-width: 900px) {
    border-left: none;
    padding-left: 0;
  }
`;

export default function CardViewList({ item }) {
  let logoComp = null;

  let content = getPublishedOrDraftContent(item);

  item = { ...item };

  if (content.image2) {
    logoComp = <Logo src={getImageURL(content.image2)} />;
  }

  if (content.hideLogo) {
    logoComp = null;
  }

  if (!content.description) content.description = "Description goes here";

  return (
    <ContentCardBoilerplate item={item}>
      <Container>
        <Branding>
          {logoComp}
          <ProjectName>{content.title}</ProjectName>
        </Branding>

        {item.type == "PROJECT_PROGRAMMING" ? (
          getCardTagline({ item, Component: ProjectTagline })
        ) : (
          <ProjectTagline> {content.description}</ProjectTagline>
        )}
      </Container>
    </ContentCardBoilerplate>
  );
}
