import { styled } from "styled-components";
import ContentActionButtons from "../ContentActionButtons";
import getImageURL from "../../../controllers/getImageURL";
import getDateString from "../../../controllers/getDateString";
import getPublishedOrDraftContent from "../../../controllers/getPublishedOrDraftContent";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 50px;
  width: 38vw;

  @media (max-width: 900px) {
    width: 90vw;
  }
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const Title = styled.h2`
  margin: 0;
`;
const Description = styled.div``;

const DateString = styled.div`
  margin: 0;
  font-size: 21px;
`;

const Logo = styled.img`
  height: 30px;
  width: 30px;
`;

const Branding = styled.div``;

const Course = styled.div`
  margin: 0;
  font-size: 21px;
`;

const Main = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  gap: 20px;
  padding: 20px;
  background-color: var(--translucent);
`;

export default function RenderExperience({ value, setValue }) {
  let desc = null;

  let content = getPublishedOrDraftContent(value);
  content = { ...content };

  if (content.description) {
    desc = <Description>{content.description}</Description>;
  }

  return (
    <Container>
      <Main>
        <Branding>
          {content.image ? <Logo src={getImageURL(content.image)} /> : null}
          <Title>{content.title}</Title>
        </Branding>

        <TextContainer>
          <Course>{content.role}</Course>

          <DateString>
            {getDateString(content.from)} -{" "}
            {content.to ? getDateString(content.to) : "On Going"}
          </DateString>
          {desc}
        </TextContainer>
      </Main>

      <ContentActionButtons content={value} setContent={setValue} />
    </Container>
  );
}
