import styled from "styled-components";
import goTo from "../../controllers/goTo";
import CustomButton from "../helperComponents/CustomButton";

const Button = styled.div`
  display: flex;
  flex-direction: row;
  padding: 15px 25px;
  gap: 15px;
  border-radius: 25px;
  cursor: pointer;
  border: 1px solid var(--translucentHard);
  align-items: center;
`;
const ButtonIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 22px;
`;

const RoundButton = styled(Button)`
  padding: 0;
  border: none;
`;
const ButtonText = styled.div``;
const HeroButtons = styled.div`
  display: flex;
  flex-direction: row;
  gap: 25px;
  align-items: center;
`;

const IconButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 23px;
  height: 40px;
  width: 40px;
  border-radius: 5px;
  cursor: pointer;

  &:hover {
    background-color: var(--translucentHard);
  }
`;

export default function SocialLink({
  baseLink,
  icon,
  value,
  type,
  style,
  variant = "minimal",
  hoverVariant = "SCALE_DOWN",
}) {
  if (!value) return null;

  let linksThatStartWithAt = ["YOUTUBE", "UNSPLASH", "TIKTOK"];

  if (linksThatStartWithAt.includes(type)) {
    if (value[0] !== "@") {
      value = "@" + value;
    }
  }

  if (type == "UPWORK") {
    if (value[0] !== "~") {
      value = "~" + value;
    }
  }

  let iconBtnStyle = { fontSize: "27px", color: "var(--headerTextColor)" };

  if (style) iconBtnStyle = { ...iconBtnStyle, ...style };

  return (
    <CustomButton
      hoverVariant={hoverVariant}
      style={iconBtnStyle}
      onClick={goTo(`${baseLink}/${value}`, {
        isAbsolute: true,
      })}
      circular
      size="50px"
      icon={icon}
      variant={variant}
    />
  );
}
