import { useContext } from "react";
import { styled } from "styled-components";
import ContentActionButtons from "../ContentActionButtons";
import VideoEmbed from "../../misc/VideoEmbed";
import Context from "../../../Context";

const Container = styled.div`
  width: 62vw;
  display: flex;
  font-size: 21px;
  flex-direction: column;
  gap: 50px;

  @media (max-width: 900px) {
    width: 90vw;
    font-size: 18px;
    gap: 30px;
  }
`;

const TopPart = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;
const MainInfo = styled.div`
  display: flex;
  flex-direction: row;
  gap: 25px;
`;

const ProjectName = styled.div`
  font-size: 21px;
  font-weight: 900;
`;
const Buttons = styled.div`
  display: flex;
  gap: 15px;
  align-items: center;
  flex-direction: row;
`;
const Content = styled.div`
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  gap: 50px;

  @media (max-width: 900px) {
    gap: 30px;
  }
`;
const Tagline = styled.div``;
const Description = styled.div`
  width: 100%;
  opacity: 0.7;
`;

const SlideshowImage = styled.img`
  border-radius: 10px;
`;

export default function RenderVideo({ value, setValue }) {
  const { loggedInUserID } = useContext(Context);

  if (!value.content) {
    value.content = {};
  }

  if (!value.content.description) {
    value.content.description = "Project Description Missing";
  }

  if (!value.content.tagline) {
    value.content.tagline = "Project Tagline Missing";
  }

  console.log(value);
  return (
    <Container>
      <TopPart>
        <MainInfo>
          <ProjectName>{value.title}</ProjectName>
        </MainInfo>
      </TopPart>

      <Content>
        <VideoEmbed theVideoUrl={value.content.videoLink} height={400} />
        <Description>{value.content.description}</Description>
      </Content>

      <ContentActionButtons content={value} setContent={setValue} />
    </Container>
  );
}
