import {
  LuLayout,
  LuPopsicle,
  LuSchool,
  LuScroll,
  LuUser2,
} from "react-icons/lu";
import BasicInfoEditor from "../components/editors/BasicInfoEditor";
import LifeStoryEditor from "../components/editors/LifeStoryEditor";
import SocialLinksEditor from "../components/editors/SocialLinksEditor";
import { TbBriefcase2, TbLego, TbVectorTriangle } from "react-icons/tb";
import { RiShapesLine, RiTreeLine } from "react-icons/ri";
import {
  PiBuildings,
  PiCertificate,
  PiLinkSimple,
  PiPaintBrush,
} from "react-icons/pi";
import { GrArticle } from "react-icons/gr";
import { SlLink } from "react-icons/sl";
import {
  MdOutlineLightbulb,
  MdOutlineLocalGroceryStore,
  MdOutlinePhotoCamera,
} from "react-icons/md";
import { BsPaintBucket, BsVectorPen } from "react-icons/bs";
import { MdOutlineSchool } from "react-icons/md";
import LinksEditor from "../components/editors/LinksEditor";
import { FiNavigation } from "react-icons/fi";
import { AiOutlineOrderedList, AiOutlineVideoCamera } from "react-icons/ai";
import ThemeSelector from "../components/editors/ThemeSelector";
import { RxSection } from "react-icons/rx";
import { LiaUserFriendsSolid } from "react-icons/lia";

let generalSection = {
  name: "General",
  items: [
    {
      value: "basic",
      label: "Profile",
      component: <BasicInfoEditor />,
      icon: <LuUser2 />,
    },
    {
      value: "theme",
      label: "Theming",
      component: <ThemeSelector />,
      icon: <LuLayout />,
    },
    {
      value: "pages",
      label: "Pages",
      contentListType: "PAGE",
      icon: <FiNavigation />,
    },
  ],
};

let links = {
  name: "Links",
  items: [
    {
      value: "social",
      label: "Social Links",
      component: <SocialLinksEditor />,
      icon: <LiaUserFriendsSolid />,
    },
    {
      value: "links",
      label: "Links",

      contentListType: "LINK",
      icon: <PiLinkSimple />,
    },
  ],
};

let professional = {
  name: "Professional",
  items: [
    {
      value: "educations",
      label: "Education",
      contentListType: "EDUCATION",

      icon: <MdOutlineSchool />,
    },
    {
      value: "experiences",
      label: "Experience",
      contentListType: "EXPERIENCE",
      icon: <PiBuildings />,
    },
    {
      value: "certificates",
      label: "Certificates",
      contentListType: "CERTIFICATE",
      icon: <PiCertificate />,
    },
  ],
};

let projects = {
  name: "Projects",
  items: [
    {
      value: "coding-projects",
      label: "Coding Projects",
      contentListType: "PROJECT_PROGRAMMING",
      icon: <MdOutlineLightbulb />,
    },
    {
      value: "graphic-design",
      label: "Graphic Design",
      contentListType: "PROJECT_GRAPHIC_DESIGN",
      icon: <TbVectorTriangle />,
    },
    {
      value: "project-art",
      label: "Art",
      contentListType: "PROJECT_ART",
      icon: <PiPaintBrush />,
    },
    {
      value: "project-photography",
      label: "Photography",
      contentListType: "PROJECT_PHOTOGRAPHY",
      icon: <MdOutlinePhotoCamera />,
    },
  ],
};

let content = {
  name: "Content",
  items: [
    {
      value: "articles",
      label: "Articles",
      contentListType: "ARTICLE",
      icon: <GrArticle />,
    },
    {
      value: "List",
      label: "List",
      contentListType: "LIST",
      icon: <AiOutlineOrderedList />,
    },
    {
      value: "cards",
      label: "Cards",
      contentListType: "CARD",
      icon: <RxSection />,
    },
    {
      value: "services",
      label: "Freelance Services",
      contentListType: "SERVICE",
      icon: <TbBriefcase2 />,
    },
  ],
};

let extras = {
  name: "Extra Sections",
  items: [
    {
      value: "story",
      label: "Story",
      component: <LifeStoryEditor />,
      icon: <LuScroll />,
    },
    {
      value: "store",
      label: "Store",
      contentListType: "SALEABLE",
      icon: <MdOutlineLocalGroceryStore />,
    },
    {
      value: "videos",
      label: "Videos",
      contentListType: "VIDEO",
      icon: <AiOutlineVideoCamera />,
    },
  ],
};

export const siteEditorSectionList = [
  ...generalSection.items,
  ...content.items,
  ...projects.items,
  ...links.items,
  ...professional.items,
  ...extras.items,
];

export const siteEditorSectionGroups = [
  generalSection,
  projects,
  links,
  content,

  professional,

  // appearance,
  extras,
];
