import styled from "styled-components";
import ContentCardBoilerplate from "../cardForProfile/ContentCardBoilerplate";
import getImageURL from "../../controllers/getImageURL";
import getPublishedOrDraftContent from "../../controllers/getPublishedOrDraftContent";

const Thumbnail = styled.img`
  border-radius: 10px;
  /* max-height: 300px; */
  height: auto;
  width: 100%;
  object-fit: cover;
`;

export default function CardViewJustThumbnail({ item }) {
  let content = getPublishedOrDraftContent(item);

  return (
    <ContentCardBoilerplate item={item}>
      <Thumbnail src={getImageURL(content.image)} />
    </ContentCardBoilerplate>
  );
}
