import styled from "styled-components";
import getLocationString from "../../controllers/getLocationString";

const Description = styled.div`
  font-size: 20px;
  opacity: 0.8;
  line-height: 27px;
  width: 90%;
  /* display: flex; */
  /* flex-direction: column; */
  font-family: "Montserrat", sans-serif;

  @media (max-width: 900px) {
    text-align: center;
    font-size: 18px;
    margin-top: 0;
    margin-bottom: 0;
    width: 80vw;

    ${({ mobileStyle }) => {
      return mobileStyle;
    }}
  }
`;
const Bold = styled.span`
  font-weight: 600;
`;

export default function ProfilePageDescription({
  profileData,
  style,
  mobileStyle,
}) {
  let locationStr = getLocationString(profileData.profileMetaData);

  return (
    <Description style={style} mobileStyle={mobileStyle}>
      <Bold>{profileData.profileMetaData.tagline}</Bold>{" "}
      {profileData.profileMetaData.description}{" "}
      {locationStr ? locationStr : null}
    </Description>
  );
}
