import styled from "styled-components";
import goTo from "../../../controllers/goTo";
import BrandContainer from "../../brand/BrandContainer";
import CustomButton from "../../helperComponents/CustomButton";
import AskClassification from "../../onboarding/AskClassification";
import AskClassificationMain from "../../onboarding/AskClassificationMain";
import { AiOutlineArrowLeft } from "react-icons/ai";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100%;
  overflow-y: auto;
`;
const Top = styled.div``;
const Main = styled.div`
  width: 38vw;
  margin-top: 100px;
  display: flex;
  flex-direction: column;
  gap: 20px;

  @media (max-width: 900px) {
    width: 90vw;
  }
`;

export default function AskClassificationPage() {
  return (
    <Container>
      <Main>
        <Top>
          <CustomButton icon={<AiOutlineArrowLeft />} onClick={goTo(-1)}>
            Back
          </CustomButton>
        </Top>

        <br />

        <AskClassificationMain />
      </Main>
    </Container>
  );
}
