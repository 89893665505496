import DefaultTheme from "../components/profilePage/profileThemes/DefaultTheme";
import VerticalSquareTheme from "../components/profilePage/profileThemes/verticalThree/VerticalThreeTheme";
import VerticalOneTheme from "../components/profilePage/profileThemes/verticalOne/VerticalOneTheme";
import VerticalTwoTheme from "../components/profilePage/profileThemes/verticalTwo/VerticalTwoTheme";
import OneLineTheme from "../components/profilePage/profileThemes/one_line/OneLineTheme";
import VerticalFourTheme from "../components/profilePage/profileThemes/verticalFour/VerticalFourTheme";

let lightColors = {
  previewColor: "#fff",
  logoFilter: "invert(1)",
  bgColor: "#FFF5ED",
  bgColorDark: "#E6DCD4",
  bgColor2: "#ffffff",
  color: "#111111",
  headerTextColor: "#111111",
  translucentInteraction: "rgba(0,0,0,0.05)",
  translucentLight: "rgba(0,0,0,0.1)",
  translucent: "rgba(0,0,0,0.1)",
  accentColor: "#4caf50",
  translucentMedium: "rgba(0,0,0,0.1)",
  translucentHard: "rgba(0,0,0,0.2)",
  accentShadow: "rgb(0 0 0 / 19%)",
};

let darkColors = {
  previewColor: "#222222",
  logoFilter: "invert(0)",
  bgColor: "#000000",
  bgColorDark: "rgba(255,255,255,0.06)",
  bgColorLight: "#222222",
  bgColor2: "#000000",
  color: "#ffffff",
  headerTextColor: "#ffffff",
  accentColor: "#4caf50",
  translucentLight: "#222",
  translucentInteraction: "rgba(255,255,255,0.07)",
  translucent: "rgb(212 226 227 / 10%)",
  translucentMedium: "rgba(255,255,255,0.1)",
  translucentHard: "rgba(255,255,255,0.2)",
  accentShadow: "rgb(0 0 0 / 9%)",
};

let babyBlue = {
  ...lightColors,
  previewColor: "rgb(155 170 203 / 100%)",
  bgColorDark: "rgb(155 170 203 / 84%)",
  bgColor: "#DCE2F1",
  color: "#0c1b44",
  headerTextColor: "#0c1b44",
  translucentInteraction: "rgb(141 158 194 / 27%)",
  translucentLight: "rgb(172 186 215 / 68%)",
  translucent: "rgb(172 186 215 / 80%)",
  translucentHard: "rgb(106 124 161 / 88%)",
  accentShadow: "rgb(106 124 161 / 58%)",
};
let pink = {
  ...lightColors,
  previewColor: "pink",
  bgColorDark: "#e8c3b6",
  headerTextColor: "#4d1e0e",
  color: "#4d1e0e",
  translucentInteraction: "rgb(232 194 182 / 40%)",
  translucentLight: "rgb(216 125 96 / 22%)",
  translucent: "rgb(216 125 96 / 32%)",
  translucentHard: "rgb(185 153 143 / 85%)",
  bgColor: "#ffffff",
  accentShadow: "rgb(185 153 143 / 85%)",
};

let blackAndWhite = {
  ...lightColors,
  logoFilter: "invert(0)",
  headerTextColor: "#ffffff",
  bgColorDark: "#111111",
  bgColor: "#ffffff",
};

let coralBlue = {
  ...lightColors,
  logoFilter: "invert(0)",
  headerTextColor: "#ffffff",
  bgColorDark: "#062223",
  bgColor: "#E9F6F6",
};

let neon = {
  ...darkColors,
  previewColor: "linear-gradient(#7e00ff, #1c1c98)",
  // bgColorDark: "rgb(0 0 0 / 32%)",
  bgColorDark: "rgb(255 255 255 / 04%)",
  bgImage: "neon_background.jpg",

  accentShadow: "rgb(0 0 0 / 38%)",
};

let lightGradient = {
  ...lightColors,
  bgColorDark: "rgb(0 0 0 / 9%)",
  previewColor: "linear-gradient(pink, #b03636)",
  bgImage: "light_gradient.jpg",
  accentShadow: "rgb(0 0 0 / 39%)",
  // color: "#15315c",
  // headerTextColor: "#15315c",
};

let paperBackground = {
  ...lightColors,
  bgColorDark: "rgb(0 0 0 / 9%)",
  previewColor: "linear-gradient(pink, #b03636)",
  previewImage: "/color_previews/paper_color_preview.jpg",
  bgImage: "paper_background.jpg",
  accentShadow: "rgb(0 0 0 / 23%)",
};

let colorVariations = {
  DARK: darkColors,
  LIGHT: lightColors,
  PINK: pink,
  BABY_BLUE: babyBlue,
  NEON: neon,
  LIGHT_GRADIENT: lightGradient,
  PAPER: paperBackground,
};

let themeList = [
  {
    name: "Simple",
    _id: "SIMPLE",
    previewImage: "one.png",
    component: DefaultTheme,
    colorVariation: "LIGHT",
  },
  {
    name: "Vertical One",
    _id: "VERTICAL_ONE",
    previewImage: "two.png",
    component: VerticalOneTheme,
    colorVariation: "BABY_BLUE",
  },

  // {
  //   name: "Vertical Two",
  //   _id: "VERTICAL_TWO",
  //   previewImage: "vertical_two.jpg",
  //   component: VerticalTwoTheme,
  //   cardStyle: "LINES",
  // },
  {
    name: "Vertical Three",
    _id: "VERTICAL_THREE",
    previewImage: "three.png",
    component: VerticalSquareTheme,
    colorVariation: "NEON",
    // cardStyle: "LINES",
  },
  {
    name: "Vertical Four",
    _id: "VERTICAL_FOUR",
    previewImage: "four.png",
    component: VerticalFourTheme,
    cardStyle: "LINES",
    colorVariation: "DARK",
  },
  // {
  //   name: "One Line",
  //   _id: "ONE_LINE",
  //   previewImage: "one_line.jpg",
  //   component: OneLineTheme,
  //   cardStyle: "LINES",
  // },
];

let themeIdVsThemeData = {};

for (let item of themeList) {
  themeIdVsThemeData[item._id] = item;
}

export { themeList, themeIdVsThemeData, colorVariations };
