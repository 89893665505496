import { useState } from "react";
import styled from "styled-components";
import selectFile from "../../controllers/selectFile";
import compressAndUploadFile from "../../controllers/compressAndUploadFile";
import LoadingSection from "../helperComponents/LoadingSection";
import getImageURL from "../../controllers/getImageURL";

const Row = styled.div`
  background-color: var(--translucentInteraction);
  border-radius: 10px;
  display: flex;
  flex-direction: row;
  padding: 5px;
  gap: 10px;
`;
const ImagePreview = styled.img`
  height: 100px;
  width: 100px;
  border-radius: 5px;
  object-fit: cover;
`;
const Column = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
`;
const Button = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 45px;
  background-color: var(--translucentInteraction);
  border-radius: 10px;
  cursor: pointer;
  &:hover {
    background-color: var(--translucent);
  }
`;

export default function CustomListImageUploader({
  state,
  onChange,
  variation,
}) {
  const [uploading, setUploading] = useState(false);

  if (!variation) variation = "ONE";
  let unsupportedVariation = ["ZERO", "ONE", "TWO"];
  if (unsupportedVariation.includes(variation)) return null;

  if (uploading)
    return (
      <Row>
        <LoadingSection />
      </Row>
    );

  return (
    <Row>
      <ImagePreview src={getImageURL(state.draftState.image)} />

      <Column>
        <Button onClick={uploadImage}> Upload Image </Button>
        <Button onClick={hideOrShowImage}> {getHideImageText()} </Button>
      </Column>
    </Row>
  );

  function getHideImageText() {
    let newState = { ...state };

    if (!newState.draftState) newState.draftState = {};

    if (newState.draftState.hideThumbnail) {
      return "Show thumbnail";
    } else {
      return "Hide thumbnail";
    }
  }

  async function uploadImage() {
    let selectedFiles = await selectFile({ onlyImage: true });

    if (!selectedFiles) return null;
    if (!selectedFiles[0]) return null;

    setUploading(true);

    let fileData = await compressAndUploadFile(null, selectedFiles[0]);

    let newState = { ...state };

    if (!newState.draftState) newState.draftState = {};

    newState.draftState.image = {
      type: "USER_UPLOAD",
      data: fileData.fileName,
    };

    onChange(newState);

    setUploading(false);
  }

  function hideOrShowImage() {
    let newState = { ...state };

    if (!newState.draftState) newState.draftState = {};

    if (newState.draftState.hideThumbnail) {
      newState.draftState.hideThumbnail = false;
    } else {
      newState.draftState.hideThumbnail = true;
    }

    onChange(newState);
  }
}
