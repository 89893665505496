import { styled } from "styled-components";
import { useContext, useEffect, useRef, useState } from "react";

import selectFile from "../../controllers/selectFile.js";
import compressAndUploadFile from "../../controllers/compressAndUploadFile.js";
import { serverLine } from "../../controllers/serverLine.js";

import Context from "../../Context.js";

import { AiOutlineCheck, AiOutlineUpload } from "react-icons/ai/index.js";
import CustomToggle from "../helperComponents/CustomToggle.js";
import ProfilePicture from "../helperComponents/ProfilePicture.js";
import ShowSelectedImage from "../helperComponents/ShowSelectedImage.js";
import DropDownLocationInput from "../helperComponents/DropDownLocationInput.js";
import MaterialInput from "../helperComponents/MaterialInput.js";
import CustomButton from "../helperComponents/CustomButton.js";
import ProfileTagsEditorParent from "./ProfileTagsEditorParent.js";
import LoadingSection from "../helperComponents/LoadingSection.js";
import sendPostMessage from "../../controllers/sendPostMessage.js";
import onChangeStop from "../../controllers/onChangeStop.js";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 0;
  width: 100%;

  gap: 50px;
  align-items: center;
`;

const Inputs = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  width: 100%;
  gap: 10px;
  justify-content: center;

  @media (max-width: 900px) {
    width: 90vw;
  }
`;

const Button = styled.div`
  width: 100%;
  font-size: 18px;
  border: 1px solid var(--translucent);
  background: var(--translucent);
  border-radius: 15px;
  color: var(--color);
  text-align: center;
  cursor: pointer;
  padding: 20px 15px;
`;

const Textarea = styled.textarea`
  font-size: 18px;
  border: none;
  border-bottom: 1px solid var(--translucentHard);
  background: transparent;
  border-radius: 0;
  color: var(--color);
  /* text-align: center; */
  width: 100%;
  font-family: "Inter";
  resize: none;

  padding: 15px 0;

  &::placeholder {
    color: var(--translucentHard);
  }
`;

const ImageUploadSection = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  gap: 25px;
  /* width: 100%; */
  justify-content: center;
  align-items: center;
`;

const ImageUploadButton = styled(Button)`
  /* width: 300px; */
  position: absolute;
  right: 5px;
  bottom: 5px;
  width: 50px;
  background: var(--bgColor);
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100px;
  height: 50px;
  transition: 0.25s ease-in-out;

  &:hover {
    transform: scale(1.3);
  }
`;

export default function BasicInfoEditor({ callback }) {
  const { profileMetaData, setProfileMetaData, popupAlert } =
    useContext(Context);
  const [config, setConfig] = useState(null);

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!config && profileMetaData) {
      let newState = {};
      newState = { ...profileMetaData };
      setConfig(newState);
    }
  }, [profileMetaData]);

  if (!profileMetaData || !config) return <LoadingSection />;

  let profilePicObj = (
    <ProfilePicture
      imageObj={config.profileImage}
      height="200px"
      width="200px"
    />
  );

  return (
    <Container>
      {loading ? <LoadingSection /> : null}
      <ImageUploadSection>
        {profilePicObj}
        <ImageUploadButton onClick={chooseImage}>
          <AiOutlineUpload />
        </ImageUploadButton>
      </ImageUploadSection>
      <Inputs>
        <MaterialInput
          value={config.name}
          onChange={makeChange("name")}
          label={"Type your name here"}
        />

        <MaterialInput
          value={config.tagline}
          multiline={true}
          onChange={makeChange("tagline")}
          label={"Tagline"}
        />

        <MaterialInput
          value={config.description}
          multiline={true}
          onChange={makeChange("description")}
          label={"Description. Describe yourself in one paragraph."}
        />

        <DropDownLocationInput
          onChange={makeChange("country", { isNotEvent: true })}
          type="COUNTRY"
          placeholder={"Select Country"}
          value={config.country}
        />

        <DropDownLocationInput
          onChange={makeChange("state", { isNotEvent: true })}
          type="STATE"
          country={config.country}
          placeholder={"Select State"}
          value={config.state}
        />

        <DropDownLocationInput
          onChange={makeChange("city", { isNotEvent: true })}
          type="CITY"
          country={config.country}
          state={config.state}
          placeholder={"Select City"}
          value={config.city}
        />

        <CustomToggle
          // name="Gender"
          toggleStyle={{ width: "100%" }}
          options={[
            { label: "Male", value: "MALE" },
            { label: "Female", value: "FEMALE" },
          ]}
          value={config.gender}
          onChange={makeChange("gender", { isNotEvent: true })}
        />

        <ProfileTagsEditorParent
          value={config.skillTags}
          onChange={makeChange("skillTags", { isNotEvent: true })}
        />
      </Inputs>
      {/* <br /> */}
      {/* <CustomButton
        style={{
          width: "100%",
        }}
        icon={<AiOutlineCheck />}
        onClick={() => {
          save(null);
        }}
      >
        Save
      </CustomButton> */}
      <br /> <br />
    </Container>
  );

  function makeChange(fieldName, isString) {
    return (e) => {
      if (isString) {
        let newE = { target: { value: e } };
        e = newE;
      }

      let newItem = { ...config };
      newItem[fieldName] = e.target.value;
      setConfig(newItem);

      onChangeStop(
        "basicInfoEditor",
        () => {
          console.log("changeStopped", newItem);
          save(newItem);
        },
        1000
      );
    };
  }

  async function chooseImage() {
    let selectedImages = await selectFile({ onlyImage: true });

    if (!selectedImages) return null;
    if (!selectedImages.length) return null;
    setLoading(true);
    console.log("Uploading Image");
    let fileData = await compressAndUploadFile(null, selectedImages[0]);

    if (fileData) {
      if (fileData.fileName) {
        let newConfig = { ...config };
        newConfig.profileImage = {
          type: "USER_UPLOAD",
          data: fileData.fileName,
        };

        setConfig(newConfig);
        save(newConfig);
      }
    }

    setLoading(false);
  }

  async function save(theState) {
    if (!theState) theState = config;

    let newConfig = { ...theState };

    try {
      let newData = { ...profileMetaData, ...newConfig };
      sendPostMessage({ profileMetaData: { draftState: newData } });
      await serverLine.patch("/my-profile-meta", { changes: { ...theState } });
      setProfileMetaData(newData);
      // popupAlert("Saved!");
    } catch (e) {
      popupAlert(e.message);
    }
  }
}
