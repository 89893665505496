import styled from "styled-components";
import goToProfile from "../../controllers/goToProfile";
import getImageURL from "../../controllers/getImageURL";
import LikeDislikeButtons from "../contentPage/LikeDislikeButtons";
import SemanticButton from "./SemanticButton";
import getProfileLink from "../../controllers/getProfileLink";

const User = styled.a`
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: var(--translucent);
  border-radius: 100px;
  gap: 10px;
  padding: 0 20px;
  overflow: hidden;
  height: 45px;

  cursor: pointer;

  opacity: 0.5;
  font-size: 15px;
  text-transform: capitalize;

  &:hover {
    background-color: var(--translucentHard);
  }

  @media (max-width: 900px) {
    font-size: 10px;
    height: 35px;
  }
`;
const Container = styled.div`
  display: flex;
  flex-direction: row;
  /* margin: 50px 0; */
  justify-content: space-between;
  /* gap: 15px; */
  font-size: 18px;
  align-items: center;
`;
const Image = styled.img`
  height: 45px;
  width: 45px;
  border-radius: 50px;
  object-fit: cover;
`;

export default function FeedActionButtons({ item }) {
  let { author, likeStatus, likeCount, dislikeCount } = item;

  let contentID = item._id;
  return (
    <Container>
      <SemanticButton
        semanticHref={getProfileLink(author.username)}
        onClick={goToProfile(author.username)}
      >
        <User>{author.username}</User>
      </SemanticButton>

      <LikeDislikeButtons
        type="CONTENT"
        isMinimal={true}
        likeStatus={likeStatus}
        likeCount={likeCount}
        dislikeCount={dislikeCount}
        contentID={contentID}
      />
    </Container>
  );
}
