import CustomButton from "../../components/helperComponents/CustomButton";
import { AiOutlineDelete, AiOutlineSave, AiOutlineUp } from "react-icons/ai";
import { FiExternalLink, FiMinimize2 } from "react-icons/fi";

export default function getContentItemButtons({
  initialState,
  addItem,
  onSave,
  onDelete,
  index,
}) {
  let buttons = [];

  let buttonType = "outlined-filled";

  if (!initialState) {
    buttons = (
      <CustomButton
        onClick={addItem}
        // variant={buttonType}
        style={{ width: "100%", borderRadius: "5px" }}
      >
        Add
      </CustomButton>
    );
  } else {
    buttons = (
      <>
        {onDelete ? (
          <CustomButton
            icon={<AiOutlineDelete />}
            onClick={onDelete(index)}
            variant={buttonType}
          />
        ) : null}

        {/* <CustomButton
          icon={<AiOutlineSave />}
          style={{ flex: "1" }}
          onClick={() => {
            onSave(null);
            //can't call it directly otherwise state will become event object.
          }}
          variant={buttonType}
        >
          Save
        </CustomButton> */}
      </>
    );
  }

  return buttons;
}
