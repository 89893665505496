import { styled } from "styled-components";
import { useContext } from "react";

import Context from "../../Context.js";
import CustomToggle from "../helperComponents/CustomToggle.js";
import DropDownLocationInput from "../helperComponents/DropDownLocationInput.js";
import DropDownInput from "../helperComponents/DropDownInput.js";

const Container = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 25px;
  /* padding: 20px; */
`;

const Title = styled.div`
  font-size: 18px;
  font-weight: 500;
  opacity: 0.5;
  text-transform: capitalize;
`;
const TagList = styled.div`
  display: flex;
  flex-direction: row;
  row-gap: 25px;
  flex-wrap: wrap;
  gap: 25px;
`;
const SelectedTagsLabel = styled.div``;

const SearchResult = styled.div`
  display: flex;
  flex-direction: row;
  row-gap: 25px;
  flex-wrap: wrap;
  gap: 25px;
`;

let programmingTags = [
  // "backend",
  // "frontend",
  // "fullstack",
  // "app-development",
  // "game-development",
  "javascript",
  "flutter",
  "kotlin",
  "react-native",
  "react.js",
  "typescript",
  "webpack",
  "node.js",
  "mongoDB",
  "firebase",
  "mySQL",
  "postgreSQL",
  "python",
  "tensorflow",
  "pytorch",
  "flask",
  "django",
  "c#",
  "unity",
  "AWS",
  "EC2",
  "GCP",
  "Azure",
  "S3",
  "blender",
  // "maya",
  // "3dsMax",
  // "cinema4D",
];

let artTags = [
  "afterAffects",
  "figma",
  "illustrator",
  "photoshop",
  "blender",
  "maya",
  "3dsMax",
  "cinema4D",
];

let literatureTags = [
  "poem-writing",
  "short-story",
  "screenwriting",
  "song-writing",
  "journalism",
  "copywriting",
];

const SubHeading = styled.div`
  font-size: 18px;
  opacity: 0.7;
  margin-top: -10px;
`;

let classification = [
  { label: "Programming", value: "programming" },
  { label: "Art", value: "art" },
  { label: "Graphic Design", value: "graphic-design" },
  { label: "Photography", value: "photography" },
  { label: "Literature", value: "literature" },
  { label: "Video Editing", value: "video-editing" },
  { label: "Filmmaking", value: "filmmaking" },
  { label: "Animation", value: "animation" },
];

/*
  Video and tutorials are not here because they are not a classification. Just a type

*/

export default function ClassificationEditor({ value, onChange }) {
  const { loggedInUser } = useContext(Context);

  return (
    <DropDownInput
      onChange={onChange}
      options={classification}
      value={value}
      placeholder="Select Category"
      title="Category"
    />
  );

  // return (
  //   <Container>
  //     <Title>Select Category</Title>
  //     <CustomToggle
  //       // variant="fluid"
  //       value={value}
  //       onChange={onChange}
  //       options={classification}
  //     />
  //   </Container>
  // );
}
