import { styled } from "styled-components";

import { serverLine } from "../../../controllers/serverLine";
import { useContext, useEffect, useState } from "react";
import WithHeader from "../WithHeader";

import Context from "../../../Context";

import getSubPath from "../../../controllers/getSubPath";
import RenderContent from "../../contentPage/RenderContent";
import LoadingSection from "../../helperComponents/LoadingSection";
import WithProfileHeader from "../../profilePage/WithProfileHeader";
import RenderTheme from "../../profilePage/RenderTheme";
import getPublishedOrDraftMetaData from "../../../controllers/getPublishedOrDraftMetaData";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  /* gap: 50px; */
  /* overflow-y: scroll; */
  width: 100%;
  height: 100%;
`;

const Main = styled.div`
  width: 62vw;
  padding-top: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: 900px) {
    width: 100%;
    padding-top: 20px;
  }
`;

export default function ContentPage() {
  const { updateGlobalLikeStatus, nonPopupRoute } = useContext(Context);
  const [loading, setLoading] = useState(true);
  const [postData, setPostData] = useState(null);

  useEffect(() => {
    let contentID = getSubPath(1, nonPopupRoute);
    serverLine.get("/content/?contentID=" + contentID).then((newData) => {
      updateGlobalLikeStatus([newData]);
      setPostData(newData);
      setLoading(false);
    });
  }, [nonPopupRoute]);

  if (!postData || loading) return <LoadingSection />;

  let contentPageData = (
    <Container>
      <Main>
        <RenderContent setPostData={setPostData} postData={postData} />
        <br /> <br /> <br /> <br /> <br /> <br />
      </Main>
    </Container>
  );

  return (
    <RenderTheme
      contentPageData={contentPageData}
      profileData={{
        user: postData.author,
        profileMetaData: getPublishedOrDraftMetaData(postData.authorMetaData),
      }}
    ></RenderTheme>
  );
}
