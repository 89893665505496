import { useState } from "react";
import styled from "styled-components";
import ProfileSectionBlank from "./ProfileSectionBlank";
import CustomButton from "../helperComponents/CustomButton";
import capitalizeFirstLetter from "../../controllers/capitalizeFirstLetter";

const Container = styled.div``;
const TopHeader = styled.div`
  display: flex;
  flex-direction: row;
  height: 100px;
  justify-content: space-between;
  align-items: center;
  padding: 0 100px;
  border-bottom: 1px solid var(--translucent);
`;

const Name = styled.div`
  font-size: 25px;
  font-weight: 900;
`;

const Links = styled.div`
  display: flex;
  flex-direction: row;
  gap: 40px;
  align-items: center;
`;

const Link = styled.div``;

const FollowButton = styled.div`
  border: 1px solid var(--translucentHard);
  padding: 10px 25px;
  border-radius: 25px;
  cursor: pointer;
`;

const MainContent = styled.div`
  padding: 100px;
  display: flex;
  flex-direction: column;
  gap: 100px;
`;

const HeroSection = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 0;
`;

const HeroLeft = styled.div`
  display: flex;
  flex-direction: column;
  gap: 25px;
`;

const HeroTitle = styled.div`
  font-size: 35px;
  font-weight: 900;
`;

const HeroDescription = styled.div`
  font-size: 18px;
  margin-top: -15px;
  width: 600px;
  opacity: 0.7;
  line-height: 30px;
`;

const HeroRight = styled.div``;

const Section = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const SectionTitle = styled.div`
  font-size: 22px;
  opacity: 0.5;
`;

const Button = styled.div`
  display: flex;
  flex-direction: row;
  padding: 15px 25px;
  gap: 15px;
  border-radius: 25px;
  cursor: pointer;
  border: 1px solid var(--translucentHard);
  align-items: center;
`;
const ButtonIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 22px;
`;

const RoundButton = styled(Button)`
  padding: 0;
  border: none;
`;
const ButtonText = styled.div``;
const HeroButtons = styled.div`
  display: flex;
  flex-direction: row;
  gap: 25px;
  align-items: center;
`;

const IconButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 23px;
  height: 40px;
  width: 40px;
  border-radius: 5px;
  cursor: pointer;

  &:hover {
    background-color: var(--translucentHard);
  }
`;

const NothingToShow = styled.div``;

const NoMasonry = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  flex-wrap: wrap;
  gap: 10px;
  /* justify-content: space-between; */
`;

const Skill = styled.div`
  pointer-events: none;
  border-radius: 10px;
  opacity: 0.7;
  background: var(--translucentInteraction);
  padding: 10px 15px;

  ${({ theme }) => {
    if (theme.cardStyle === "LINES") {
      return `
        border: 1px solid var(--translucentHard);
        background-color: transparent;
        backdrop-filter:  blur(5px);
      `;
    }
  }}
`;

export default function ProfileSkills({ id, skillTags }) {
  let defaultView = null;

  if (!skillTags) return null;

  let list = renderSkillTags();

  if (!list) return defaultView;

  if (!list.length) return defaultView;

  return (
    <ProfileSectionBlank id={id} label={"Skills"}>
      <NoMasonry>{list}</NoMasonry>
    </ProfileSectionBlank>
  );

  function renderSkillTags() {
    return skillTags.map((item) => (
      <Skill>{capitalizeFirstLetter(item)}</Skill>
    ));
  }
}
