import styled from "styled-components";

import { useContext } from "react";
import Context from "../../Context.js";
import ProfilePageHeader from "./ProfilePageHeader.js";
import RegisterView from "../contentPage/RegisterView.js";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  width: 100vw;
  height: 100vh;
  overflow-y: auto;

  /* padding-bottom: 50px; */

  @media (max-width: 900px) {
    flex-direction: column;
    overflow-y: scroll;
    height: 100vh;
  }
`;

const Main = styled.div`
  padding: 0;
  padding-top: 0;
  display: flex;
  flex-direction: column;
  width: 62vw;
  height: auto;

  gap: 50px;

  padding-bottom: 100px;

  justify-content: flex-start;

  align-items: center;

  @media (max-width: 900px) {
    width: 90vw;
    overflow: unset;
    height: unset;
  }

  ${({ isInsideExtension }) => {
    if (isInsideExtension) return `zoom:90%;`;
  }}
`;

export default function WithProfileHeaderOneColumn({
  children,
  profileData,
  Header,
}) {
  const { loggedInUserID, colorMode } = useContext(Context);

  if (!profileData) return "User not found";

  return (
    <Container id="profileRoot">
      <Header profileData={profileData} />
      <Main>{children}</Main>
      <RegisterView subjectID={profileData.user._id} subjectType="PROFILE" />
    </Container>
  );
}
