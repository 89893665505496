import { styled } from "styled-components";
import { useContext, useEffect, useState } from "react";
import Context from "../../../Context.js";
import { serverLine } from "../../../controllers/serverLine.js";
import LoadingSection from "../../helperComponents/LoadingSection.js";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import DragAndDropItem from "../DragAndDropItem.js";
import CustomListItemEditor from "./CustomListItemEditor.js";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  /* background-color: var(--translucent); */
  padding: 0;
  width: 100%;
  gap: 50px;
  margin-top: 40px;
  /* padding-top: 20px; */
  /* border-top: 1px solid var(--translucentHard); */
`;

const Items = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  /* gap: 30px; */
`;

const DraggableItem = styled.div`
  width: 100%;
  margin-bottom: 30px;
`;

const List = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const Title = styled.div`
  font-size: 18px;
  opacity: 0.5;
  /* font-weight: 900; */
`;

const TitleSection = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
`;

const Divider = styled.div`
  height: 1px;
  flex: 1;

  background-color: var(--translucentHard);
`;

export default function CustomListDND({
  data,
  onChange,
  moveItem,
  onAdd,
  type,
  onDelete,
  variation,
}) {
  const { loggedInUser } = useContext(Context);

  if (!loggedInUser) return <LoadingSection />;

  return (
    <Container>
      <List>
        <TitleSection>
          <Title>Add List Item</Title>
          <Divider />
        </TitleSection>
        <CustomListItemEditor type={type} onAdd={onAdd} adder={true} />
      </List>

      <List>
        <TitleSection>
          <Title>List Items</Title>
          <Divider />
        </TitleSection>
        {renderItems()}
      </List>
    </Container>
  );

  function renderItems() {
    let items = [];

    for (let i = 0; i < data.length; i++) {
      let theItem = data[i];

      items.push({
        comp: (
          <CustomListItemEditor
            variation={variation}
            onDelete={onDelete(i)}
            type={type}
            data={theItem}
            onChange={onChange}
          />
        ),
        itemData: theItem,
      });
    }

    let draggableItems = [];

    let index = 0;
    for (let item of items) {
      draggableItems.push(
        <Draggable
          key={item.itemData._id}
          draggableId={item.itemData._id}
          index={index}
        >
          {(provided) => {
            return (
              <div>
                <DraggableItem
                  ref={provided.innerRef}
                  {...provided.draggableProps}
                  {...provided.dragHandleProps}
                >
                  {item.comp}
                </DraggableItem>
                {provided.placeholder}
              </div>
            );
          }}
        </Draggable>
      );

      index++;
    }

    return (
      <DragDropContext onDragEnd={handleOnDragEnd}>
        <Droppable droppableId="droppable">
          {(provided) => (
            <Items {...provided.droppableProps} ref={provided.innerRef}>
              {draggableItems}
              {provided.placeholder}
            </Items>
          )}
        </Droppable>
      </DragDropContext>
    );
  }

  function handleOnDragEnd(result) {
    console.log(result);
    if (result.destination) {
      moveItem(result.source.index, result.destination.index);
    }
  }
}
