import styled from "styled-components";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
`;

const Label = styled.div`
  opacity: 0.5;
  font-size: 15px;
`;

export default function SectionAndLabel({ children, label }) {
  return (
    <Container>
      <Label>{label}</Label>

      {children}
    </Container>
  );
}
