import { useEffect, useState } from "react";
import { colorVariations, themeIdVsThemeData } from "../../data/themeList";
import DefaultTheme from "./profileThemes/DefaultTheme";
import styled, { ThemeProvider } from "styled-components";

let ThemeBackground = styled.div`
  background-image: var(--bgImage);
  background-color: var(--bgColor);
  background-size: cover;
`;

let defaultThemeID = "SIMPLE";
let defaultColorVariation = "DARK";

export default function RenderTheme({ profileData, contentPageData }) {
  let theme = themeIdVsThemeData[defaultThemeID];

  let profileMetaData = profileData.profileMetaData;

  if (profileMetaData.themeID)
    theme = themeIdVsThemeData[profileMetaData.themeID];

  useEffect(() => {
    if (profileMetaData.themeColorID) {
      let colors = colorVariations[profileMetaData.themeColorID];
      setColors(colors);
    } else {
      let colors = colorVariations[defaultColorVariation];
      setColors(colors);
    }
  }, [profileData]);

  let toReturn = null;
  if (!profileMetaData.themeID) {
    toReturn = (
      <DefaultTheme
        profileData={profileData}
        contentPageData={contentPageData}
      />
    );
  } else {
    let ThemeComp = theme.component;

    toReturn = (
      <ThemeComp profileData={profileData} contentPageData={contentPageData} />
    );
  }

  return (
    <ThemeProvider theme={theme}>
      <ThemeBackground>{toReturn}</ThemeBackground>
    </ThemeProvider>
  );
}

function setColors(colors) {
  document.documentElement.style.setProperty("--logoFilter", colors.logoFilter);

  document.documentElement.style.setProperty(
    "--accentShadow",
    colors.accentShadow
  );

  document.documentElement.style.setProperty(
    "--bgImage",
    `url("backgrounds/${colors.bgImage}")`
  );

  document.documentElement.style.setProperty(
    "--headerTextColor",
    colors.headerTextColor
  );

  document.documentElement.style.setProperty(
    "--translucentInteraction",
    colors.translucentInteraction
  );

  document.documentElement.style.setProperty(
    "--translucentMedium",
    colors.translucentMedium
  );

  document.documentElement.style.setProperty(
    "--accentColor",
    colors.accentColor
  );

  document.documentElement.style.setProperty(
    "--bgColorDark",
    colors.bgColorDark
  );

  document.documentElement.style.setProperty(
    "--translucentLight",
    colors.translucentLight
  );

  document.documentElement.style.setProperty("--bgColor", colors.bgColor);
  document.documentElement.style.setProperty(
    "--bgColorLight",
    colors.bgColorLight
  );
  document.documentElement.style.setProperty("--bgColor2", colors.bgColor2);
  document.documentElement.style.setProperty("--color", colors.color);
  document.documentElement.style.setProperty(
    "--translucent",
    colors.translucent
  );
  document.documentElement.style.setProperty(
    "--translucentHard",
    colors.translucentHard
  );
}
