import { styled } from "styled-components";
import BrandContainer from "../brand/BrandContainer";
import AskClassificationMain from "./AskClassificationMain";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 50px 0;
  gap: 100px;
  align-items: center;
`;

const Main = styled.div`
  display: flex;
  flex-direction: column;
  gap: 25px;
`;

const Tagline = styled.h1``;

const Options = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap: 25px;
`;

export default function AskClassification() {
  return (
    <Container>
      <BrandContainer />

      <AskClassificationMain />
    </Container>
  );
}
